import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { SolidityContractDetails } from "../../../api/data-schema/SolidityContractDetails";

export function normalizeContracts(
    rawData: {
        data: {
            allContracts: {
                edges: {
                    node: {
                        id: string;
                        address: string;
                        application: SupportedApplication;
                        adminWallet?: string;
                        contractName: string;
                        symbol: string;
                        blockchainId?: number;
                        network?: {
                            chainId: number;
                        };
                        version: SolidityContractDetails["version"];
                        abi: string;
                    };
                }[];
            };
        };
    } | null,
): SolidityContractDetails[] {
    return (
        rawData?.data?.allContracts?.edges?.map(({ node }) => {
            return {
                id: node.id,
                address: node.address,
                adminWallet: node?.adminWallet,
                abi: JSON.parse(node.abi),
                name: node.contractName,
                symbol: node.symbol,
                application: node.application,
                version: node.version,
                blockchainId: Number(
                    node.network?.chainId || node.blockchainId,
                ),
            };
        }) || []
    );
}
