import { openDB } from "idb";
import { ReadyToMintTokenData } from "./data-schema";

const mintTokenDataStore = "mint-token-data";
export async function getDB(store: string) {
    try {
        if (typeof globalThis.indexedDB !== "undefined") {
            return await openDB(store, 1, {
                upgrade(db) {
                    db.createObjectStore(store);
                },
            });
        }
    } catch (e) {
        console.error(e);
    }

    return undefined;
}

export async function saveMintTokenDataToLocalStorage(
    tokenURI: string,
    payload: ReadyToMintTokenData,
) {
    try {
        const db = await getDB(mintTokenDataStore);
        if (db) {
            await db.put(mintTokenDataStore, payload, tokenURI);
        }
    } catch (e) {
        console.error(e);
    }
}
export async function removeMintTokenDataFromLocalStorage(tokenURI: string) {
    try {
        const db = await getDB(mintTokenDataStore);
        if (db) {
            await db.delete(mintTokenDataStore, tokenURI);
        }
    } catch (e) {
        console.error(e);
    }
}
export async function getMintTokenDataFromLocalStorage() {
    try {
        const db = await getDB(mintTokenDataStore);
        if (db) {
            const keys = await db.getAllKeys(mintTokenDataStore);
            return await Promise.all(
                keys.map(async (el) => db.get(mintTokenDataStore, el)),
            );
        }
    } catch (e) {
        console.error(e);
    }
    return [];
}
