import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import memoize from "lodash/memoize";
import { useHasProvider } from "~/features/web3Provider/useHasProvider";
import { authenticateWithMetamask } from "~/api/choreography";
import { useUserAccount } from "../useUserAccount";

const loadNftClientModule = memoize(
    async () => {
        const { isMobile } = await import("@liveart/nft-client/dist/isMobile");

        return { isMobile };
    },
    () => true,
);

export function ConnectWalletBtn() {
    const hasProvider = useHasProvider();
    const userAccount = useUserAccount();

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        loadNftClientModule().then(({ isMobile: detectIsMobile }) => {
            setIsMobile(detectIsMobile());
        });
    }, []);

    const dispatch = useDispatch();
    const onClick = useCallback(() => {
        dispatch(authenticateWithMetamask());
    }, [dispatch]);

    if (userAccount) {
        return null;
    }
    if (!hasProvider && !isMobile) {
        return null;
    }
    return (
        <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={onClick}
            data-target="connect-your-wallet"
        >
            Connect Your Wallet
        </Button>
    );
}
