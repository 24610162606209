import { CognitoUser } from "../data-schema/CognitoUser";

export function normalizeUsers(
    rawData: {
        edges: {
            node: {
                recordCreated: string;
                avatarImageUrl?: string;
                email: string;
                nickname?: string;
                biography?: string;
                fullName?: string;
                firstName: string;
                lastName: string;
                id: string;
                externalId: string;
                stripeCustomerId?: string;
                wallets?: {
                    edges: {
                        node: {
                            wallet: string;
                            issuedBy?: string;
                        };
                    }[];
                };
                authUsersWallets?: {
                    edges: {
                        node: {
                            issuedBy;
                            wallet;
                        };
                    }[];
                };
                socialLinks?: {
                    edges: {
                        node: {
                            link: string;
                        };
                    }[];
                };
            };
        }[];
    } | null,
): CognitoUser[] {
    return (rawData?.edges?.map(({ node }) => {
        const stytchWallets = node?.authUsersWallets?.edges?.map(
            ({ node: walletNode }) => ({
                ...(walletNode || {}),
            }),
        );
        const cognitoWallets = node?.wallets?.edges?.map(
            ({ node: walletNode }) => ({
                ...(walletNode || {}),
                ...(stytchWallets?.length ? { issuedBy: "LiveArt" } : {}),
            }),
        );
        return {
            ...(node || {}),
            wallets: cognitoWallets?.concat(stytchWallets || []),
            socialLinks: node?.socialLinks?.edges?.map(
                ({ node: socialLinkNode }) => socialLinkNode?.link,
            ),
        };
    }) || []) as CognitoUser[];
}
