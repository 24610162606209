import { createAction } from "@reduxjs/toolkit";
import { OpenEdition } from "~/api/data-schema/OpenEdition";
import { CognitoUser, Token } from "~/api/data-schema";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export const loadPOAP = createAction<{
    networkId: number;
    tokenContractAddress: string;
    parentTokenId: number;
    wallets: string[];
}>("OPEN_EDITION/LOAD_POAP");
export const loadPOAPResponse = createAction<{
    edition: OpenEdition;
    editionsMinted: number;
    claimedToken?: Token;
    deliveredToken?: Token;
}>("OPEN_EDITION/LOAD_POAP_RESPONSE");
export const claimPOAP = createAction<{
    edition: OpenEdition;
    wallet: string;
    user: CognitoUser;
    token: Token;
}>("OPEN_EDITION/CLAIM_POAP");

export const airdropTokens = createAction<{
    selectedContractDetails: SolidityContractDetails;
    editionId: string;
    wallets: string[];
    quantityPerRecipient: number;
    batchSize: number;
}>("API/TOKEN/AIRDROP_TOKENS");

export const getAdminAirdropContract = createAction<string>(
    "API/TOKEN/GET_ADMIN_AIRDROP_CONTRACT",
);

export const getAdminAirdropContractResponse = createAction<{
    isContractAdminOrMinter: boolean;
    contractDetails: SolidityContractDetails;
}>("API/TOKEN/GET_ADMIN_AIRDROP_CONTRACT_RESPONSE");

export const getAirdropTxDetailsResponse = createAction<{
    txHashes: string[];
}>("API/TOKEN/GET_ADMIN_AIRDROP_TX_DETAILS_RESPONSE");

export const getGenerateAirdropWallets = createAction<number>(
    "API/TOKEN/GENERATE_AIRDROP_WALLETS",
);

export const generatedAirdropWalletsResponse = createAction<{
    wallets: string[];
}>("API/TOKEN/GENERATED_AIRDROP_WALLETS_RESPONSE");
