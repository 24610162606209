import { call } from "redux-saga/effects";
import { getChainId } from "@liveart/nft-client/dist/web3";
import { trackExceptions } from "~/features/errorTracking";
import { trackHubSpotEvent } from "~/api/choreography";
import { SEND_TO_HUBSPOT } from "./hubspot";
import { getAccessToken } from "~/api/user/getAccessToken";
import { query } from "~/api/graphQL";

export function* trackHubSpotEventSaga({
    payload,
}: ReturnType<typeof trackHubSpotEvent>) {
    try {
        const accessToken = yield call(getAccessToken);
        if (!accessToken) return;
        const chainId = yield call(getChainId);

        yield call(query, {
            networkId: chainId,
            queryStr: SEND_TO_HUBSPOT,
            accessToken,
            variables: {
                payload: JSON.stringify(payload),
            },
        });
    } catch (e) {
        trackExceptions("Hubspot Events", e);
    }
}
