export type DymanicTokenUriState = {
    isAdminUser: boolean;
};

export type WithDymanicTokenUriState = {
    dymanicTokenUri: DymanicTokenUriState;
};

export const defaultDymanicTokenUriState: DymanicTokenUriState = {
    isAdminUser: false,
};

export const getIsAdminUserReducer = (
    state: WithDymanicTokenUriState,
    { payload },
): WithDymanicTokenUriState => {
    return {
        dymanicTokenUri: {
            ...state.dymanicTokenUri,
            isAdminUser: payload,
        },
    };
};

export const dymanicTokenUriStateSelector = (state: {
    pdp: { dymanicTokenUri: DymanicTokenUriState };
}) => state.pdp.dymanicTokenUri;
