import { Action, createAction, createReducer } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "~/features/snackbar/snackbarReducer";
import { Notification } from "~/api/data-schema";

export type NotificationHandler = (notification: Notification) => {
    action?: Action;
    notification?: Notification;
};

export type AppNotificationsState = {
    notificationHandlers: NotificationHandler[];
};
export type WithAppNotificationsState = {
    appNotifications: AppNotificationsState;
};

export const IGNORE_NOTIFICATIONS = [
    "header not found",
    "not authenticated",
    "internal server error",
    "user closed modal",
    "No provider selected for request eth_chainId",
    "Authentication required",
    "underlying network changed",
    "Chaining cycle detected for promise",
];
const ignoreNotificationHandler: NotificationHandler = (
    notification: Notification,
) => {
    const { message, errorObj } = notification;
    if (
        IGNORE_NOTIFICATIONS.find(
            (ignoredNotificationSubstring) =>
                message
                    .toLowerCase()
                    .includes(ignoredNotificationSubstring.toLowerCase()) ||
                errorObj?.message
                    ?.toLowerCase()
                    ?.includes(ignoredNotificationSubstring.toLowerCase()),
        )
    ) {
        return {};
    }

    return {
        notification,
    };
};
const snackbarNotificationHandler: NotificationHandler = (
    notification: Notification,
) => {
    return {
        action: enqueueSnackbar(notification),
    };
};

export const initialAppNotificationsState: AppNotificationsState = {
    notificationHandlers: [
        ignoreNotificationHandler,
        snackbarNotificationHandler,
    ],
};

export const registerNotificationHandler = createAction<NotificationHandler>(
    "NOTIFICATIONS/REGISTER_NOTIFICATION_HANDLER",
);

export const appNotificationsReducer = createReducer(
    initialAppNotificationsState,
    (builder) =>
        builder.addCase(registerNotificationHandler, (state, { payload }) => ({
            ...state,
            notificationHandlers: [payload, ...state.notificationHandlers],
        })),
);

export const appNotificationsSelector = (state: WithAppNotificationsState) =>
    state.appNotifications;
