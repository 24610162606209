import { createReducer } from "@reduxjs/toolkit";
import { createPipe } from "remeda";
import { TokenType } from "~/api/data-schema";
import {
    createMintingQueueResponse,
    mintToken,
    queueMintOperation,
} from "~/api/choreography";
import {
    addTokenMintData,
    addTokenMintDataReducer,
    createArtworkResponseReducer,
    completeMinting,
    createMintingQueueResponseReducer,
    FinishMintDialogState,
    initialFinishMintingDialogState,
    mintTokenReducer,
    queueMintOperationReducer,
    removeTokenMintData,
    removeTokenMintDataReducer,
    runMintingStepResponse,
    runMintingStepResponseReducer,
    updateMintingContext,
    updateMintingContextReducer,
} from "./finishMinting/finishMintingDialogReducer";
import {
    addPermission,
    addPermissionReducer,
    initialTokenPermissionsState,
    removePermission,
    removePermissionReducer,
    setFirstOwner,
    setFirstOwnerReducer,
    setPermissions,
    setPermissionsReducer,
    TokenPermissionsState,
} from "./tokenPermissions/tokenPermissionsReducer";
import {
    addTokenRight,
    addTokenRightReducer,
    removeTokenRight,
    removeTokenRightReducer,
    removeTokenRightsByPermissionReducer,
    setTokenRights,
    setTokenRightsReducer,
    tokenRightsInitialState,
    TokenRightsState,
} from "./tokenRights/tokenRightsReducer";
import {
    removeRoyaltyReceiversByPermission,
    setBuyoutPrice,
    setBuyoutPriceReducer,
    setRoyaltyReceivers,
    setRoyaltyReceiversReducer,
    setRoyaltyValue,
    setRoyaltyValueReducer,
    tokenRoyaltiesInitialState,
    TokenRoyaltiesState,
} from "./tokenRoyalties/tokenRoyaltiesReducer";
import {
    initialTokenTypeState,
    selectTokenType,
    setTokenTypeReducer,
    toggleTokenFeatures,
    toggleTokenFeaturesReducer,
    TokenTypeState,
} from "./tokenTypes/tokenTypeReducer";
import {
    initialMintingWizardState,
    MintingSteps,
    mintingWizardReducer,
    MintingWizardState,
    setMintingStep,
} from "./mintWizard/mintingWizardReducer";
import {
    ArtworkDetailsReducer,
    ArtworkDetailState,
    initialArtworkDetailsState,
    setArtworkDetails,
} from "./artworkDetails/artworkDetailsReducer";
import {
    initialUploadFilesState,
    setUPloadFiles,
    setUploadFilesReducer,
    UploadFilesState,
} from "./uploadFiles/uploadFilesReducer";
import { getApplicationFromHostname } from "~/WhiteLabel/host";
import {
    initialSelectContractState,
    SelectContractState,
    setSelectedContract,
    setSelectedContractReducer,
} from "./selectContract/selectContractReducer";
import {
    initialMintingWizardTypeState,
    mintingWizardTypeReducer,
    MintingWizardTypeState,
    setMintingType,
} from "./mintWizard/mintingWizardTypeReducer";

export type MintState = Partial<FinishMintDialogState> &
    Partial<TokenRightsState> &
    Partial<TokenRoyaltiesState> &
    Partial<TokenPermissionsState> &
    Partial<TokenTypeState> &
    Partial<MintingWizardState> &
    Partial<MintingWizardTypeState> &
    Partial<ArtworkDetailState> &
    Partial<UploadFilesState> &
    Partial<SelectContractState> & {
        project?: string;
        firstOwner?: string;
    };
export type WithMintState = {
    mint: MintState;
};

export const initialMintState: MintState = {
    ...initialFinishMintingDialogState,
    ...tokenRightsInitialState,
    ...tokenRoyaltiesInitialState,
    ...initialTokenPermissionsState,
    ...initialTokenTypeState,
    ...initialMintingWizardState,
    ...initialMintingWizardTypeState,
    project: getApplicationFromHostname(),
    ...initialArtworkDetailsState,
    ...initialUploadFilesState,
    ...initialSelectContractState,
};

export const mintReducer = createReducer(initialMintState, (builder) =>
    builder
        .addCase(
            addTokenMintData,
            addTokenMintDataReducer as unknown as () => MintState,
        )
        .addCase(
            removeTokenMintData,
            removeTokenMintDataReducer as unknown as () => MintState,
        )
        .addCase(
            queueMintOperation,
            queueMintOperationReducer as unknown as () => MintState,
        )
        .addCase(mintToken, mintTokenReducer as unknown as () => MintState)
        .addCase(
            createMintingQueueResponse,
            createMintingQueueResponseReducer as unknown as () => MintState,
        )
        .addCase(
            updateMintingContext,
            updateMintingContextReducer as unknown as () => MintState,
        )
        .addCase(
            runMintingStepResponse,
            runMintingStepResponseReducer as unknown as () => MintState,
        )
        .addCase(
            completeMinting,
            createArtworkResponseReducer as unknown as () => MintState,
        )
        .addCase(setPermissions, setPermissionsReducer)
        .addCase(addPermission, addPermissionReducer)
        .addCase(removePermission, (state, action) =>
            /* https://ramdajs.com/docs/#pipe */
            createPipe(
                (initialState) =>
                    removePermissionReducer(
                        initialState as TokenPermissionsState,
                        action,
                    ),
                (stateWithRemovedPermission) =>
                    removeTokenRightsByPermissionReducer(
                        stateWithRemovedPermission as TokenRightsState,
                        action,
                    ),
                (stateWithRemovedRights) =>
                    removeRoyaltyReceiversByPermission(
                        stateWithRemovedRights as TokenRoyaltiesState,
                        action,
                    ),
            )(state),
        )
        .addCase(setFirstOwner, setFirstOwnerReducer)
        .addCase(setRoyaltyReceivers, setRoyaltyReceiversReducer)
        .addCase(setRoyaltyValue, setRoyaltyValueReducer)
        .addCase(setBuyoutPrice, setBuyoutPriceReducer)
        .addCase(setTokenRights, setTokenRightsReducer)
        .addCase(removeTokenRight, removeTokenRightReducer)
        .addCase(addTokenRight, addTokenRightReducer)
        .addCase(selectTokenType, setTokenTypeReducer)
        .addCase(setMintingStep, (state, action) => {
            if (action.payload === MintingSteps.SELECT_TOKEN) {
                return {
                    ...initialMintState,
                    mintingFormStep: MintingSteps.SELECT_TOKEN,
                };
            }
            if (
                action.payload === MintingSteps.TOKEN_RIGHTS_AND_ROYALTIES &&
                state.tokenType === TokenType.PLAIN_ERC721
            ) {
                if (state.mintingFormStep === MintingSteps.ARTWORK_DETAILS) {
                    return mintingWizardReducer(state as MintingWizardState, {
                        ...action,
                        payload: MintingSteps.PREVIEW_AND_MINT,
                    });
                }
                if (state.mintingFormStep === MintingSteps.PREVIEW_AND_MINT) {
                    return mintingWizardReducer(state as MintingWizardState, {
                        ...action,
                        payload: MintingSteps.ARTWORK_DETAILS,
                    });
                }
            }
            return mintingWizardReducer(state as MintingWizardState, action);
        })
        .addCase(setMintingType, mintingWizardTypeReducer)
        .addCase(setArtworkDetails, ArtworkDetailsReducer)
        .addCase(setUPloadFiles, setUploadFilesReducer)
        .addCase(toggleTokenFeatures, toggleTokenFeaturesReducer)
        .addCase(setSelectedContract, setSelectedContractReducer),
);

export const mintSelector = (state: WithMintState) => state.mint;
