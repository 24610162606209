import merge from "lodash/merge";
import { getAccessToken } from "~/api/user/getAccessToken";
import { getHeaders } from "./getHeaders";
import { normalizeResponse } from "./normalizeResponse";

export async function fetchWithAuthHeaders(
    ...[input, init = {}]: Parameters<typeof fetch>
) {
    const accessToken = await getAccessToken();
    let headers = getHeaders();
    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
        };
    }

    const resp = await fetch(
        `/nft${input}`,
        merge(
            {
                headers,
            },
            init,
        ),
    );

    return normalizeResponse(resp);
}
