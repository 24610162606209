import { put, select, takeLatest } from "redux-saga/effects";
import { userAuthenticated, getTokenRights } from "~/api/choreography";
import { artworkStateSelector } from "./artworkReducer";

export function* artworkSaga() {
    yield takeLatest(
        userAuthenticated.toString(),
        function* onUserAuthenticatedSaga() {
            const { tokenId } = yield select(artworkStateSelector);
            if (tokenId) {
                yield put(getTokenRights(tokenId));
            }
        },
    );
}
