import { createAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import { Token } from "~/api/data-schema";
import { hideDialog, showDialog } from "~/features/dialog";
import { setBatchListTokens } from "./marketFeatureReducer";
import { submitTokenSellResponse } from "~/api/choreography";

export const batchListBuyNowTokens = createAction<Token[]>(
    "FEATURE/MARKET/BATCH_BUY_NOW_LIST_TOKENS",
);

function* batchListBuyNowTokensSaga(
    action: ReturnType<typeof batchListBuyNowTokens>,
) {
    yield put(setBatchListTokens(action.payload));
    yield put(showDialog("batchListBuyNowDialog"));
}
function* submitTokenSellResponseSaga() {
    yield put(hideDialog("batchListBuyNowDialog"));
}

export function* marketFeatureSaga() {
    yield takeLatest(
        batchListBuyNowTokens.toString(),
        batchListBuyNowTokensSaga,
    );
    yield takeLatest(
        submitTokenSellResponse.toString(),
        submitTokenSellResponseSaga,
    );
}
