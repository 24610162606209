import { createAction, createReducer } from "@reduxjs/toolkit";
import { PaymentMethod, StripeCardElement } from "@stripe/stripe-js";
import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import {
    bidFailed,
    ethBidPlaced,
    cashBidPlaced,
    loadCashBuyerResponse,
    loadPaymentMethods,
    loadPaymentMethodsResponse,
    removePaymentMethodResponse,
    loadTokenMarketData,
    loadTokenMarketDataResponse,
    makeBid,
    newAuctionBidFromSubscription,
    buyArtwork,
    buyArtworkWithCreditCard,
    buyNowComplete,
    buyNowFailed,
    loadRemoteConfigResponse,
} from "~/api/choreography";
import { getValidMinimumBid } from "~/api/market";
import { BasicAuction, CashBuyer } from "~/api/data-schema";
import { hideDialog, showDialog } from "~/features/dialog";
import {
    onStripeCardChange,
    setBid,
    setBidInUSD,
    setFullName,
    submitStripeBid,
    toggleSaveStripeCard,
} from "./purchaseFlowActions";
import {
    buyArtworkReducer,
    buyArtworkWithCreditCardReducer,
    buyNowCardChangeReducer,
    buyNowCompleteReducer,
    buyNowFailedReducer,
    BuyNowFlowState,
    buyNowFullNameReducer,
    buyNowSaveCardReducer,
    buyNowSetPurchaseMethodReducer,
    initialBuyNowState,
    onBuyNowStripeCardChange,
    setBuyNowFullName,
    setBuyNowSelectedPurchaseMethod,
    setBuyNowStateReducer,
    toggleBuyNowSaveStripeCard,
} from "./BuyDialog";
import { getApplicationFromHostname } from "~/WhiteLabel/host";

export enum CardPaymentUI {
    SAVED_CARD = "SAVED_CARD",
    NEW_CARD = "NEW_CARD",
}

export enum PurchaseMethod {
    CASH = "cash",
    ETHER = "ether",
}

export type PurchaseFlowState = {
    bidIncrement: BigNumber | string | number;
    bid: string;
    minimumBid: string;
    startingBid: string;
    bidInUSD?: number;
    bidTotal?: string;
    totalBidAmountInEth?: string;
    fullName?: string;
    tokenId: number;
    bidFailed: boolean;
    selectedPurchaseMethod: PurchaseMethod;
    saveStripeCard: boolean;
    stripeError?: Error;
    stripeComplete?: boolean;
    cashBuyer?: CashBuyer;
    card?: StripeCardElement;
    auctionId?: number;
    paymentMethods?: PaymentMethod[];
    defaultCardUI: CardPaymentUI;
    cashBiddingInProgress: boolean;
    ethBiddingInProgress: boolean;
    ethBidPlaced: boolean;
    cashBidPlaced: boolean;
    buyNowFlow: BuyNowFlowState;
};
export type WithPurchaseFlow = {
    purchaseFlow: PurchaseFlowState;
};

export const setSelectedPurchaseMethod = createAction<
    PurchaseFlowState["selectedPurchaseMethod"]
>("PURCHASE_FLOW/SET_SELECTED_PURCHASE_METHOD");

export const initialPurchaseFlowState: PurchaseFlowState = {
    bidIncrement: "",
    bid: "",
    minimumBid: "",
    startingBid: "",
    tokenId: NaN,
    bidFailed: false,
    selectedPurchaseMethod: PurchaseMethod.ETHER,
    saveStripeCard: true,
    fullName: "",
    defaultCardUI: CardPaymentUI.SAVED_CARD,
    cashBiddingInProgress: false,
    ethBiddingInProgress: false,
    ethBidPlaced: false,
    cashBidPlaced: false,
    buyNowFlow: initialBuyNowState,
};

const TOTAL_BID_MULTIPLIER = 1.02; // 2% market fee
function calculateTotalBidAmountInEth(bid: string) {
    return new BigNumber(bid)
        .multipliedBy(TOTAL_BID_MULTIPLIER)
        .decimalPlaces(16)
        .toString();
}

export const purchaseFlowReducer = createReducer(
    initialPurchaseFlowState,
    (builder) =>
        builder
            .addCase(
                setBid,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => {
                    const { bidInEth, ethUsdExchangeRate } = payload;
                    const bidInUSD = ethUsdExchangeRate
                        ? new BigNumber(bidInEth)
                              .multipliedBy(ethUsdExchangeRate)
                              .decimalPlaces(16)
                              .toNumber()
                        : state.bidInUSD;
                    const { ethBidPlaced, ethBiddingInProgress } = state;
                    const bidTotal =
                        ethBidPlaced || ethBiddingInProgress
                            ? state.bidTotal
                            : calculateTotalBidAmountInEth(bidInEth);
                    return {
                        ...state,
                        bid: bidInEth,
                        bidTotal,
                        totalBidAmountInEth:
                            calculateTotalBidAmountInEth(bidInEth),
                        ...(bidInUSD ? { bidInUSD } : {}),
                    };
                },
            )
            .addCase(
                setBidInUSD,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => {
                    const { bidInUSD, usdEthExchangeRate } = payload;
                    const bid = new BigNumber(bidInUSD)
                        .multipliedBy(usdEthExchangeRate)
                        .decimalPlaces(16)
                        .toString();
                    const { cashBidPlaced, cashBiddingInProgress } = state;
                    const bidTotal =
                        cashBidPlaced || cashBiddingInProgress
                            ? state.bidTotal
                            : calculateTotalBidAmountInEth(bid);
                    return {
                        ...state,
                        bidInUSD,
                        bid,
                        bidTotal,
                        totalBidAmountInEth: calculateTotalBidAmountInEth(bid),
                    };
                },
            )
            .addCase(
                setFullName,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => ({
                    ...state,
                    fullName: payload,
                }),
            )
            .addCase(
                loadTokenMarketData,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => {
                    return {
                        ...state,
                        tokenId: payload.tokenId,
                    };
                },
            )
            .addCase(
                loadTokenMarketDataResponse,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => {
                    const { tokenId } = state;
                    const [auction] = payload[tokenId]?.auctions || [];
                    if (auction) {
                        const bid = getValidMinimumBid(auction);
                        return {
                            ...state,
                            bidIncrement: auction.bidIncrement,
                            bid,
                            minimumBid: bid,
                            startingBid: String(auction.startingBid),
                            bidInUSD: undefined,
                            totalBidAmountInEth:
                                calculateTotalBidAmountInEth(bid),
                            fullName: undefined,
                        };
                    }
                    return state;
                },
            )
            .addCase(
                newAuctionBidFromSubscription,
                (
                    state: PurchaseFlowState,
                    { payload: { bid } },
                ): PurchaseFlowState => {
                    const minimumBid = getValidMinimumBid({
                        bids: [bid],
                        bidIncrement: state.bidIncrement,
                    } as BasicAuction);
                    return {
                        ...state,
                        bid: minimumBid,
                        bidInUSD: undefined,
                        minimumBid,
                    };
                },
            )
            .addCase(
                bidFailed,
                (state: PurchaseFlowState): PurchaseFlowState => ({
                    ...state,
                    bidFailed: true,
                    cashBiddingInProgress: false,
                    ethBiddingInProgress: false,
                }),
            )
            .addCase(
                onStripeCardChange,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => ({
                    ...state,
                    ...payload,
                }),
            )
            .addCase(
                toggleSaveStripeCard,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => ({
                    ...state,
                    saveStripeCard: payload,
                }),
            )
            .addCase(
                submitStripeBid,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => {
                    return {
                        ...state,
                        card: payload.card,
                        auctionId: payload.auctionId,
                        cashBiddingInProgress: true,
                        bidFailed: false,
                    };
                },
            )
            .addCase(
                loadCashBuyerResponse,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => ({
                    ...state,
                    cashBuyer: payload,
                    buyNowFlow: setBuyNowStateReducer(state, {
                        payload: {
                            cashBuyer: payload,
                        },
                    }).buyNowFlow,
                }),
            )
            .addCase(
                loadPaymentMethods,
                (state: PurchaseFlowState): PurchaseFlowState => ({
                    ...state,
                    paymentMethods: undefined,
                    buyNowFlow: setBuyNowStateReducer(state, {
                        payload: {
                            paymentMethods: undefined,
                        },
                    }).buyNowFlow,
                }),
            )
            .addCase(
                loadPaymentMethodsResponse,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => ({
                    ...state,
                    paymentMethods: payload || [],
                    buyNowFlow: setBuyNowStateReducer(state, {
                        payload: {
                            paymentMethods: payload || [],
                        },
                    }).buyNowFlow,
                }),
            )
            .addCase(
                removePaymentMethodResponse,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => ({
                    ...state,
                    paymentMethods: state.paymentMethods?.filter(
                        (pm) => pm?.id !== payload?.id,
                    ),
                    buyNowFlow: setBuyNowStateReducer(state, {
                        payload: {
                            paymentMethods: state.paymentMethods?.filter(
                                (pm) => pm?.id !== payload?.id,
                            ),
                        },
                    }).buyNowFlow,
                }),
            )
            .addCase(
                makeBid,
                (state: PurchaseFlowState): PurchaseFlowState => ({
                    ...state,
                    ethBiddingInProgress: true,
                    bidFailed: false,
                }),
            )
            .addCase(
                ethBidPlaced,
                (state: PurchaseFlowState): PurchaseFlowState => ({
                    ...state,
                    ethBidPlaced: true,
                    cashBiddingInProgress: false,
                    ethBiddingInProgress: false,
                }),
            )
            .addCase(
                cashBidPlaced,
                (state: PurchaseFlowState): PurchaseFlowState => ({
                    ...state,
                    cashBidPlaced: true,
                    cashBiddingInProgress: false,
                    ethBiddingInProgress: false,
                }),
            )
            .addCase(
                setSelectedPurchaseMethod,
                (state: PurchaseFlowState, { payload }): PurchaseFlowState => ({
                    ...state,
                    selectedPurchaseMethod: payload,
                }),
            )
            .addCase(buyArtwork, (state) => ({
                ...state,
                buyNowFlow: buyArtworkReducer(state).buyNowFlow,
            }))
            .addCase(buyArtworkWithCreditCard, (state, action) => ({
                ...state,
                buyNowFlow: buyArtworkWithCreditCardReducer(state, action)
                    .buyNowFlow,
            }))
            .addCase(buyNowComplete, (state) => ({
                ...state,
                buyNowFlow: buyNowCompleteReducer(state).buyNowFlow,
            }))
            .addCase(buyNowFailed, (state, action) => ({
                ...state,
                buyNowFlow: buyNowFailedReducer(state, action).buyNowFlow,
            }))
            .addCase(setBuyNowFullName, (state, action) => ({
                ...state,
                buyNowFlow: buyNowFullNameReducer(state, action).buyNowFlow,
            }))
            .addCase(onBuyNowStripeCardChange, (state, action) => ({
                ...state,
                buyNowFlow: buyNowCardChangeReducer(state, action).buyNowFlow,
            }))
            .addCase(toggleBuyNowSaveStripeCard, (state, action) => ({
                ...state,
                buyNowFlow: buyNowSaveCardReducer(state, action).buyNowFlow,
            }))
            .addCase(setBuyNowSelectedPurchaseMethod, (state, action) => ({
                ...state,
                buyNowFlow: buyNowSetPurchaseMethodReducer(state, action)
                    .buyNowFlow,
            }))
            .addCase(loadRemoteConfigResponse, (state, { payload }) => {
                return {
                    ...state,
                    selectedPurchaseMethod:
                        (payload.defaultPaymentMethod.defaultValue.value[
                            getApplicationFromHostname()
                        ] as PurchaseMethod) || state.selectedPurchaseMethod,
                    buyNowFlow: {
                        ...state.buyNowFlow,
                        selectedPurchaseMethod:
                            payload.defaultPaymentMethod.defaultValue.value[
                                getApplicationFromHostname()
                            ] || state.buyNowFlow.selectedPurchaseMethod,
                    },
                };
            })
            .addMatcher(
                (action) => {
                    return (
                        action.type === showDialog.toString() ||
                        action.type === hideDialog.toString()
                    );
                },
                (
                    state: PurchaseFlowState,
                    { type, payload },
                ): PurchaseFlowState => {
                    if (payload === "bidDialog") {
                        return {
                            ...state,
                            ethBidPlaced: false,
                            cashBidPlaced: false,
                            bidFailed: false,
                        };
                    }
                    if (
                        payload === "buyNowDialog" &&
                        type === hideDialog.toString()
                    ) {
                        return {
                            ...state,
                            buyNowFlow: {
                                ...state.buyNowFlow,
                                buyComplete: false,
                                buyFailed: false,
                                failReason: undefined,
                                selectedPurchaseMethod:
                                    state.buyNowFlow.selectedPurchaseMethod,
                            },
                        };
                    }
                    return {
                        ...state,
                    };
                },
            ),
);

export const purchaseFlowSelector = (state: WithPurchaseFlow) =>
    state.purchaseFlow;
