function getPageContextKey() {
    return `api/pageContextSessionStorage/pageContextKey/${globalThis?.location?.pathname}`;
}

function getPageContext() {
    try {
        return JSON.parse(sessionStorage.getItem(getPageContextKey()) || "");
    } catch {
        return {};
    }
}

export const pageContextSessionStorage = {
    setItem(key: string, value: string) {
        sessionStorage.setItem(
            getPageContextKey(),
            JSON.stringify({
                ...getPageContext(),
                [key]: value,
            }),
        );
    },
    getItem(key: string) {
        return getPageContext()[key];
    },
};
