import Snackbar from "@mui/material/Snackbar";
import { useFeature } from "~/features/featureFlag";

const { version } = require("@/package.json");

export function AppVersion() {
    const showAppVersionEnabled = Boolean(useFeature("showAppVersion"));

    return showAppVersionEnabled ? (
        <Snackbar open message={`App version: ${version}`} />
    ) : null;
}
