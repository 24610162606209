import memoize from "lodash/memoize";
import { call, takeLatest } from "redux-saga/effects";
import { createModifiedMetadata, getIsAdminUser } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721/dymanicTokenUriSaga"),
    () => 1,
);

export function* dynamicTokenUriApiSaga() {
    yield takeLatest(
        getIsAdminUser.toString(),
        function* withGetIsAdminUserSaga(
            action: ReturnType<typeof getIsAdminUser>,
        ) {
            const { getIsAdminUserSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(getIsAdminUserSaga, action);
        },
    );
    yield takeLatest(
        createModifiedMetadata.toString(),
        function* withMetamaskSelectedChainId(
            action: ReturnType<typeof createModifiedMetadata>,
        ) {
            const { metamaskSelectedChainId } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(metamaskSelectedChainId, action);
        },
    );
}
