import { createReducer } from "@reduxjs/toolkit";
import { stytchSignOut } from "@liveart/auth";
import {
    getTokenRightsResponse,
    addToAuthenticationLine,
    getCognitoUserResponse,
    cognitoLogout,
    accountDisconnect,
    clearAuthenticationLine,
    userAuthenticated,
    loadTokenMarketDataResponse,
} from "~/api/choreography";
import { UserState } from "./userState";
import { weiToEther } from "~/api/web3";

export const initialUserState: UserState = {
    canSellRights: [],
    actionsLine: [],
};

export const userReducer = createReducer(initialUserState, (builder) =>
    builder
        .addCase(
            userAuthenticated,
            (state: UserState, { payload }): UserState => {
                try {
                    return {
                        ...state,
                        account: payload.account,
                        ethBalance: weiToEther(payload.balance || ""),
                    };
                } catch {
                    return {
                        ...state,
                        account: payload.account,
                        ethBalance: payload.balance,
                    };
                }
            },
        )
        .addCase(
            getCognitoUserResponse,
            (state: UserState, { payload }): UserState => ({
                ...state,
                cognitoUser: payload,
            }),
        )
        .addCase(
            getTokenRightsResponse,
            (state: UserState, { payload }): UserState => {
                return {
                    ...state,
                    canSellRights: payload.canSellRights,
                };
            },
        )
        .addCase(loadTokenMarketDataResponse, (state, { payload }) => {
            const [marketData] = Object.values(payload);
            if (marketData.hasLock) {
                return {
                    ...state,
                    canSellRights: [],
                };
            }

            return state;
        })
        .addCase(
            addToAuthenticationLine,
            (state: UserState, { payload }): UserState => {
                return {
                    ...state,
                    actionsLine: [...state.actionsLine, payload],
                };
            },
        )
        .addCase(clearAuthenticationLine, (state: UserState): UserState => {
            return {
                ...state,
                actionsLine: [],
            };
        })
        .addCase(
            cognitoLogout,
            (state: UserState): UserState => ({
                ...state,
                cognitoUser: undefined,
            }),
        )
        .addCase(
            stytchSignOut,
            (state: UserState): UserState => ({
                ...state,
                cognitoUser: undefined,
            }),
        )
        .addCase(
            accountDisconnect,
            (state: UserState): UserState => ({
                ...state,
                account: undefined,
            }),
        ),
);
