import { put } from "redux-saga/effects";
import { trackExceptions } from "./trackExceptions";
import { Notification } from "~/api/data-schema";
import {
    NotificationHandler,
    registerNotificationHandler,
} from "~/features/notifications";

const sentryNotificationHandler: NotificationHandler = (
    notification: Notification,
) => {
    if (notification.options?.variant === "error")
        trackExceptions(notification.message, notification.errorObj);

    return { notification };
};

export function* sentryTrackingSaga() {
    yield put(registerNotificationHandler(sentryNotificationHandler));
}
