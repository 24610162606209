import memoize from "lodash/memoize";
import { call, takeLatest } from "redux-saga/effects";
import { Action } from "redux";
import { trackHubSpotEvent } from "~/api/choreography";

const importHubspot = memoize(
    () => import("./hubspot"),
    () => 1,
);

export function* hubspotApiSaga() {
    yield takeLatest(
        trackHubSpotEvent.toString(),
        function* withHubspotTracking(action: Action) {
            const { trackHubSpotEventSaga } = yield call(importHubspot);

            yield call(trackHubSpotEventSaga, action);
        },
    );
}
