import { createReducer } from "@reduxjs/toolkit";
import { LoadingSlots } from "~/api/data-schema";
import { setLoading } from "~/api/choreography";

export type LoadingState = {
    [slot in keyof Partial<typeof LoadingSlots>]: boolean;
};
export type WithLoadingState = {
    loadingState: LoadingState;
};
export const initialLoadingState: LoadingState = {};

export const loadingStateReducer = createReducer(
    initialLoadingState,
    (builder) =>
        builder.addCase(setLoading, (state, { payload }) => {
            return {
                ...state,
                [payload.slot]: payload.loading,
            };
        }),
);

export const loadingStateSelector = (state: WithLoadingState) =>
    state?.loadingState;
