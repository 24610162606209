import { useSelector } from "react-redux";
import { initialThemeState, themeSelector } from "./themeReducer";
import { useFeature } from "~/features/featureFlag";
import { SupportedThemes } from "~/theme";

export function useThemeName() {
    const { themeName } = useSelector(themeSelector) || initialThemeState;
    const testIcaThemeEnabled = Boolean(useFeature("icaTheme"));

    return testIcaThemeEnabled ? SupportedThemes.pink : themeName;
}
