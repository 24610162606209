import { call, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import { getTokenRights } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721/getTokenRightsSaga"),
    () => 1,
);

export function* getTokenRightsApiSaga() {
    yield takeLatest(
        getTokenRights.toString(),
        function* withGetTokenRightsSaga(
            action: ReturnType<typeof getTokenRights>,
        ) {
            const { getTokenRightsSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(getTokenRightsSaga, action);
        },
    );
}
