import { call, fork, takeEvery, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import { loadToken, loadTokens } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721/loadTokenSaga"),
    () => 1,
);

export function* loadTokenApiSaga() {
    yield fork(function* () {
        yield takeEvery(
            loadTokens.toString(),
            function* withLoadTokensSaga(
                action: ReturnType<typeof loadTokens>,
            ) {
                const { loadTokensSaga } = (yield call(
                    importSagaModule,
                )) as Unpromisify<ReturnType<typeof importSagaModule>>;
                yield call(loadTokensSaga, action);
            },
        );
    });

    yield fork(function* () {
        yield takeLatest(
            loadToken.toString(),
            function* withLoadTokenSaga(action: ReturnType<typeof loadToken>) {
                const { loadTokenSaga } = (yield call(
                    importSagaModule,
                )) as Unpromisify<ReturnType<typeof importSagaModule>>;

                yield call(loadTokenSaga, action);
            },
        );
    });
}
