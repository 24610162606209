import React from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import LogoIcon from "~/features/icon/logoNew.svg";
import EphermalIcon from "~/features/icon/ephermalIcon.svg";
import { getApplicationFromHostname } from "../../host";
import logoArtechouse from "./logoArtechouse.png";

const LogoICA = dynamic<Parameters<SvgrComponent>[0]>(
    () => import("./logoICA.svg"),
);

const LogoArtuner = dynamic<Parameters<SvgrComponent>[0]>(
    () => import("./logoArtuner.svg"),
);

type SvgIconProps = {
    className?: string;
    width?: string | number;
    height?: string | number;
    viewBox?: string;
    fill?: string;
    xmlns?: string;
};

export const LogoIconByApplication: React.FC<SvgIconProps> = ({
    className,
    width,
    ...other
}) => {
    const application = getApplicationFromHostname();

    switch (application) {
        case SupportedApplication.artuner:
            return (
                <LogoArtuner
                    className={className}
                    width={Math.max(300, width ? +width : 0)}
                />
            );
        case SupportedApplication.artechouse:
            return (
                <Image
                    src={logoArtechouse}
                    className={className}
                    width={Math.max(300, width ? +width : 0)}
                    height={66}
                />
            );
        case SupportedApplication.icamiami:
            return (
                <LogoICA
                    className={className}
                    {...other}
                    width={Math.max(300, width ? +width : 0)}
                />
            );
        case SupportedApplication.ephemeralEternal:
            return <EphermalIcon className={className} {...other} />;
        default:
            return <LogoIcon className={className} {...other} />;
    }
};
