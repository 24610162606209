import { call, put, select } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import {
    setLoading,
    mintTokenResponse,
    handleAppNotification,
} from "~/api/choreography";
import {
    LoadingSlots,
    MintingContext,
    ReadyToMintTokenData,
} from "~/api/data-schema";
import {
    getMintTokenDataFromLocalStorage,
    removeMintTokenDataFromLocalStorage,
} from "~/api/indexedDB";
import { normalizeSmartContractError } from "~/api/normalize";
import {
    addTokenMintData,
    FinishMintDialogState,
    finishMintingDialogSelector,
    removeTokenMintData,
    runMintingStep,
    runMintingStepResponse,
    updateMintingContext,
} from "./finishMintingDialogReducer";
import { getCurrentCompletedMintingStep } from "./getCurrentCompletedMintingStep";
import {
    setMintingStep,
    MintingSteps,
} from "../mintWizard/mintingWizardReducer";

export const closeFinishMintingDialog = createAction(
    "FINISH_MINTING_DIALOG/CLOSE_FINISH_MINTING_DIALOG",
);
export function* closeFinishMintingDialogSaga() {
    yield put(
        setLoading({
            slot: LoadingSlots.MINTING,
            loading: false,
        }),
    );
}

const processedMintTokenData = {};
export function* watchForReadyToMintTokenData() {
    const mintData: ReadyToMintTokenData[] = yield call(
        getMintTokenDataFromLocalStorage,
    );
    for (const el of mintData) {
        if (el) {
            const { tokenURI } = el;
            if (!(tokenURI in processedMintTokenData)) {
                processedMintTokenData[tokenURI] = true;
                yield put(addTokenMintData(el));
            }
        }
    }
}

export function* artworkCreatedSaga(
    action: ReturnType<typeof mintTokenResponse>,
) {
    const {
        payload: { success },
    } = action;

    yield put(
        setLoading({
            slot: LoadingSlots.MINTING,
            loading: false,
        }),
    );

    if (success) {
        yield put(setMintingStep(MintingSteps.MINTING_SUCCESS));
    }
}

async function executeMintingStepFn(
    step: number,
    queue: FinishMintDialogState["mintTokenQueue"],
    ctx: MintingContext,
): Promise<{
    nextCtx?: MintingContext;
    error?: Error;
}> {
    try {
        const { nextCtx, error } = await queue[step].operationFunc(ctx);
        return {
            nextCtx,
            error,
        };
    } catch (error) {
        return {
            error,
        };
    }
}
export function* runMintingStepSaga(action: ReturnType<typeof runMintingStep>) {
    const { step, runNext } = action.payload;
    try {
        yield put(
            setLoading({
                slot: LoadingSlots.EXECUTE_MINTING_STEP,
                loading: true,
            }),
        );
        const { mintTokenQueue, mintingContext } = (yield select(
            finishMintingDialogSelector,
        )) as FinishMintDialogState;
        const { nextCtx, error } = yield call(
            executeMintingStepFn,
            step,
            mintTokenQueue,
            mintingContext,
        );
        if (error) {
            throw error;
        }

        yield put(updateMintingContext(nextCtx));

        yield put(
            runMintingStepResponse({
                step,
                success: true,
            }),
        );
        yield put(
            setLoading({
                slot: LoadingSlots.EXECUTE_MINTING_STEP,
                loading: false,
            }),
        );

        if (runNext && step < mintTokenQueue.length - 1) {
            yield put(
                runMintingStep({
                    step: step + 1,
                    runNext,
                }),
            );
        }
        if (step === mintTokenQueue.length - 1) {
            yield put(
                mintTokenResponse({
                    tokenId: mintingContext.tokenId as number,
                    success: true,
                    tokenURI: String(mintingContext.tokenURI),
                }),
            );
        }
    } catch (e) {
        yield put(
            runMintingStepResponse({
                step,
                success: false,
            }),
        );
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                ...notification,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
        yield put(
            setLoading({
                slot: LoadingSlots.EXECUTE_MINTING_STEP,
                loading: false,
            }),
        );
    }
}

export const runAllMintingSteps = createAction("MINTING/RUN_ALL_MINTING_STEPS");
export function* runAllMintingStepsSaga() {
    const { completedSteps } = (yield select(
        finishMintingDialogSelector,
    )) as FinishMintDialogState;
    const completedStep = getCurrentCompletedMintingStep(completedSteps);

    if (typeof completedStep === "number") {
        yield put(
            runMintingStep({
                step: completedStep + 1,
                runNext: true,
            }),
        );
    } else {
        yield put(
            runMintingStep({
                step: 0,
                runNext: true,
            }),
        );
    }
}

export const cancelFinishMinting = createAction(
    "FINISH_MINTING_DIALOG/CANCEL_FINISH_MINTING_DIALOG",
);
export function* cancelFinishMintingSaga() {
    yield put(closeFinishMintingDialog());
    const mintTokenData: ReadyToMintTokenData[] = yield call(
        getMintTokenDataFromLocalStorage,
    );
    for (const el of mintTokenData) {
        yield call(removeMintTokenDataFromLocalStorage, el.tokenURI);
        yield put(removeTokenMintData(el.tokenURI));
    }
}
