import memoize from "lodash/memoize";
import { call, takeLatest } from "redux-saga/effects";
import {
    subscribeTobidResponse,
    subscribeToNewAuctionBids,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importPusherModule = memoize(
    () => import("./pusher"),
    () => 1,
);

export function* pusherApiSaga() {
    yield takeLatest(
        subscribeToNewAuctionBids.toString(),
        function* withSubscribeToNewBidsSaga(
            action: ReturnType<typeof subscribeToNewAuctionBids>,
        ) {
            const { subscribeToNewBidsSaga } = (yield call(
                importPusherModule,
            )) as Unpromisify<ReturnType<typeof importPusherModule>>;
            yield call(subscribeToNewBidsSaga, action);
        },
    );
    yield takeLatest(
        subscribeTobidResponse.toString(),
        function* withSubscribeToBidsRsponseSaga(
            action: ReturnType<typeof subscribeTobidResponse>,
        ) {
            const { subscribeToBidsRsponseSaga } = (yield call(
                importPusherModule,
            )) as Unpromisify<ReturnType<typeof importPusherModule>>;
            yield call(subscribeToBidsRsponseSaga, action);
        },
    );
}
