import { createAction } from "@reduxjs/toolkit";

export type UploadFilesForm = {
    highResFile?: File;
    highResFileObjectUrl?: string;
    previewFile?: File;
    previewFileObjectUrl?: string;
    artworkFactSheet: string;
    ownershipGuidelines: string;
    unlockableContent?: string;
    hasPhygital: boolean;
    phygitalMedium?: string;
    phygitalDimensions?: string;
    phygitalImage?: File;
    phygitalImageUrl?: string;
};

export type UploadFilesState = {
    uploadFiles: UploadFilesForm;
};
export const initialUploadFilesState: UploadFilesState = {
    uploadFiles: {
        hasPhygital: false,
        artworkFactSheet: "",
        ownershipGuidelines: "",
        unlockableContent: "",
    },
};

export const setUPloadFiles = createAction<UploadFilesForm>(
    "MINTING/UPLOD_FILES",
);

export const setUploadFilesReducer = (
    state: UploadFilesState,
    { payload }: ReturnType<typeof setUPloadFiles>,
): UploadFilesState => ({
    ...state,
    uploadFiles: payload,
});
export const uploadFilesSelector = (state: {
    mint: { uploadFiles };
}): UploadFilesForm => state.mint.uploadFiles;
