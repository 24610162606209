import memoize from "lodash/memoize";
import { call, takeEvery, takeLatest } from "redux-saga/effects";
import {
    bidResponse,
    buyArtwork,
    buyArtworkWithCreditCard,
    createPaymentIntent,
    endAuction,
    getCurrencyRates,
    loadCashBuyer,
    loadPaymentMethods,
    loadTokenMarketData,
    makeBid,
    placeCashBid,
    removePaymentMethod,
    submitBatchBuyNowTokenSell,
    submitTreasurerTokenSell,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importMarketModule = memoize(
    () => import("./market"),
    () => 1,
);
const importTreasurerModule = memoize(
    () => import("./treasurer"),
    () => 1,
);

export function* marketApiSaga() {
    yield takeEvery(
        loadTokenMarketData.toString(),
        function* withLoadTokenMarketDataSaga(
            action: ReturnType<typeof loadTokenMarketData>,
        ) {
            const { loadTokenMarketDataSaga } = (yield call(
                importMarketModule,
            )) as Unpromisify<ReturnType<typeof importMarketModule>>;

            yield call(loadTokenMarketDataSaga, action);
        },
    );
    yield takeLatest(
        createPaymentIntent.toString(),
        function* withCreatePaymentIntentSaga(
            action: ReturnType<typeof createPaymentIntent>,
        ) {
            const { createPaymentIntentSaga } = (yield call(
                importMarketModule,
            )) as Unpromisify<ReturnType<typeof importMarketModule>>;

            yield call(createPaymentIntentSaga, action);
        },
    );
    yield takeLatest(
        loadPaymentMethods.toString(),
        function* withLoadPaymentMethodsSaga(
            action: ReturnType<typeof loadPaymentMethods>,
        ) {
            const { loadPaymentMethodsSaga } = (yield call(
                importMarketModule,
            )) as Unpromisify<ReturnType<typeof importMarketModule>>;

            yield call(loadPaymentMethodsSaga, action);
        },
    );
    yield takeLatest(
        placeCashBid.toString(),
        function* withPlaceCashBidSaga(
            action: ReturnType<typeof placeCashBid>,
        ) {
            const { treasurerPlaceCashBid } = (yield call(
                importTreasurerModule,
            )) as Unpromisify<ReturnType<typeof importTreasurerModule>>;
            yield call(treasurerPlaceCashBid, action);
        },
    );
    yield takeLatest(
        loadCashBuyer.toString(),
        function* withLoadCashBuyerSaga(
            action: ReturnType<typeof loadCashBuyer>,
        ) {
            const { loadCashBuyerSaga } = (yield call(
                importMarketModule,
            )) as Unpromisify<ReturnType<typeof importMarketModule>>;

            yield call(loadCashBuyerSaga, action);
        },
    );
    yield takeLatest(
        removePaymentMethod.toString(),
        function* withRemovePaymentMethodSaga(
            action: ReturnType<typeof removePaymentMethod>,
        ) {
            const { removePaymentMethodSaga } = (yield call(
                importMarketModule,
            )) as Unpromisify<ReturnType<typeof importMarketModule>>;

            yield call(removePaymentMethodSaga, action);
        },
    );
    yield takeEvery(
        getCurrencyRates.toString(),
        function* withGetCurrencyRatesSaga(
            action: ReturnType<typeof getCurrencyRates>,
        ) {
            const { getCurrencyRatesSaga } = (yield call(
                importMarketModule,
            )) as Unpromisify<ReturnType<typeof importMarketModule>>;

            yield call(getCurrencyRatesSaga, action);
        },
    );
    yield takeLatest(
        bidResponse.toString(),
        function* withLoadToBidresponseSaga(
            action: ReturnType<typeof bidResponse>,
        ) {
            const { bidReponseSaga } = (yield call(
                importMarketModule,
            )) as Unpromisify<ReturnType<typeof importMarketModule>>;
            yield call(bidReponseSaga, action);
        },
    );
    yield takeLatest(
        endAuction.toString(),
        function* withEndAuctionSaga(action: ReturnType<typeof endAuction>) {
            const { treasurerEndAuction } = (yield call(
                importTreasurerModule,
            )) as Unpromisify<ReturnType<typeof importTreasurerModule>>;
            yield call(treasurerEndAuction, action);
        },
    );
    yield takeLatest(
        buyArtwork.toString(),
        function* withBuyArtworkSaga(action: ReturnType<typeof buyArtwork>) {
            const { treasurerBuyNowSaga } = (yield call(
                importTreasurerModule,
            )) as Unpromisify<ReturnType<typeof importTreasurerModule>>;
            yield call(treasurerBuyNowSaga, action);
        },
    );
    yield takeLatest(
        buyArtworkWithCreditCard.toString(),
        function* withBuyArtworkWithCreditCardSaga(
            action: ReturnType<typeof buyArtworkWithCreditCard>,
        ) {
            const { treasurerBuyArtworkWithCreditCardSaga } = (yield call(
                importTreasurerModule,
            )) as Unpromisify<ReturnType<typeof importTreasurerModule>>;
            yield call(treasurerBuyArtworkWithCreditCardSaga, action);
        },
    );
    yield takeLatest(
        makeBid.toString(),
        function* withMakeBidSaga(action: ReturnType<typeof makeBid>) {
            const { treasurerMakeEthBid } = (yield call(
                importTreasurerModule,
            )) as Unpromisify<ReturnType<typeof importTreasurerModule>>;
            yield call(treasurerMakeEthBid, action);
        },
    );
    yield takeLatest(
        submitTreasurerTokenSell.toString(),
        function* withTreasurerSubmitTokenSellSaga(
            action: ReturnType<typeof submitTreasurerTokenSell>,
        ) {
            const { treasurerSubmitTokenSellSaga } = (yield call(
                importTreasurerModule,
            )) as Unpromisify<ReturnType<typeof importTreasurerModule>>;

            yield call(treasurerSubmitTokenSellSaga, action);
        },
    );
    yield takeLatest(
        submitBatchBuyNowTokenSell.toString(),
        function* withSubmitBatchBuyNowTokenSell(
            action: ReturnType<typeof submitBatchBuyNowTokenSell>,
        ) {
            const { submitBatchBuyNowTokenSellSaga } = (yield call(
                importTreasurerModule,
            )) as Unpromisify<ReturnType<typeof importTreasurerModule>>;

            yield call(submitBatchBuyNowTokenSellSaga, action);
        },
    );
}
