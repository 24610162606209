import { call, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import {
    loadEditionsForSelectedContract,
    loadAllWhiteLists,
    createWhiteList,
    deleteSelectedWhiteList,
    addWalletToWhiteList,
    getWalletsByWhiteListId,
    batchAddWalletsToWhiteList,
    deleteSelectedWhiteListWallet,
    updateExistingWhiteList,
    updateWalletOnWhiteList,
    updateWLConfigOnContractEdition,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importConfigurationSagaModule = memoize(
    () => import("./whiteListedAddresses/WLMintConfigurationSaga"),
    () => 1,
);
const importContractSagaModule = memoize(
    () => import("./whiteListedAddresses/WLMintContractSaga"),
    () => 1,
);
const importOperationSagaModule = memoize(
    () => import("./whiteListedAddresses/WLMintOperationSaga"),
    () => 1,
);

export function* whiteListedMintedAddressesApiSaga() {
    yield takeLatest(
        loadEditionsForSelectedContract.toString(),
        function* withValidateSelectedContractSaga(
            action: ReturnType<typeof loadEditionsForSelectedContract>,
        ) {
            const { loadEditionsForSelectedContractSaga } = (yield call(
                importContractSagaModule,
            )) as Unpromisify<ReturnType<typeof importContractSagaModule>>;

            yield call(loadEditionsForSelectedContractSaga, action);
        },
    );
    yield takeLatest(
        loadAllWhiteLists.toString(),
        function* withgetAllWhitelistsSaga(
            action: ReturnType<typeof loadAllWhiteLists>,
        ) {
            const { getAllWhitelistsSaga } = (yield call(
                importConfigurationSagaModule,
            )) as Unpromisify<ReturnType<typeof importConfigurationSagaModule>>;

            yield call(getAllWhitelistsSaga, action);
        },
    );

    yield takeLatest(
        createWhiteList.toString(),
        function* withCreateWhiteListSaga(
            action: ReturnType<typeof createWhiteList>,
        ) {
            const { createWhitelistSaga } = (yield call(
                importConfigurationSagaModule,
            )) as Unpromisify<ReturnType<typeof importConfigurationSagaModule>>;

            yield call(createWhitelistSaga, action);
        },
    );

    yield takeLatest(
        deleteSelectedWhiteList.toString(),
        function* withDeleteSelectedWhiteListSaga(
            action: ReturnType<typeof deleteSelectedWhiteList>,
        ) {
            const { deleteSelectedWhiteListSaga } = (yield call(
                importConfigurationSagaModule,
            )) as Unpromisify<ReturnType<typeof importConfigurationSagaModule>>;

            yield call(deleteSelectedWhiteListSaga, action);
        },
    );

    yield takeLatest(
        addWalletToWhiteList.toString(),
        function* withDeleteSelectedWhiteListSaga(
            action: ReturnType<typeof addWalletToWhiteList>,
        ) {
            const { addWalletToWhiteListSaga } = (yield call(
                importOperationSagaModule,
            )) as Unpromisify<ReturnType<typeof importOperationSagaModule>>;

            yield call(addWalletToWhiteListSaga, action);
        },
    );

    yield takeLatest(
        getWalletsByWhiteListId.toString(),
        function* withGetWalletsByWhiteListIdSaga(
            action: ReturnType<typeof getWalletsByWhiteListId>,
        ) {
            const { getWalletsByWhiteListIdSaga } = (yield call(
                importOperationSagaModule,
            )) as Unpromisify<ReturnType<typeof importOperationSagaModule>>;

            yield call(getWalletsByWhiteListIdSaga, action);
        },
    );

    yield takeLatest(
        batchAddWalletsToWhiteList.toString(),
        function* withSetbatchAddAddressesToWhiteListSaga(
            action: ReturnType<typeof batchAddWalletsToWhiteList>,
        ) {
            const { batchAddWalletsSaga } = (yield call(
                importOperationSagaModule,
            )) as Unpromisify<ReturnType<typeof importOperationSagaModule>>;

            yield call(batchAddWalletsSaga, action);
        },
    );

    yield takeLatest(
        deleteSelectedWhiteListWallet.toString(),
        function* withDeleteSelectedWhiteListWalletSaga(
            action: ReturnType<typeof deleteSelectedWhiteListWallet>,
        ) {
            const { deleteWhitelistWalletSaga } = (yield call(
                importOperationSagaModule,
            )) as Unpromisify<ReturnType<typeof importOperationSagaModule>>;

            yield call(deleteWhitelistWalletSaga, action);
        },
    );

    yield takeLatest(
        updateExistingWhiteList.toString(),
        function* withUpdateExistingWhiteListSaga(
            action: ReturnType<typeof updateExistingWhiteList>,
        ) {
            const { updateWhiteListOnBESaga } = (yield call(
                importConfigurationSagaModule,
            )) as Unpromisify<ReturnType<typeof importConfigurationSagaModule>>;

            yield call(updateWhiteListOnBESaga, action);
        },
    );

    yield takeLatest(
        updateWalletOnWhiteList.toString(),
        function* withUpdateWalletOnWhiteListSaga(
            action: ReturnType<typeof updateWalletOnWhiteList>,
        ) {
            const { updateWalletOnWhiteListSaga } = (yield call(
                importOperationSagaModule,
            )) as Unpromisify<ReturnType<typeof importOperationSagaModule>>;

            yield call(updateWalletOnWhiteListSaga, action);
        },
    );

    yield takeLatest(
        updateWLConfigOnContractEdition.toString(),
        function* withUpdateWLConfigOnContractEditionSaga(
            action: ReturnType<typeof updateWLConfigOnContractEdition>,
        ) {
            const { updateWLConfigOnContractEditionSaga } = (yield call(
                importContractSagaModule,
            )) as Unpromisify<ReturnType<typeof importContractSagaModule>>;

            yield call(updateWLConfigOnContractEditionSaga, action);
        },
    );
}
