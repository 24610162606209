import { toWei, fromWei } from "web3-utils";

export function priceInEth(price: string) {
    return toWei(price, "ether");
}

export function weiToEther(inWei: string) {
    // error. value is already in eth
    if (inWei.includes(".")) {
        return inWei;
    }

    return fromWei(inWei, "ether");
}
