import { weiToEther } from "~/api/web3";
import { fromContractReturnedRights } from "../ERC721";
import {
    BuyNowListing,
    TokenType,
    ContractReturnedRights,
} from "../data-schema";

export function fromContractReturnedBuyNowListings(
    listings: BuyNowListing[],
): BuyNowListing[] {
    return listings.map((el) => ({
        seller: el.seller,
        price: weiToEther(el.price.toString()),
        tokenId: +el.tokenId,
        listingID: +el.listingID,
        tokenContractAddress: el.tokenContractAddress,
        tokenType: el.tokenType as TokenType,
        listingFee: {
            feeRecepient: el.listingFee.feeRecepient,
            feeVolumePercentage: el.listingFee.feeVolumePercentage.toString(),
        },
        sellRights: fromContractReturnedRights(
            el.sellRights as unknown as ContractReturnedRights,
        ),
        liveArtMarketFee: {
            feeRecepient: el.liveArtMarketFee.feeRecepient,
            feeVolumePercentage:
                el.liveArtMarketFee.feeVolumePercentage.toString(),
        },
    }));
}
