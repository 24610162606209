import { getChainId } from "@liveart/nft-client/dist/web3";
import {
    BINANCE_CHAIN_ID,
    BINANCE_TESTNET_CHAIN_ID,
    GOERLI_CHAIN_ID,
    MAIN_NET_ID,
    PALM_CHAIN_ID,
    PALM_TEST_CHAIN_ID,
} from "@liveart/nft-client/dist/chainId";
import { getMetamaskProvider } from "@liveart/nft-client/dist/metamask";
import { createContractWithMetamaskSigner } from "~/api/solidityContracts/createContractWithMetamaskSigner";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { loadContractByName } from "~/BFF/blockchain/solidityContracts/loadContractByName";
import { createReadonlyContractInstance } from "~/BFF/blockchain/solidityContracts/createReadonlyContractInstance";
import { getBlockchainId } from "~/api/web3";

export async function loadMarketContract(blockchainId?: number) {
    const networkId = blockchainId || (await getBlockchainId());

    switch (networkId) {
        case BINANCE_CHAIN_ID:
        case PALM_CHAIN_ID: {
            const MarketContract = (await loadContractByName({
                contractName: "Market",
                networkId: MAIN_NET_ID,
            })) as SolidityContractDetails;
            return createReadonlyContractInstance({
                contract: MarketContract,
                blockchainId: MAIN_NET_ID,
            });
        }
        case BINANCE_TESTNET_CHAIN_ID:
        case PALM_TEST_CHAIN_ID: {
            const MarketContract = (await loadContractByName({
                contractName: "Market",
                networkId: GOERLI_CHAIN_ID,
            })) as SolidityContractDetails;
            return createReadonlyContractInstance({
                contract: MarketContract,
                blockchainId: GOERLI_CHAIN_ID,
            });
        }
        default: {
            const MarketContract = (await loadContractByName({
                contractName: "Market",
                networkId,
            })) as SolidityContractDetails;
            return createReadonlyContractInstance({
                contract: MarketContract,
                blockchainId: networkId,
            });
        }
    }
}

export async function loadUnlockableContentContract() {
    const mmProvider = await getMetamaskProvider();

    if (mmProvider) {
        const mmChainId = await getChainId();
        const UnlockableContentContract = (await loadContractByName({
            contractName: "MultitenantUnlockableContentExtension",
            networkId: mmChainId,
        })) as SolidityContractDetails;
        return createContractWithMetamaskSigner({
            contract: UnlockableContentContract,
        });
    }

    const blockchainId = await getBlockchainId();
    const UnlockableContentContract = (await loadContractByName({
        contractName: "MultitenantUnlockableContentExtension",
        networkId: blockchainId,
    })) as SolidityContractDetails;
    return createReadonlyContractInstance({
        contract: UnlockableContentContract,
        blockchainId,
    });
}

export async function loadTreasurerContract() {
    const mmChainId = await getChainId();
    const TreasurerContract = (await loadContractByName({
        contractName: "TreasurerV2",
        networkId: mmChainId,
    })) as SolidityContractDetails;

    return createContractWithMetamaskSigner({ contract: TreasurerContract });
}

export async function loadDymanicTokenUriContract() {
    const mmChainId = await getChainId();
    const DynamicTokenContract = (await loadContractByName({
        contractName: "MultitenantDynamicTokenExtension",
        networkId: mmChainId,
    })) as SolidityContractDetails;

    return createContractWithMetamaskSigner({ contract: DynamicTokenContract });
}
