import { useSelector } from "react-redux";
import { userStateSelector } from "./userStateSelector";
import { CognitoUser } from "~/api/data-schema";

export function useCognitoUser(): CognitoUser | undefined {
    const { cognitoUser } = useSelector(userStateSelector) || {
        cognitoUser: null,
    };

    return cognitoUser;
}
