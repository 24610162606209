import { createAction } from "@reduxjs/toolkit";
import { TokenType } from "~/api/data-schema";

export type TokenTypeState = {
    tokenType: TokenType | undefined;
    tokenFeaturesExpanded: boolean;
};
export const initialTokenTypeState: TokenTypeState = {
    tokenType: undefined,
    tokenFeaturesExpanded: false,
};
export const selectTokenType = createAction<TokenType>(
    "MINTING/SELECT_TOKEN_TYPE",
);
export const setTokenTypeReducer = (
    state: TokenTypeState,
    { payload }: ReturnType<typeof selectTokenType>,
): TokenTypeState => ({
    ...state,
    tokenType: payload,
});

export const toggleTokenFeatures = createAction(
    "MINTING/TOGGLE_TOKEN_FEATURES",
);
export const toggleTokenFeaturesReducer = (
    state: TokenTypeState,
): TokenTypeState => ({
    ...state,
    tokenFeaturesExpanded: !state.tokenFeaturesExpanded,
});

export const tokenTypeSelector = (state: { mint: { tokenType: TokenType } }) =>
    state.mint.tokenType;
export const tokenFeaturesExpandedSelector = (state: {
    mint: { tokenFeaturesExpanded: boolean };
}) => state.mint.tokenFeaturesExpanded;
