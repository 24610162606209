import { createAction, createReducer } from "@reduxjs/toolkit";
import {
    getFeatureFlagFromStorage,
    setFeatureFlagToStorage,
} from "~/api/featureFLag";
import { FeatureFLag } from "~/api/data-schema";

export type FeatureFlagState = {
    [key in keyof typeof FeatureFLag]: boolean;
};
export type WithFeatureFlagState = {
    featureFlag: FeatureFlagState;
};

export const initialFeatureFlagState: FeatureFlagState = {
    testMode: getFeatureFlagFromStorage("testMode"),
    icaTheme: getFeatureFlagFromStorage("icaTheme"),
    dynamicTokenContent: getFeatureFlagFromStorage("dynamicTokenContent"),
    artechouseTestTokensPool: getFeatureFlagFromStorage(
        "artechouseTestTokensPool",
    ),
    batchBuyNowListing: getFeatureFlagFromStorage("batchBuyNowListing"),
    showAppVersion: getFeatureFlagFromStorage("showAppVersion"),
    listForSale: getFeatureFlagFromStorage("listForSale"),
    isNewPDPEnabled: getFeatureFlagFromStorage("isNewPDPEnabled"),
    eePoapTokenDev: getFeatureFlagFromStorage("eePoapTokenDev"),
    claimEmail: getFeatureFlagFromStorage("claimEmail"),
    noWebComponents: getFeatureFlagFromStorage("noWebComponents"),
    showWlSelect: getFeatureFlagFromStorage("showWlSelect"),
    showLiveArtHeader: getFeatureFlagFromStorage("showLiveArtHeader"),
    isPDPV4Enabled: getFeatureFlagFromStorage("isPDPV4Enabled"),
    showWinterProjectId: getFeatureFlagFromStorage("showWinterProjectId"),
    registerWinterProjectOnMint: getFeatureFlagFromStorage(
        "registerWinterProjectOnMint",
    ),
};

export const setFeatureFLag = createAction<{
    flag: keyof typeof FeatureFLag;
    value: boolean;
}>("FEATURE_FLAG/SET_FEATURE_FLAG");

export const featureFLagReducer = createReducer(
    initialFeatureFlagState,
    (builder) =>
        builder.addCase(
            setFeatureFLag,
            (state, { payload: { flag, value } }) => {
                setFeatureFlagToStorage(flag, value);

                return {
                    ...state,
                    [flag]: value,
                };
            },
        ),
);

export const featureFlagSelector = (state: WithFeatureFlagState) =>
    state?.featureFlag;
