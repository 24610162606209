import React from "react";
import { Box } from "@mui/material";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";

export function H2({
    children,
    color,
    strong,
    mobile,
    id,
}: React.PropsWithChildren<{
    color?: string;
    strong?: boolean;
    mobile?: boolean;
    id?: string;
}>) {
    return (
        <Box
            id={id}
            component="h2"
            fontFamily={getFontFamilyBySupportedApplication()}
            fontWeight={strong ? "600" : "400"}
            fontStyle="normal"
            fontSize={mobile ? "22px" : "32px"}
            lineHeight={mobile ? "28px" : "40.7px"}
            color={color || colors.black}
            margin={0}
        >
            {children}
        </Box>
    );
}
