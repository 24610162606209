import { call } from "redux-saga/effects";
import { trackExceptions } from "~/features/errorTracking";
import { trackSegmentEvent } from "../choreography";
import { trackSegment } from "./trackSegment";

export function* trackSegmentEventSaga({
    payload,
}: ReturnType<typeof trackSegmentEvent>) {
    try {
        yield call(trackSegment, payload);
    } catch (e) {
        trackExceptions("Segment Events", e);
    }
}
