import { getManuallySelectedApplication } from "@liveart/injectables/dist/WhiteLabel/manuallySelectedApplication";
import { getApplicationFromHostname } from "@liveart/injectables/dist/WhiteLabel/host";
import Head from "next/head";
import { useDappWlConfig } from "~/features/remoteConfig/useDappWlConfig";

export function WlHead() {
    const wlConfig = useDappWlConfig(
        getManuallySelectedApplication() || getApplicationFromHostname(),
    );
    if (wlConfig?.head?.links) {
        return (
            <Head>
                {wlConfig?.head?.links.map((props) => (
                    <link key={Object.values(props).join("+")} {...props} />
                ))}
            </Head>
        );
    }

    return null;
}
