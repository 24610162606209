import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import {
    LiveArtProjectPermissions,
    LiveArtProjectRoyaltyReceivers,
    LiveArtProjectTokenRights,
} from "./LiveArtProject";

export const RoyaltyReceiversByProject = {
    [SupportedApplication.liveart]: LiveArtProjectRoyaltyReceivers,
};

export const PermissionsByProject = {
    [SupportedApplication.liveart]: LiveArtProjectPermissions,
};

export const TokenRightsByProject = {
    [SupportedApplication.liveart]: LiveArtProjectTokenRights,
};
