import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getPublisher } from "@liveart/nft-client/dist/publisher";
import { getSelectedMetamaskAccount } from "@liveart/nft-client/dist/metamask";
import {
    createMintingQueueResponse,
    mintToken,
    mintTokenResponse,
    setLoading,
} from "~/api/choreography";
import { LoadingSlots } from "~/api/data-schema";
import { mintSelector, MintState } from "./mintReducer";
import {
    addTokenMintData,
    removeTokenMintData,
    runMintingStep,
} from "./finishMinting/finishMintingDialogReducer";
import {
    runMintingStepSaga,
    watchForReadyToMintTokenData,
    runAllMintingSteps,
    runAllMintingStepsSaga,
    artworkCreatedSaga,
    cancelFinishMinting,
    cancelFinishMintingSaga,
    closeFinishMintingDialog,
    closeFinishMintingDialogSaga,
} from "./finishMinting/finishMintingDialogSaga";
import {
    completeSuccessMintingSaga,
    completeSuccessMinting,
} from "./successDialog/successDialogSaga";
import {
    addNewRole,
    initTokenRoyalties,
    removeRole,
    setRoyaltyReceivers,
} from "./tokenRoyalties/tokenRoyaltiesReducer";
import {
    addPermission,
    removePermission,
    setFirstOwner,
    setPermissions,
} from "./tokenPermissions/tokenPermissionsReducer";
import {
    initTokenRights,
    setTokenRights,
} from "./tokenRights/tokenRightsReducer";
import {
    PermissionsByProject,
    RoyaltyReceiversByProject,
    TokenRightsByProject,
} from "./creatorsByProject";
import {
    mintingFailed,
    mintingFailedSaga,
    submitTokenMint,
    submitTokenMintSaga,
} from "./premint/premintSaga";
import {
    MintingSteps,
    setMintingStep,
} from "./mintWizard/mintingWizardReducer";

function* onCreateArtworkSaga() {
    yield put(
        setLoading({
            slot: LoadingSlots.CREATE_MINTING_QUEUE,
            loading: true,
        }),
    );
}
function* onCreateMintingQueueResponseSaga() {
    yield put(
        setLoading({
            slot: LoadingSlots.CREATE_MINTING_QUEUE,
            loading: false,
        }),
    );
}

function* onRemoveTokenMintData() {
    yield put(
        setLoading({
            slot: LoadingSlots.EXECUTE_MINTING_STEP,
            loading: false,
        }),
    );
    setLoading({
        slot: LoadingSlots.CREATE_MINTING_QUEUE,
        loading: false,
    });
}

function* initTokenRightsAndRoyaltiesSaga() {
    const publisher = yield call(getPublisher);
    const currentUser = yield call(getSelectedMetamaskAccount);
    const { project = "", tokenType } = (yield select(
        mintSelector,
    )) as MintState;

    yield put(setFirstOwner(currentUser));
    yield put(
        setPermissions(
            PermissionsByProject[project]?.(publisher, currentUser) || [],
        ),
    );
    yield put(
        setRoyaltyReceivers(
            RoyaltyReceiversByProject[project]?.(publisher, currentUser) || [],
        ),
    );
    yield put(
        setTokenRights(
            TokenRightsByProject[project]?.(
                publisher,
                currentUser,
                tokenType,
            ) || [],
        ),
    );
}

function* addNewRoleSaga({ payload }: ReturnType<typeof addNewRole>) {
    yield put(addPermission(payload));
}

function* removeRoleSaga({ payload }: ReturnType<typeof removeRole>) {
    yield put(removePermission(payload));
}

export function* mintSaga() {
    yield takeLatest(submitTokenMint.toString(), submitTokenMintSaga);

    yield takeEvery("*", watchForReadyToMintTokenData);
    yield takeLatest(
        addTokenMintData.toString(),
        function* navigateToFinishMintingDialogSaga() {
            const { mintingFormStep } = (yield select(
                mintSelector,
            )) as MintState;
            if (mintingFormStep !== MintingSteps.MINTING_STEPS) {
                yield put(setMintingStep(MintingSteps.MINTING_STEPS));
            }
        },
    );
    yield takeLatest(
        cancelFinishMinting.toString(),
        function* onCloseMintingDialogSaga() {
            yield call(cancelFinishMintingSaga);
            yield put(setMintingStep(MintingSteps.SELECT_TOKEN));
        },
    );

    yield takeLatest(mintingFailed.toString(), mintingFailedSaga);
    yield takeLatest(mintTokenResponse.toString(), artworkCreatedSaga);
    yield takeLatest(mintToken.toString(), onCreateArtworkSaga);
    yield takeLatest(
        createMintingQueueResponse.toString(),
        onCreateMintingQueueResponseSaga,
    );

    yield takeLatest(runMintingStep.toString(), runMintingStepSaga);
    yield takeLatest(runAllMintingSteps.toString(), runAllMintingStepsSaga);
    yield takeLatest(removeTokenMintData.toString(), onRemoveTokenMintData);

    yield takeLatest(
        closeFinishMintingDialog.toString(),
        closeFinishMintingDialogSaga,
    );

    yield takeLatest(
        [initTokenRights.toString(), initTokenRoyalties.toString()],
        initTokenRightsAndRoyaltiesSaga,
    );
    yield takeLatest(addNewRole.toString(), addNewRoleSaga);
    yield takeLatest(removeRole.toString(), removeRoleSaga);
    yield takeLatest(
        completeSuccessMinting.toString(),
        completeSuccessMintingSaga,
    );
}
