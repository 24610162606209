import React from "react";
import { Box } from "@mui/material";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";

export function H5({
    children,
    strong,
    mobile,
    color = colors.black,
    id,
}: React.PropsWithChildren<{
    strong?: boolean;
    mobile?: boolean;
    color?: string;
    id?: string;
}>) {
    return (
        <Box
            id={id}
            component="h5"
            fontFamily={getFontFamilyBySupportedApplication()}
            fontWeight={strong ? "600" : "400"}
            fontStyle="normal"
            fontSize={mobile ? "13px" : "16px"}
            lineHeight={mobile ? "17px" : "24px"}
            letterSpacing="0.15px"
            color={color}
            margin={0}
        >
            {children}
        </Box>
    );
}
