import { Notification } from "~/api/data-schema";
import { enqueueSnackbar } from "~/features/snackbar";
import { normalizeMessage } from "./normalizeMessage";

const targetMessage = normalizeMessage("wallet is not unique");

export function walletIsNotUniqueAuthHandler(notification: Notification) {
    const { message } = notification;
    if (normalizeMessage(message).includes(targetMessage)) {
        return {
            action: enqueueSnackbar({
                ...notification,
                message:
                    "This wallet is already associated with another account.",
            }),
        };
    }

    return {
        notification,
    };
}
