import React, { useMemo } from "react";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import Head from "next/head";
import { getApplicationFromHostname } from "../../host";

export function FaviconByApplication() {
    const application = getApplicationFromHostname();

    const faviconRef = useMemo(() => {
        switch (application) {
            case SupportedApplication.artechouse: {
                return "/nft/faviconArtechouse.ico";
            }
            case SupportedApplication.ephemeralEternal: {
                return "/nft/faviconEphemeralEthernal.ico";
            }
            default: {
                return "/nft/faviconLiveArt.ico";
            }
        }
    }, [application]);

    return (
        <Head>
            <link
                rel="shortcut icon"
                key="favicon"
                href={faviconRef}
                data-testid="favicon"
            />
        </Head>
    );
}
