import { call, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import { loadCertificate } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721/loadCertificateSaga"),
    () => 1,
);

export function* loadCertificateApiSaga() {
    yield takeLatest(
        loadCertificate.toString(),
        function* withLoadCertificateSaga(
            action: ReturnType<typeof loadCertificate>,
        ) {
            const { loadCertificateSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(loadCertificateSaga, action);
        },
    );
}
