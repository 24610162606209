import { FinishMintDialogState } from "./finishMintingDialogReducer";

export const getCurrentCompletedMintingStep = (
    completedSteps: FinishMintDialogState["completedSteps"],
): number => {
    const completedStepsArray = Object.keys(completedSteps)
        .map((el) => +el)
        .filter((el) => typeof el === "number");

    if (completedStepsArray.length > 0) {
        return Math.max(...completedStepsArray);
    }
    return -1;
};
