import { call, put, takeLatest } from "redux-saga/effects";
import {
    claimPOAP,
    loadPOAP,
    loadPOAPResponse,
} from "~/api/choreography/openEdition";
import { claimPOAPOnBe, loadPOAPFromBe } from "~/api/openEdition/openEditionBE";
import { normalizeSmartContractError } from "~/api/normalize";
import {
    handleAppNotification,
    setLoading,
    claimTokenEmail,
} from "~/api/choreography";
import { LoadingSlots } from "~/api/data-schema";
import { sendEmailNotificationToClaimToken } from "../market/marketBE";
import { EmailNotificationType } from "~/BFF/emailNotifications/emailNotificationType";

export function* loadPOAPSaga(action: ReturnType<typeof loadPOAP>) {
    try {
        const response = yield call(() => loadPOAPFromBe(action.payload));
        yield put(loadPOAPResponse(response));
    } catch (e) {
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                errorObj: e,
                ...notification,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    } finally {
        yield put(
            setLoading({
                slot: LoadingSlots.CLAIM_POAP,
                loading: false,
            }),
        );
    }
}
export function* claimPOAPSaga(action: ReturnType<typeof claimPOAP>) {
    try {
        const { token, user, wallet, edition } = action.payload;
        yield put(
            setLoading({
                slot: LoadingSlots.CLAIM_POAP,
                loading: true,
            }),
        );

        yield call(() => claimPOAPOnBe({ wallet, edition }));
        yield call(
            sendEmailNotificationToClaimToken,
            user,
            token,
            EmailNotificationType.notifyClaimTokenUser,
        );
        yield put(
            loadPOAP({
                networkId: action.payload.edition.networkId,
                tokenContractAddress:
                    action.payload.edition.tokenContractAddress,
                parentTokenId: action.payload.edition.parentTokenId,
                wallets: [action.payload.wallet],
            }),
        );
    } catch (e) {
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                errorObj: e,
                ...notification,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
export function* claimTokenEmailSaga({
    payload: { user, token },
}: ReturnType<typeof claimTokenEmail>) {
    yield call(
        sendEmailNotificationToClaimToken,
        user,
        token,
        EmailNotificationType.notifyClaimTokenUser,
    );
}

export function* openEditionSaga() {
    yield takeLatest(loadPOAP.toString(), loadPOAPSaga);
    yield takeLatest(claimPOAP.toString(), claimPOAPSaga);
    yield takeLatest(claimTokenEmail.toString(), claimTokenEmailSaga);
}
