import memoize from "lodash/memoize";
import { Contract, providers } from "ethers";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { getRpcUrlByChainId } from "~/BFF/blockchain/getRpcUrlByChainId";

export const createReadonlyContractInstance: ({
    contract,
    blockchainId,
}: {
    contract: SolidityContractDetails;
    blockchainId: number;
}) => Contract = memoize(
    ({
        contract,
        blockchainId,
    }: {
        contract: SolidityContractDetails;
        blockchainId: number;
    }) => {
        return new Contract(
            contract.address,
            contract.abi,
            new providers.JsonRpcProvider(
                getRpcUrlByChainId(blockchainId),
                blockchainId,
            ),
        );
    },
    ({ contract, blockchainId }) => contract.address + blockchainId,
);
