import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { Token } from "~/api/data-schema";

export function generatePDPLink({
    tokenId,
    application,
    blockchainId,
    tokenContractAddress,
    baseUrl = "",
    withBasePath = false,
}: {
    tokenId: Token["tokenId"];
    application: SupportedApplication;
    blockchainId: number;
    tokenContractAddress: string;
    baseUrl?: string;
    withBasePath?: boolean;
    v4?: boolean;
}) {
    const basePath = withBasePath ? "/nft" : "";
    const PDPPath = `${baseUrl}${basePath}/artwork/`;
    return `${PDPPath}${tokenId}?application=${application}&blockchainId=${blockchainId}&tokenContractAddress=${tokenContractAddress}`;
}
