import React from "react";
import { Theme } from "@mui/material/styles";
import { useThemeName } from "./useThemeName";
import { SupportedThemes, theme, themePink } from "~/theme";

export function useTheme({
    shadowRootElement,
    outerTheme,
}: {
    shadowRootElement?: HTMLDivElement;
    outerTheme?: Theme;
}) {
    const themeName = useThemeName();
    const themeObj =
        React.useMemo(() => {
            if (outerTheme) {
                return outerTheme;
            }
            if (themeName === SupportedThemes.pink) {
                return themePink;
            }
            return theme;
        }, [outerTheme, themeName]) || {};

    return {
        ...themeObj,
        components: {
            ...((
                themeObj as unknown as {
                    components: object;
                }
            ).components || {}),
            MuiPopover: {
                defaultProps: {
                    container: shadowRootElement,
                },
            },
            MuiPopper: {
                defaultProps: {
                    container: shadowRootElement,
                },
            },
            MuiModal: {
                defaultProps: {
                    container: shadowRootElement,
                },
            },
        },
    };
}
