import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { useEffect } from "react";
import { Dispatch } from "redux";
import { weiToEther } from "~/api/web3/eth";
import { tokenMarketDataSelector } from "./tokenMarketDataReducer";
import { searchUsersByWallets } from "~/api/choreography";
import type {
    LoadTokenMarketDataResponsePayload,
    Token,
} from "~/api/data-schema";
import { Currency } from "~/api/data-schema";
import { useUsersByWallet } from "~/features/users";
import { useCurrencyExchange } from "~/features/currencyExchange";

export function useTokenMarketData(): LoadTokenMarketDataResponsePayload;
export function useTokenMarketData(
    tokenId: number,
): LoadTokenMarketDataResponsePayload[number];

export function useTokenMarketData(tokenId?: number) {
    const tokenMarketData = useSelector(tokenMarketDataSelector);

    if (typeof tokenId === "undefined") {
        return tokenMarketData;
    }

    return (
        tokenMarketData[tokenId] ?? {
            buyNowDeals: [],
            auctions: [],
            transfers: [],
        }
    );
}

const searchUsers = debounce((dispatch: Dispatch, wallets: string[]) => {
    if (typeof dispatch === "function") {
        dispatch(searchUsersByWallets(wallets));
    }
});
const searchUsersBuffer: string[] = [];

function truncateWallet(wallet: string) {
    if (wallet.trim()) {
        return `${wallet.slice(0, 6)}...${wallet.slice(-6)}`;
    }

    return wallet;
}

export function useTokenMarketDataFromToken(token: Token) {
    const [tokenWithMarketDataId, marketData] = Object.entries(
        token?.editionsMarketData || { [token.tokenId]: token.marketData },
    )
        .sort(([tokenId1], [tokenId2]) => +tokenId1 - +tokenId2)
        .reduce(
            ([accTokenId, accMarketData], [nextTokenId, nextMarketData]) => {
                if (!accMarketData?.auction && !accMarketData?.buyNow) {
                    if (nextMarketData?.buyNow || nextMarketData?.auction) {
                        return [nextTokenId, nextMarketData];
                    }
                }

                return [accTokenId, accMarketData];
            },
        );

    const auction = marketData?.auction;
    const upcomingAuction =
        auction && new Date(auction.auctionStartDate) > new Date();
    const activeAuction =
        auction && new Date(auction.auctionEndDate) > new Date();
    const endedAuction =
        auction && new Date(auction.auctionEndDate) <= new Date();
    const buyNow = marketData?.buyNow;

    const buyNowPrice = buyNow ? weiToEther(buyNow.price) : "";
    const soldFor = marketData?.soldFor || "";
    const highestBid =
        auction && auction.highestBid ? weiToEther(auction.highestBid) : "";
    const highestBidderWallet: string = auction
        ? (auction.highestBidder as string)
        : "";

    const usersByWallets = useUsersByWallet();
    const cognitoUser = usersByWallets[highestBidderWallet];
    let highestBidder =
        cognitoUser && cognitoUser.nickname
            ? `@${cognitoUser.nickname}`
            : highestBidderWallet;
    if (highestBidder.startsWith(`0x`)) {
        highestBidder = truncateWallet(highestBidder);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        if (
            !searchUsersBuffer.includes(highestBidderWallet) &&
            highestBidderWallet
        ) {
            searchUsersBuffer.push(highestBidderWallet);
        }

        searchUsers(dispatch, searchUsersBuffer);
    }, [highestBidderWallet, dispatch]);

    const soldForUsd = useCurrencyExchange({
        value: soldFor,
        from: Currency.ETH,
        to: Currency.USD,
    });

    const buyNowPriceUsd = useCurrencyExchange({
        value: buyNowPrice,
        from: Currency.ETH,
        to: Currency.USD,
    });

    const highestBidUsd = useCurrencyExchange({
        value: highestBid,
        from: Currency.ETH,
        to: Currency.USD,
    });

    return {
        auction,
        upcomingAuction,
        activeAuction,
        endedAuction,
        buyNow,
        buyNowPriceUsd,
        buyNowPrice,
        soldFor,
        soldForUsd,
        highestBid,
        highestBidUsd,
        highestBidder,
        tokenWithMarketDataId,
    };
}
