import memoize from "lodash/memoize";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { normalizeContracts } from "./normalizeContracts";
import { query } from "~/api/graphQL";
import { currentContractsQueryApiVersion } from "~/BFF/blockchain/solidityContracts/currentContractsQueryApiVersion";
import { queryNetworkByApiVersion } from "~/BFF/blockchain/solidityContracts/queryNetworkByApiVersion";
import { v1FindContractByNameQuery } from "~/BFF/blockchain/solidityContracts/v1FindContractByNameQuery";
import { v2FindContractByNameQuery } from "~/BFF/blockchain/solidityContracts/v2FindContractByNameQuery";

export const loadContractByName = memoize(
    async ({
        contractName,
        networkId,
        apiVersion = currentContractsQueryApiVersion(),
    }: {
        contractName: string;
        networkId: number;
        apiVersion?: ReturnType<typeof currentContractsQueryApiVersion>;
    }): Promise<SolidityContractDetails | undefined> => {
        const contractsList = normalizeContracts(
            await query({
                networkId: queryNetworkByApiVersion(apiVersion),
                queryStr:
                    apiVersion === "v1"
                        ? v1FindContractByNameQuery
                        : v2FindContractByNameQuery,
                variables: {
                    contractNameIlike: contractName,
                },
            }),
        );

        return contractsList.find((el) => +el.blockchainId === +networkId);
    },
    ({
        contractName,
        networkId,
        apiVersion = currentContractsQueryApiVersion(),
    }) => contractName + networkId + String(apiVersion),
);
