import { createReducer } from "@reduxjs/toolkit";
import { toggleBuyETHDialog } from "~/api/choreography";

export type BuyCryptoState = {
    isBuyCryptoDialogOpen: boolean;
    amountToBuy?: string;
};

export type WithBuyCryptoState = {
    buyCrypto: BuyCryptoState;
};

export const initialBuyCryptoState: BuyCryptoState = {
    isBuyCryptoDialogOpen: false,
};

export const buyCryptoReducer = createReducer(
    initialBuyCryptoState,
    (builder) =>
        builder.addCase(toggleBuyETHDialog, (state, { payload }) => {
            return {
                ...state,
                isBuyCryptoDialogOpen: !state.isBuyCryptoDialogOpen,
                amountToBuy: payload ? payload.amountToBuy : "",
            };
        }),
);

export const buyCryptoStateSelector = (state: WithBuyCryptoState) =>
    state.buyCrypto;
