import { createAction } from "@reduxjs/toolkit";
import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import { AuctionInputData, BuyNowInputData } from "~/api/data-schema";

export const submitTreasurerTokenSell = createAction<{
    buyNow:
        | Omit<BuyNowInputData, "tokenContractAddress" | "listingFee">
        | undefined;
    auction: AuctionInputData | undefined;
}>("API/TREASURER/SUBMIT_SELL_TOKEN");

export const submitBatchBuyNowTokenSell = createAction<{
    seller: string | BigNumber;
    price: number | BigNumber | string;
    tokenIds: (number | BigNumber | string)[];
    tokenContractAddress: string | undefined;
    tokenTypes: string[];
}>("API/TREASURER/SUBMIT_BATCH_SELL_TOKEN");
