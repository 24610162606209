import { createAction } from "@reduxjs/toolkit";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { FeBid } from "../data-schema";

export const unsubscribeFromNewBids = createAction<{
    auctionId: number;
    tokenId: number;
    application: SupportedApplication;
}>("PUSHER/UNSUBSCRIBE_TO_NEW_BIDS");
export const unsubscribeFromBidResponse = createAction<{
    auctionId: number;
    tokenId: number;
    application: SupportedApplication;
}>("PUSHER/UNSUBSCRIBE_TO_BID_RESPONSE");
export const subscribeToNewAuctionBids = createAction<{
    auctionId: number;
    tokenId: number;
    application: SupportedApplication;
}>("PUSHER/SUBSCRIBE_TO_NEW_BIDS");
export const subscribeTobidResponse = createAction<{
    auctionId: number;
    tokenId: number;
    networkId: number;
    application: SupportedApplication;
    reqId: string;
}>("PUSHER/SUBSCRIBE_TO_BID_RESPONSE");
export const bidResponse = createAction<{
    success: boolean;
    message: string;
    isCash: boolean;
    tokenId: number;
    auctionId: number;
    application: SupportedApplication;
}>("PUSHER/BID_RESPONSE");
export const newAuctionBidFromSubscription = createAction<{
    auctionId: number;
    tokenId: number;
    bid: FeBid;
}>("PUSHER/NEW_AUCTION_BID_FROM_SUBSCRIPTION");
