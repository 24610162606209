import React from "react";
import { Box } from "@mui/material";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";

export function H4({
    children,
    strong,
    color = colors.black,
    id,
}: React.PropsWithChildren<{ strong?: boolean; color?: string; id?: string }>) {
    return (
        <Box
            id={id}
            component="h4"
            fontFamily={getFontFamilyBySupportedApplication()}
            fontWeight={strong ? "600" : "400"}
            fontStyle="normal"
            fontSize="22px"
            lineHeight="27.98px"
            color={color}
            margin={0}
        >
            {children}
        </Box>
    );
}
