import { call, put } from "redux-saga/effects";
import type { MarketInstance } from "@liveart/nft-client/truffle-contracts";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { getMetamaskProvider } from "@liveart/nft-client/dist/metamask";
import { providers } from "ethers";
import { loadMarketContract } from "~/api/nft-client";
import { priceInEth, getBlockchainId } from "~/api/web3";
import {
    setLoading,
    handleAppNotification,
    loadTokenMarketData,
    loadTokenMarketDataResponse,
    getTokenRights,
} from "~/api/choreography";
import {
    LoadingSlots,
    BasicAuction,
    ContractReturnedBasicAuction,
} from "~/api/data-schema";
import { normalizeSmartContractError } from "~/api/normalize";
import { fromContractReturnedBasicAuctions } from "./auction";
import { getTokenMarketDataFromBe } from "./marketBE";
import { getPDPPageApplication } from "~/api/application";
import { trackExceptions } from "~/features/errorTracking";
import { Unpromisify } from "~/typescript";
import { normalizeMarketDataFromBE } from "~/api/market/normalizeMarketDataFromBE";
import { getTokenContractAddress } from "~/api/getTokenContractAddress";
import { afterTokensListed, processLocks } from "~/api/treasurer/treasurerBE";

export async function isEnoughBalance(price: string, address: string) {
    const selectedMMProvider = await getMetamaskProvider();

    if (selectedMMProvider) {
        const web3Provider = new providers.Web3Provider(selectedMMProvider);

        const balance = await web3Provider.getBalance(address);

        return balance.gte(priceInEth(price));
    }

    return false;
}
export function* loadTokenMarketDataSaga(
    action: ReturnType<typeof loadTokenMarketData>,
) {
    const {
        tokenId,
        application = getPDPPageApplication(),
        networkId,
    } = action.payload;

    yield put(
        setLoading({ slot: LoadingSlots.LOAD_MARKET_DATA, loading: true }),
    );

    try {
        const blockchainId = networkId || (yield call(getBlockchainId));

        const tokenContractAddress = getTokenContractAddress();
        const { buyNowDeals, auctions, transfers, tokenBids, hasLock, seller } =
            (yield call(async () =>
                getTokenMarketDataFromBe({
                    networkId: blockchainId,
                    application: application as SupportedApplication,
                    tokenId,
                    tokenContractAddress,
                }),
            )) as Unpromisify<ReturnType<typeof getTokenMarketDataFromBe>>;

        yield put(
            loadTokenMarketDataResponse({
                [tokenId]: normalizeMarketDataFromBE({
                    buyNowDeals,
                    auctions,
                    transfers,
                    tokenBids,
                    hasLock,
                    seller,
                }),
            }),
        );

        if (hasLock) {
            yield put(
                setLoading({
                    slot: LoadingSlots.LOAD_MARKET_DATA,
                    loading: false,
                }),
            );
            yield call(processLocks, blockchainId);
            yield call(afterTokensListed, {
                tokenIds: [tokenId],
                blockchainId,
                application: application as SupportedApplication,
                tokenContractAddress,
            });
            yield put(action);
            return;
        }

        yield put(getTokenRights(tokenId));
        yield put(
            setLoading({ slot: LoadingSlots.LOAD_MARKET_DATA, loading: false }),
        );
    } catch (e) {
        trackExceptions(e.message, e);
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                ...notification,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

export async function getTokenAuction(auctionId: number, tokenId: number) {
    const marketInstance =
        (await loadMarketContract()) as unknown as MarketInstance;
    const networkId = await getBlockchainId();

    const tokenContractAddress = getTokenContractAddress();
    const auctions: BasicAuction[] = await fromContractReturnedBasicAuctions(
        (await marketInstance.getTokenAuctions(
            tokenId,
            tokenContractAddress,
        )) as unknown as ContractReturnedBasicAuction[],
        networkId,
        tokenId,
    );
    return auctions.find((el) => +el.auctionId === +auctionId);
}

export function* assertAuctionIsOpen(auctionId: number, tokenId: number) {
    const auction = yield call(getTokenAuction, auctionId, tokenId);
    if (!auction) {
        yield put(loadTokenMarketData({ tokenId }));

        throw new Error("Can't bid: auction is closed");
    }
}
