import { useWeb3User } from "@liveart/injectables/dist/auth/web3connectors/useWeb3User";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { attachWallet, userAuthenticated } from "~/api/choreography";
import { useCognitoUser } from "~/features/user/useCognitoUser";

export function Web3UserDispatcher() {
    const { account, balance, chainId } = useWeb3User();
    const dispatch = useDispatch();
    const beUser = useCognitoUser();

    useEffect(() => {
        if (account) {
            dispatch(
                userAuthenticated({
                    account,
                    balance,
                    chainId,
                }),
            );

            if (beUser) {
                const walletConnected = beUser?.wallets?.find(
                    (wallet) =>
                        wallet?.wallet?.toLowerCase() ===
                        account?.toLowerCase(),
                );
                if (!walletConnected) {
                    dispatch(
                        attachWallet({
                            walletAddress: account,
                            walletType: "ethereum",
                        }),
                    );
                }
            }
        }
    }, [account, balance, chainId, dispatch, beUser]);

    return null;
}
