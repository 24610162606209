import { createAction, createReducer } from "@reduxjs/toolkit";

export type HelpState = {
    talkToOurTeamDialogOpen: boolean;
};
export type WithHelpFeature = {
    help: HelpState;
};

export const initialHelpState: HelpState = {
    talkToOurTeamDialogOpen: false,
};

export const showTalkToOurTeamDialog = createAction(
    "HELP/SHOW_TALK_TO_OUR_TEAM_DIALOG",
);
export const hideTalkToOurTeamDialog = createAction(
    "HELP/HIDE_TALK_TO_OUR_TEAM_DIALOG",
);
export const helpReducer = createReducer(initialHelpState, (builder) =>
    builder
        .addCase(showTalkToOurTeamDialog, (state) => ({
            ...state,
            talkToOurTeamDialogOpen: true,
        }))
        .addCase(hideTalkToOurTeamDialog, (state) => ({
            ...state,
            talkToOurTeamDialogOpen: false,
        })),
);

export const helpStateSelector = (state: WithHelpFeature) => state.help;
