import { Box, Typography } from "@mui/material";
import React from "react";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";

export function BodyS({
    children,
    color,
    strong,
    dataTarget,
    fontSize = "14px",
    lineHeight = "18px",
    id,
}: React.PropsWithChildren<{
    color?: string;
    strong?: boolean;
    dataTarget?: string;
    fontSize?: string;
    lineHeight?: string;
    id?: string;
}>) {
    return (
        <Typography id={id}>
            <Box
                component="span"
                fontFamily={getFontFamilyBySupportedApplication()}
                fontWeight={strong ? "600" : "400"}
                fontStyle="normal"
                data-target={dataTarget}
                fontSize={fontSize}
                lineHeight={lineHeight}
                letterSpacing="0.07px"
                color={color || colors.black}
                margin={0}
            >
                {children}
            </Box>
        </Typography>
    );
}
