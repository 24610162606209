import memoize from "lodash/memoize";
import { call, takeLatest } from "redux-saga/effects";
import { setDefaultBlockchainId } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importWeb3Module = memoize(
    () => import("./web3"),
    () => 1,
);

export function* web3ApiSaga() {
    yield takeLatest(
        setDefaultBlockchainId.toString(),
        function* withSetDefaultBlockchainIdSaga(
            action: ReturnType<typeof setDefaultBlockchainId>,
        ) {
            const { setDefaultBlockchainIdSaga } = (yield call(
                importWeb3Module,
            )) as Unpromisify<ReturnType<typeof importWeb3Module>>;
            yield call(setDefaultBlockchainIdSaga, action);
        },
    );
}
