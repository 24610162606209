import { call, put, select } from "redux-saga/effects";
import { useAuthModalDevEnvSelector } from "@liveart/injectables/dist/auth/authConfigReducer";
import { handleAppNotification } from "~/api/choreography";
import { getCashBuyer, removePaymentMethod } from "./marketBE";
import {
    loadCashBuyer,
    loadCashBuyerResponse,
    removePaymentMethodResponse,
} from "../choreography";
import { getPDPPageApplication } from "../application";

export function* loadCashBuyerSaga(action: ReturnType<typeof loadCashBuyer>) {
    try {
        const { cognitoUserId = "", connectedWalletAddress } = action.payload;
        const application = getPDPPageApplication();

        const useAuthModalDevEnv = yield select(useAuthModalDevEnvSelector);
        const json = yield call(getCashBuyer, {
            useDevEnv: useAuthModalDevEnv,
            cognitoUserId,
            application,
            connectedWalletAddress,
        });

        yield put(loadCashBuyerResponse(json));
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

export function* removePaymentMethodSaga(
    action: ReturnType<typeof removePaymentMethod>,
) {
    try {
        const { networkId, paymentMetohdId } = action.payload;

        const json = yield call(removePaymentMethod, {
            paymentMetohdId,
            networkId,
        });

        yield put(removePaymentMethodResponse(json));
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
