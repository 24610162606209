import memoize from "lodash/memoize";
import { CognitoUser } from "../data-schema/CognitoUser";
import { query } from "../graphQL";
import { getCurrentUserQuery } from "./GetCurrentUserQuery";
import { normalizeUsers } from "./normalizeUsers";

export const getCurrentCognitoUser = memoize(
    async (
        accessToken: string,
        chainId: number,
    ): Promise<CognitoUser | undefined> => {
        const respData = await query({
            networkId: chainId,
            queryStr: getCurrentUserQuery,
            accessToken,
        });
        const { currentUser } = respData.data;

        if (!currentUser) {
            return undefined;
        }

        const [user] = normalizeUsers(currentUser);

        return user;
    },
    (accessToken: string, chainId: number) => accessToken + chainId,
);
