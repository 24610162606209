import { Box, Link } from "@mui/material";
import { styled } from "@mui/system";
import React, { useMemo } from "react";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { colors, fontFamilies, fonts } from "~/theme";
import { CertificateOfRegistrationIconByApplication } from "./certificateOfRegistrationIconByApplication";
import { getApplicationFromHostname } from "../host";

const Root = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(),
    backgroundColor: colors.sectionColor,
    justifyContent: "space-between",
}));
const ImageContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginTop: "-40px",
});
const TopText = styled(Box)(({ theme }) => ({
    ...fonts.hx16Medium,
    color: colors.black,
    padding: theme.spacing(),
    "& > a": {
        textDecoration: "underline",
        paddingLeft: "5px",
    },
}));

export const UniquenessBlockByApplication: React.FC<
    unknown & { isNewPOA?: boolean }
> = ({ isNewPOA = false }) => {
    const application = getApplicationFromHostname();

    const description = useMemo(() => {
        switch (application) {
            case SupportedApplication.ephemeralEternal:
                return (
                    <TopText>
                        This work has been tested by ephemeral ethernal for
                        uniqueness in the Palm blockchain.
                    </TopText>
                );
            case SupportedApplication.artuner:
                return (
                    <TopText>
                        This work has been tested by ARTUNER for uniqueness in
                        the Ethereum blockchain.
                    </TopText>
                );
            case SupportedApplication.artechouse:
                return (
                    <TopText>
                        This work has been tested by ARTECHOUSE for uniqueness
                        in the Palm blockchain.
                    </TopText>
                );
            case SupportedApplication.icamiami:
                return (
                    <TopText>
                        This work has been tested by ICA, Miami for uniqueness
                        in the Palm blockchain.
                        <Link
                            href="https://liveart.gitbook.io/liveartx/marketplace/certificate-of-authenticity"
                            target="_blank"
                        >
                            Learn more
                        </Link>
                    </TopText>
                );
            default:
                return (
                    <TopText>
                        This work has been tested by LiveArt for uniqueness in
                        the Ethereum blockchain.
                        <Link
                            href="https://liveart.gitbook.io/liveartx/marketplace/certificate-of-authenticity"
                            target="_blank"
                        >
                            Learn more
                        </Link>
                    </TopText>
                );
        }
    }, [application]);

    if (isNewPOA) {
        return (
            <Box
                display="flex"
                justifyContent="space-between"
                maxWidth="404px"
                border={1}
                padding="16px"
                borderColor={colors.lightGrey}
                alignItems="center"
            >
                <Box fontFamily={fontFamilies.poppins} fontSize="16px">
                    {description}
                </Box>
                <Box display="flex" alignItems="center">
                    <CertificateOfRegistrationIconByApplication
                        width="76px"
                        height="76px"
                        isNewPOA={isNewPOA}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <Root>
            {description}
            <ImageContainer>
                <CertificateOfRegistrationIconByApplication
                    width="96px"
                    height="96px"
                />
            </ImageContainer>
        </Root>
    );
};
