import { Typography } from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/system";
import { cognitoLogout, navigateToSSO } from "~/api/choreography";
import { useCognitoUser } from "../useCognitoUser";

const StyledTypography = styled(Typography)({
    cursor: "pointer",
}) as typeof Typography;

export function CognitoAuthenticationBtn() {
    const cognitoUser = useCognitoUser();
    const dispatch = useDispatch();
    const login = useCallback(() => {
        dispatch(navigateToSSO({}));
    }, [dispatch]);
    const logout = useCallback(() => {
        dispatch(cognitoLogout({ user: cognitoUser }));
    }, [dispatch, cognitoUser]);

    return cognitoUser ? (
        <StyledTypography
            component="a"
            data-target="cognito-logout"
            onClick={logout}
        >
            Log out
        </StyledTypography>
    ) : (
        <StyledTypography
            component="a"
            data-target="cognito-login"
            onClick={login}
        >
            Login
        </StyledTypography>
    );
}
