import memoize from "lodash/memoize";
import { getMetamaskProvider } from "@liveart/nft-client/dist/metamask";
import { Contract, providers } from "ethers";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { createReadonlyContractInstance } from "~/BFF/blockchain/solidityContracts/createReadonlyContractInstance";
import { getBlockchainId } from "~/api/web3/getBlockchainId";

export const createContractWithMetamaskSigner = memoize(
    async ({ contract }: { contract: SolidityContractDetails }) => {
        const mmProvider = await getMetamaskProvider();
        if (mmProvider) {
            const web3Provider = new providers.Web3Provider(mmProvider);
            return new Contract(
                contract.address,
                contract.abi,
                web3Provider.getSigner(),
            );
        }

        return createReadonlyContractInstance({
            contract,
            blockchainId: await getBlockchainId(),
        });
    },
    ({ contract }) => contract.address,
);
