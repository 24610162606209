import { createAction } from "@reduxjs/toolkit";
import {
    DirectMintEdition,
    WalletDetails,
    DirectMintStates,
    DirectMintActiveMintDetails,
    DirectMintEditionDetials,
} from "~/api/data-schema";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { WinterProject } from "~/api/data-schema/Winter";
import { WhiteList } from "../data-schema/WhiteListedMintAddresses";

export const loadDirectMintContractResponse =
    createAction<SolidityContractDetails>(
        "API/DIRECT_MINT/LOAD_DIRECT_MINT_CONTRACT",
    );

export const loadDirectMintEditionDetails = createAction<{
    blockchainId: string;
    tokenContractAddress: string;
    editionId: number;
}>("API/DIRECT_MINT/LOAD_DIRECT_MINT_EDITION_DETAILS");

export const loadDirectMintDetailsResponse = createAction<
    Partial<DirectMintEdition> & {
        winterProject: WinterProject | undefined;
    }
>("API/DIRECT_MINT/LOAD_DIRECT_MINT_DETAILS_RESPONSE");

export const loadDirectMintWhiteListsResponse = createAction<WhiteList[]>(
    "API/DIRECT_MINT/LOAD_DIRECT_MINT_WHITELISTS_RESPONSE",
);

export const walletDetails = createAction<Partial<WalletDetails>>(
    "API/DIRECT_MINT/WALLET_DETAILS",
);

export const directMintEditionTokens = createAction<{
    editionId: number;
    quantity: number;
    recipient: string;
    tokenContractAddress: string;
    publicMintPriceInFinney: number;
    isFreeMint?: boolean;
}>("API/DIRECT_MINT/EDITION_TOKENS");

export const directMintWhitelistMint = createAction<{
    editionId: number;
    maxAmount: number;
    mintPriceInFinney: number;
    quantity: number;
    recipient: string;
    tokenContractAddress: string;
    whitelistId?: string;
    isFreeMint?: boolean;
}>("API/DIRECT_MINT/WHITELIST_MINT");

export const directMintUserMintCount = createAction<{
    tokenContractAddress: string;
    blockchainId: string;
    editionId: number;
    recipient: string;
}>("API/DIRECT_MINT/REMAINING_USER_MINT_LIMIT");

export const directMintUserMintCountResponse = createAction<number>(
    "API/DIRECT_MINT/REMAINING_USER_MINT_COUNT_RESPONSE",
);

export const updateMintStatus = createAction<DirectMintStates>(
    "API/DIRECT_MINT/UPDATE_MINT_STATUS",
);

export const mintedTokenIdResponse = createAction<string>(
    "API/DIRECT_MINT/MINTED_TOKEN_ID_RESPONSE",
);

export const updateEditionAfterDirectMint = createAction<{
    editionId: number;
    contractAddress: string;
}>("API/DIRECT_MINT/UPDATE_EDITION_AFTER_DIRECT_MINT");

export const loadActiveMintDetailsResponse = createAction<
    Partial<DirectMintActiveMintDetails>
>("API/DIRECT_MINT/LOAD_ACTIVE_MINT_DETAILS_RESPONSE");

export const loadDirectMintTimes = createAction<{
    whitelists: WhiteList[];
    edition: DirectMintEditionDetials;
    mintStageCount: number;
}>("API/DIRECT_MINT/LOAD_DIRECT_MINT_TIMES");

export const loadTokenMintStagesResponse = createAction<number>(
    "API/DIRECT_MINT/LOAD_TOKEN_MINT_STAGES_RESPONSE",
);
