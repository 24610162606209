export enum EventNames {
    user_logged_in = "pe21506169_user_logged_in",
    user_log_out = "pe21506169_user_log_out",
    artwork_engagement = "pe21506169_artwork_engagement",
    bid_placed = "pe21506169_bid_placed",
    meta_wallet_connected = "pe21506169_meta_wallet_connected",
    artwork_link_clicked = "pe21506169_link_clicked",
    button_clicked = "pe21506169_button_clicked",
    deal_action = "pe21506169_deal_action",

    user_signed_up = "pe21506169_user_signed_up",
}

export enum ArtworkEngagementEventTypes {
    artwork_viewed = "artwork viewed",
}

export enum DealActionEventTypes {
    aquire_now_clicked = "acquire now clicked",
}

export enum BidPlacedEventTypes {
    credit_card_bid_placed = "credit card bid placed",
    eth_bid_placed = "ether bid placed",
}

export enum ArtworkLinkClickedEventTypes {
    view_etherscan = "view artwork on etherscan",
    view_metadata = "view artwork on metadata",
    view_ipfs = "view artwork on ipfs",
}

export enum ArtworkButtonClickedEventTypes {
    view_poa = "artwork view proof of authenticity",
    place_bid = "artwork place bid clicked",
    sell_art = "sell art clicked",
    contact_liveart = "contact liveart clicked",
}
