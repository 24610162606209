import { useEffect } from "react";
import { Provider } from "react-redux";
import { Store } from "@reduxjs/toolkit";
import { init, stytchLoadAuthenticationState } from "@liveart/auth";

export function StytchProvider({
    children,
    store,
}: React.PropsWithChildren<{
    store: Store;
}>) {
    useEffect(() => {
        init({
            dispatch: store.dispatch,
        });
        store.dispatch(stytchLoadAuthenticationState());
    }, [store]);

    return <Provider store={store}>{children}</Provider>;
}
