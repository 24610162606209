import { put } from "redux-saga/effects";
import { setLoading, handleAppNotification } from "~/api/choreography";
import { LoadingSlots } from "~/api/data-schema";
import {
    unsubscribeFromBidResponse,
    bidResponse,
    cashBidPlaced,
    ethBidPlaced,
    loadTokenMarketData,
    bidFailed,
    getCurrencyRates,
} from "../choreography";
import { Currency } from "../data-schema";

export function* bidReponseSaga(action: ReturnType<typeof bidResponse>) {
    const { success, message, tokenId, auctionId, isCash, application } =
        action.payload;
    if (success) {
        if (isCash) yield put(cashBidPlaced());
        else yield put(ethBidPlaced());
    } else {
        if (message.toLowerCase().includes("invalid payment intent price")) {
            yield put(
                getCurrencyRates({
                    from: Currency.ETH,
                    to: Currency.USD,
                }),
            );
            yield put(
                getCurrencyRates({
                    from: Currency.USD,
                    to: Currency.ETH,
                }),
            );
        }
        yield put(bidFailed());
        yield put(
            handleAppNotification({
                message: "Failed to place bid - please try again later",
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
    if (tokenId) yield put(loadTokenMarketData({ tokenId }));
    yield put(
        setLoading({
            slot: LoadingSlots.BUY_IN_PROGRESS,
            loading: false,
        }),
    );
    yield put(
        unsubscribeFromBidResponse({
            tokenId,
            auctionId,
            application,
        }),
    );
}
