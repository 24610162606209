import React, { useMemo, useContext } from "react";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import Head from "next/head";
import { getApplicationFromHostname } from "../../host";
import { HostContext } from "~/features/app/hostContext";

export function MetaTagsByApplication() {
    const value = useContext(HostContext);
    const application = getApplicationFromHostname(value);

    const metaByApp = useMemo(() => {
        switch (application) {
            case SupportedApplication.artechouse: {
                return {
                    title: "ARTECHOUSE | NFT Art Marketplace",
                    description:
                        "View the ARTECHOUSE Experiential NFT collections here.",
                    url: "https://nft.artechouse.com/",
                    logo: "https://drive.google.com/uc?export=view&id=1nsHJvC7bVO7hDIc2nhUWeO3HAq1IIp3U",
                    domain: "nft.artechouse.com",
                };
            }
            case SupportedApplication.ephemeralEternal: {
                return {
                    title: "Ephemeral / Ethernal",
                    description: "",
                    url: "https://nft.ephemeralethernal.com/",
                    logo: "https://drive.google.com/uc?export=view&id=1S7mq13elyKfO0rpDZ5d3n6pNTwR07b5I",
                    domain: "nft.ephemeralethernal.com",
                };
            }
            default: {
                return {
                    title: "LiveArtX The Curated NFT Art Marketplace",
                    description:
                        "Creators and collectors meet Web3 at LiveArtX. Built by art insiders and tech innovators.",
                    url: "https://liveart.xyz/",
                    logo: "https://drive.google.com/uc?export=view&id=1L1ueqstHc9O4wJ0Mxzv0Ot0o_uwKqdZz",
                    domain: "liveart.xyz",
                };
            }
        }
    }, [application]);

    return (
        <Head>
            {/* HTML Meta Tags */}
            <title>{metaByApp.title}</title>
            <meta name="description" content={metaByApp.description} />

            {/* Facebook Meta Tags */}
            <meta property="og:url" content={metaByApp.url} />
            <meta property="og:title" content={metaByApp.title} />
            <meta property="og:description" content={metaByApp.description} />
            <meta property="og:image" content={metaByApp.logo} />

            {/* Twitter Meta Tags */}
            <meta property="twitter:domain" content={metaByApp.domain} />
            <meta property="twitter:url" content={metaByApp.url} />
            <meta name="twitter:title" content={metaByApp.title} />
            <meta name="twitter:description" content={metaByApp.description} />
            <meta name="twitter:image" content={metaByApp.logo} />
        </Head>
    );
}
