import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import { weiToEther } from "~/api/web3";
import { fromContractReturnedRights } from "../ERC721/rights";
import {
    BeBid,
    FeBid,
    ContractReturnedBasicAuction,
    BasicAuction,
} from "../data-schema";
import { getAuctionBids } from "./marketBE";
import { timestampToDate } from "../timestamp";

export function normalizeBeBid(bid: BeBid): FeBid {
    return {
        ...bid,
        bidAmount: weiToEther(bid.bidAmount.toString()),
        timestamp: timestampToDate(+bid.timestamp),
    };
}

export async function getAuctionBidsFromBack(
    networkId: number,
    tokenId: number,
    auctionId: number | BigNumber,
) {
    const { bids } = await getAuctionBids(networkId, auctionId);
    return bids.map(normalizeBeBid);
}

export async function fromContractReturnedBasicAuctions(
    auctions: ContractReturnedBasicAuction[],
    networkId: number,
    tokenId: number,
): Promise<BasicAuction[]> {
    return Promise.all(
        auctions.map(async (auction) => {
            return {
                tokenId: +auction.tokenId,
                tokenContractAddress: auction.tokenContractAddress,
                tokenType: auction.tokenType,
                seller: auction.seller,
                auctionType: auction.auctionType,
                auctionId: +auction.auctionId,
                bidIncrement: new BigNumber(String(auction.bidIncrement))
                    .div(10000)
                    .toString(),
                bids: await getAuctionBidsFromBack(
                    +networkId,
                    +tokenId,
                    +auction.auctionId,
                ),
                sellRights: fromContractReturnedRights(auction.sellRights),
                startingBid: weiToEther(auction.startingBid.toString()),
                reservePrice: weiToEther(auction.reservePrice.toString()),
                auctionStartDate: new Date(+auction.auctionStartDate * 1000),
                auctionEndDate: new Date(+auction.auctionEndDate * 1000),
                listingFee: {
                    feeRecepient: auction.listingFee.feeRecepient,
                    feeVolumePercentage:
                        +auction.listingFee.feeVolumePercentage,
                },
                liveArtMarketFee: {
                    feeRecepient: auction.liveArtMarketFee.feeRecepient,
                    feeVolumePercentage:
                        +auction.liveArtMarketFee.feeVolumePercentage,
                },
            };
        }),
    );
}

export function isBidValid(auction: BasicAuction, bid: string): boolean {
    const [lastBid] = auction.bids;

    if (lastBid) {
        return new BigNumber(bid).gte(
            new BigNumber(lastBid.bidAmount).plus(
                new BigNumber(lastBid.bidAmount).multipliedBy(
                    new BigNumber(auction.bidIncrement),
                ),
            ),
        );
    }

    return new BigNumber(bid).gte(auction.startingBid);
}

export function getValidMinimumBid(
    auction: Pick<BasicAuction, "bids" | "bidIncrement" | "startingBid">,
): string {
    const [lastBid] = auction.bids;

    if (lastBid) {
        return new BigNumber(lastBid.bidAmount)
            .plus(
                new BigNumber(lastBid.bidAmount).multipliedBy(
                    new BigNumber(auction.bidIncrement),
                ),
            )
            .toString();
    }

    return auction.startingBid.toString();
}

export function isReservePriceMet(
    auction: BasicAuction,
    lastBid: Omit<FeBid, "timestamp">,
): boolean {
    return new BigNumber(lastBid.bidAmount).gte(
        new BigNumber(auction.reservePrice),
    );
}
