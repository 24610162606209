import { createAction } from "@reduxjs/toolkit";
import { StripeCardElement } from "@stripe/stripe-js";
import {
    CognitoUser,
    CreatedPaymentIntentAuctionMetadata,
} from "~/api/data-schema";

export const setBid = createAction<{
    bidInEth: string;
    ethUsdExchangeRate?: number;
}>("PURCHASE_FLOW/SET_BID");

export const setBidInUSD = createAction<{
    bidInUSD: number;
    usdEthExchangeRate: number;
}>("PURCHASE_FLOW/SET_BID_IN_USD");

export const setFullName = createAction<string>("PURCHASE_FLOW/SET_FULL_NAME");

export const onStripeCardChange = createAction<{
    stripeError: Error | undefined;
    stripeComplete: boolean | undefined;
}>("PURCHASE_FLOW/ON_STRIPE_CARD_CHANGE");

export const toggleSaveStripeCard = createAction<boolean>(
    "PURCHASE_FLOW/TOGGLE_SAVE_STRIPE_CARD",
);

export const submitStripeBid = createAction<{
    card: StripeCardElement | undefined;
    amount: string;
    customerId?: string;
    paymentMethodId?: string;
    tokenId: number;
    auctionId: number;
    metadata?: CreatedPaymentIntentAuctionMetadata;
    user?: CognitoUser;
}>("PURCHASE_FLOW/SUBMIT_STRIPE_BID");
