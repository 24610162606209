import { call } from "redux-saga/effects";
import { setDefaultBlockchainId } from "~/api/choreography";
import { pageContextSessionStorage } from "~/api/pageContextSessionStorage";
import { setSearchParam } from "~/api/location";
import { notifyToDefaultBlockchainIdChange } from "./defaultBlockchainId";

export const defaultBlockchainIdKey = "web3/defaultBlockchainId";
export function* setDefaultBlockchainIdSaga(
    action: ReturnType<typeof setDefaultBlockchainId>,
) {
    const blockchainId = action.payload;
    yield call(() => {
        pageContextSessionStorage.setItem(
            defaultBlockchainIdKey,
            String(blockchainId),
        );
        setSearchParam("blockchainId", String(blockchainId));
        notifyToDefaultBlockchainIdChange();
    });
}
