import { createReducer } from "@reduxjs/toolkit";
import { setLoadFileProgress } from "~/api/choreography";

export interface LoadFileState {
    fileLoading: boolean;
    fileName: string;
    fileSize: number | null;
    loaded: number | null;
}
export interface WithLoadFile {
    uploadProgress: LoadFileState;
}
const defaultState: LoadFileState = {
    fileLoading: false,
    fileName: "",
    fileSize: null,
    loaded: null,
};

export const uploadProgressReducer = createReducer(defaultState, (builder) =>
    builder.addCase(setLoadFileProgress, (state, { payload }) => ({
        ...state,
        ...payload,
    })),
);

export const uploadProgressSelector = (state: WithLoadFile) =>
    state.uploadProgress;
