import { getChainId } from "@liveart/nft-client/dist/web3";
import { getSelectedMetamaskAccount } from "@liveart/nft-client/dist/metamask";
import { CashBuyer, CognitoUserWallet } from "~/api/data-schema";
import { getCurrentCognitoUser } from "~/api/user";
import { getAccessToken } from "~/api/user/getAccessToken";

export async function getBuyerWallet(cashBuyer: CashBuyer) {
    const selectedMmAccount = await getSelectedMetamaskAccount();
    const blockchainId = await getChainId();
    const currentUser = await getCurrentCognitoUser(
        (await getAccessToken()) as string,
        blockchainId,
    );
    const userNonShadowWallet = (
        currentUser?.wallets?.find(
            (wallet) => wallet?.issuedBy?.toLowerCase() !== "liveart",
        ) as CognitoUserWallet | undefined
    )?.wallet;

    return selectedMmAccount || userNonShadowWallet || cashBuyer.walletAddress;
}
