export function setSearchParam(key: string, value: string) {
    const searchParams = new URLSearchParams(
        globalThis?.window?.location?.search,
    );
    searchParams.set(key, value);
    globalThis?.window?.history?.replaceState(
        null,
        "null",
        `${globalThis?.window?.location?.pathname}?${searchParams}`,
    );
}
