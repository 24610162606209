export async function normalizeResponse(resp: Response) {
    if (!resp.ok) {
        const errMsg = await resp.clone().text();
        if (errMsg.includes("socket hang up")) {
            const clone = resp.clone();

            return new Proxy(clone, {
                get(target: Response, p: string | symbol): unknown {
                    if (p === "status") {
                        return 500;
                    }

                    return target[p];
                },
            });
        }

        return resp.clone();
    }

    return resp;
}
