import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { getApplicationFromHostname } from "../../host";

export function useLabelByApplication(
    label:
        | "ByNowDialog: Pending Header"
        | "Buy Now"
        | "Buy"
        | "What are you buying?",
) {
    const application = getApplicationFromHostname();

    switch (application) {
        case SupportedApplication.artechouse: {
            switch (label) {
                case "ByNowDialog: Pending Header":
                    return "Your payment is pending";
                case "Buy Now":
                    return "Purchase Now";
                case "Buy":
                    return "Pay".toUpperCase();
                case "What are you buying?":
                    return "What did you purchase?";
                default:
                    return label;
            }
        }
        default:
            switch (label) {
                case "ByNowDialog: Pending Header":
                    return "Transaction pending";
                default:
                    return label;
            }
    }
}
