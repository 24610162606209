import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { Currency, LoadingSlots } from "~/api/data-schema";
import { getCurrencyRates } from "~/api/choreography";
import { useLoadingSlot } from "~/features/loading";
import { currencyExchangeSelector } from "./currencyExchangeReducer";

export function useCurrencyExchange({
    value,
    from,
    to,
}: {
    value: string | number | BigNumber | undefined;
    from: Currency | undefined;
    to: Currency | undefined;
}): number | undefined {
    const dispatch = useDispatch();
    const loading = useLoadingSlot(LoadingSlots.EXCHANGING_CURRENCY);
    const exchangeRate = useSelector(currencyExchangeSelector(from, to));

    useEffect(() => {
        if (!exchangeRate && !loading && from && to) {
            dispatch(getCurrencyRates({ from, to }));
        }
    }, [dispatch, exchangeRate, loading, from, to]);

    const exchanged: number | undefined = useMemo<number | undefined>(
        () =>
            !value || !exchangeRate
                ? undefined
                : new BigNumber(value).multipliedBy(exchangeRate).toNumber(),
        [value, exchangeRate],
    );

    return exchanged;
}
