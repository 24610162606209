import { getChainId } from "@liveart/nft-client/dist/web3";
import { SEND_TO_HUBSPOT } from "./hubspot";
import { query } from "../graphQL";

export async function sendToHubspot(payload: Object, accessToken: string) {
    const chainId = await getChainId();
    return query({
        networkId: chainId,
        queryStr: SEND_TO_HUBSPOT,
        accessToken,
        variables: {
            payload: JSON.stringify(payload),
        },
    });
}
