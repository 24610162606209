import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import memoize from "lodash/memoize";
import {
    MAIN_NET_ID,
    RINKEBY_CHAIN_ID,
} from "@liveart/nft-client/dist/chainId";
import { fetchWithAuthHeaders } from "~/api/fetchWithHeaders/fetchWithAuthHeaders";
import { CustomHeaders } from "~/api/fetchWithHeaders/getHeaders";
import { getWeb3Token } from "../web3/web3-token";
import {
    BasicAuction,
    BeBid,
    BuyNowListing,
    CashTransferInputData,
    FeBid,
    SellEvent,
    Token,
} from "../data-schema";
import { CognitoUser, CashBuyer } from "~/api/data-schema";
import { EmailNotificationType } from "~/BFF/emailNotifications/emailNotificationType";
import { normalizeBidUser } from "~/api/treasurer/normalizeBidUser";

export async function getLastBid(
    networkId: number,
    auctionId: number,
    retry = 0,
): Promise<BeBid | undefined> {
    const searchParams = new URLSearchParams();
    searchParams.set("networkId", String(networkId));
    searchParams.set("auctionId", String(auctionId));

    const resp = await fetchWithAuthHeaders(
        `/api/bid/last-bid?${searchParams.toString()}`,
    );

    if (resp.status >= 500 && retry <= 10) {
        return getLastBid(networkId, auctionId, retry + 1);
    }

    if (resp.status === 404) {
        return undefined;
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
export async function getAuctionBids(
    networkId: number,
    auctionId: number | BigNumber,
    retry = 0,
): Promise<{ bids: BeBid[] }> {
    const searchParams = new URLSearchParams();
    searchParams.set("networkId", String(networkId));
    searchParams.set("auctionId", String(auctionId));

    const resp = await fetchWithAuthHeaders(
        `/api/bid/auction-bids?${searchParams.toString()}`,
    );

    if (resp.status >= 500 && retry <= 10) {
        return getAuctionBids(networkId, auctionId, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function releaseAllPayments(
    networkId: number,
    auctionId: number | BigNumber,
    retry = 0,
) {
    const searchParams = new URLSearchParams();
    searchParams.set("networkId", String(networkId));
    searchParams.set("auctionId", String(auctionId));

    const resp = await fetchWithAuthHeaders(
        `/api/cash-purchase/release-all-payment-intents?${searchParams}`,
    );
    if (resp.status >= 500 && retry <= 10) {
        return releaseAllPayments(networkId, auctionId, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return undefined;
}

export async function addSellEvent(
    {
        networkId,
        sellEvent,
        application,
        tokenContractAddress,
    }: {
        networkId: number;
        sellEvent: SellEvent;
        application: SupportedApplication;
        tokenContractAddress: string;
    },
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders("/api/sell-event", {
        method: "POST",
        body: JSON.stringify({
            networkId,
            sellEvent,
            application,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return addSellEvent(
            {
                networkId,
                sellEvent,
                application,
                tokenContractAddress,
            },
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return undefined;
}
export async function getSellEvents(
    {
        tokenId,
        tokenContractAddress,
    }: {
        tokenId: number;
        tokenContractAddress: string;
    },
    retry = 0,
): Promise<SellEvent[]> {
    const params = new URLSearchParams();
    params.set("tokenId", String(tokenId));
    params.set("tokenContractAddress", String(tokenContractAddress));

    const resp = await fetchWithAuthHeaders(`/api/sell-events?${params}`);

    if (resp.status >= 500 && retry <= 10) {
        return getSellEvents({ tokenId, tokenContractAddress }, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    const data = await resp.json();

    return data.map((event) => ({
        ...event,
        timestamp: new Date(event.timestamp),
    }));
}

export const getCashBuyer: (
    props: {
        useDevEnv: boolean;
        cognitoUserId: string;
        application: SupportedApplication;
        connectedWalletAddress?: string;
    },
    retry?: number,
) => Promise<CashBuyer | undefined> = memoize(
    async (
        {
            useDevEnv,
            cognitoUserId,
            application,
            connectedWalletAddress,
        }: {
            useDevEnv: boolean;
            cognitoUserId: string;
            application: SupportedApplication;
            connectedWalletAddress?: string;
        },
        retry = 0,
    ) => {
        const networkId = useDevEnv ? RINKEBY_CHAIN_ID : MAIN_NET_ID;
        const searchParams = new URLSearchParams();

        searchParams.set("networkId", String(networkId));
        searchParams.set("application", application);
        searchParams.set("cognitoUserId", cognitoUserId);
        if (connectedWalletAddress) {
            searchParams.set("connectedWalletAddress", connectedWalletAddress);
        }

        const resp = await fetchWithAuthHeaders(
            `/api/cash-purchase/cash-buyer?${searchParams.toString()}`,
        );

        if (!resp.ok && retry <= 5) {
            return getCashBuyer(
                {
                    useDevEnv,
                    cognitoUserId,
                    application,
                    connectedWalletAddress,
                },
                retry + 1,
            );
        }

        if (!resp.ok) {
            throw new Error(await resp.text());
        }

        return resp.json();
    },
    ({ useDevEnv, cognitoUserId, application, connectedWalletAddress }) =>
        useDevEnv + cognitoUserId + application + connectedWalletAddress,
);

export async function getShadowWalletFromBe(
    networkId: number,
    cognitoUserId: string,
    application: SupportedApplication,
    retry = 0,
): Promise<{
    walletAddress: string;
    privateKey: string;
}> {
    const searchParams = new URLSearchParams();
    searchParams.set("networkId", String(networkId));
    searchParams.set("application", application);
    searchParams.set("cognitoUserId", cognitoUserId);

    const resp = await fetchWithAuthHeaders(
        `/api/cash-purchase/get-shadow-wallet?${searchParams.toString()}`,
    );

    if (resp.status >= 500 && retry <= 10) {
        return getShadowWalletFromBe(
            networkId,
            cognitoUserId,
            application,
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function getPaymentMethodsFromBe(
    { customerId, useDevEnv }: { useDevEnv: boolean; customerId: string },
    retry = 0,
) {
    const networkId = useDevEnv ? RINKEBY_CHAIN_ID : MAIN_NET_ID;
    const searchParams = new URLSearchParams();
    searchParams.set("customerId", customerId);
    searchParams.set("networkId", String(networkId));

    const resp = await fetchWithAuthHeaders(
        `/api/stripe/payment-methods?${searchParams.toString()}`,
    );

    if (resp.status >= 500 && retry <= 10) {
        return getPaymentMethodsFromBe({ useDevEnv, customerId }, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function removePaymentMethod(
    {
        paymentMetohdId,
        networkId,
    }: {
        paymentMetohdId: string;
        networkId: number;
    },
    retry = 0,
) {
    const searchParams = new URLSearchParams();
    searchParams.set("paymentMetohdId", String(paymentMetohdId));
    searchParams.set("networkId", String(networkId));

    const resp = await fetchWithAuthHeaders(
        `/api/cash-purchase/remove-payment-method?${searchParams.toString()}`,
    );

    if (resp.status >= 500 && retry <= 10) {
        return removePaymentMethod({ paymentMetohdId, networkId }, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function cashTransferOnBe(
    networkId: string,
    inputData: CashTransferInputData,
    to: string,
    application: string,
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(
        `/api/cash-purchase/cash-transfer`,
        {
            method: "POST",
            body: JSON.stringify({
                networkId,
                inputData,
                to,
            }),
            headers: {
                [CustomHeaders.X_WEB3_TOKEN]: await getWeb3Token(),
                [CustomHeaders.X_APPLICATION]: application,
            },
        },
    );

    if (resp.status >= 500 && retry <= 10) {
        return cashTransferOnBe(
            networkId,
            inputData,
            to,
            application,
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function notifyAboutNewBid(
    networkId: number,
    bid: BeBid,
    tokenId: string | number,
    auctionId: string | number,
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders("/api/bid/notify", {
        method: "POST",
        body: JSON.stringify({
            networkId,
            auctionId,
            tokenId,
            bid: normalizeBidUser(bid),
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return notifyAboutNewBid(networkId, bid, tokenId, auctionId, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function sendEmailNotificationWinnerAndAcquireNow(
    user: CognitoUser,
    token: Token,
    notificationType: EmailNotificationType,
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(`/api/email-notification`, {
        method: "POST",
        body: JSON.stringify({
            notificationType,
            user,
            token,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return sendEmailNotificationWinnerAndAcquireNow(
            user,
            token,
            notificationType,
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
export async function sendEmailNotificationToClaimToken(
    user: CognitoUser,
    token: Token,
    notificationType: EmailNotificationType,
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(`/api/email-notification`, {
        method: "POST",
        body: JSON.stringify({
            notificationType,
            user,
            token,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return sendEmailNotificationToClaimToken(
            user,
            token,
            notificationType,
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function sendEmailNotificationToOutbidUser(
    user: CognitoUser,
    token: Token,
    bidAmount: string,
    previousBidAmount: string,
    bidIncrement: string,
    notificationType: EmailNotificationType,
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(`/api/email-notification`, {
        method: "POST",
        body: JSON.stringify({
            notificationType,
            user,
            token,
            bidAmount,
            previousBidAmount,
            bidIncrement,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return sendEmailNotificationToOutbidUser(
            user,
            token,
            bidAmount,
            previousBidAmount,
            bidIncrement,
            notificationType,
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function getTokenMarketDataFromBe(
    payload: {
        networkId: number;
        application: SupportedApplication;
        tokenId: number;
        tokenContractAddress: string;
    },
    retry = 0,
): Promise<{
    buyNowDeals: BuyNowListing[];
    auctions: BasicAuction[];
    transfers: SellEvent[];
    tokenBids: FeBid[];
    hasLock?: boolean;
    seller: string;
}> {
    const resp = await fetchWithAuthHeaders(
        `/api/treasurer/get-token-market-data`,
        {
            method: "POST",
            body: JSON.stringify(payload),
        },
    );

    if (resp.status >= 500 && retry <= 10) {
        return getTokenMarketDataFromBe(payload, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
