import React from "react";
import { useTheme } from "@mui/system";
import dynamic from "next/dynamic";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import CertificateOfRegistrationFilled from "~/features/icon/certificateOfRegistrationFilled.svg";
import CertificateOfRegistrationFilledInverted from "~/features/icon/certificateOfRegistrationFilledInverted.svg";
import CertificateOfRegistrationLiveart from "~/features/icon/certificateOfRegistrationLiveart.svg";
import { getApplicationFromHostname } from "../../host";

const CertificateOfRegistrationFilledICA = dynamic<
    Parameters<SvgrComponent>[0]
>(() => import("./certificateOfRegistrationFilledICA.svg"));

const CertificateOfRegistrationArtuner = dynamic<Parameters<SvgrComponent>[0]>(
    () => import("./certificateOfRegistrationArtuner.svg"),
);

const CertificateOfRegistrationArtechouse = dynamic<
    Parameters<SvgrComponent>[0]
>(() => import("./certificateOfRegistrationArtechouse.svg"));

const CertificateOfRegistrationArtechouseWhite = dynamic<
    Parameters<SvgrComponent>[0]
>(() => import("./certificateOfRegistrationArtechouseWhite.svg"));

const CertificateOfRegistrationEphemeralEthernal = dynamic<
    Parameters<SvgrComponent>[0]
>(() => import("./certificateOfRegistrationEphemeralEthernal.svg"));

const CertificateOfRegistrationEphemeralEthernalWhite = dynamic<
    Parameters<SvgrComponent>[0]
>(() => import("./certificateOfRegistrationEphemeralEthernalWhite.svg"));

type SvgIconProps = {
    className?: string;
    width?: string | number;
    height?: string | number;
    viewBox?: string;
    fill?: string;
    xmlns?: string;
    inverted?: boolean;
    isPdfDialog?: boolean;
};

export const CertificateOfRegistrationIconByApplication: React.FC<
    SvgIconProps & { isNewPOA?: boolean }
> = ({ className, inverted, isNewPOA = false, ...other }) => {
    const theme = useTheme();
    const application = getApplicationFromHostname();
    switch (application) {
        case SupportedApplication.ephemeralEternal:
            return inverted ? (
                <CertificateOfRegistrationEphemeralEthernalWhite
                    className={className}
                    {...other}
                />
            ) : (
                <CertificateOfRegistrationEphemeralEthernal
                    className={className}
                    {...other}
                    viewBox="0 0 76 76"
                />
            );
        case SupportedApplication.artuner:
            return (
                <CertificateOfRegistrationArtuner
                    className={className}
                    {...other}
                />
            );
        case SupportedApplication.artechouse:
            return inverted ? (
                <CertificateOfRegistrationArtechouseWhite
                    className={className}
                    {...other}
                />
            ) : (
                <CertificateOfRegistrationArtechouse
                    className={className}
                    {...other}
                />
            );
        case SupportedApplication.icamiami:
            return (
                <CertificateOfRegistrationFilledICA
                    className={className}
                    color={theme.palette.secondary.main}
                    {...other}
                />
            );
        default:
            if (isNewPOA) {
                return (
                    <CertificateOfRegistrationLiveart
                        className={className}
                        color={theme.palette.secondary.main}
                        {...other}
                    />
                );
            }
            return inverted ? (
                <CertificateOfRegistrationFilledInverted
                    className={className}
                    color={theme.palette.secondary.main}
                    {...other}
                />
            ) : (
                <CertificateOfRegistrationFilled
                    className={className}
                    color={theme.palette.secondary.main}
                    {...other}
                />
            );
    }
};
