import React from "react";
import { AppProps } from "next/app";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MuiPickersAdapter } from "@mui/x-date-pickers/internals/models";
import Head from "~/features/head";
import { HostContext } from "~/features/app/hostContext";
import { HeavyApp } from "~/pages/HeavyApp";

interface BasicAppProps extends AppProps {
    host: string;
}

export default function BasicApp(props: BasicAppProps) {
    const { host } = props;

    return (
        <HostContext.Provider value={host}>
            <LocalizationProvider
                dateAdapter={
                    AdapterDateFns as unknown as new (
                        ...args: unknown[]
                    ) => MuiPickersAdapter<unknown>
                }
            >
                <Head />
                <HeavyApp {...props} />
            </LocalizationProvider>
        </HostContext.Provider>
    );
}

BasicApp.getInitialProps = async ({ ctx }) => {
    return {
        host: ctx.req?.headers.host,
    };
};
