import { TokenType } from "../data-schema";

export enum MarketTokenType {
    LIVEART_PROPRIETARY = "LIVEART_PROPRIETARY",
    PLAIN_ERC721 = "PLAIN_ERC721",
    EIP755 = "EIP755",
}

export const MapTokenTypeToMarketTokenType: {
    [index in TokenType]: MarketTokenType;
} = {
    [TokenType.PROPRIETARY_DEPRECATED]: MarketTokenType.LIVEART_PROPRIETARY,
    [TokenType.PLAIN_ERC721]: MarketTokenType.PLAIN_ERC721,
    [TokenType.ERC721_WITH_VOLUNTARY_ROYALTIES]: MarketTokenType.EIP755,
    [TokenType.ERC721_WITH_BUY_OUT]: MarketTokenType.EIP755,
};
