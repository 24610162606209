import { Unpromisify } from "~/typescript";
import { getTokenMarketDataFromBe } from "~/api/market/marketBE";

export function normalizeMarketDataFromBE({
    buyNowDeals,
    auctions,
    transfers,
    tokenBids,
    hasLock,
    seller,
}: Unpromisify<ReturnType<typeof getTokenMarketDataFromBe>>) {
    return {
        buyNowDeals,
        auctions: auctions.map((el) => ({
            ...el,
            auctionStartDate: new Date(el.auctionStartDate),
            auctionEndDate: new Date(el.auctionEndDate),
            bids: el.bids.map((bid) => ({
                ...bid,
                timestamp: new Date(bid.timestamp),
            })),
        })),
        transfers: transfers.map((el) => ({
            ...el,
            timestamp: new Date(el.timestamp),
        })),
        tokenBids: tokenBids.map((el) => ({
            ...el,
            timestamp: new Date(el.timestamp),
        })),
        hasLock,
        seller,
    };
}
