import { call, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import {
    updateConfiguration,
    loadConfiguration,
    isWhitelistedContract,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./discover/discoverSaga"),
    () => 1,
);

export function* discoverApiSaga() {
    yield takeLatest(
        updateConfiguration.toString(),
        function* withUpdateConfigurationContractSaga(
            action: ReturnType<typeof updateConfiguration>,
        ) {
            const { updateConfigurationSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(updateConfigurationSaga, action);
        },
    );

    yield takeLatest(
        loadConfiguration.toString(),
        function* withLoadConfigurationSaga() {
            const { loadConfigurationSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(loadConfigurationSaga);
        },
    );

    yield takeLatest(
        isWhitelistedContract.toString(),
        function* withLoadConfigurationSaga(
            action: ReturnType<typeof isWhitelistedContract>,
        ) {
            const { isWhitelistedContractSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(isWhitelistedContractSaga, action);
        },
    );
}
