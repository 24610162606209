export type UnlockableContentState = {
    hasUnlockableContent?: boolean;
    isContentOwner?: boolean;
    unlockableContent?: string;
};
export type WithUnlockableContentState = {
    unlockableContent: UnlockableContentState;
};
export const defaultUnlockableContentState: UnlockableContentState = {
    hasUnlockableContent: false,
    isContentOwner: false,
    unlockableContent: "",
};

export const hasUnlockableContentResponseReducer = (
    state: WithUnlockableContentState,
    { payload },
): WithUnlockableContentState => {
    return {
        unlockableContent: {
            ...state.unlockableContent,
            hasUnlockableContent: payload,
        },
    };
};
export const isOwnerUnlockableContentResponseReducer = (
    state: WithUnlockableContentState,
    { payload },
): WithUnlockableContentState => {
    return {
        unlockableContent: {
            ...state.unlockableContent,
            isContentOwner: payload,
        },
    };
};
export const setUnlockableContentResponseReducer = (
    state: WithUnlockableContentState,
    { payload },
): WithUnlockableContentState => {
    return {
        unlockableContent: {
            ...state.unlockableContent,
            unlockableContent: payload,
        },
    };
};

export const unlockableContentStateSelector = (state: {
    pdp: { unlockableContent: UnlockableContentState };
}) => state.pdp.unlockableContent;
