import Head from "next/head";
import React from "react";

export default function () {
    return (
        <Head>
            {/* Facebook Meta Tags */}
            <meta property="og:type" content="website" />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
        </Head>
    );
}
