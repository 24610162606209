import { createReducer } from "@reduxjs/toolkit";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { loadDeployedContractsListResponse } from "~/api/choreography/solidityContracts";

export type SolidityContractsState = {
    deployedContracts: SolidityContractDetails[];
};

export const initialContractsState: SolidityContractsState = {
    deployedContracts: [],
};

export const solidityContractsReducer = createReducer(
    initialContractsState,
    (builder) =>
        builder.addCase(
            loadDeployedContractsListResponse,
            (state, { payload }) => ({
                ...state,
                deployedContracts: payload,
            }),
        ),
);

export const solidityContractStateSelector = (state: {
    solidityContracts: SolidityContractsState;
}) => state.solidityContracts;
