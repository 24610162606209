import { createReducer } from "@reduxjs/toolkit";
import {
    getAdminAirdropContractResponse,
    getAirdropTxDetailsResponse,
    generatedAirdropWalletsResponse,
} from "~/api/choreography";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export type AirdropState = {
    isContractAdminOrMinter: boolean;
    contractDetails: SolidityContractDetails;
    transactionHashes: string[];
    generatedWallets: string[];
};

export const initialAirdropState: AirdropState = {
    isContractAdminOrMinter: false,
    contractDetails: {} as SolidityContractDetails,
    transactionHashes: [],
    generatedWallets: [],
};

export const airdropReducer = createReducer(initialAirdropState, (builder) =>
    builder
        .addCase(
            getAdminAirdropContractResponse,
            (
                state,
                { payload: { isContractAdminOrMinter, contractDetails } },
            ) => ({
                ...state,
                isContractAdminOrMinter,
                contractDetails,
            }),
        )
        .addCase(
            getAirdropTxDetailsResponse,
            (state, { payload: { txHashes } }) => ({
                ...state,
                transactionHashes: txHashes,
            }),
        )
        .addCase(
            generatedAirdropWalletsResponse,
            (state, { payload: { wallets } }) => ({
                ...state,
                generatedWallets: wallets,
            }),
        ),
);

export const airdropSelector = (state: { airdrop: AirdropState }) =>
    state.airdrop;
