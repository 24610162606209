import { put, takeLatest } from "redux-saga/effects";
import {
    setApplicationAction,
    setApplicationSagaAction,
} from "./applicationModeActions";

export function* setApplicationSaga({
    payload,
}: ReturnType<typeof setApplicationSagaAction>) {
    globalThis?.localStorage?.setItem("application", payload);

    yield put(setApplicationAction(payload));
}

export function* applicationModeSaga() {
    yield takeLatest(setApplicationSagaAction.toString(), setApplicationSaga);
}
