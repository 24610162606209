import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { fetchWithAuthHeaders } from "~/api/fetchWithHeaders/fetchWithAuthHeaders";
import { Token } from "~/api/data-schema";
import { getBlockchainId } from "~/api/web3/getBlockchainId";
import { loadContractByAddress } from "~/BFF/blockchain/solidityContracts/loadContractByAddress";

export async function saveTokenOnBe(
    {
        token,
        networkId,
        application,
    }: { token: Token; networkId: number; application: SupportedApplication },
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(`/api/save-token`, {
        method: "POST",
        body: JSON.stringify({
            token,
            networkId,
            application,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return saveTokenOnBe(
            {
                token,
                networkId,
                application,
            },
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function updateTokenOnBe(
    token: { tokenId: number } & Partial<Token>,
    tokenContractAddress: string,
    retry = 0,
) {
    const networkId = await getBlockchainId();
    const contract = await loadContractByAddress(tokenContractAddress);
    const { application } = contract;

    const resp = await fetchWithAuthHeaders(
        `/api/update-token?application=${application}`,
        {
            method: "POST",
            body: JSON.stringify({
                networkId,
                token,
                tokenContractAddress,
            }),
        },
    );

    if (resp.status >= 500 && retry <= 10) {
        return updateTokenOnBe(token, tokenContractAddress, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function saveOpenEditionOnBe(
    {
        networkId,
        tokenContractAddress,
        parentTokenId,
        tokenMetadataURI,
    }: {
        networkId: number;
        tokenContractAddress: string;
        parentTokenId: number;
        tokenMetadataURI: string;
    },
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(`/api/tokens/open-edition`, {
        method: "POST",
        body: JSON.stringify({
            networkId,
            tokenContractAddress,
            parentTokenId,
            tokenMetadataURI,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return saveOpenEditionOnBe(
            {
                networkId,
                tokenContractAddress,
                parentTokenId,
                tokenMetadataURI,
            },
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
