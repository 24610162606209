import { Action } from "redux";
import { call, put, select } from "redux-saga/effects";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { stytchLoadAuthenticationStateSaga } from "@liveart/auth/lib/src/features/authentication/stytchLoadAuthenticationStateSaga";
import { trackSegmentEvent } from "@liveart/injectables/dist/choreography/segmentActions";
import { useAuthModalDevEnvSelector } from "@liveart/injectables/dist/auth/authConfigReducer";
import { GOERLI_CHAIN_ID, MAIN_NET_ID } from "@liveart/nft-client/dist/chainId";
import {
    CognitoPersistKeys,
    getValueFromStorage,
} from "@liveart/injectables/dist/user/getValueFromStorage";
import { trackExceptions } from "~/features/errorTracking";
import {
    cognitoLogout,
    finishCognitoAuthentication,
    getCognitoUserResponse,
    handleAppNotification,
    navigateTo,
    navigateToSSO,
    searchUsersById,
    searchUsersByIdResponse,
    searchUsersByWallets,
    searchUsersByWalletsResponse,
    setDefaultBlockchainId,
    setLamCognitoAuthInfo,
    setLoading,
    updateCurrentUser,
} from "~/api/choreography";
import { LoadingSlots } from "~/api/data-schema";
import { Unpromisify } from "~/typescript";
import { getApplicationFromHostname } from "~/WhiteLabel/host";
import { sendToHubspot } from "~/api/hubspot/sendToHubspot";
import { uploadFileToS3 } from "~/api/fileUpload";
import { getBlockchainId, web3AddressesEqual } from "~/api/web3";
import { CognitoUser } from "~/api/data-schema/CognitoUser";
import { getCurrentCognitoUser } from "./getCognitoUser";
import { searchAllUsers } from "./searchAllUsers";
import { updateCognitoUser } from "./updateCognitoUser";
import { EventNames } from "~/api/hubspot/hubspotEvents";
import { SegmentEvents, SegmentOption } from "~/api/segment/segmentEvents";
import { getApplicationFromLocation } from "~/api/application";
import { getCashBuyer } from "~/api/market/marketBE";
import { getENV } from "./getENV";
import { clearAllCookies } from "~/api/cookies/clearAllCookies";

export function* navigateToSSOSaga(action: ReturnType<typeof navigateToSSO>) {
    const { signup } = action.payload as { signup?: boolean };
    const { clientId, authenticationURL } = yield call(getENV);

    const searchParams = new URLSearchParams();
    searchParams.set(
        "redirect_uri",
        `${globalThis.location.origin}/authenticated`,
    );

    const state = JSON.stringify({
        signInRedirect: globalThis.location.href,
        signUpRedirect: "/sign-up-confirmation",
    });

    searchParams.set("state", state);
    searchParams.set("return_provider_props", "true");
    searchParams.set("respond_with_get", "true");
    searchParams.set("client_id", clientId);

    const url = `${authenticationURL}?${searchParams.toString()}${
        signup ? `&sign_up=true` : ""
    }`;
    globalThis.location = url as unknown as Location;
}

export function* finishCognitoAuthenticationSaga({
    payload: { last_auth_user, access_token, refresh_token, state, signed_up },
}: ReturnType<typeof finishCognitoAuthentication>) {
    globalThis?.localStorage?.setItem(
        CognitoPersistKeys.ACCESS_TOKEN,
        access_token,
    );
    globalThis?.localStorage?.setItem(
        CognitoPersistKeys.REFRESH_TOKEN,
        refresh_token,
    );

    const stateSearchParams = new URLSearchParams(state);
    const blockchainId =
        Number(stateSearchParams.get("blockchainId")) ||
        (yield call(getBlockchainId));
    yield put(setDefaultBlockchainId(blockchainId));
    const cognitoUser = yield call(
        getCurrentCognitoUser,
        access_token,
        blockchainId,
    );
    if (cognitoUser) {
        if (signed_up) {
            yield call(trackSegmentEvent, {
                eventName: SegmentEvents.CUSTOMER_CREATED,
                properties: {
                    email: cognitoUser?.email,
                    firstName: cognitoUser?.firstName,
                    lastName: cognitoUser?.lastName,
                },
                option: SegmentOption.track,
            });
            yield call(trackSegmentEvent, {
                eventName: SegmentEvents.IDENTIFY_SIGN_UP,
                properties: {
                    user_id: cognitoUser?.externalId,
                    email: cognitoUser?.email,
                    name: `${cognitoUser?.firstName}  ${cognitoUser?.lastName}`,
                },
                option: SegmentOption.identify,
            });
        }
        yield call(trackSegmentEvent, {
            eventName: SegmentEvents.IDENTIFY_LOG_IN,
            properties: {
                user_id: cognitoUser?.externalId,
                email: cognitoUser?.email,
                name: `${cognitoUser?.firstName}  ${cognitoUser?.lastName}`,
            },
            option: SegmentOption.identify,
        });
        yield call(trackSegmentEvent, {
            eventName: SegmentEvents.USER_LOGGED_IN,
            properties: {
                user_id: cognitoUser?.externalId,
                email: cognitoUser?.email,
                name: cognitoUser?.fullName,
            },
        });
        yield call(
            sendToHubspot,
            {
                eventName: EventNames.user_logged_in,
                email: cognitoUser?.email,
                properties: {
                    user_id: cognitoUser?.externalId,
                },
            },
            access_token,
        );
    }
    yield put(getCognitoUserResponse(cognitoUser));

    const useAuthModalDevEnv = yield select(useAuthModalDevEnvSelector);

    if (globalThis?.window?.location) {
        setTimeout(async () => {
            const application = getApplicationFromLocation();

            try {
                await getCashBuyer({
                    useDevEnv: useAuthModalDevEnv,
                    cognitoUserId: last_auth_user,
                    application,
                });
                // eslint-disable-next-line no-empty
            } catch {}

            try {
                const { signInRedirect, signUpRedirect } = JSON.parse(state);

                if (signed_up) {
                    globalThis.window.location.href = signUpRedirect;
                } else {
                    globalThis.window.location.href = signInRedirect;
                }
            } catch {
                if (signed_up && application === SupportedApplication.liveart) {
                    globalThis.window.location.href = "/sign-up-confirmation";
                    return;
                }
                globalThis.window.location.href = state;
            }
        }, 1000);
    }

    clearAllCookies();
}

export function* getCognitoUserSaga(action: Action, retries = 5) {
    try {
        yield put(
            setLoading({
                slot: LoadingSlots.COGNITO_USER,
                loading: true,
            }),
        );

        const accessToken = getValueFromStorage(
            CognitoPersistKeys.ACCESS_TOKEN,
        ) as string;

        const useAuthModalDevEnv = yield select(useAuthModalDevEnvSelector);
        const chainId = useAuthModalDevEnv ? GOERLI_CHAIN_ID : MAIN_NET_ID;

        const cognitoUser = yield call(
            getCurrentCognitoUser,
            accessToken,
            chainId,
        );

        if (!cognitoUser && accessToken) {
            yield call(stytchLoadAuthenticationStateSaga);
        }

        if (!cognitoUser && retries > 0) {
            yield call(getCognitoUserSaga, action, retries - 1);
        }

        if (cognitoUser || retries <= 0) {
            yield put(getCognitoUserResponse(cognitoUser));
        }
    } catch (e) {
        trackExceptions(e.message, e);
    } finally {
        yield put(
            setLoading({
                slot: LoadingSlots.COGNITO_USER,
                loading: false,
            }),
        );
    }
}

export function* updateCurrentUserSaga(
    action: ReturnType<typeof updateCurrentUser>,
) {
    try {
        const {
            payload: { newAvatar, userData, redirectOnSuccess },
        } = action;
        const { biography, nickname, socialLinks, wallets } = userData;

        yield put(
            setLoading({
                slot: LoadingSlots.UPDATE_PROFILE,
                loading: true,
            }),
        );

        const accessToken = getValueFromStorage(
            CognitoPersistKeys.ACCESS_TOKEN,
        ) as string;

        let avatarS3Key;
        if (newAvatar) {
            avatarS3Key = yield call(
                uploadFileToS3,
                newAvatar,
                `${userData.id}.avatar.${newAvatar.name.split(".").pop()}`,
                accessToken,
            );
        }

        const chainId = (yield call(getBlockchainId)) as Unpromisify<
            ReturnType<typeof getBlockchainId>
        >;
        yield call(updateCognitoUser, chainId, accessToken, {
            avatarS3Key,
            biography,
            nickname,
            socialLinks,
            wallets,
        });

        const user = yield call(getCurrentCognitoUser, accessToken, chainId);

        if (redirectOnSuccess) {
            yield put(
                navigateTo({
                    href: `/profile/${user.id}${globalThis?.window?.location?.search}`,
                    method: "push",
                }),
            );
        }

        yield call(getCognitoUserSaga, action);
    } catch (e) {
        trackExceptions(e.message, e);
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    } finally {
        yield put(
            setLoading({
                slot: LoadingSlots.UPDATE_PROFILE,
                loading: false,
            }),
        );
    }
}

export function* setLamCognitoAuthInfoSaga(
    action: ReturnType<typeof setLamCognitoAuthInfo>,
) {
    const {
        cognitoUserId,
        accessToken,
        refreshToken: newRefreshToken,
    } = action.payload;

    globalThis?.localStorage?.setItem(
        CognitoPersistKeys.COGNITO_USER_ID,
        cognitoUserId,
    );
    globalThis?.localStorage?.setItem(
        CognitoPersistKeys.ACCESS_TOKEN,
        accessToken,
    );
    globalThis?.localStorage?.setItem(
        CognitoPersistKeys.REFRESH_TOKEN,
        newRefreshToken,
    );

    const chainId = yield call(getBlockchainId);
    const cognitoUser = yield call(getCurrentCognitoUser, accessToken, chainId);
    yield put(getCognitoUserResponse(cognitoUser));
}

export function* cognitoLogoutSaga(action: ReturnType<typeof cognitoLogout>) {
    const { user } = action.payload as { user?: CognitoUser };
    const accessToken = getValueFromStorage(
        CognitoPersistKeys.ACCESS_TOKEN,
    ) as string;
    yield call(
        sendToHubspot,
        {
            eventName: EventNames.user_log_out,
            email: user?.email,
            properties: {
                user_id: user?.externalId,
            },
        },
        accessToken,
    );
    yield call(trackSegmentEvent, {
        eventName: SegmentEvents.USER_LOG_OUT,
        properties: {
            user_id: user?.externalId,
            email: user?.email,
            firstName: user?.firstName,
            lastName: user?.lastName,
        },
    });
    globalThis?.localStorage?.removeItem(CognitoPersistKeys.COGNITO_USER_ID);
    globalThis?.localStorage?.removeItem(CognitoPersistKeys.ID_TOKEN);
    globalThis?.localStorage?.removeItem(CognitoPersistKeys.ACCESS_TOKEN);
    globalThis?.localStorage?.removeItem(
        CognitoPersistKeys.STYTCH_ACCESS_TOKEN,
    );
    globalThis?.localStorage?.removeItem(CognitoPersistKeys.REFRESH_TOKEN);

    const application = getApplicationFromHostname();

    if (application === SupportedApplication.artechouse) {
        yield put(
            navigateTo({
                href: "https://www.artechouse.com/",
                method: "push",
            }),
        );
    }

    if (application === SupportedApplication.artuner) {
        yield put(
            navigateTo({
                href: "https://www.artuner.com/",
                method: "push",
            }),
        );
    } else {
        yield put(
            navigateTo({
                href: "/",
                method: "push",
            }),
        );
    }
}

export function* searchUsersByWalletsSaga({
    payload: wallets,
}: ReturnType<typeof searchUsersByWallets>) {
    try {
        const chainId = yield call(getBlockchainId);
        const accessToken = getValueFromStorage(
            CognitoPersistKeys.ACCESS_TOKEN,
        );
        const users = (yield call(
            searchAllUsers,
            accessToken as string,
            chainId,
            {
                walletIn: wallets,
            },
        )) as CognitoUser[];

        yield put(
            searchUsersByWalletsResponse(
                wallets.reduce((acc, wallet) => {
                    return {
                        ...acc,
                        [wallet]: users.find((user) =>
                            (user.wallets || []).find((el) =>
                                web3AddressesEqual(el.wallet, wallet),
                            ),
                        ),
                    };
                }, {}),
            ),
        );
    } catch (e) {
        trackExceptions(e.message, e);
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

export function* searchUsersByIdSaga({
    payload: ids,
}: ReturnType<typeof searchUsersById>) {
    try {
        const chainId = yield call(getBlockchainId);
        const accessToken = getValueFromStorage(
            CognitoPersistKeys.ACCESS_TOKEN,
        );
        const users = (yield call(
            searchAllUsers,
            accessToken as string,
            chainId,
            {
                idIn: ids,
            },
        )) as CognitoUser[];

        yield put(
            searchUsersByIdResponse(
                ids.reduce((acc, id) => {
                    return {
                        ...acc,
                        [id]: users.find((user) => user.id === id),
                    };
                }, {}),
            ),
        );
    } catch (e) {
        trackExceptions(e.message, e);
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
