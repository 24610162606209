import React, { useMemo } from "react";
import { Box, NoSsr } from "@mui/material";
import { styled } from "@mui/system";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { getApplicationFromHostname } from "../../host";

const Root = styled(Box)({
    margin: "0 auto",
    minHeight: 200,
});
const Icon = styled("img")({
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 100,
});

export function PreLoaderByApplication() {
    const application = getApplicationFromHostname();

    const preloaderImage = useMemo(() => {
        switch (application) {
            case SupportedApplication.artechouse:
                return "placehlderArtechouse.gif";
            case SupportedApplication.ephemeralEternal:
                return "preloaderEphemeralethernal.png";
            default:
                return "preloader.gif";
        }
    }, [application]);

    return (
        <NoSsr>
            <Root data-targetid="preloader-by-application">
                {globalThis?.location?.hostname && (
                    <Icon
                        src={`${globalThis?.location?.origin}/${preloaderImage}`}
                        alt=""
                        data-testid="preloader-image"
                    />
                )}
            </Root>
        </NoSsr>
    );
}
