export const allUsersQuery = `
    query AllUsers($walletIn: [String], $idIn: [ID]) {
        allUsers(wallet_In: $walletIn, id_In: $idIn) {
            edges {
                node {
                    avatarImageUrl
                    email
                    nickname
                    biography
                    fullName
                    firstName
                    lastName
                    wallets {
                        edges {
                            node {
                                wallet
                                issuedBy
                            }
                        }
                    }
                    id
                    externalId
                    socialLinks {
                        edges {
                            node {
                                link
                            }
                        }
                    }
                }
            }
        }
    }
`;
