export enum FeatureFLag {
    testMode = "testMode",
    icaTheme = "icaTheme",
    dynamicTokenContent = "dynamicTokenContent",
    artechouseTestTokensPool = "artechouseTestTokensPool",
    batchBuyNowListing = "batchBuyNowListing",
    showAppVersion = "showAppVersion",
    listForSale = "listForSale",
    isNewPDPEnabled = "isNewPDPEnabled",
    isPDPV4Enabled = "isPDPV4Enabled",
    eePoapTokenDev = "eePoapTokenDev",
    claimEmail = "claimEmail",
    noWebComponents = "noWebComponents",
    showWlSelect = "showWlSelect",
    showLiveArtHeader = "showLiveArtHeader",
    showWinterProjectId = "showWinterProjectId",
    registerWinterProjectOnMint = "registerWinterProjectOnMint",
}
