import React from "react";
import { Box, Container, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { TypographyProps } from "@mui/material/Typography/Typography";
import Getintouch from "./getintouch.svg";

const Copyrights = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
        padding: "30px 0px",
        margin: 0,
    },
}));

const OuterContainer = styled(Box)(({ theme }) => ({
    padding: "50px 30px",
    backgroundColor: "#f1e2e0",
    [theme.breakpoints.down("xs")]: {
        padding: "0px",
    },
}));

const PinkDiv = styled(Box)(({ theme }) => ({
    backgroundColor: "#FF5893",
    transform: "matrix(1, -0.02, -0.02, -1, 0, 0)",
    [theme.breakpoints.down("xs")]: {
        transform: "initial",
    },
}));

const StyledFooter = styled(Box)(({ theme }) => ({
    color: "#F1E3E1",
    backgroundColor: "#6E62C3",
    transform: "matrix(1, -0.02, -0.02, -1, 0, 0)",
    [theme.breakpoints.down("xs")]: {
        transform: "initial",
    },
}));

const FooterBox = styled(Box)(({ theme }) => ({
    paddingTop: "170px",
    paddingBottom: "170px",
    borderBottom: "1px dashed #F1E3E1",
    [theme.breakpoints.down("xs")]: {
        padding: "100px 0px",
    },
}));

const FooterHead = styled(Typography)(({ theme }) => ({
    fontSize: "130px",
    color: "#F1E3E1",
    marginBottom: "70px",
    fontFamily: "Forum",
    fontWeight: 400,
    [theme.breakpoints.down(500)]: {
        marginBottom: "50px",
        fontSize: "73px",
    },
})) as React.ComponentType<
    TypographyProps & {
        component: string;
    }
>;

const ContactWrap = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down(376)]: {
        display: "block",
        width: "100%",
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
}));

const TextBox = styled(Box)(({ theme }) => ({
    margin: "0px 35px",
    textAlign: "center",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
        margin: "0px 0px 30px 0px",
    },
    [theme.breakpoints.down("md")]: {
        margin: "0px 20px",
    },
}));

const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    marginBottom: "10px",
    fontFamily: "Forum",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
        fontSize: "16px",
    },
}));

const StyledText = styled(Typography)(({ theme }) => ({
    fontSize: "22px",
    fontFamily: "Forum",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
        fontSize: "18px",
    },
}));

const StyledButton = styled(Button)(() => ({
    backgroundColor: "#FF5893",
    borderRadius: "64px",
    width: "245px",
    height: "50px",
    color: "white",
    fontFamily: "Arial",
    fontSize: "16px",
    lineHeight: "18.4px",
    "&:hover": {
        backgroundColor: "#FF5893",
    },
}));

const FooterBottom = styled(Box)(({ theme }) => ({
    width: "60%",
    margin: "auto",
    textAlign: "center",
    padding: "40px 0px",
    [theme.breakpoints.down("xs")]: {
        width: "100%",
    },
}));

export interface FooterProps {
    backgroundColor?: string;
    removeBackgroundImage?: boolean;
}

export const ICAFooter: React.FC<FooterProps> = () => {
    return (
        <OuterContainer data-testid="ica-footer">
            <PinkDiv>
                <StyledFooter>
                    <Container>
                        <FooterBox>
                            <Box textAlign="center">
                                <FooterHead
                                    component="h1"
                                    data-testid="ica-footer-contact"
                                >
                                    Contact
                                </FooterHead>
                            </Box>
                            <ContactWrap>
                                <TextBox>
                                    <SmallText data-testid="ica-footer-phone">
                                        Phone:
                                    </SmallText>

                                    <StyledText data-testid="ica-footer-phone-number">
                                        305 901 5272
                                    </StyledText>
                                </TextBox>
                                <TextBox>
                                    <SmallText data-testid="ica-footer-address">
                                        Address:
                                    </SmallText>

                                    <StyledText data-testid="ica-footer-address-street">
                                        61 NE 41st Street Miami,
                                    </StyledText>

                                    <StyledText data-testid="ica-footer-address-state">
                                        FL 33137
                                    </StyledText>
                                </TextBox>
                                <TextBox>
                                    <SmallText data-testid="ica-footer-email-label">
                                        Email:
                                    </SmallText>
                                    <StyledText data-testid="ica-footer-email-address">
                                        365party@icamiami.org
                                    </StyledText>
                                </TextBox>
                            </ContactWrap>

                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                marginTop="56px"
                            >
                                <StyledButton
                                    endIcon={<Getintouch />}
                                    href="mailto:365party@icamiami.org"
                                    data-testid="get-in-touch-button"
                                >
                                    Get intouch
                                </StyledButton>
                            </Box>
                        </FooterBox>
                        <FooterBottom>
                            <Typography data-testid="contrubution-text">
                                All contributions in excess of the fair market
                                value of the evening ($200 per person for
                                cocktails and dinner) are tax-deductible within
                                the limits prescribed by law
                            </Typography>
                        </FooterBottom>
                    </Container>
                </StyledFooter>
            </PinkDiv>
            <Copyrights>
                <svg
                    width="76"
                    height="24"
                    viewBox="0 0 76 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="navbar_logo__ZxVMW"
                >
                    <path
                        d="M2 22.5v-21M74 22.5v-21"
                        stroke="#6E62C3"
                        strokeWidth="2.55"
                    />
                    <path
                        d="m17.91 1.5 14 20.364M58 1.5 44 21.864"
                        stroke="#6E62C3"
                        strokeWidth="2.545"
                    />
                </svg>
                <Typography
                    style={{ marginLeft: "30px", color: "#6e62c3" }}
                    data-testid="copyright"
                >
                    Copyright 2022. All rights reserved
                </Typography>
            </Copyrights>
        </OuterContainer>
    );
};
