import React from "react";
import { Box } from "@mui/material";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";

export function H3({
    children,
    color,
    mobile,
    strong,
    id,
    fontFamily,
}: React.PropsWithChildren<{
    color?: string;
    mobile?: boolean;
    strong?: boolean;
    id?: string;
    fontFamily?: string;
}>) {
    return (
        <Box
            id={id}
            component="h3"
            fontFamily={fontFamily || getFontFamilyBySupportedApplication()}
            fontWeight={strong ? "600" : "400"}
            fontStyle="normal"
            fontSize={mobile ? "20px" : "25px"}
            lineHeight={mobile ? "22px" : "31.8px"}
            color={color || colors.black}
            margin={0}
        >
            {children}
        </Box>
    );
}
