import {
    MetadataJSONSchema,
    MetadataProperties,
    MetadataProperty,
} from "~/api/metadata";
import type { TokenType } from "~/api/data-schema";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export const prepareMetadata = ({
    mediaFile,
    mediaFileURL,
    previewFile,
    previewFileURL,
    mediaPhygitalFileURL,
    additionalProperties,
    formProperties,
    AP,
    tokenType,
    externalUrl,
    selectedContract,
}: {
    mediaFile: File;
    mediaFileURL: string;
    previewFile?: File;
    previewFileURL?: string;
    mediaPhygitalFileURL: string[];
    additionalProperties: {
        name: string;
        value: string;
    }[];
    formProperties: {
        name: string | number;
        description: string | number;
        phygitalData: {
            phygitalMedium?: string;
            phygitalDimensions?: {
                width: number;
                height: number;
            };
            phygitalImage?: string;
        }[];
    };
    AP: number;
    tokenType: TokenType;
    externalUrl?: string;
    selectedContract: SolidityContractDetails;
}) => {
    const basicProperties: {
        name: keyof MetadataProperties;
        value: MetadataProperty["description"];
    }[] = [
        {
            name: "media",
            value: mediaFileURL,
        },
        {
            name: "createdAt",
            value: new Date().toString(),
        },
        {
            name: "fileName",
            value: mediaFile.name,
        },
        {
            name: "fileSize",
            value: mediaFile.size,
        },
        {
            name: "fileType",
            value: mediaFile.type,
        },
        {
            name: "AP",
            value: AP,
        },
        {
            name: "application",
            value: selectedContract.application,
        },
        {
            name: "tokenType",
            value: tokenType,
        },
    ];

    const props = Object.entries(formProperties)
        .map((i) => ({ name: i[0], value: i[1] }))
        .concat(additionalProperties)
        .concat(
            basicProperties as ConcatArray<{
                name: string;
                value: string | number;
            }>,
        )
        .concat(
            previewFile && previewFileURL
                ? [
                      {
                          name: "preview",
                          value: previewFileURL,
                      },
                      {
                          name: "previewFileType",
                          value: previewFile.type,
                      },
                  ]
                : [],
        )
        .filter(
            (el: { name: string; value: string }) =>
                el.name.trim() && el.value && el.value.toString().trim(),
        )
        .reduce((acc, el) => {
            return {
                ...acc,
                [el.name]: {
                    type: typeof el.value,
                    description: el.value,
                },
            };
        }, {}) as MetadataProperties;
    const phygitalData = [...formProperties.phygitalData];
    if (
        mediaPhygitalFileURL &&
        mediaPhygitalFileURL.length === phygitalData.length
    ) {
        for (let i = 0; i < mediaPhygitalFileURL.length; i += 1) {
            phygitalData[i].phygitalImage = mediaPhygitalFileURL[i];
        }
    }
    const metadata: MetadataJSONSchema = {
        title: "Asset Metadata",
        type: "object",
        properties: props,
        image: previewFile && previewFileURL ? previewFileURL : mediaFileURL,
        external_url: externalUrl,
        description: formProperties.description as string,
        name: formProperties.name as string,
        phygitalData,
    };

    return metadata;
};
