import { createAction } from "@reduxjs/toolkit";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import {
    Policy,
    Token,
    ReadyToMintTokenData,
    MintOperation,
    RawModifiedTokenData,
    CognitoUser,
} from "../data-schema";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export const getTokenRightsResponse = createAction<{
    tokenId: number;
    rights: Policy[];
    canSellRights: Policy[];
    contractAddress: string;
    success: boolean;
}>("API/TOKEN/GET_TOKEN_RIGHTS_RESPONSE");
export const loadTokens = createAction<{
    editionOf?: number;
    offset?: number;
    limit?: number;
    userWallets?: string[];
    tokenNames?: string[];
    tokenIds?: string[];
    applications?: SupportedApplication[];
    networkId: number;
    reset?: boolean;
}>("API/TOKEN/LOAD_TOKENS");
export const setTokensLoaded = createAction<{
    loaded: boolean;
}>("API/TOKEN/SET_TOKENS_LOADED");

export const loadTokensResponse = createAction<{
    networkId: number;
    tokens: Token[];
}>("API/TOKEN/LOAD_TOKENS_RESPONSE");
export const getTokenRights = createAction<number>(
    "API/TOKEN/GET_TOKEN_RIGHTS",
);
export const getCertificateURI = createAction<string>(
    "API/TOKEN/GET_CERTIFICATE_URI",
);
export const loadToken = createAction<{
    tokenId: number;
    networkId: number;
    tokenContractAddress: string;
    application?: SupportedApplication;
}>("API/TOKEN/LOAD_TOKEN");
export const loadTokenResponse = createAction<Token>(
    "API/TOKEN/LOAD_TOKEN_RESPONSE",
);
export const mintToken = createAction<ReadyToMintTokenData>(
    "API/TOKEN/MINT_TOKEN",
);
export const loadCertificate = createAction<number>(
    "API/TOKEN/LOAD_CERTIFICATE",
);
export const claimToken = createAction<{
    tokenId: number;
    cognitoUserId: string;
    to: string;
}>("API/TOKEN/CLAIM_TOKEN");
export const claimTokenEmail = createAction<{
    token: Token;
    user: CognitoUser;
}>("API/TOKEN/CLAIM_TOKEN_EMAIL");
export const mintTokenResponse = createAction<{
    tokenId: number;
    tokenURI: string;
    success: boolean;
}>("API/TOKEN/MINT_TOKEN_RESPONSE");
export const queueMintOperation = createAction<MintOperation>(
    "API/TOKEN/QUEUE_MINT_OPERATION",
);
export const createMintingQueueResponse = createAction<{
    success: boolean;
}>("API/TOKEN/CREATE_MINT_QUEUE");
export const getHasUnlockableContent = createAction<number>(
    "API/TOKEN/GET_HAS_UNLOCKABLE_CONTENT",
);
export const hasUnlockableContentResponse = createAction<boolean>(
    "API/TOKEN/HAS_UNLOCKABLE_CONTENT_RESPONSE",
);
export const getUnlockableContent = createAction<number>(
    "API/TOKEN/GET_UNLOCKABLE_CONTENT",
);
export const unlockableContentResponse = createAction<string>(
    "API/TOKEN/GET_UNLOCKABLE_CONTENT_RESPONSE",
);
export const getIsOwnerUnlockableContent = createAction<number>(
    "API/TOKEN/GET_IS_OWNER_UNLOCKABLE_CONTENT",
);
export const isOwnerUnlockableContentResponse = createAction<boolean>(
    "API/TOKEN/GET_IS_OWNER_UNLOCKABLE_CONTENT_RESPONSE",
);
export const getIsAdminUser = createAction<{
    userAccount: string;
    tokenContractDetails: SolidityContractDetails | undefined;
}>("API/TOKEN/GET_IS_ADMIN_USER");
export const getIsAdminUserResponse = createAction<boolean>(
    "API/TOKEN/GET_IS_ADMIN_USER_RESPONSE",
);
export const createModifiedMetadata = createAction<{
    modifiedMetadata: RawModifiedTokenData;
    token: Token;
    tokenContractDetails: SolidityContractDetails;
    requiredTokenURI: string;
}>("DYMANIC_TOKEN_URI/SUBMIT_MODIFIED_IPFS_DATA");
