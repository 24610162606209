import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { getApplicationFromHostname } from "@liveart/injectables/dist/WhiteLabel/host";

export const fontFamilies = {
    hurme2: '"Hurme hurmegeometricsans2","Fira sans",sans-serif',
    poppins: '"Poppins","Fira sans",sans-serif',
    firaSans: '"Fira sans"',
    polySans: '"PolySans Trial","Fira sans",sans-serif',
    raleway: "Raleway",
};

export const getFontFamilyBySupportedApplication = (
    defaultFont = fontFamilies.hurme2,
) => {
    const application = getApplicationFromHostname();
    switch (application) {
        case SupportedApplication.artechouse:
            return fontFamilies.poppins;
        case SupportedApplication.ephemeralEternal:
            return fontFamilies.raleway;
        default:
            return defaultFont;
    }
};

export enum TextTags {
    h1 = "h1",
}

const fontSizeByTag = {
    [TextTags.h1]: {
        [fontFamilies.raleway]: "40px",
        [fontFamilies.poppins]: "40px",
        [fontFamilies.hurme2]: "44px",
    },
};

export const getTagFontSizeBySupportedApplication = (
    textTag: TextTags,
    defaultFontSize = "16px",
) => {
    const application = getApplicationFromHostname();
    switch (application) {
        case SupportedApplication.artechouse:
            return fontSizeByTag[textTag][fontFamilies.poppins];
        case SupportedApplication.liveart:
            return fontSizeByTag[textTag][fontFamilies.hurme2];
        case SupportedApplication.ephemeralEternal:
            return fontSizeByTag[textTag][fontFamilies.raleway];
        default:
            return defaultFontSize;
    }
};

export const fonts = {
    h1: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 46,
        lineHeight: "110%",
    },
    h2: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 32,
        lineHeight: "120%",
        letterSpacing: "-0.02em",
    },
    h3: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 24,
        lineHeight: "150%",
        letterSpacing: "-0.02em",
    },
    h4: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "130%",
        letterSpacing: "-0.02em",
    },

    px40Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 40,
        lineHeight: "110%",
    },

    px26Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 26,
        lineHeight: "184%",
    },

    px24Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 24,
        lineHeight: "150%",
    },

    px22Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 22,
        lineHeight: "118%",
    },

    px20Medium: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "130%",
    },
    px20Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "130%",
    },
    px20400: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 20,
        lineHeight: "120%",
    },

    px18Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 18,
        lineHeight: "130%",
    },
    px18Medium: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "130%",
    },

    px16Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "130%",
    },

    px16400: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "120%",
    },

    px16Medium: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "130%",
    },
    px16Light: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: 16,
        lineHeight: "130%",
    },

    px14Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "130%",
    },
    px14Medium: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "130%",
    },

    px13Light: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: 13,
        lineHeight: "150%",
    },

    px12Regular: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "130%",
    },
    px12Medium: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.firaSans),
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "130%",
    },
    hx15Medium: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontSize: "15px",
        fontWeight: "normal",
        lineHeight: "22px",
    },
    hx18Medium: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    hx16Medium: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: "normal",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    hx16600: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    hx13400: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "13px",
        lineHeight: "17px",
        letterSpacing: "0.15px",
    },
    hx13400120: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "13px",
        lineHeight: "120%",
    },
    bannerBigText: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "72px",
        lineHeight: "72px",
        letterSpacing: "0.37px",
    },
    bannerSmallText: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "22px",
        lineHeight: "27.98px",
    },
    bannerSmallTextSM: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "15px",
        lineHeight: "22px",
    },
    secondBannerBigText: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "54px",
        lineHeight: "72px",
        letterSpacing: "0.37px",
    },
    secondBannerBigTextSM: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "28px",
        lineHeight: "34px",
        letterSpacing: "0.37px",
    },
    bannerBigTextSM: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "44px",
        lineHeight: "54px",
        letterSpacing: "0.37px",
    },
    hx22600: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "22px",
        lineHeight: "27.98px",
    },
    hx24600: {
        fontFamily: getFontFamilyBySupportedApplication(),
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: "24px",
        lineHeight: "30.53px",
    },
    hx26500: {
        fontFamily: getFontFamilyBySupportedApplication(fontFamilies.poppins),
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "26px",
        lineHeight: "32.2px",
    },
} as const;

export default {
    fonts,
    fontFamilies,
    getFontFamilyBySupportedApplication,
    getTagFontSizeBySupportedApplication,
};
