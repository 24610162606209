import { createAction } from "@reduxjs/toolkit";
import { ContractDetails } from "~/api/data-schema";

export const updateConfiguration = createAction<ContractDetails>(
    "API/DISCOVER/UPDATE_DISCOVER_CONFIGURATION",
);

export const loadConfiguration = createAction(
    "API/DISCOVER/LOAD_DISCOVER_CONFIGURATION",
);

export const loadConfigurationResponse = createAction<string[]>(
    "API/DISCOVER/LOAD_DISCOVER_CONFIGURATION_RESPONSE",
);

export const isWhitelistedContract = createAction<string>(
    "API/DISCOVER/IS_WHITELISTED_CONTRACT",
);
