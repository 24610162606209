import pick from "lodash/pick";
import { BeBid } from "~/api/data-schema";

export function normalizeBidUser(bid: BeBid): BeBid {
    return {
        ...bid,
        ...(bid.paymentIntent
            ? {
                  paymentIntent: {
                      ...bid.paymentIntent,
                      user: pick(bid?.paymentIntent?.user || {}, [
                          "id",
                          "externalId",
                          "email",
                      ]),
                  },
              }
            : {}),
    };
}
