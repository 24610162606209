export const v1FindContractByNameQuery = `
query FindContractByName($contractNameIlike: String) {
  allContracts(contractName_Ilike: $contractNameIlike) {
    edges {
      node {
        id
        address
        application
        adminWallet
        abi
        contractName
        symbol
        blockchainId
        version
      }
    }
  }
}
`;
