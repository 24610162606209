import { call, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import {
    loadDirectMintEditionDetails,
    directMintEditionTokens,
    directMintUserMintCount,
    updateEditionAfterDirectMint,
    directMintWhitelistMint,
    loadDirectMintTimes,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./directMint/directMintSaga"),
    () => 1,
);

const directMintTimesSaga = memoize(
    () => import("./directMint/directMintTimesSaga"),
    () => 1,
);

export function* directMintApiSaga() {
    yield takeLatest(
        loadDirectMintEditionDetails.toString(),
        function* withLoadDirectMintEditionDetailsSaga(
            action: ReturnType<typeof loadDirectMintEditionDetails>,
        ) {
            const { loadDirectMintDetailsSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(loadDirectMintDetailsSaga, action);
        },
    );

    yield takeLatest(
        directMintEditionTokens.toString(),
        function* withDirectMintEditionTokensSaga(
            action: ReturnType<typeof directMintEditionTokens>,
        ) {
            const { directMintEditionTokensSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(directMintEditionTokensSaga, action);
        },
    );

    yield takeLatest(
        directMintUserMintCount.toString(),
        function* withdirectMintUserMintCountSaga(
            action: ReturnType<typeof directMintUserMintCount>,
        ) {
            const { directMintUserMintCountSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(directMintUserMintCountSaga, action);
        },
    );

    yield takeLatest(
        updateEditionAfterDirectMint.toString(),
        function* withUpdateEditionAfterDirectMint(
            action: ReturnType<typeof updateEditionAfterDirectMint>,
        ) {
            const { updateEditionAfterDirectMintSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(updateEditionAfterDirectMintSaga, action);
        },
    );

    yield takeLatest(
        directMintWhitelistMint.toString(),
        function* withDirectMintWhitelistMintSaga(
            action: ReturnType<typeof directMintWhitelistMint>,
        ) {
            const { directMintWhitelistMintSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(directMintWhitelistMintSaga, action);
        },
    );

    yield takeLatest(
        loadDirectMintTimes.toString(),
        function* withLoadDirectMintTimesSaga(
            action: ReturnType<typeof loadDirectMintTimes>,
        ) {
            const { loadDirectMintTimesSaga } = (yield call(
                directMintTimesSaga,
            )) as Unpromisify<ReturnType<typeof directMintTimesSaga>>;

            yield call(loadDirectMintTimesSaga, action);
        },
    );
}
