import {
    CognitoPersistKeys,
    getValueFromStorage,
} from "@liveart/injectables/dist/user/getValueFromStorage";
import { getBlockchainId } from "~/api/web3";
import { getCurrentCognitoUser } from "~/api/user/getCognitoUser";
import { trackExceptions } from "~/features/errorTracking";
import { getENV } from "./getENV";

function setValueToStorage(key: CognitoPersistKeys, value: string) {
    return globalThis?.localStorage?.setItem(key, value);
}
function removeValueFromStorage(key: CognitoPersistKeys) {
    return globalThis?.localStorage?.removeItem(key);
}
function getRefreshToken() {
    return getValueFromStorage(CognitoPersistKeys.REFRESH_TOKEN);
}

async function refreshToken(retries = 5) {
    const { clientId } = await getENV();

    if (!getRefreshToken()) {
        return Promise.resolve(undefined);
    }

    const networkId = await getBlockchainId();
    const searchParams = new URLSearchParams();
    searchParams.set("clientId", clientId);
    searchParams.set("refreshToken", getRefreshToken() || "");
    searchParams.set("networkId", String(networkId));

    return fetch(`/nft/api/refresh-token?${searchParams.toString()}`)
        .then((resp) => {
            return resp.json();
        })
        .then(
            (jsonResp: {
                access_token: string;
                expires_in: number;
                id_token: string;
                token_type: "Bearer";
                error?: string;
            }) => {
                if (jsonResp.access_token) {
                    setValueToStorage(
                        CognitoPersistKeys.ACCESS_TOKEN,
                        jsonResp.access_token,
                    );

                    return jsonResp.access_token;
                }

                if (retries > 0) {
                    return refreshToken(retries - 1);
                }
                if (retries === 0) {
                    removeValueFromStorage(CognitoPersistKeys.REFRESH_TOKEN);
                    removeValueFromStorage(CognitoPersistKeys.ACCESS_TOKEN);
                }

                return undefined;
            },
        )
        .catch((err) => {
            trackExceptions(err.message, err);
        });
}

export async function getAccessToken() {
    const accessToken = getValueFromStorage(CognitoPersistKeys.ACCESS_TOKEN);
    if (accessToken) {
        try {
            const chainId = await getBlockchainId();
            const user = await getCurrentCognitoUser(accessToken, chainId);
            if (!user) {
                await refreshToken();
            }
        } catch {
            await refreshToken();
        }
    }

    return getValueFromStorage(CognitoPersistKeys.ACCESS_TOKEN);
}
