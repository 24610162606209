import { Box, Button, ButtonProps, BoxProps } from "@mui/material";
import { withStyles } from "@mui/styles";
import { getFontFamilyBySupportedApplication } from "~/theme";

const StyledButton = withStyles({
    root: {
        textTransform: "inherit",
        fontFamily: getFontFamilyBySupportedApplication(),
    },
    contained: {
        boxShadow: "none",
        "&:hover": {
            boxShadow: "none",
        },
    },
})(Button);

export function PrimaryContainedButton({
    width,
    height = "40px",
    color,
    backgroundColor,
    border,
    borderRadius,
    ...props
}: ButtonProps & {
    width?: BoxProps["width"];
    height?: BoxProps["height"];
    darkTheme?: boolean;
    color?: string;
    backgroundColor?: string;
    border?: string;
    borderRadius?: string;
}) {
    return (
        <Box
            width={width}
            height={height}
            style={{
                color,
                backgroundColor,
                border,
                borderRadius,
                cursor: "pointer",
            }}
        >
            <StyledButton
                sx={{
                    width,
                    height,
                }}
                color="primary"
                variant="contained"
                {...props}
            />
        </Box>
    );
}
