import { call, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import {
    getHasUnlockableContent,
    getIsOwnerUnlockableContent,
    getUnlockableContent,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721/unlockableContentSaga"),
    () => 1,
);

export function* unlockableContentApiSaga() {
    yield takeLatest(
        getHasUnlockableContent.toString(),
        function* withHasUnlockableContentSaga(
            action: ReturnType<typeof getHasUnlockableContent>,
        ) {
            const { hasUnlockableContentSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(hasUnlockableContentSaga, action);
        },
    );
    yield takeLatest(
        getIsOwnerUnlockableContent.toString(),
        function* withOwnerOfUnlockableContentSaga(
            action: ReturnType<typeof getIsOwnerUnlockableContent>,
        ) {
            const { ownerOfUnlockableContentSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(ownerOfUnlockableContentSaga, action);
        },
    );
    yield takeLatest(
        getUnlockableContent.toString(),
        function* withGetUnlockableContentSaga(
            action: ReturnType<typeof getUnlockableContent>,
        ) {
            const { getUnlockableContentSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(getUnlockableContentSaga, action);
        },
    );
}
