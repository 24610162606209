import { isTestnet } from "./blockchain/isTestnet";

const PROD_GRAPH_QL_API_URL = "https://api.liveart.io/graphql";
const DEV_GRAPH_QL_API_URL = "https://api-dev.liveart.io/graphql";

export async function query({
    networkId,
    queryStr,
    accessToken,
    variables,
    Authorization = "Bearer",
    apiEndpoints = {
        prod: PROD_GRAPH_QL_API_URL,
        dev: DEV_GRAPH_QL_API_URL,
    },
}: {
    networkId: number;
    queryStr: string;
    accessToken?: string;
    variables?: object;
    Authorization?: "Bearer" | "Basic";
    apiEndpoints?: {
        dev: string;
        prod: string;
    };
}) {
    async function getURL(networkId: number): Promise<string> {
        if (isTestnet(networkId)) {
            return apiEndpoints.dev;
        }
        return apiEndpoints.prod;
    }

    const resp = await fetch(await getURL(networkId), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...(accessToken
                ? {
                      Authorization: `${Authorization} ${accessToken}`,
                  }
                : {}),
        },
        body: JSON.stringify({
            query: queryStr,
            variables,
        }),
    });

    if (resp.status !== 200) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
