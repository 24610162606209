import { ConfirmCardPaymentData, loadStripe, Stripe } from "@stripe/stripe-js";
import { isBrowser, isStorybook } from "~/features/env";
import { getStripePublicKey } from "./stripeBE";

const testPublicKey =
    "pk_test_51IpaSZIXXz3GdALwg5CE6EMNILXWrTV1YViiL1OTJD1G2aiNmihZtfLeLL1yxPItwr6iJpIuA8H5pJhcqk76etQG00Yi1VYeWY";

const stripeByPublicKey = new Map();

export async function getStripe(): Promise<Stripe> {
    if (!isBrowser()) {
        return new Promise(() => {});
    }

    const publicKey = isStorybook()
        ? testPublicKey
        : await getStripePublicKey();

    const stripePromise = loadStripe(publicKey) as Promise<Stripe>;

    if (!stripeByPublicKey.has(publicKey)) {
        stripeByPublicKey.set(publicKey, stripePromise);
    }

    return stripeByPublicKey.get(publicKey);
}

export async function confirmCardPayment(
    clientSecret: string,
    data?: ConfirmCardPaymentData,
) {
    const stripe = await getStripe();

    const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        data,
    );

    if (error) {
        throw new Error(error.message);
    }

    return paymentIntent;
}
