import { createReducer } from "@reduxjs/toolkit";
import {
    defaultSellArtworkState,
    SellArtworkState,
    setDialogStep as sellArtworkSetDialogStep,
    setDialogStepReducer,
    showDialogReducer as sellArtworkShowDialogReducer,
} from "./sellArtwork/sellArtworkReducer";
import {
    claimTokenResponseReducer,
    ClaimTokenState,
    defaultClaimTokenState,
    setClaimTokenState,
    setClaimTokenStateReducer,
} from "./claimToken/claimTokenReducer";
import {
    hasUnlockableContentResponseReducer,
    isOwnerUnlockableContentResponseReducer,
    setUnlockableContentResponseReducer,
    UnlockableContentState,
    defaultUnlockableContentState,
} from "./unlockableContent/unlockableContentReducer";
import {
    getIsAdminUserReducer,
    DymanicTokenUriState,
    defaultDymanicTokenUriState,
} from "./dymanicTokenUri/dymanicTokenUriReducer";
import {
    claimTokenResponse,
    hasUnlockableContentResponse,
    isOwnerUnlockableContentResponse,
    unlockableContentResponse,
    getIsAdminUserResponse,
} from "~/api/choreography";
import { showDialog } from "~/features/dialog";

export type WithPDPState = {
    pdp: PDPState;
};

export type PDPState = {
    sellArtwork: SellArtworkState;
    claimToken: ClaimTokenState;
    unlockableContent: UnlockableContentState;
    dymanicTokenUri: DymanicTokenUriState;
};
export const defaultPdpState: PDPState = {
    sellArtwork: defaultSellArtworkState,
    claimToken: defaultClaimTokenState,
    unlockableContent: defaultUnlockableContentState,
    dymanicTokenUri: defaultDymanicTokenUriState,
};

export type PDPLoadingState = {
    state: boolean;
};

export const pdpReducer = createReducer<PDPState>(defaultPdpState, (builder) =>
    builder
        .addCase(sellArtworkSetDialogStep, (state, action) => ({
            ...state,
            sellArtwork: setDialogStepReducer(state, action).sellArtwork,
        }))
        .addCase(setClaimTokenState, (state, action) => ({
            ...state,
            claimToken: setClaimTokenStateReducer(state, action).claimToken,
        }))
        .addCase(claimTokenResponse, (state, action) => ({
            ...state,
            claimToken: claimTokenResponseReducer(state, action).claimToken,
        }))
        .addCase(showDialog, (state, action) => ({
            ...state,
            sellArtwork: sellArtworkShowDialogReducer(state, action)
                .sellArtwork,
        }))
        .addCase(hasUnlockableContentResponse, (state, action) => ({
            ...state,
            unlockableContent: hasUnlockableContentResponseReducer(
                state,
                action,
            ).unlockableContent,
        }))
        .addCase(isOwnerUnlockableContentResponse, (state, action) => ({
            ...state,
            unlockableContent: isOwnerUnlockableContentResponseReducer(
                state,
                action,
            ).unlockableContent,
        }))
        .addCase(unlockableContentResponse, (state, action) => ({
            ...state,
            unlockableContent: setUnlockableContentResponseReducer(
                state,
                action,
            ).unlockableContent,
        }))
        .addCase(getIsAdminUserResponse, (state, action) => ({
            ...state,
            dymanicTokenUri: getIsAdminUserReducer(state, action)
                .dymanicTokenUri,
        })),
);

export const pdpStateSelector = (state: WithPDPState) => state.pdp;
