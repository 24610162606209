import {
    MAIN_NET_ID,
    PALM_CHAIN_ID,
    PALM_TEST_CHAIN_ID,
    RINKEBY_CHAIN_ID,
    GOERLI_CHAIN_ID,
    BINANCE_CHAIN_ID,
    BINANCE_TESTNET_CHAIN_ID,
} from "@liveart/nft-client/dist/chainId";
import {
    mainnetInfuraAddress,
    palmMainNetInfureAddress,
    palmtestInfuraAddress,
    rinkebyInfuraAddress,
    goerliInfureAddress,
    binanceTestnetRPCAddress,
    bianceRPCAddress,
} from "~/BFF/blockchain/infura";

export function getRpcUrlByChainId(networkId: number) {
    switch (networkId) {
        case MAIN_NET_ID:
            return mainnetInfuraAddress;
        case BINANCE_CHAIN_ID:
            return bianceRPCAddress;
        case BINANCE_TESTNET_CHAIN_ID:
            return binanceTestnetRPCAddress;
        case RINKEBY_CHAIN_ID:
            return rinkebyInfuraAddress;
        case GOERLI_CHAIN_ID:
            return goerliInfureAddress;
        case PALM_TEST_CHAIN_ID:
            return palmtestInfuraAddress;
        case PALM_CHAIN_ID:
            return palmMainNetInfureAddress;
        default:
            return undefined;
    }
}
