import { createAction } from "@reduxjs/toolkit";
import { PaymentMethod, StripeCardElement } from "@stripe/stripe-js";
import {
    BuyNowListing,
    TokenType,
    CreatedPaymentIntent,
    CreatedPaymentIntentAuctionMetadata,
    CreatedPaymentIntentMarketMetadata,
    AuctionInputData,
    BuyNowInputData,
    CashBuyer,
    Currency,
    LoadTokenMarketDataResponsePayload,
    CognitoUser,
} from "../data-schema";

export const toggleBuyETHDialog = createAction<
    { amountToBuy: string } | undefined
>("API/MARKET/TOGGLE_BUY_ETH_DIALOG");

export const buyArtwork = createAction<BuyNowListing>("API/MARKET/BUY_NOW");
export const buyArtworkWithCreditCard = createAction<{
    listing: BuyNowListing;
    cashBuyer: CashBuyer;
    stripeData: {
        fullName?: string;
        price: string;
        card?: StripeCardElement;
        saveStripeCard?: boolean;
        paymentMethodId?: string;
    };
}>("API/MARKET/BUY_NOW_CREDIT_CARD");
export const buyNowFailed = createAction<string>("API/MARKET/BUY_NOW_FAILED");
export const buyNowComplete = createAction("API/MARKET/BUY_NOW_COMPLETE");
export const cancelAuction = createAction<{
    tokenId: number;
    auctionId: number;
}>("API/MARKET/CANCeL_AUCTION");
export const endAuction = createAction<{
    tokenId: number;
    auctionId: number;
    tokenType: TokenType;
}>("API/MARKET/END_AUCTION");
export const makeBid = createAction<{
    tokenId: number;
    auctionId: number;
    bidAmount: string;
    user?: CognitoUser;
}>("API/MARKET/SET_BID");
export const bidFailed = createAction("API/MARKET/BID_FAILED");
export const ethBidPlaced = createAction("API/MARKET/BID_PLACED");
export const submitTokenSell = createAction<{
    buyNow:
        | Omit<BuyNowInputData, "tokenContractAddress" | "listingFee">
        | undefined;
    auction: AuctionInputData | undefined;
}>("API/MARKET/SUBMIT_SELL_TOKEN");
export const createPaymentIntent = createAction<{
    amount: string;
    customerId: string;
    metadata:
        | CreatedPaymentIntentAuctionMetadata
        | CreatedPaymentIntentMarketMetadata
        | undefined;
    paymentMethodId?: string;
    user?: CognitoUser;
}>("API/STRIPE/CREATE_PAYMENT_INTENT");
export const createPaymentIntentResponse = createAction<CreatedPaymentIntent>(
    "API/STRIPE/CREATE_PAYMENT_INTENT_RESPONSE",
);
export const loadPaymentMethods = createAction<{
    customerId: string;
}>("API/STRIPE/LOAD_PAYMENT_METHODS");
export const loadPaymentMethodsResponse = createAction<PaymentMethod[]>(
    "API/STRIPE/LOAD_PAYMENT_METHODS_RESPONSE",
);
export const loadCashBuyer = createAction<{
    networkId: number;
    cognitoUserId: string | undefined;
    connectedWalletAddress?: string;
}>("API/CASH_PURCHASE/LOAD_CASH_BUYER");
export const loadCashBuyerResponse = createAction<CashBuyer | undefined>(
    "API/CASH_PURCHASE/LOAD_CASH_BUYER_RESPONSE",
);
export const removePaymentMethod = createAction<{
    paymentMetohdId: string;
    networkId: number;
}>("API/CASH_PURCHASE/REMOVE_PAYMENT_METHOD");
export const removePaymentMethodResponse = createAction<{
    id: string;
}>("API/CASH_PURCHASE/REMOVE_PAYMENT_METHOD_RESPONSE");
export const cashBidPlaced = createAction("API/CASH_PURCHASE/CASH_BID_PLACED");
export const placeCashBid = createAction<{
    networkId: number;
    paymentIntent: CreatedPaymentIntent;
    tokenId: number;
    auctionId: number;
    bidder: string;
    price: string;
    user?: Partial<CognitoUser>;
}>("API/CASH_PURCHASE/PLACE_CASH_BID");
export const getCurrencyRates = createAction<{
    from: Currency;
    to: Currency;
}>("API/CURRENCY/GET_RATES");
export const getCurrencyRatesResponse = createAction<{
    [key in Currency]?: Record<Currency, number>;
}>("API/CURRENCY/GET_RATES_RESPONSE");

export const loadTokenMarketData = createAction<{
    tokenId: number;
    networkId?: number;
    application?: string;
}>("API/MARKET/LOAD_TOKEN_MARKET_DATA");
export const loadTokenMarketDataResponse =
    createAction<LoadTokenMarketDataResponsePayload>(
        "API/MARKET/LOAD_TOKEN_MARKET_DATA_RESPONSE",
    );

export const submitTokenSellResponse = createAction<{
    success: boolean;
}>("API/MARKET/SUBMIT_SELL_TOKEN_RESPONSE");
export const submitBuyNowPriceResponse = createAction(
    "API/MARKET/SUBMIT_BUW_NOW_RESPONSE",
);
export const submitStartAuctionResponse = createAction(
    "API/MARKET/SUBMIT_START_AUCTION_RESPONSE",
);
export const claimTokenResponse = createAction<{ success: boolean }>(
    "API/TOKEN/CLAIM_TOKEN_RESPONSE",
);
