import { GenerateCertificatePayload } from "../data-schema";

const host = "https://certificates.liveart.ai/generate_pdf/";

export async function generateCertificate({
    params,
    ...data
}: GenerateCertificatePayload) {
    const urlSearchParams = new URLSearchParams();
    for (const key in data) {
        if (data[key]) {
            urlSearchParams.append(key, data[key]);
        }
    }
    urlSearchParams.append("params", JSON.stringify(params));
    const resp = await fetch(
        `/nft/api/cors?url=${host}&${urlSearchParams.toString()}`,
    );
    const json = await resp.json();
    return json.url;
}
