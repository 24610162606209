import { MetadataJSONSchema } from "~/api/metadata";
import { WhiteList } from "~/api/data-schema/WhiteListedMintAddresses";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { WinterProject } from "~/api/data-schema/Winter";

export type DirectMintWhiteListContractDetails = {
    id: string;
    name: string;
    price: number;
    amount: number;
    totalWallets: number;
    root: string;
    mintStart: number;
};

export type DirectMintEditionDetials = {
    editionId: number;
    baseURI: string;
    maxSupply: number;
    currentSupply: number;
    burnedSupply: number;
    createdBy: string;
    publicMintPriceInFinney: number;
    publicMintStartTS: number;
    publicMintEndTS: number;
    maxMintPerWallet: number;
    isOpenEdition: boolean;
};

export type DirectMintActiveMintDetails = {
    startTime: number;
    endTime: number;
    maxMintPerWallet: number;
    mintPrice: number;
    mintTitle: string;
    root?: string;
    whitelistId?: string;
};

export const editionKeys = [
    "maxSupply",
    "currentSupply",
    "burnedSupply",
    "publicMintPriceInFinney",
    "publicMintStartTS",
    "publicMintEndTS",
    "maxMintPerWallet",
    "isOpenEdition",
];

export enum DirectMintStates {
    NO_PUBLIC_MINT_END_DATE = "NO_PUBLIC_MINT_END_DATE",
    SOLDOUT = "SOLDOUT",
    OPEN = "OPEN",
    CLOSED = "CLOSED",
}

export enum WalletNames {
    METAMASK = "Metamask",
    WALLET_CONNECT = "WalletConnect",
    COINBASE = "Coinbase",
}

export enum DirectMintingStates {
    PRE_MINT = "Pre-mint",
    PUBLIC_MINT = "Public mint",
}

export enum MetadataStates {
    COMMON = "Common metadata across token supply",
    CUSTOM = "Custom metadata per token",
}

export enum WalletStates {
    CONNECTED_WALLET = "CONNECTED_WALLET",
    CONNECTING_WALLET = "CONNECTING_WALLET",
    ETH_CHECKOUT_DETAILS = "ETH_CHECKOUT_DETAILS",
    TRANSACTION_APPROVAL = "TRANSACTION_APPROVAL",
    PROCESSING_ETH_PAYMENT = "PROCESSING_ETH_PAYMENT",
    SUCCESS_ETH_PAYMENT = "SUCCESS_ETH_PAYMENT",
}

export enum Dialogs {
    PAY_WITH_ETH = "directMintPayWithEthDialog",
}

export type DirectMintEdition = {
    edition: DirectMintEditionDetials;
    metadata: MetadataJSONSchema;
};

export type DirectMintState = {
    contract: SolidityContractDetails;
    edition: DirectMintEditionDetials;
    metadata: MetadataJSONSchema;
    whitelists: WhiteList[];
    activeMintDetails: DirectMintActiveMintDetails;
    selectedWallet: WalletDetails;
    userMintCount: number;
    mintState: DirectMintStates;
    mintedTokenId: string;
    winterProject?: WinterProject | undefined;
    totalMintStages: number;
};

export type WalletDetails = {
    walletName: string;
    walletState: WalletStates;
    address: string;
    walletLoaded: boolean;
};
