const defaultBlockchainId = globalThis?.window?.document?.createElement("div");

const changeDefaultBlockchainIdEvent = "changeDefaultBlockchainIdEvent";
export const subscribeToDefaultBlockchainIdChange = (cb: Function) => {
    defaultBlockchainId.addEventListener(
        changeDefaultBlockchainIdEvent,
        cb as EventListenerOrEventListenerObject,
    );
    return () =>
        defaultBlockchainId.removeEventListener(
            changeDefaultBlockchainIdEvent,
            cb as EventListenerOrEventListenerObject,
        );
};

export const notifyToDefaultBlockchainIdChange = () => {
    const event = new Event(changeDefaultBlockchainIdEvent);
    defaultBlockchainId.dispatchEvent(event);
};
