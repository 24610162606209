import { createAction } from "@reduxjs/toolkit";
import { call, put, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import filesize from "filesize";
import { ROLE_CURATOR } from "@liveart/rights-management";
import { ERC721WithRoyaltiesInstance } from "@liveart/nft-client/truffle-contracts";
import { Contract } from "ethers";
import { getChainId } from "@liveart/nft-client/dist/web3";
import { getSelectedMetamaskAccount } from "@liveart/nft-client/dist/metamask";
import { priceInEth, web3AddressesEqual } from "~/api/web3";
import {
    handleAppNotification,
    setLoading,
    searchUsersByWallets,
    setLoadFileProgress,
} from "~/api/choreography";
import { LoadingSlots, RawMintTokenData, TokenType } from "~/api/data-schema";
import { getSellableRights } from "~/api/ERC721/rights";
import { saveMintTokenDataToLocalStorage } from "~/api/indexedDB";
import { uploadToIPFS } from "~/api/ipfs";
import { uploadMetadata } from "./uploadMetadata";
import { rawMintTokenDataToMetadata } from "./rawMintTokenDataToMetadata";
import { setFirstOwner } from "../tokenPermissions/tokenPermissionsReducer";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { loadContractByAddress } from "~/BFF/blockchain/solidityContracts/loadContractByAddress";
import { createContractWithMetamaskSigner } from "~/api/solidityContracts/createContractWithMetamaskSigner";

export const mintingFailed = createAction("MINTING_FORM/MINTING_FAILED");
export function* mintingFailedSaga() {
    yield put(
        setLoading({
            slot: LoadingSlots.MINTING,
            loading: false,
        }),
    );
}

enum UploadChannelEventType {
    PROGRESS = "PROGRESS",
    FINISHED = "FINISHED",
    ERROR = "ERROR",
}
interface UploadChannelEvent {
    type: UploadChannelEventType;
    payload: {
        total: number;
        loaded: number;
        fullPath?: string;
        cid?: string;
        error?: string;
    };
}
function createUploadChannel(
    file: File,
    chainId: number,
    selectedContract: SolidityContractDetails,
) {
    return eventChannel((emit) => {
        uploadToIPFS(file, chainId, selectedContract.address, {
            onProgress(total: number, loaded: number) {
                emit({
                    type: UploadChannelEventType.PROGRESS,
                    payload: { total, loaded },
                });
            },
            onLoaded: ({ fullPath, cid, total, loaded }) => {
                emit({
                    type: UploadChannelEventType.FINISHED,
                    payload: { fullPath, cid, total, loaded },
                });
            },
        }).catch((err) => {
            emit({
                type: UploadChannelEventType.ERROR,
                payload: {
                    error: err.message,
                },
            });
        });

        return () => {};
    });
}
function* watchUploadChannelEventsSaga(
    file: File,
    chainId: number,
    selectedContract: SolidityContractDetails,
) {
    const uploadChannel = yield call(
        createUploadChannel,
        file,
        chainId,
        selectedContract,
    );
    let lastEvent: UploadChannelEvent | null = null;

    while (!lastEvent || lastEvent.type !== UploadChannelEventType.FINISHED) {
        lastEvent = yield take<UploadChannelEvent>(uploadChannel);

        if (lastEvent?.type === UploadChannelEventType.ERROR) {
            yield put(
                setLoadFileProgress(file.name, file.size, file.size, false),
            );
            throw new Error(lastEvent?.payload?.error);
        }

        if (lastEvent && lastEvent.type === UploadChannelEventType.PROGRESS) {
            yield put(
                setLoadFileProgress(
                    file.name,
                    file.size,
                    lastEvent.payload.loaded,
                    true,
                ),
            );
        }

        if (lastEvent && lastEvent.type === UploadChannelEventType.FINISHED) {
            yield put(
                setLoadFileProgress(file.name, file.size, file.size, true),
            );
        }
    }

    return {
        fullPath: lastEvent.payload.fullPath,
        cid: lastEvent.payload.cid,
    };
}
export function* prepareDataForMintingSaga(
    chainId: number,
    payload: RawMintTokenData,
) {
    try {
        const {
            files,
            properties: additionalProperties,
            tokenRights: rights,
            royaltyReceivers,
            maxTokenSupply: editions,
            openEdition,
            AP,
            knownProperties,
            tokenType,
            buyoutPrice,
            unlockableContent,
            selectedContract,
        } = payload;

        const maxTokenSupply = +editions + +AP;
        const mediaFile = files.highResFile;
        const mediaPhygitalFile = files.phygitalFile;
        const { previewFile } = files;

        yield put(setLoadFileProgress(mediaFile.name, mediaFile.size, 0, true));
        const { fullPath: mediaFileURL } = yield watchUploadChannelEventsSaga(
            mediaFile,
            chainId,
            selectedContract,
        );
        yield put(
            setLoadFileProgress(
                mediaFile.name,
                mediaFile.size,
                mediaFile.size,
                false,
            ),
        );
        yield put(
            handleAppNotification({
                message: `${mediaFile.name}: upload finished`,
                options: {
                    variant: "info",
                },
            }),
        );
        const mediaPhygitalFileURL: string[] = [];
        if (mediaPhygitalFile) {
            for (let i = 0; i < mediaPhygitalFile.length; i += 1) {
                const pFile = mediaPhygitalFile[i];
                yield put(setLoadFileProgress(pFile.name, pFile.size, 0, true));
                const { fullPath } = yield watchUploadChannelEventsSaga(
                    pFile,
                    chainId,
                    selectedContract,
                );
                mediaPhygitalFileURL.push(fullPath);
                yield put(
                    setLoadFileProgress(
                        pFile.name,
                        pFile.size,
                        pFile.size,
                        false,
                    ),
                );
                yield put(
                    handleAppNotification({
                        message: `${pFile.name}: upload finished`,
                        options: {
                            variant: "info",
                        },
                    }),
                );
            }
        }
        if (previewFile) {
            yield put(
                setLoadFileProgress(
                    previewFile.name,
                    previewFile.size,
                    0,
                    true,
                ),
            );
        }
        const { fullPath: previewFileURL } = previewFile
            ? yield watchUploadChannelEventsSaga(
                  previewFile,
                  chainId,
                  selectedContract,
              )
            : {
                  fullPath: undefined,
              };
        if (previewFile) {
            yield put(
                setLoadFileProgress(
                    previewFile.name,
                    previewFile.size,
                    previewFile.size,
                    false,
                ),
            );
            yield put(
                handleAppNotification({
                    message: `${previewFile.name}: upload finished`,
                    options: {
                        variant: "info",
                    },
                }),
            );
        }

        const metadata = rawMintTokenDataToMetadata(
            payload,
            mediaFileURL,
            previewFileURL,
            mediaPhygitalFileURL,
        );

        // Upload metadata
        const { fullPath: tokenURI } = yield uploadMetadata(
            metadata,
            chainId,
            selectedContract,
        );

        yield put(
            handleAppNotification({
                message: "token metadata upload finished",
                options: {
                    variant: "info",
                },
            }),
        );

        const editionOf = 0;

        const account = yield call(getSelectedMetamaskAccount);
        const certificateProperties = [
            {
                key: "Media",
                value: `${mediaFile.type} (${filesize(mediaFile.size)})`,
            },
            ...(knownProperties.artworkCreationYear
                ? [
                      {
                          key: "Creation date",
                          value: knownProperties.artworkCreationYear,
                      },
                  ]
                : []),
            ...additionalProperties.map(({ name, value }) => ({
                key: name,
                value,
            })),
        ];
        if (mediaPhygitalFile) {
            for (let i = 0; i < mediaPhygitalFile.length; i += 1) {
                const pFile = mediaPhygitalFile[i];
                certificateProperties.push({
                    key: "Phygital Media",
                    value: `${pFile.type} (${filesize(pFile.size || 0)})`,
                });
            }
        }
        yield saveMintTokenDataToLocalStorage(tokenURI, {
            royaltyReceivers,
            rights,
            tokenURI,
            editionOf,
            maxTokenSupply,
            openEdition,
            AP,
            knownProperties,
            certificateProperties,
            artworkPreviewURL: previewFileURL || mediaFileURL,
            tokenType,
            buyOutReceivers: [
                {
                    wallet: account,
                    percentage: 100 * 100,
                    role: ROLE_CURATOR,
                },
            ],
            buyOutPrice: buyoutPrice ? priceInEth(buyoutPrice.toString()) : "",
            to: account,
            unlockableContent,
            selectedContract,
        });
    } catch (e) {
        yield put(mintingFailed());
        yield put(
            handleAppNotification({
                message: e.message,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
function toBasicPoints(percent: number) {
    // https://ethereum.stackexchange.com/a/55702
    // https://www.investopedia.com/terms/b/basispoint.asp
    return percent * 100;
}

export const submitTokenMint = createAction<RawMintTokenData>(
    "MINTING/BEGIN_MINTING_FILE",
);
export function* submitTokenMintSaga({
    payload,
}: ReturnType<typeof submitTokenMint>) {
    try {
        yield put(
            setLoading({
                slot: LoadingSlots.MINTING,
                loading: true,
            }),
        );

        const chainId = yield call(getChainId);

        const { tokenRights, royaltyReceivers, tokenType, selectedContract } =
            payload;

        const currentUser = yield call(getSelectedMetamaskAccount);
        const contract = yield call(() =>
            loadContractByAddress(selectedContract.address),
        );

        if (contract.version !== "v4") {
            if (tokenType !== TokenType.PLAIN_ERC721) {
                const totalPercentage = royaltyReceivers.reduce(
                    (acc, el) => acc + el.percentage,
                    0,
                );
                if (totalPercentage !== 100) {
                    throw new Error(
                        "total initial sale percentage should be 100",
                    );
                }
            }
        }

        if (contract.version === "v2") {
            const erc721ContractInstance = (yield call(() =>
                createContractWithMetamaskSigner({ contract }),
            )) as Contract & ERC721WithRoyaltiesInstance;
            const sellBundle = getSellableRights(tokenRights);
            for (const tokenRight of sellBundle) {
                const owner = tokenRight.permission.wallet.toString();
                if (!web3AddressesEqual(owner, currentUser)) {
                    const isOperator = yield call(
                        erc721ContractInstance.isApprovedForAll,
                        owner,
                        currentUser,
                    );
                    if (!isOperator) {
                        throw new Error(
                            `you should be an operator for the
                            ${owner} wallet to mint this token`,
                        );
                    }
                }
            }
        }

        if (tokenType === TokenType.PLAIN_ERC721 && currentUser)
            yield put(setFirstOwner(currentUser));
        yield put(searchUsersByWallets([currentUser]));

        yield call(prepareDataForMintingSaga, chainId, {
            ...payload,
            tokenRights,
            royaltyReceivers: royaltyReceivers.map((el) => ({
                ...el,
                percentage: toBasicPoints(el.percentage),
                resalePercentage: toBasicPoints(el.resalePercentage),
                CAPPS: toBasicPoints(el.CAPPS),
            })),
        });
    } catch (e) {
        console.error(`submitTokenMintSaga error: ${e.message}`);

        yield put(
            handleAppNotification({
                message: e.message,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
