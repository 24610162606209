import { createReducer } from "@reduxjs/toolkit";
import { OpenEdition } from "~/api/data-schema/OpenEdition";
import { Token } from "~/api/data-schema";
import { loadPOAPResponse } from "~/api/choreography/openEdition";

export type OpenEditionState = {
    poap?: {
        edition: OpenEdition;
        editionsMinted: number;
        claimedToken?: Token;
        deliveredToken?: Token;
    };
};
export type WithOpenEditionState = {
    openEdition: OpenEditionState;
};
export const initialOpenEditionState: OpenEditionState = {};

export const openEditionReducer = createReducer(
    initialOpenEditionState,
    (builder) =>
        builder.addCase(loadPOAPResponse, (state, action) => ({
            ...state,
            poap: action.payload,
        })),
);

export const openEditionSelector = (state: WithOpenEditionState) =>
    state.openEdition;
