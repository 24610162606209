export const SEND_TO_HUBSPOT = `
    mutation SendUserTrackingEvent($payload: JSONString!) {
        sendUserTrackingEvent(payload: $payload) {
            isSuccess
        }
    }
`;

export const getUtms = () => {
    const utmPrams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
    ];
    const urlSearch = globalThis.location.search;
    return urlSearch
        .replace("?", "")
        .split("&")
        .reduce((a, n) => {
            if (utmPrams.includes(n.split("=")[0]))
                return { ...a, [n.split("=")[0]]: n.split("=")[1] };
            return { ...a };
        }, {});
};
