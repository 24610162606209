import add from "date-fns/add";
import {
    getMetamaskProvider,
    getSelectedMetamaskAccount,
} from "@liveart/nft-client/dist/metamask";
import { providers } from "ethers";

const web3TokenStorageKey = "web3-token";

function web3TokenFromStorage(address: string) {
    try {
        const body = localStorage.getItem(`${address}:${web3TokenStorageKey}`);
        const { token, expire } = JSON.parse(body || "");
        if (new Date() < new Date(expire)) {
            return token;
        }

        return undefined;
    } catch {
        return undefined;
    }
}
function saveWeb3TokenToStorage(address: string, token: string, expire: Date) {
    try {
        const body = JSON.stringify({
            token,
            expire: expire.toString(),
        });
        localStorage.setItem(`${address}:${web3TokenStorageKey}`, body);

        return undefined;
    } catch {
        return undefined;
    }
}
export async function getWeb3Token() {
    const selectedMMProvider = await getMetamaskProvider();
    if (selectedMMProvider) {
        const Web3Token = await import("web3-token");

        const address = await getSelectedMetamaskAccount();

        const tokenFromStorage = web3TokenFromStorage(address);
        if (tokenFromStorage) {
            return tokenFromStorage;
        }

        // generating a token with 1 day of expiration time
        const token = await Web3Token.sign((msg) => {
            const web3Provider = new providers.Web3Provider(selectedMMProvider);

            return web3Provider.getSigner().signMessage(msg);
        }, "1d");
        saveWeb3TokenToStorage(
            address,
            token,
            add(new Date(), {
                days: 1,
            }),
        );

        return token;
    }
    return "";
}
