import { defaultBlockchainIdKey } from "./web3Saga";
import { pageContextSessionStorage } from "~/api/pageContextSessionStorage";

export async function getBlockchainId() {
    const { getChainId } = await import("@liveart/nft-client/dist/web3");

    const searchParams = new URLSearchParams(
        globalThis?.window?.location?.search,
    );
    const networkId = await getChainId();

    return +(
        searchParams.get("blockchainId") ||
        searchParams.get("networkId") ||
        pageContextSessionStorage.getItem(defaultBlockchainIdKey) ||
        networkId
    );
}
