import React, { useCallback } from "react";
import { styled } from "@mui/system";
import {
    Drawer,
    Button,
    Box,
    ListItem,
    Typography,
    IconButton,
    Link as MUILink,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import { stytchAuthenticatedSelector } from "@liveart/auth";
import {
    CognitoPersistKeys,
    getValueFromStorage,
} from "@liveart/injectables/dist/user/getValueFromStorage";
import { AuthModalTrigger } from "@liveart/injectables/dist/header/web/liveart/Authentication/AuthModalTrigger";
import { CloseIcon } from "~/features/icon/CloseIcon";
import DiscordICA from "~/features/icon/discordICA.svg";
import TwitterICA from "~/features/icon/twitterICA.svg";
import InstaICA from "~/features/icon/instagramICA.svg";
import { colors } from "~/theme";
import Logo from "./whitelogo.svg";
import Getintouch from "./getintouch.svg";
import HamburgerIcon from "./HamIco.svg";
import { showDialog } from "~/features/dialog";

const List = styled("div")({
    height: "100%",
    width: "100vw",
    "& > button": {
        width: "100%",
        "& > div": {
            width: "100%",
        },
    },
});
const StyledMUILink = styled(MUILink)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    fontFamily: "Arial",
    fontSize: "46px",
    lineHeight: "53px",
    marginBottom: "6px",

    "& p": {
        fontSize: "46px",
        lineHeight: "53px",
        [theme.breakpoints.down(376)]: {
            fontSize: "28px",
            lineHeight: "32px",
            marginBottom: "2px",
        },
    },
    "& > *:first-child": {
        marginRight: "10px",
    },
}));
const Header = styled(Box)({
    display: "flex",
    height: 56,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 18px 0px 8px",
});
const ListLink = styled(Box)(({ theme }) => ({
    marginBottom: "10px",
    marginTop: "80px",
    paddingLeft: "20px",
    [theme.breakpoints.down(376)]: {
        marginTop: "30px",
    },
}));
const ButtonWrap = styled(Box)(({ theme }) => ({
    display: "flex",
    paddingLeft: "45px",
    marginTop: "40px",
    [theme.breakpoints.down(376)]: {
        marginTop: "20px",
        paddingLeft: "40px",
    },
}));
const FooterBox = styled(Box)({
    padding: "0px 20px 50px",
});
const ContactWrap = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "auto",
    borderTop: `1px dashed ${colors.pinkBackground}`,
    color: "rgba(241, 227, 225, 1)",
    marginTop: "110px",
    padding: "35px 20px 0px",
    [theme.breakpoints.down(376)]: {
        margin: "30px 0px 0px 0px",
        padding: "30px 0px 0px 16px",
        display: "block",
        width: "100%",
    },
}));
const TextBox = styled(Box)(({ theme }) => ({
    textAlign: "center",
    flex: 1,
    margin: "0px 10px",
    [theme.breakpoints.down(376)]: {
        textAlign: "left",
        margin: "0px 0px 15px 0px",
    },
}));
const Text = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontFamily: "Forum",
    fontWeight: 400,
    lineHeight: "22px",
    [theme.breakpoints.down(376)]: {
        fontSize: "18px",
    },
}));
const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    marginBottom: "10px",
    fontFamily: "Forum",
    fontStyle: "normal",
    fontWeight: 400,
    [theme.breakpoints.down(376)]: {
        fontSize: "15px",
    },
}));
const GetInTouchWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "35px",
    [theme.breakpoints.down(376)]: {
        justifyContent: "flex-start",
        marginTop: "20px",
    },
}));
const GetInTouch = styled(Button)(({ theme }) => ({
    backgroundColor: colors.pink,
    borderRadius: "64px",
    width: "245px",
    height: "50px",
    color: "white",
    fontFamily: "Arial",
    fontSize: "16px",
    lineHeight: "18.4px",
    "&:hover": {
        backgroundColor: colors.pink,
    },
    [theme.breakpoints.down(376)]: {
        width: "170px",
        height: "32px",
        fontSize: "14px",
        marginLeft: "16px",
        marginTop: "20px",
    },
}));
const SocialWrap = styled(Box)(({ theme }) => ({
    marginTop: "20px",
    textAlign: "center",
    [theme.breakpoints.down(376)]: {
        marginTop: "20px",
        marginLeft: "20px",
        textAlign: "left",
    },
}));
const SocialButton = styled("a")({
    margin: "0 12px",
});

type Anchor = "left";
export interface Props {
    openState?: boolean;
}
export default function MobileDrawer(props: Props) {
    const { openState } = props;
    const [state, setState] = React.useState({
        left: openState || false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setState({ ...state, [anchor]: open });
        };

    const accessToken = getValueFromStorage(CognitoPersistKeys.ACCESS_TOKEN);
    const stytchAuthenticated = useSelector(stytchAuthenticatedSelector);
    const isAuthenticated = accessToken || stytchAuthenticated;

    const dispatch = useDispatch();
    const showAuthModal = useCallback(() => {
        dispatch(showDialog("stytchDialog"));
    }, [dispatch]);

    return (
        <div>
            {(["left"] as Anchor[]).map((anchor) => (
                <React.Fragment key={anchor}>
                    <HamburgerIcon
                        color="#6e62c3"
                        onClick={toggleDrawer(anchor, true)}
                        data-testid="ica-header-hamburger"
                    />
                    <Drawer
                        PaperProps={{
                            sx: {
                                backgroundColor: colors.purple,
                            },
                        }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <List
                            role="presentation"
                            onClick={toggleDrawer(anchor, false)}
                            onKeyDown={toggleDrawer(anchor, false)}
                            data-testid="ica-header-mobile-drawer"
                        >
                            <Header>
                                <Link
                                    href="/"
                                    passHref
                                    data-testid="ica-header-mobile-drawer-logo-home-link"
                                >
                                    <IconButton>
                                        <Logo />
                                    </IconButton>
                                </Link>
                                <CloseIcon
                                    color="white"
                                    onClick={toggleDrawer(anchor, false)}
                                    onKeyDown={toggleDrawer(anchor, false)}
                                    data-testid="ica-header-mobile-drawer-close-button"
                                />
                            </Header>

                            <ListLink>
                                <ListItem>
                                    <StyledMUILink
                                        href="https://365.icamiami.org/"
                                        underline="none"
                                        data-testid="ica-header-mobile-drawer-home-link"
                                    >
                                        <Typography>Home</Typography>
                                    </StyledMUILink>
                                </ListItem>
                                <ListItem>
                                    <StyledMUILink
                                        href="https://nft.icamiami.org"
                                        underline="none"
                                        data-testid="ica-header-mobile-drawer-nft-auction-link"
                                    >
                                        <Typography>NFT Auction</Typography>
                                    </StyledMUILink>
                                </ListItem>
                                <ListItem>
                                    <StyledMUILink
                                        href="https://365.icamiami.org/#about-the-artist"
                                        underline="none"
                                        data-testid="ica-header-mobile-drawer-about-the-artist-link"
                                    >
                                        <Typography>
                                            About the Artist
                                        </Typography>
                                    </StyledMUILink>
                                </ListItem>
                                <ListItem>
                                    <StyledMUILink
                                        href="https://365.icamiami.org/#honoree"
                                        underline="none"
                                        data-testid="ica-header-mobile-drawer-honoree-link"
                                    >
                                        <Typography>Honoree</Typography>
                                    </StyledMUILink>
                                </ListItem>
                                <ListItem>
                                    <StyledMUILink
                                        href="https://365.icamiami.org/#tickets-and-tables"
                                        underline="none"
                                        data-testid="ica-header-mobile-drawer-tickets-link"
                                    >
                                        <Typography>
                                            Tickets + Tables
                                        </Typography>
                                    </StyledMUILink>
                                </ListItem>
                            </ListLink>

                            <ButtonWrap>
                                {!isAuthenticated && (
                                    <Box onClick={showAuthModal}>
                                        <AuthModalTrigger />
                                    </Box>
                                )}
                            </ButtonWrap>

                            <FooterBox>
                                <ContactWrap data-testid="ica-header-mobile-contact">
                                    <TextBox>
                                        <SmallText data-testid="ica-header-mobile-phone-label">
                                            Phone:
                                        </SmallText>

                                        <Text data-testid="ica-header-mobile-phone-number">
                                            305 901 5272
                                        </Text>
                                    </TextBox>
                                    <TextBox>
                                        <SmallText data-testid="ica-header-mobile-address-label">
                                            Address:
                                        </SmallText>

                                        <Text data-testid="ica-header-mobile-address-street">
                                            61 NE 41st Street Miami,
                                        </Text>

                                        <Text data-testid="ica-header-mobile-address-state">
                                            FL 33137
                                        </Text>
                                    </TextBox>
                                    <TextBox>
                                        <SmallText data-testid="ica-header-mobile-email-label">
                                            Email:
                                        </SmallText>
                                        <Text data-testid="ica-header-mobile-email-address">
                                            365party@icamiami.org
                                        </Text>
                                    </TextBox>
                                </ContactWrap>

                                <GetInTouchWrapper>
                                    <GetInTouch
                                        endIcon={<Getintouch />}
                                        href="mailto:365party@icamiami.org"
                                        data-testid="ica-header-mobile-get-intouch-button"
                                    >
                                        Get intouch
                                    </GetInTouch>
                                </GetInTouchWrapper>
                                <SocialWrap data-testid="ica-header-mobile-socials">
                                    <SocialButton
                                        href="https://discord.com/invite/3Jn7vSHXbZ"
                                        target="_blank"
                                        rel="noreferrer"
                                        data-testid="ica-header-mobile-discord-link"
                                    >
                                        <DiscordICA />
                                    </SocialButton>
                                    <SocialButton
                                        href="https://twitter.com/PalmNft"
                                        target="_blank"
                                        rel="noreferrer"
                                        data-testid="ica-header-mobile-twitter-link"
                                    >
                                        <TwitterICA />
                                    </SocialButton>

                                    <SocialButton
                                        href="https://www.instagram.com/palmnft/"
                                        target="_blank"
                                        rel="noreferrer"
                                        data-testid="ica-header-mobile-instagram-link"
                                    >
                                        <InstaICA />
                                    </SocialButton>
                                </SocialWrap>
                            </FooterBox>
                        </List>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
