export const rinkebyInfuraAddress =
    "https://rinkeby.infura.io/v3/a6ad1943144e4f8297947cf79ef5c56e";
export const mainnetInfuraAddress =
    "https://mainnet.infura.io/v3/a6ad1943144e4f8297947cf79ef5c56e";
export const palmtestInfuraAddress =
    "https://palm-testnet.infura.io/v3/a6ad1943144e4f8297947cf79ef5c56e";
export const palmMainNetInfureAddress =
    "https://palm-mainnet.infura.io/v3/a6ad1943144e4f8297947cf79ef5c56e";
export const goerliInfureAddress =
    "https://goerli.infura.io/v3/a6ad1943144e4f8297947cf79ef5c56e";
export const binanceTestnetRPCAddress =
    "https://alien-small-dawn.bsc-testnet.quiknode.pro/001e264b760c7d9457f553e770f2b84804c733b7";
export const bianceRPCAddress =
    "https://black-green-gadget.bsc.quiknode.pro/26680fe32f741ad4511188c4facafb4cbaa5ad53";
