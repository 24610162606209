import { createAction } from "@reduxjs/toolkit";
import {
    TokenDataSchema,
    PaginatedTokenDataSchema,
    FetchAllTokenListsByAddressesPayload,
} from "~/api/ERC721v4/TokenDataJSONSchema";
import {
    FetchMetadataPayload,
    FetchAddressTokensPayload,
} from "~/api/ERC721v4/MorailsPayloadTypes";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export const fetchTokenMetadata = createAction<FetchMetadataPayload>(
    "API/SOLIDITY_CONTRACTS/V4/FETCH_TOKEN_BASE_URI",
);

export const fetchTokenMetadataResponse = createAction<TokenDataSchema>(
    "API/SOLIDITY_CONTRACTS/V4/FETCH_TOKEN_BASE_URI/RESPONSE",
);

export const fetchTokenListByAddress = createAction<FetchAddressTokensPayload>(
    "API/SOLIDITY_CONTRACTS/V4/FETCH_TOKEN_LIST_BY_WALLET",
);

export const fetchAllTokenListsByAddresses =
    createAction<FetchAllTokenListsByAddressesPayload>(
        "API/SOLIDITY_CONTRACTS/V4/FETCH_ALL_TOKEN_LISTS_BY_ADDRESSES",
    );

export const fetchTokenListByAddressResponse =
    createAction<PaginatedTokenDataSchema>(
        "API/SOLIDITY_CONTRACTS/V4/FETCH_TOKEN_LIST_BY_WALLET/RESPONSE",
    );

export const fetchEditionNamesBySelectedContract = createAction<{
    selectedContract: SolidityContractDetails;
}>("API/SOLIDITY_CONTRACTS/V4/FETCH_EDITION_NAMES_BY_SELECTED_CONTRACT");

export const setEditionNames = createAction<string[]>(
    "MINTING/SELECT_CONTRACT/EDITION_NAMES",
);
