export function parseQuery(queryString: string) {
    const parsedQuery: {
        [index: string]: string;
    } = {};
    const pairs = (
        queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (let i = 0; i < pairs.length; i += 1) {
        const pair = pairs[i].split("=");
        parsedQuery[decodeURIComponent(pair[0])] = decodeURIComponent(
            pair[1] || "",
        );
    }
    return parsedQuery;
}
