import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";

import {
    getApplicationFromHostname,
    getApplicationFromQueryParams,
} from "~/WhiteLabel/host";

export function getApplicationFromLocation(): SupportedApplication {
    return (
        getApplicationFromQueryParams() ||
        getApplicationFromHostname(globalThis?.location?.hostname || "")
    );
}
