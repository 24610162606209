import {
    call,
    cancel,
    debounce,
    fork,
    put,
    select,
    take,
    takeLatest,
} from "redux-saga/effects";
import type { NotUndefined, Task } from "@redux-saga/types";
import { EventChannel } from "redux-saga";
import { getChainId, getUserBalance } from "@liveart/nft-client/dist/web3";
import {
    getSelectedMetamaskAccount,
    requestMetamaskAccount,
} from "@liveart/nft-client/dist/metamask";

import { stytchGetCurrentUser } from "@liveart/auth/lib/src/features/authentication/stytchGetCurrentUserSaga";
import { finishStytchAuthentication } from "@liveart/injectables/dist/choreography/cognitioActions";
import { stytchLoadAuthenticationState } from "@liveart/auth/lib/src/features/authentication/stytchLoadAuthenticationStateSaga";
import {
    authenticateWithMetamask,
    authenticateWithMetamaskResponse,
    getCognitoUserResponse,
    trackHubSpotEvent,
    getCognitoUser,
    handleAppNotification,
    updateCurrentUser,
    userAuthenticated,
    clearAuthenticationLine,
    replayAuthenticationLine,
    trackSegmentEvent,
} from "~/api/choreography";
import { getAccessToken } from "~/api/user/getAccessToken";
import { searchAllUsers } from "~/api/user/searchAllUsers";
import { getCurrentCognitoUser } from "~/api/user/getCognitoUser";
import { CognitoUser } from "~/api/data-schema";
import { registerNotificationHandler } from "~/features/notifications/notificationsReducer";
import { userStateSelector } from "./userStateSelector";
import {
    walletIsNotUniqueAuthHandler,
    walletIsNotConnectedHandler,
    authenticationRequestIsAlreadyPendingHandler,
} from "~/features/user/authentication";
import {
    metamaskEventsChannel,
    METAMASK_ACCOUNT_CHANGED,
} from "~/api/web3/metamask";
import { web3AddressesEqual } from "~/api/web3/address";
import { Unpromisify } from "~/typescript";
import { EventNames } from "~/api/hubspot";
import { SegmentEvents } from "~/api/segment/segmentEvents";
import { persistUserAccessToken } from "~/api/user/persistUserAccessToken";

export function* authenticateWithMetamaskSaga(
    action: ReturnType<typeof authenticateWithMetamask>,
) {
    const providerId = action.payload;
    try {
        const account = yield call(requestMetamaskAccount, providerId);
        if (account) {
            const balance = yield call(getUserBalance);

            yield put(userAuthenticated({ account, balance }));
            yield put(authenticateWithMetamaskResponse(account));
        } else {
            yield put(userAuthenticated({ account, balance: undefined }));
        }
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

function* getAccount() {
    try {
        const account = yield call(getSelectedMetamaskAccount);
        if (account) {
            const balance = yield call(getUserBalance);

            yield put(userAuthenticated({ account, balance }));
        } else {
            yield put(userAuthenticated({ account, balance: undefined }));
        }
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

function* subscribeToAccountChanged() {
    const channel: EventChannel<NotUndefined> = yield call(
        metamaskEventsChannel,
    );

    try {
        while (true) {
            const event = yield take(channel);
            if (event === METAMASK_ACCOUNT_CHANGED) {
                yield getAccount();
            }
        }
    } catch (e) {
        console.error("error occurred in subscribeToAccountChanged");
        console.error(e.message);
    } finally {
        channel?.close();
    }
}

export function* replayAuthenticationLineSaga() {
    try {
        const { actionsLine } = yield select(userStateSelector);

        for (let index = 0; index < actionsLine.length; index += 1) {
            const [actionCreator, payload] = actionsLine[index];

            const action = actionCreator(payload);

            yield put(action);
        }

        yield put(clearAuthenticationLine());
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

function* saveCognitoUserWallet(wallet: string, cognitoUser: CognitoUser) {
    const accessToken = yield call(getAccessToken);
    const networkId = yield call(getChainId);

    const [foundUserByWallet] = (yield call(
        searchAllUsers,
        accessToken,
        +networkId,
        {
            walletIn: [wallet],
        },
    )) as Unpromisify<ReturnType<typeof searchAllUsers>>;

    const isTheSameUser = () => {
        return (
            foundUserByWallet?.id?.toString()?.toLowerCase() ===
            cognitoUser?.id?.toString()?.toLowerCase()
        );
    };

    if (foundUserByWallet && !isTheSameUser()) {
        throw new Error(
            "This wallet is already associated with another account.",
        );
    }

    if (isTheSameUser()) {
        return; // already assigned
    }

    /**
     * do not save the wallet if user already has it;
     * probably user is connecting the wallet on another device/browser
     */
    if (
        !cognitoUser?.wallets?.find((el) =>
            web3AddressesEqual(el.wallet, wallet),
        )
    ) {
        yield put(
            updateCurrentUser({
                userData: {
                    wallets: (cognitoUser?.wallets || []).concat({
                        wallet,
                    }),
                },
                redirectOnSuccess: false,
            }),
        );
    }
}

function* onAuthenticateWithMetamaskResponse({
    payload: wallet,
}: ReturnType<typeof authenticateWithMetamaskResponse>) {
    const watchCognitoUserTask = (yield fork(
        function* watchCognitoUserResponseSaga() {
            yield takeLatest(
                getCognitoUserResponse.toString(),
                function* onCognitoUserReceiveSaga({
                    payload: cognitoUser,
                }: ReturnType<typeof getCognitoUserResponse>) {
                    try {
                        if (cognitoUser) {
                            yield call(
                                saveCognitoUserWallet,
                                wallet,
                                cognitoUser,
                            );
                            yield put(
                                trackHubSpotEvent({
                                    EventName: EventNames.meta_wallet_connected,
                                    email: cognitoUser?.email,
                                    properties: {
                                        user_id: cognitoUser?.externalId,
                                        wallet_id: wallet,
                                    },
                                }),
                            );
                        }

                        yield cancel(watchCognitoUserTask);
                    } catch (e) {
                        yield put(
                            handleAppNotification({
                                message: e.message,
                                errorObj: e,
                                options: {
                                    variant: "error",
                                    persist: true,
                                },
                            }),
                        );
                    }
                },
            );
        },
    )) as Task;
    yield put(getCognitoUser());
}

export function* userSaga() {
    yield fork(getAccount);
    yield put(getCognitoUser());
    yield put(stytchLoadAuthenticationState());

    yield put(registerNotificationHandler(walletIsNotUniqueAuthHandler));
    yield put(registerNotificationHandler(walletIsNotConnectedHandler));
    yield put(
        registerNotificationHandler(
            authenticationRequestIsAlreadyPendingHandler,
        ),
    );

    yield takeLatest(
        authenticateWithMetamask.toString(),
        authenticateWithMetamaskSaga,
    );
    yield debounce(
        1000,
        userAuthenticated.toString(),
        function* onUserAuthenticatedSaga(
            action: ReturnType<typeof userAuthenticated>,
        ) {
            try {
                yield put(replayAuthenticationLine());
                const accessToken = yield call(getAccessToken);
                const networkId = yield call(getChainId);
                const cognitoUser = yield call(
                    getCurrentCognitoUser,
                    accessToken,
                    networkId,
                );
                if (action.payload.account !== "" && cognitoUser) {
                    yield put(
                        trackSegmentEvent({
                            eventName: SegmentEvents.WALLET_CONNECTED,
                            properties: {
                                email: cognitoUser?.email,
                                user_id: cognitoUser?.externalId,
                                wallet_id: action.payload.account,
                            },
                        }),
                    );
                }
            } catch (e) {
                yield put(
                    handleAppNotification({
                        message: e.message,
                        errorObj: e,
                        options: {
                            variant: "error",
                            persist: true,
                        },
                    }),
                );
            }
        },
    );
    yield takeLatest(
        userAuthenticated.toString(),
        replayAuthenticationLineSaga,
    );

    yield takeLatest(
        authenticateWithMetamaskResponse.toString(),
        onAuthenticateWithMetamaskResponse,
    );

    yield takeLatest(
        [
            authenticateWithMetamaskResponse.toString(),
            userAuthenticated.toString(),
        ],
        subscribeToAccountChanged,
    );

    yield takeLatest(stytchGetCurrentUser.toString(), function* (action) {
        const { accessToken } = (
            action as unknown as {
                payload: {
                    accessToken: string;
                };
            }
        ).payload;
        if (accessToken) {
            persistUserAccessToken(accessToken);

            yield put(getCognitoUser());
        }
    });
    yield takeLatest(finishStytchAuthentication.toString(), function* () {
        yield put(getCognitoUser());
    });
}
