import {
    RINKEBY_CHAIN_ID,
    GOERLI_CHAIN_ID,
    PALM_TEST_CHAIN_ID,
    BINANCE_TESTNET_CHAIN_ID,
} from "@liveart/nft-client/dist/chainId";

export function isTestnet(networkId: number) {
    return (
        networkId === RINKEBY_CHAIN_ID ||
        networkId === GOERLI_CHAIN_ID ||
        networkId === BINANCE_TESTNET_CHAIN_ID ||
        networkId === PALM_TEST_CHAIN_ID
    );
}
