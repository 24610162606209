import axios from "axios";
import { getPinataJwtToken } from "./dappBE";

declare module "axios" {
    export interface AxiosRequestConfig {
        maxBodyLength?: number;
    }
}

type PinFileToIPFSRes = {
    IpfsHash: string;
    PinSize: number;
    Timestamp: string;
};

export async function uploadToIPFS(
    data: File,
    chainId: number,
    tokenContractAddress: string,
    props?: {
        onProgress?: (total: number, loaded: number) => void;
        onLoaded: ({
            fullPath,
            cid,
            total,
            loaded,
        }: {
            fullPath: string;
            cid: string;
            total: number;
            loaded: number;
        }) => void;
    },
) {
    const total = data.size;

    const formData = new FormData();
    formData.append("file", data);

    const onUploadProgress = ({ loaded }: ProgressEvent) =>
        props?.onProgress && props?.onProgress(total, loaded);

    const jwt = await getPinataJwtToken({ tokenContractAddress });
    const pinUrl = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    const {
        data: { IpfsHash: cid },
    } = await axios.post<PinFileToIPFSRes>(pinUrl, formData, {
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        headers: {
            ...axios.defaults.headers.post,
            Authorization: `Bearer ${jwt}`,
        },
        onUploadProgress,
    });

    const fullPath = `${process.env.NEXT_PUBLIC_IPFS_GATEWAY_PREFIX}${cid}`;
    if (props?.onLoaded) {
        props.onLoaded({
            fullPath,
            cid,
            total,
            loaded: total,
        });
    }

    return {
        fullPath,
        cid,
    };
}
