import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { SupportedThemes } from "~/theme";
import { getApplicationFromHostname } from "./host";

export function getThemeFromApplication(): SupportedThemes {
    switch (getApplicationFromHostname()) {
        case SupportedApplication.icamiami:
            return SupportedThemes.pink;
        default:
            return SupportedThemes.default;
    }
}
