import { call } from "redux-saga/effects";
import { setDefaultApplication } from "~/api/choreography";
import { pageContextSessionStorage } from "~/api/pageContextSessionStorage";
import { setSearchParam } from "~/api/location";

export const defaultApplicationKey = "api/application/defaultApplicationKey";
export function* setDefaultApplicationSaga(
    action: ReturnType<typeof setDefaultApplication>,
) {
    const application = action.payload;
    yield call(() => {
        pageContextSessionStorage.setItem(defaultApplicationKey, application);
        setSearchParam("application", application);
    });
}
