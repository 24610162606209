import { getApplicationFromHostname } from "~/WhiteLabel/host";
import { FeatureFLag } from "./data-schema";

const featureFlagDefaultsByApplication = {};
const featureFlagDefaults = {
    [FeatureFLag.isNewPDPEnabled]: true,
    [FeatureFLag.isPDPV4Enabled]: true,
    [FeatureFLag.registerWinterProjectOnMint]: true,
} as {
    [flag in FeatureFLag]: boolean;
};

export const getFeatureFlagFromStorage = (flag: keyof typeof FeatureFLag) => {
    const application = getApplicationFromHostname();
    return (
        globalThis?.localStorage?.getItem(flag) === "true" ||
        featureFlagDefaultsByApplication[application]?.[flag] ||
        featureFlagDefaults[flag]
    );
};

export const setFeatureFlagToStorage = (
    flag: keyof typeof FeatureFLag,
    value: boolean,
) => {
    globalThis?.localStorage?.setItem(flag, String(value));
};
