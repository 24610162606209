import { createAction } from "@reduxjs/toolkit";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export type SelectContractState = {
    selectedContract?: SolidityContractDetails;
};
export const initialSelectContractState: SelectContractState = {};

export const setSelectedContract = createAction<SolidityContractDetails>(
    "MINTING/SELECT_CONTRACT",
);
export const setSelectedContractReducer = (
    state: SelectContractState,
    { payload },
): SelectContractState => ({
    ...state,
    selectedContract: payload,
});
export const selectedContractSelector = (state: {
    mint: SelectContractState;
}) => state.mint.selectedContract;
