import { createAction } from "@reduxjs/toolkit";
import { sellableRights } from "@liveart/rights-management";
import { Permission, Policy } from "~/api/data-schema";

export type TokenRightsState = {
    tokenRights: Policy[];
    /* <external dependencies> */
    permissions?: Permission[];
    /* </external dependencies> */
};
export const tokenRightsInitialState: TokenRightsState = {
    tokenRights: [],
};

function filterOutTokenRight(tokenRights: Policy[], rightToRemove: Policy) {
    return tokenRights.filter(
        (el) =>
            !(
                el.action === rightToRemove.action &&
                el.permission.wallet === rightToRemove.permission.wallet
            ),
    );
}

export const setTokenRights = createAction<Policy[]>(
    "MINTING/SET_TOKEN_RIGHTS",
);
export const setTokenRightsReducer = (
    state: TokenRightsState,
    { payload }: ReturnType<typeof setTokenRights>,
): TokenRightsState => ({
    ...state,
    tokenRights: payload,
});

export const removeTokenRight = createAction<Policy>(
    "MINTING/REMOVE_TOKEN_RIGHT",
);
export const removeTokenRightReducer = (
    state: TokenRightsState,
    { payload }: ReturnType<typeof removeTokenRight>,
): TokenRightsState => ({
    ...state,
    tokenRights: filterOutTokenRight(state.tokenRights as Policy[], payload),
});

export const removeTokenRightsByPermissionReducer = (
    state,
    { payload }: { payload: Permission },
) => ({
    ...state,
    tokenRights: state.tokenRights.filter(
        (el) => el.permission.wallet !== payload.wallet,
    ),
});

export const addTokenRight = createAction<Policy>("MINTING/ADD_TOKEN_RIGHT");
export const addTokenRightReducer = (
    state: TokenRightsState,
    { payload }: ReturnType<typeof addTokenRight>,
): TokenRightsState => {
    const isSellableRight = sellableRights.find(
        (sr) => sr.action === payload.action,
    );
    if (isSellableRight) {
        return {
            ...state,
            tokenRights: state.tokenRights
                .filter((el) => el.action !== payload.action)
                .concat(payload),
        };
    }

    const rightIsPresent = state.tokenRights.find((el) => {
        return (
            el.action === payload.action &&
            el.permission.wallet === payload.permission.wallet
        );
    });
    if (rightIsPresent) {
        return state;
    }
    return {
        ...state,
        tokenRights: state.tokenRights.concat(payload),
    };
};

export const initTokenRights = createAction("TOKEN_RIGHTS/INIT_TOKEN_RIGHTS");

export const tokenRightsSelector = (state: { mint: TokenRightsState }) =>
    state.mint;
