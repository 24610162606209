import Box, { BoxProps } from "@mui/material/Box";
import { SystemProps } from "@mui/system";

interface Properties extends BoxProps {}

export function ContentWrapper({
    children,
    className,
    ...rest
}: Properties): JSX.Element {
    return (
        <Box
            sx={
                {
                    maxWidth: 1440,
                    margin: "0 auto",
                    padding: "0 40px",

                    "@media (max-width: 768px)": {
                        padding: "0 20px",
                    },

                    "@media (max-width: 375px)": {
                        padding: "0 15px",
                    },
                } as unknown as SystemProps
            }
            {...rest}
        >
            {children}
        </Box>
    );
}
