import { createAction } from "@reduxjs/toolkit";
import { CognitoUser } from "../data-schema";

export const cognitoLogout = createAction<{ user?: CognitoUser }>(
    "API/COGNITO/LOGOUT",
);
export const navigateToSSO = createAction<{ signup?: boolean }>(
    "API/COGNITO/NAVIGATE_TO_SSO",
);
export const getCognitoUserResponse = createAction<CognitoUser>(
    "API/COGNITO/GET_USER_RESPONSE",
);

export const searchUsersByWallets = createAction<string[]>(
    "API/COGNITO/SEARCH_USERS_BY_WALLETS",
);
export const searchUsersByWalletsResponse = createAction<{
    [wallet: string]: CognitoUser;
}>("API/COGNITO/SEARCH_USERS_BY_WALLETS_RESPONSE");

export const searchUsersById = createAction<string[]>(
    "API/COGNITO/SEARCH_USERS_BY_ID",
);
export const searchUsersByIdResponse = createAction<{
    [userId: string]: CognitoUser;
}>("API/COGNITO/SEARCH_USERS_BY_ID_RESPONSE");

export const finishCognitoAuthentication = createAction<{
    last_auth_user: string;
    access_token: string;
    refresh_token: string;
    state: string;
    signed_up: string;
}>("API/COGNITO/FINISH_AUTHENTICATION");

export const getCognitoUser = createAction("API/COGNITO/GET_USER");

export const setLamCognitoAuthInfo = createAction<{
    cognitoUserId: string;
    accessToken: string;
    refreshToken: string;
}>("API/COGNITO/SET_LAM_COGNITO_AUTH_INFO");

export const updateCurrentUser = createAction<{
    newAvatar?: File;
    userData: Omit<Partial<CognitoUser>, "avatarImageUrl">;
    redirectOnSuccess: boolean;
}>("USER/COGNITO/UPDATE_CURRENT_USER");

export const userAuthenticated = createAction<{
    account: string;
    balance?: string;
    chainId?: number;
}>("USER/AUTHENTICATED");
export const clearAuthenticationLine = createAction(
    "USER/CLEAT_AUTHENTICATION_LINE",
);
export const replayAuthenticationLine = createAction(
    "API/EXCHANGE/REPLAY_AUTHENTICATION_LINE",
);
export const accountDisconnect = createAction("USER/ACCOUNT_DISCONNECT");

export const attachWallet = createAction<{
    walletAddress: string;
    walletType: string;
}>("USER/ATTACH_WALLET");
