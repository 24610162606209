import { createReducer } from "@reduxjs/toolkit";
import { loadRemoteConfigResponse } from "~/api/choreography";
import { getFeatureFlagFromStorage } from "~/api/featureFLag";

export type TrustDropState = {
    tokensPool: number[];
};

export type WithTrustDropState = {
    trustDrop: TrustDropState;
};

export const initialTrustDropState: TrustDropState = {
    tokensPool: [],
};

export const trustDropReducer = createReducer(
    initialTrustDropState,
    (builder) =>
        builder.addCase(
            loadRemoteConfigResponse,
            (state: TrustDropState, { payload }): TrustDropState => ({
                ...state,
                tokensPool: getFeatureFlagFromStorage(
                    "artechouseTestTokensPool",
                )
                    ? payload?.artechouseTestTokensPool?.defaultValue?.value
                    : payload?.artechouseTokensPool?.defaultValue?.value,
            }),
        ),
);

export const artechouseSelector = (state: WithTrustDropState) =>
    state.trustDrop;
