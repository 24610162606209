import { eventChannel } from "redux-saga";
import { subscribeToDefaultBlockchainIdChange } from "./defaultBlockchainId";

export const METAMASK_ACCOUNT_CHANGED = "METAMASK_ACCOUNT_CHANGED";
export const METAMASK_CHAIN_CHANGED = "METAMASK_CHAIN_CHANGED";
export const metamaskEventsChannel = async () => {
    const { getMetamaskProvider } = await import(
        "@liveart/nft-client/dist/metamask"
    );

    try {
        const provider = await getMetamaskProvider();
        return eventChannel((emitter) => {
            function onAccountsChanged() {
                emitter(METAMASK_ACCOUNT_CHANGED);
            }
            function onChainChanged() {
                emitter(METAMASK_CHAIN_CHANGED);
            }

            if (provider) {
                provider?.addListener?.("accountsChanged", onAccountsChanged);
                provider?.addListener?.("chainChanged", onChainChanged);
            }
            return () => {
                if (provider) {
                    provider.removeListener(
                        "accountsChanged",
                        onAccountsChanged,
                    );
                    provider.removeListener("chainChanged", onChainChanged);
                }
            };
        });
    } catch (e) {
        console.error(e);
    }

    return undefined;
};

export function onNetworkIdChanged(
    cb: (newNetworkId: number) => void,
): () => void {
    import("@liveart/nft-client/dist/metamask").then(
        ({ getMetamaskProvider }) => {
            getMetamaskProvider().then((provider) => {
                if (provider) {
                    provider?.addListener?.("chainChanged", cb);
                }
            });
        },
    );

    const unsubscribeFromDefaultBlockchainIdChange =
        subscribeToDefaultBlockchainIdChange(cb);
    return () => {
        import("@liveart/nft-client/dist/metamask").then(
            ({ getMetamaskProvider }) => {
                getMetamaskProvider().then((provider) => {
                    if (provider) {
                        provider.removeListener("chainChanged", cb);
                    }
                });
            },
        );

        unsubscribeFromDefaultBlockchainIdChange();
    };
}

export const metamaskCWSURL =
    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn";
