import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Currency, LoadingSlots } from "~/api/data-schema";
import { getCurrencyRates } from "~/api/choreography";
import { useLoadingSlot } from "~/features/loading";
import { currencyExchangeSelector } from "./currencyExchangeReducer";

export function useCurrencyExchangeRate({
    from,
    to,
}: {
    from: Currency;
    to: Currency;
}) {
    const dispatch = useDispatch();
    const loading = useLoadingSlot(LoadingSlots.EXCHANGING_CURRENCY);
    const exchangeRate = useSelector(currencyExchangeSelector(from, to));

    useEffect(() => {
        if (!exchangeRate) {
            dispatch(getCurrencyRates({ from, to }));
        }
    }, [dispatch, exchangeRate, from, to]);

    if (loading || !exchangeRate) {
        return undefined;
    }

    return exchangeRate;
}
