import { call, put } from "redux-saga/effects";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import axios from "axios";
import {
    downloadPDF,
    downloadPDFByUrl,
    printPDF,
    trackSegmentEvent,
} from "./choreography";
import { SegmentEvents } from "~/api/segment/segmentEvents";

export async function fetchPdfBlob(uri: string) {
    const response = await fetch(uri, {
        method: "GET",
        headers: {
            "Content-Type": "application/pdf",
        },
    });

    return response.blob();
}

export function* downloadPDFByUrlSaga(
    action: ReturnType<typeof downloadPDFByUrl>,
) {
    const { url, contentType, fileName = "document" } = action.payload;
    try {
        const blob = yield axios.get(url, {
            method: "GET",
            responseType: "blob",
            headers: {
                "Content-Type": contentType,
            },
        });
        const fileUrl = URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    } catch (error) {
        console.error(error);
    }
}

export function* downloadPDFSaga(action: ReturnType<typeof downloadPDF>) {
    const {
        dialogId,
        email,
        user_id,
        artwork_id,
        artwork_name,
        artist_name,
        step,
    } = action.payload;
    const element = document.getElementById(dialogId);
    const hideElements = document.querySelectorAll('[hide-on-print="true"]');

    if (element) {
        hideElements.forEach((hideElement) => {
            // eslint-disable-next-line no-param-reassign
            (hideElement as HTMLElement).style.display = "none";
        });
        const canvas = yield html2canvas(element, { useCORS: true });
        hideElements.forEach((hideElement) => {
            // eslint-disable-next-line no-param-reassign
            (hideElement as HTMLElement).style.display = "block";
        });
        const data = canvas.toDataURL("image/png");

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("certificate.pdf");
    }
    yield put(
        trackSegmentEvent({
            eventName: SegmentEvents.ARTWORK_PDF_GENERATED,
            properties: {
                email,
                user_id,
                artwork_id,
                artwork_name,
                artist_name,
                step,
            },
        }),
    );
}

export function* printPDFSaga(action: ReturnType<typeof printPDF>) {
    const uri = action.payload;

    const blob = yield call(fetchPdfBlob, uri);
    const blobURL = URL.createObjectURL(blob);

    // Automatically open print
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function onload() {
        setTimeout(() => {
            iframe.focus();
            iframe.contentWindow?.print();
        }, 1);
    };
}
