import omitBy from "lodash/omitBy";
import { CognitoUserWallet } from "../data-schema/CognitoUser";
import { query } from "../graphQL";
import { userInfoMutation } from "./UserInfoMutation";

export async function updateCognitoUser(
    chainId: number,
    accessToken: string,
    userData: Partial<{
        avatarS3Key: string;
        biography: string;
        nickname: string;
        socialLinks: string[];
        wallets: CognitoUserWallet[];
    }>,
) {
    const { avatarS3Key, biography, nickname, socialLinks, wallets } = userData;

    const resp = await query({
        networkId: chainId,
        queryStr: userInfoMutation,
        accessToken,
        variables: {
            inputData: omitBy(
                {
                    avatarS3Key,
                    biography,
                    nickname,
                    socialLinks,
                    wallets,
                },
                (value) => typeof value === "undefined",
            ),
        },
    });

    if (resp.errors) {
        throw new Error(resp.errors?.[0]?.message);
    }
}
