import { fetchWithAuthHeaders } from "~/api/fetchWithHeaders/fetchWithAuthHeaders";
import { OpenEdition } from "~/api/data-schema/OpenEdition";

export async function loadPOAPFromBe(
    {
        networkId,
        tokenContractAddress,
        parentTokenId,
        wallets,
    }: {
        networkId: number;
        tokenContractAddress: string;
        parentTokenId: number;
        wallets: string[];
    },
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(`/api/tokens/poap`, {
        method: "POST",
        body: JSON.stringify({
            networkId,
            tokenContractAddress,
            parentTokenId,
            wallets,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return loadPOAPFromBe(
            {
                networkId,
                tokenContractAddress,
                parentTokenId,
                wallets,
            },
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
export async function claimPOAPOnBe(
    {
        edition,
        wallet,
    }: {
        edition: OpenEdition;
        wallet: string;
    },
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(`/api/tokens/claim-poap`, {
        method: "POST",
        body: JSON.stringify({
            edition,
            wallet,
        }),
    });

    if (resp.status >= 500 && retry <= 10) {
        return claimPOAPOnBe(
            {
                edition,
                wallet,
            },
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
