import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import debounce from "lodash/debounce";
import { fetchWithAuthHeaders } from "~/api/fetchWithHeaders/fetchWithAuthHeaders";
import { BeBid, TokenLock } from "~/api/data-schema";
import { SlackChannels } from "~/BFF/slack/postToSlackChannel";
import { normalizeBidUser } from "~/api/treasurer/normalizeBidUser";
import { getBlockchainId } from "~/api/web3/getBlockchainId";

export async function lockTokenOnBe(lock: TokenLock, retries = 0) {
    const tokenChainId = await getBlockchainId();
    const resp = await fetchWithAuthHeaders("/api/state-machine/lock-token", {
        method: "POST",
        body: JSON.stringify({
            lock,
            tokenChainId,
        }),
    });

    if (!resp.ok) {
        console.error("lockTokenOnBe response", resp);
    }

    if (resp.status >= 500 && retries <= 3) {
        return lockTokenOnBe(lock, retries + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

let processLocksPromise = Promise.resolve();
const progressiveAwait = (): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            progressiveAwait.timeout *= 2;

            resolve();
        }, progressiveAwait.timeout * 1000);
    });
};
progressiveAwait.timeout = 60;
export const processLocks = debounce((networkId: number, retries = 0) => {
    processLocksPromise = processLocksPromise
        .then(async () => {
            try {
                const resp = await fetchWithAuthHeaders(
                    "/api/state-machine/process-locks",
                    {
                        method: "POST",
                        body: JSON.stringify({ networkId }),
                    },
                );

                if (resp.status >= 500 && retries <= 10) {
                    processLocksPromise = Promise.resolve();
                    return await processLocks(networkId, retries + 1);
                }
            } catch {
                if (retries <= 10) {
                    processLocksPromise = Promise.resolve();
                    return processLocks(networkId, retries + 1);
                }
            }

            return undefined;
        })
        .then(progressiveAwait);

    return processLocksPromise;
}, 120);

export async function placeTreasurerEthBid(
    payload: {
        blockchainId: number;
        bid: BeBid;
        tokenId: number;
        tokenContractAddress: string;
        auctionId: number;
    },
    retries = 0,
) {
    const resp = await fetchWithAuthHeaders("/api/treasurer/place-eth-bid", {
        method: "POST",
        body: JSON.stringify(payload),
    });

    if (resp.status >= 500 && retries <= 10) {
        return placeTreasurerEthBid(payload, retries + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function placeTreasurerUsdBid(
    {
        blockchainId,
        bid,
        tokenId,
        tokenContractAddress,
        auctionId,
        paymentIntent,
    }: {
        blockchainId: number;
        bid: BeBid;
        tokenId: number;
        tokenContractAddress: string;
        auctionId: number;
        paymentIntent: string;
    },
    retries = 0,
) {
    const payload = {
        blockchainId,
        bid: normalizeBidUser(bid),
        tokenId,
        tokenContractAddress,
        auctionId,
        paymentIntent,
    };

    const resp = await fetchWithAuthHeaders("/api/treasurer/place-usd-bid", {
        method: "POST",
        body: JSON.stringify(payload),
    });

    if (resp.status >= 500 && retries <= 10) {
        return placeTreasurerUsdBid(
            {
                blockchainId,
                bid,
                tokenId,
                tokenContractAddress,
                auctionId,
                paymentIntent,
            },
            retries + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function releaseLock(lock: TokenLock, retries = 0) {
    const resp = await fetchWithAuthHeaders("/api/state-machine/release-lock", {
        method: "POST",
        body: JSON.stringify(lock),
    });

    if (resp.status >= 500 && retries <= 10) {
        return releaseLock(lock, retries + 1);
    }

    return resp.json();
}

export async function logPaymentFlow(
    payload: {
        networkId: number;
        msg: string;
        channel?: SlackChannels;
    },
    retries = 0,
) {
    const resp = await fetchWithAuthHeaders("/api/treasurer/log", {
        method: "POST",
        body: JSON.stringify(payload),
    });

    if (resp.status === 500 && retries <= 10) {
        return logPaymentFlow(payload, retries + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function afterTokensListed(
    {
        tokenIds,
        blockchainId,
        application,
        tokenContractAddress,
    }: {
        tokenIds: (number | string)[];
        blockchainId: number;
        application: SupportedApplication;
        tokenContractAddress: string;
    },
    retries = 0,
) {
    const resp = await fetchWithAuthHeaders(
        "/api/treasurer/after-tokens-listed",
        {
            method: "POST",
            body: JSON.stringify({
                tokenIds,
                blockchainId,
                application,
                tokenContractAddress,
            }),
        },
    );

    if (resp.status >= 500 && retries <= 10) {
        return afterTokensListed(
            {
                tokenIds,
                blockchainId,
                application,
                tokenContractAddress,
            },
            retries + 1,
        );
    }

    return undefined;
}
