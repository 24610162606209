import {
    sellableRights,
    SupportedAction,
    supportedActions,
    ROLE_CREATOR,
    buyOutTokenRight,
    nonSellableRights,
} from "@liveart/rights-management";
import { ContractReturnedRights, Policy } from "../data-schema";

export function supportedActionsToPolicies(
    actions: SupportedAction<string>[],
    role: string,
    wallet: string,
): Policy[] {
    return actions.map((el) => ({
        action: el.action,
        target: 0,
        permission: {
            role,
            wallet,
        },
    }));
}

export function creatorMintingRights(creator: string): Policy[] {
    return supportedActionsToPolicies(supportedActions, ROLE_CREATOR, creator);
}

export const publisherMintingRights = creatorMintingRights;

export function getTokenEditionRights(rights: Policy[]): Policy[] {
    return rights;
}

export function getSellableRights(rights: Policy[]): Policy[] {
    return rights.filter((el) =>
        sellableRights
            .concat(buyOutTokenRight)
            .find((sr) => sr.action === el.action),
    );
}
export function getOtherRights(rights: Policy[]): Policy[] {
    return rights.filter((el) =>
        nonSellableRights.find(
            (nonSellableRight) => nonSellableRight.action === el.action,
        ),
    );
}

export function fromContractReturnedRights(
    rights: ContractReturnedRights,
): Policy[] {
    return rights.map((el) => ({
        action: el[0],
        target: +el[1],
        permission: {
            role: el[2][0],
            wallet: el[2][1],
        },
    }));
}
