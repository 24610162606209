import { createReducer } from "@reduxjs/toolkit";
import { RemoteConfig } from "~/api/data-schema";
import { loadRemoteConfigResponse } from "~/api/choreography";

export const remoteConfigReducer = createReducer(
    {} as RemoteConfig,
    (builder) =>
        builder.addCase(
            loadRemoteConfigResponse,
            (state, { payload }) => payload,
        ),
);

export const remoteConfigSelector = (state) =>
    state?.remoteConfig as RemoteConfig;
