import { query } from "../graphQL";
import { allUsersQuery } from "./AllUsersQuery";
import { normalizeUsers } from "./normalizeUsers";
import { CognitoUser } from "../data-schema/CognitoUser";

export async function searchAllUsers(
    accessToken: string,
    chainId: number,
    variables: {
        walletIn?: string[];
        idIn?: string[];
    },
): Promise<CognitoUser[]> {
    const respData = await query({
        networkId: chainId,
        queryStr: allUsersQuery,
        accessToken,
        variables,
    });
    const { allUsers } = respData.data;
    return normalizeUsers(allUsers);
}
