import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { Box, AppBarProps } from "@mui/material";
import dynamic from "next/dynamic";
import type { LiveArtHeaderProps } from "@liveart/injectables/dist/header/web/liveart/Header";
import { getApplicationFromHostname } from "@liveart/injectables/dist/WhiteLabel/host";
import { ICAHeader } from "./ica";

export type Position = AppBarProps["position"];

export const ArtechouseHeader = dynamic<{
    position?: Position;
}>(() => import("./artechouse").then((module) => module.ArtechouseHeader));

const ArtunerHeader = dynamic<{
    position?: Position;
}>(() => import("./artuner").then((module) => module.ArtunerHeader));

const EphemeralHeader = dynamic<{
    position?: Position;
}>(() => import("./ephemeral").then((module) => module.EphemeralHeader));

const LiveArtHeader = dynamic<LiveArtHeaderProps>(() =>
    import("@liveart/injectables/dist/header/web/liveart/Header").then(
        (module) => module.LiveArtHeader,
    ),
);

export type SupportedApplicationHeaderProps = React.PropsWithChildren<{
    application?: SupportedApplication;
    position?: Position;
    isDarkMode?: Boolean;
    items?: {
        label: string;
        Content: React.FC<unknown>;
        value: string;
    }[];
}>;

export function SupportedApplicationHeader({
    application = getApplicationFromHostname(),
    position = "fixed",
    isDarkMode = false,
    items,
}: SupportedApplicationHeaderProps) {
    switch (application) {
        case SupportedApplication.artuner:
            return (
                <Box paddingBottom="3.5rem">
                    <ArtunerHeader position={position} />
                </Box>
            );
        case SupportedApplication.icamiami:
            return (
                <Box paddingBottom="3.5rem">
                    <ICAHeader position={position} />
                </Box>
            );
        case SupportedApplication.artechouse:
            return (
                <Box paddingBottom="3.5rem">
                    <ArtechouseHeader position={position} />
                </Box>
            );
        case SupportedApplication.ephemeralEternal:
            return (
                <Box paddingBottom="3.5rem">
                    <EphemeralHeader position={position} />
                </Box>
            );
        default:
            return (
                <LiveArtHeader
                    position={position}
                    isDarkMode={!!isDarkMode}
                    items={items}
                />
            );
    }
}
