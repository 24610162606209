import { useEffect } from "react";
import { trackExceptions } from "./trackExceptions";

export function UncaughtExceptionErrorReporter() {
    useEffect(() => {
        (globalThis?.window || {}).onerror = (message) => {
            trackExceptions(message);
        };
    }, []);

    return null;
}
