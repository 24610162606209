import { createAction } from "@reduxjs/toolkit";
import { claimTokenResponse } from "~/api/choreography";

export enum ClaimTokenStates {
    ENTER_WALLET = "ENTER_WALLET",
    CONFIRMATION = "CONFIRMATION",
    PROCESSING = "PROCESSING",
    CONGRATULATION = "CONGRATULATION",
}
export type ClaimTokenState = {
    state: ClaimTokenStates;
    wallet?: string;
    confirmWallet?: string;
};
export type WithClaimTokenState = {
    claimToken: ClaimTokenState;
};

export const defaultClaimTokenState: ClaimTokenState = {
    state: ClaimTokenStates.ENTER_WALLET,
    wallet: undefined,
};

export const setClaimTokenState = createAction<Partial<ClaimTokenState>>(
    "CLAIM_TOKEN/SET_CLAIM_TOKEN_STATE",
);

export const setClaimTokenStateReducer = (
    state: WithClaimTokenState,
    { payload },
): WithClaimTokenState => {
    return {
        claimToken: {
            ...state.claimToken,
            ...payload,
        },
    };
};

export const claimTokenResponseReducer = (
    state: WithClaimTokenState,
    { payload }: ReturnType<typeof claimTokenResponse>,
): WithClaimTokenState => ({
    claimToken: {
        ...state.claimToken,
        state: payload.success
            ? ClaimTokenStates.CONGRATULATION
            : ClaimTokenStates.ENTER_WALLET,
    },
});

export const claimTokenStateSelector = (state: {
    pdp: { claimToken: ClaimTokenState };
}) => state.pdp.claimToken;
