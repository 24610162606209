import { uploadToIPFS } from "~/api/ipfs";
import { MetadataJSONSchema } from "~/api/metadata";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export const uploadMetadata: (
    metadata: MetadataJSONSchema,
    chainId: number,
    selectedContract: SolidityContractDetails,
) => Promise<{
    cid: string;
    fullPath: string;
}> = async (metadata, chainId, selectedContract) => {
    const bits: BlobPart[] = [Buffer.from(JSON.stringify(metadata))];

    const file = new File(
        bits,
        `${encodeURIComponent(metadata.title)}_metadata.json`,
        {
            type: "application/json",
        },
    );
    return uploadToIPFS(file, chainId, selectedContract.address);
};
