import React from "react";
import { styled } from "@mui/material/styles";
import { EphermalDesktopFooter } from "./EphermalDesktopFooter";
import { EphermalMobileFooter } from "./EphermalMobileFooter";

const MobileFooter = styled("footer")(() => ({
    display: "block",
    "@media (min-width: 767px)": {
        display: "none",
    },
}));

const DesktopFooter = styled("footer")(() => ({
    display: "none",
    "@media (min-width: 767px)": {
        display: "block",
    },
}));

export const Footer = () => {
    return (
        <>
            <MobileFooter>
                <EphermalMobileFooter />
            </MobileFooter>
            <DesktopFooter>
                <EphermalDesktopFooter />
            </DesktopFooter>
        </>
    );
};
