import { Box, Typography } from "@mui/material";
import React from "react";
import { colors, getFontFamilyBySupportedApplication } from "~/theme";

export function BodyM({
    children,
    color,
    mobile,
    strong,
    light,
    dataTarget,
    id,
}: React.PropsWithChildren<{
    color?: string;
    mobile?: boolean;
    strong?: boolean;
    light?: boolean;
    dataTarget?: string;
    id?: string;
}>) {
    let fontWeight = "400";
    if (strong) {
        fontWeight = "600";
    } else if (light) {
        fontWeight = "300";
    }
    return (
        <Typography id={id}>
            <Box
                component="span"
                fontFamily={getFontFamilyBySupportedApplication()}
                fontWeight={fontWeight}
                fontStyle="normal"
                data-target={dataTarget}
                fontSize={mobile ? "13px" : "16px"}
                lineHeight={mobile ? "17px" : "24px"}
                letterSpacing="0.15px"
                color={color || colors.black}
                margin={0}
            >
                {children}
            </Box>
        </Typography>
    );
}

export function AdaptiveBodyM(props: Parameters<typeof BodyM>[0]) {
    return (
        <>
            <Box
                display={{
                    xs: "contents",
                    sm: "contents",
                    md: "none",
                }}
            >
                <BodyM mobile {...props} />
            </Box>
            <Box
                display={{
                    xs: "none",
                    sm: "none",
                    md: "contents",
                }}
            >
                <BodyM {...props} />
            </Box>
        </>
    );
}
