import memoize from "lodash/memoize";
import { call, takeLatest } from "redux-saga/effects";
import {
    fetchTokenMetadata,
    fetchAllTokenListsByAddresses,
    fetchEditionNamesBySelectedContract,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721v4"),
    () => 1,
);

export function* erc721v4ApiSaga() {
    yield takeLatest(
        fetchTokenMetadata.toString(),
        function* withTokenMetadataSaga(
            action: ReturnType<typeof fetchTokenMetadata>,
        ) {
            const { tokenMetadataSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(tokenMetadataSaga, action);
        },
    );

    yield takeLatest(
        fetchAllTokenListsByAddresses.toString(),
        function* withTokenListByAddresSaga(
            action: ReturnType<typeof fetchAllTokenListsByAddresses>,
        ) {
            const { tokenListByWalletsSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(tokenListByWalletsSaga, action);
        },
    );

    yield takeLatest(
        fetchEditionNamesBySelectedContract.toString(),
        function* withEditionNamesBySelectedContract(
            action: ReturnType<typeof fetchEditionNamesBySelectedContract>,
        ) {
            const { editionNamesBySelectedContractSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(editionNamesBySelectedContractSaga, action);
        },
    );
}
