import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import dynamic from "next/dynamic";
import { Footer as LiveArtFooter } from "@liveart/injectables/dist/footer/web/liveart/Footer";
import { getApplicationFromHostname } from "~/WhiteLabel/host";
import { ICAFooter } from "./ica";
import { Footer as EphemeralFooter } from "./ephermal";

export const ArtechouseFooter = dynamic(() =>
    import("./artechouse").then((module) => module.ArtechouseFooter),
);

export const ArtunerFooter = dynamic(() =>
    import("./artuner").then((module) => module.ArtunerFooter),
);

export function SupportedApplicationFooter({
    application = getApplicationFromHostname(),
}: React.PropsWithChildren<{
    application?: SupportedApplication;
}>) {
    switch (application) {
        case SupportedApplication.artuner:
            return <ArtunerFooter />;
        case SupportedApplication.icamiami:
            return <ICAFooter />;
        case SupportedApplication.artechouse:
            return <ArtechouseFooter />;
        case SupportedApplication.ephemeralEternal:
            return <EphemeralFooter />;
        default:
            return <LiveArtFooter />;
    }
}
