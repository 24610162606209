import { theme, themePink } from "./theme";
import { colors } from "./color/colors";
import {
    fonts,
    fontFamilies,
    getFontFamilyBySupportedApplication,
    getTagFontSizeBySupportedApplication,
    TextTags,
} from "./font/font";

export {
    theme,
    themePink,
    colors,
    fonts,
    fontFamilies,
    getFontFamilyBySupportedApplication,
    getTagFontSizeBySupportedApplication,
    TextTags,
};

export const THEME_DARK = "THEME_DARK";

export const THEME_WHITE = "THEME_WHITE";

export enum SupportedThemes {
    default = "DEFAULT",
    pink = "PINK",
}
