export const getCurrentUserQuery = `
query GetCurrentUser {
    currentUser {
        edges {
            node {
                avatarImageUrl
                email
                nickname
                biography
                fullName
                firstName
                lastName
                wallets {
                    edges {
                        node {
                            wallet
                            issuedBy
                        }
                    }
                }
                authUsersWallets {
                    edges {
                        node {
                            issuedBy
                            wallet
                        }
                    }
                }
                id
                stripeCustomerId
                externalId
                socialLinks {
                    edges {
                        node {
                            link
                        }
                    }
                }
            }
        }
    }
}
`;
