const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

export const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;

    return {
        type: ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = (key) => ({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = (key) => ({
    type: REMOVE_SNACKBAR,
    key,
});

export type SnackbarState = {
    notifications: {
        message: string;
        key: string | number;
        options: {
            key?: string | number;
            variant: "warning" | "error" | "success";
            action?: Function;
            onClose?: Function;
            persist?: boolean;
        };
        dismissed?: boolean;
        title: string;
        reload?: boolean;
    }[];
};
export type WithSnackbarState = {
    snackbar: SnackbarState;
};
const defaultState: SnackbarState = {
    notifications: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const snackbarReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR: {
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification,
                    },
                ],
            };
        }

        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification },
                ),
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.key !== action.key,
                ),
            };

        default:
            return state;
    }
};

export const snackbarSelector = (state: WithSnackbarState) => state.snackbar;
