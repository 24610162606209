import { call, takeLatest } from "redux-saga/effects";
import memoize from "lodash/memoize";
import {
    getAdminAirdropContract,
    airdropTokens,
    getGenerateAirdropWallets,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./openEdition/airdropSaga"),
    () => 1,
);

export function* airdropApiSaga() {
    yield takeLatest(
        getAdminAirdropContract.toString(),
        function* withGetIsOwnerAirdropContractSaga(
            action: ReturnType<typeof getAdminAirdropContract>,
        ) {
            const { adminAirdropContractSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(adminAirdropContractSaga, action);
        },
    );
    yield takeLatest(
        airdropTokens.toString(),
        function* withAirdropTokensSaga(
            action: ReturnType<typeof airdropTokens>,
        ) {
            const { airdropSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(airdropSaga, action);
        },
    );
    yield takeLatest(
        getGenerateAirdropWallets.toString(),
        function* withGenerateAirdropWalletsSaga(
            action: ReturnType<typeof getGenerateAirdropWallets>,
        ) {
            const { generateAirdropWalletsSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(generateAirdropWalletsSaga, action);
        },
    );
}
