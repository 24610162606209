import { ContractReturnedBasicAuction } from "./ContractReturnedBasicAuction";
import { Policy } from "./Policy";

export enum AuctionStatus {
    AUCTION_CANCELLED = "AUCTION_CANCELLED",
    AUCTION_CLOSED_WITH_WINNER = "AUCTION_CLOSED_WITH_WINNER",
    AUCTION_CLOSED_WITHOUT_WINNER = "AUCTION_CLOSED_WITHOUT_WINNER",
}

export type NormalizedContractReturnedBasicAuction = Omit<
    ContractReturnedBasicAuction,
    "sellRights"
> & {
    sellRights: Policy[];
};
