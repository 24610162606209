import { getApplicationFromHostname } from "~/WhiteLabel/host";

export enum CustomHeaders {
    X_APPLICATION = "x-application",
    X_WEB3_TOKEN = "x-web3-token",
}

export function getHeaders(): { [key: string]: string } {
    return {
        [CustomHeaders.X_APPLICATION]: getApplicationFromHostname(
            globalThis?.location?.hostname || "",
        ),
    };
}
