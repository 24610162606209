import { createReducer } from "@reduxjs/toolkit";
import get from "lodash/get";
import { Currency } from "~/api/data-schema";
import { getCurrencyRatesResponse } from "~/api/choreography";

export type CurrencyExchangeState = {
    [fromKey in Currency]?: {
        [toKey in Currency]?: number;
    };
};

export type WithCurrencyExchangeState = {
    currencyExchange: CurrencyExchangeState;
};

export const initialCurrencyExchangeState: CurrencyExchangeState = {};

export const currencyExchangeReducer = createReducer(
    initialCurrencyExchangeState,
    (builder) =>
        builder.addCase(getCurrencyRatesResponse, (state, { payload }) => {
            return {
                ...state,
                ...(payload as {
                    [key in Currency]?: Record<Currency, number>;
                }),
            };
        }),
);

export const currencyExchangeSelector =
    (from: Currency | undefined, to: Currency | undefined) =>
    (state: WithCurrencyExchangeState) =>
        get(state.currencyExchange, `${from}.${to}`);
