import { call, put } from "redux-saga/effects";
import { ERC721WithRoyaltiesInstance } from "@liveart/nft-client/truffle-contracts";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { Contract, providers } from "ethers";
import {
    getSelectedMetamaskAccount,
    requestChainById,
} from "@liveart/nft-client/dist/metamask";
import { getChainId } from "@liveart/nft-client/dist/web3";
import { loadUnlockableContentContract } from "../nft-client";
import {
    getHasUnlockableContent,
    hasUnlockableContentResponse,
    getUnlockableContent,
    unlockableContentResponse,
    getIsOwnerUnlockableContent,
    isOwnerUnlockableContentResponse,
    handleAppNotification,
} from "~/api/choreography";
import { normalizeSmartContractError } from "~/api/normalize";
import { web3AddressesEqual } from "~/api/web3/address";
import { getPDPPageApplication } from "~/api/application";
import { getBlockchainId } from "~/api/web3/getBlockchainId";
import { getAccessToken } from "~/api/user/getAccessToken";
import { getCurrentCognitoUser } from "~/api/user/getCognitoUser";
import { getTokenContractAddress } from "~/api/getTokenContractAddress";
import { getRpcUrlByChainId } from "~/BFF/blockchain/getRpcUrlByChainId";
import { loadContractByName } from "~/BFF/blockchain/solidityContracts/loadContractByName";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { loadContractByAddress } from "~/BFF/blockchain/solidityContracts/loadContractByAddress";
import { createReadonlyContractInstance } from "~/BFF/blockchain/solidityContracts/createReadonlyContractInstance";
import { Unpromisify } from "~/typescript";

async function getCurrentContractInfo() {
    const blockchainId = await getBlockchainId();
    const contract = await loadContractByAddress(getTokenContractAddress());
    const instance = createReadonlyContractInstance({
        contract,
        blockchainId,
    }) as Contract & ERC721WithRoyaltiesInstance;

    return {
        name: await instance.name(),
        version: contract.version,
    };
}

export function* hasUnlockableContentSaga(
    action: ReturnType<typeof getHasUnlockableContent>,
) {
    const { payload: tokenId } = action;

    try {
        const accessToken = yield call(getAccessToken);
        const cognitoUser = yield call(
            getCurrentCognitoUser,
            accessToken,
            yield call(getBlockchainId),
        );

        const wallets: string[] = cognitoUser?.wallets || [""];
        let account = wallets.length ? wallets[0] : "";
        try {
            account = yield call(getSelectedMetamaskAccount);
        } catch (e) {
            if (!account) {
                throw e;
            }
        }

        const { name: contractName, version } = (yield call(
            getCurrentContractInfo,
        )) as Unpromisify<ReturnType<typeof getCurrentContractInfo>>;

        const application = contractName;

        if (version !== "v3") {
            const unlockableContentInstance = yield call(
                loadUnlockableContentContract,
            );

            const result: boolean = yield call(async () =>
                unlockableContentInstance.hasUnlockableContent(
                    application,
                    tokenId,
                ),
            );
            yield put(hasUnlockableContentResponse(result));
        }
    } catch (e) {
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                ...notification,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

export function* ownerOfUnlockableContentSaga(
    action: ReturnType<typeof getIsOwnerUnlockableContent>,
) {
    const { payload: tokenId } = action;
    try {
        const account = yield call(getSelectedMetamaskAccount);
        let isOwner: boolean = false;

        if (
            getPDPPageApplication() !== SupportedApplication.liveartProprietary
        ) {
            const blockchainId = yield call(getBlockchainId);

            const artifact: SolidityContractDetails = yield call(() =>
                loadContractByName({
                    contractName: "LiveArt",
                    networkId: blockchainId,
                }),
            );
            if (artifact) {
                const tokenContractAddress = getTokenContractAddress();

                const instance = new Contract(
                    tokenContractAddress,
                    artifact.abi,
                    new providers.JsonRpcProvider(
                        getRpcUrlByChainId(blockchainId),
                        blockchainId,
                    ),
                );

                const ownerAddress = yield call(async () => {
                    try {
                        const owner = await instance.ownerOf(tokenId);

                        return owner;
                    } catch {
                        return "";
                    }
                });

                if (web3AddressesEqual(ownerAddress, account)) isOwner = true;
            }

            yield put(isOwnerUnlockableContentResponse(isOwner));
        }
    } catch (e) {
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                ...notification,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

export function* getUnlockableContentSaga(
    action: ReturnType<typeof getUnlockableContent>,
) {
    const { payload: tokenId } = action;
    try {
        const account = yield call(getSelectedMetamaskAccount);

        const blockchainId = yield call(getBlockchainId);

        const { name: contractName } = (yield call(
            getCurrentContractInfo,
        )) as Unpromisify<ReturnType<typeof getCurrentContractInfo>>;
        const application = contractName;

        const chainId = yield call(getChainId);

        if (blockchainId !== chainId) {
            yield call(requestChainById, blockchainId);
        } else {
            const unlockableContentInstance = yield call(
                loadUnlockableContentContract,
            );

            const result = yield call(
                unlockableContentInstance.retrieveUnlockableContent,
                application,
                tokenId,
                {
                    from: account,
                },
            );

            yield put(unlockableContentResponse(result));
        }
    } catch (e) {
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                ...notification,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
