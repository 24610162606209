import { createReducer, createAction } from "@reduxjs/toolkit";
import { loadConfigurationResponse } from "~/api/choreography";

export enum WhitelistedState {
    NOT_VERIFIED = "NOT_VERIFIED",
    WHITELISTED = "WHITELISTED",
    NOT_WHITELISTED = "NOT_WHITELISTED",
}

export const isWhitelistedContractResponse = createAction<WhitelistedState>(
    "API/DISCOVER/IS_WHITELISTED_CONTRACT_RESPONSE",
);

export type DiscoverState = {
    whitelistedAddresses: string[];
    isWhitelisted: WhitelistedState;
};

export const initialDiscoverState: DiscoverState = {
    whitelistedAddresses: [],
    isWhitelisted: WhitelistedState.NOT_VERIFIED,
};

export const discoverReducer = createReducer(initialDiscoverState, (builder) =>
    builder
        .addCase(
            loadConfigurationResponse,
            (state, { payload: contractAddresses }) => ({
                ...state,
                whitelistedAddresses: contractAddresses,
            }),
        )
        .addCase(
            isWhitelistedContractResponse,
            (state, { payload: result }) => ({
                ...state,
                isWhitelisted: result,
            }),
        ),
);

export const discoverSelector = (state: { discover: DiscoverState }) =>
    state.discover;
