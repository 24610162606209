import { createAction } from "@reduxjs/toolkit";
import { Permission, RoyaltyReceiver } from "~/api/data-schema";

export type TokenRoyaltiesState = {
    royaltyReceivers: RoyaltyReceiver[];
    buyoutPrice?: number;
    /* <external dependencies> */
    permissions?: Permission[];
    /* </external dependencies> */
};
export const tokenRoyaltiesInitialState: TokenRoyaltiesState = {
    royaltyReceivers: [],
};

function filterOutRoyaltyReceiver(
    royaltyReceivers: RoyaltyReceiver[],
    receiverToRemove: Permission,
) {
    return royaltyReceivers.filter(
        (el) => el.wallet !== receiverToRemove.wallet,
    );
}
export const removeRoyaltyReceiversByPermission = (
    state: TokenRoyaltiesState,
    { payload }: { payload: Permission },
): TokenRoyaltiesState => ({
    ...state,
    royaltyReceivers: filterOutRoyaltyReceiver(state.royaltyReceivers, payload),
});

export const setRoyaltyReceivers = createAction<RoyaltyReceiver[]>(
    "MINTING/SET_ROYALTY_RECEIVERS",
);
export const setRoyaltyReceiversReducer = (
    state: TokenRoyaltiesState,
    { payload }: ReturnType<typeof setRoyaltyReceivers>,
): TokenRoyaltiesState => ({
    ...state,
    royaltyReceivers: payload,
});

export const setRoyaltyValue = createAction<{
    permission: Permission;
    field: "percentage" | "resalePercentage" | "CAPPS";
    value: number;
}>("MINTING/SET_ROYALTY_RECEIVER_VALUE");
export const setRoyaltyValueReducer = (
    state: TokenRoyaltiesState,
    { payload }: ReturnType<typeof setRoyaltyValue>,
): TokenRoyaltiesState => {
    const { permission, field, value } = payload;
    const receiver = state.royaltyReceivers.find(
        (el) => el.wallet === permission.wallet && el.role === permission.role,
    ) || {
        role: permission.role,
        wallet: permission.wallet.toString(),
        percentage: 0,
        CAPPS: 0,
        resalePercentage: 0,
        fixedCut: 0,
    };

    return {
        ...state,
        royaltyReceivers: filterOutRoyaltyReceiver(
            state.royaltyReceivers,
            permission,
        ).concat({
            ...receiver,
            [field]: value,
        }),
    };
};

export const initTokenRoyalties = createAction(
    "TOKEN_ROYALTIES/INIT_TOKEN_ROYALTIES",
);

export const addNewRole = createAction<Permission>(
    "ROLES_AND_COMMISSION_SPLITS/ADD_NEW_ROLE",
);
export const removeRole = createAction<Permission>(
    "ROLES_AND_COMMISSION_SPLITS/REMOVE_ROLE",
);

export const setBuyoutPrice = createAction<number>("MINTING/SET_BUYOUT_PRICE");
export const setBuyoutPriceReducer = (
    state: TokenRoyaltiesState,
    { payload: buyoutPrice }: ReturnType<typeof setBuyoutPrice>,
): TokenRoyaltiesState => ({
    ...state,
    buyoutPrice,
});

export const tokenRoyaltiesSelector = (state: {
    mint: Partial<TokenRoyaltiesState>;
}) => state.mint;
