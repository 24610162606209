import { ThemeProvider, Theme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { getManuallySelectedApplication } from "@liveart/injectables/dist/WhiteLabel/manuallySelectedApplication";
import { getApplicationFromHostname } from "@liveart/injectables/dist/WhiteLabel/host";
import { merge } from "lodash";
import { Typography } from "@mui/material/styles/createTypography";
import { useDappWlConfig } from "~/features/remoteConfig/useDappWlConfig";
import { useTheme } from "./useTheme";

export type WlEnhancedTheme = Theme & {
    components: {
        CollectionName?: {
            styleOverrides?: Typography;
        };
        CollectionDetails?: {
            styleOverrides?: Typography;
        };
        ReadMore?: {
            styleOverrides?: Typography;
        };
        CountdownText?: {
            styleOverrides?: Typography;
        };
        CountdownTime?: {
            styleOverrides?: Typography;
        };
        DirectMintStateTitle?: {
            styleOverrides?: Typography;
        };
        DirectMintTimelineStep?: {
            styleOverrides?: Typography;
        };
        DirectMintTimelineActiveStep: {
            styleOverrides?: Typography;
        };
        DirectMintEditionPriceBlockTitle?: {
            styleOverrides?: Typography;
        };
        DirectMintEditionPriceBlockValue?: {
            styleOverrides?: Typography;
        };
        DirectMintCTA?: {
            styleOverrides?: Typography;
        };
    };
};

export function Mui5ThemeProvider({
    children,
    shadowRootElement,
    outerTheme,
}: React.PropsWithChildren<{
    shadowRootElement?: HTMLDivElement;
    outerTheme?: Theme;
}>) {
    const theme = useTheme({ shadowRootElement, outerTheme });
    const wlConfig = useDappWlConfig(
        getManuallySelectedApplication() || getApplicationFromHostname(),
    );
    const wlEnhancedTheme = useMemo(() => {
        if (wlConfig?.theme) {
            return merge({}, theme, wlConfig?.theme);
        }

        return theme;
    }, [wlConfig, theme]);

    return <ThemeProvider theme={wlEnhancedTheme}>{children}</ThemeProvider>;
}
