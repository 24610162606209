import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { MarketInstance } from "@liveart/nft-client/truffle-contracts";
import random from "lodash/random";
import { artechouseSelector, TrustDropState } from "./trustDropReducer";
import { getBlockchainId } from "~/api/web3";
import { BuyNowListing, LoadingSlots } from "~/api/data-schema";
import { fromContractReturnedBuyNowListings } from "~/api/market";
import { loadMarketContract } from "~/api/nft-client";
import {
    loadToken,
    loadTokenMarketDataResponse,
    setLoading,
} from "~/api/choreography";
import { getPDPPageApplication } from "~/api/application";
import { loadContractByName } from "~/BFF/blockchain/solidityContracts/loadContractByName";
import { createReadonlyContractInstance } from "~/BFF/blockchain/solidityContracts/createReadonlyContractInstance";

export const loadArtechoseTokenMarketData = createAction(
    "feature/ARTECHOUSE/LOAD_TOKEN_MARKET_DATA",
);

function* loadArtechoseTokenMarketDataSaga() {
    yield put(
        setLoading({ slot: LoadingSlots.LOAD_MARKET_DATA, loading: true }),
    );

    const { tokensPool = [] } = (yield select(
        artechouseSelector,
    )) as TrustDropState;

    const blockchainId = yield call(getBlockchainId);
    const application = getPDPPageApplication();
    const contract = yield call(() =>
        loadContractByName({
            contractName: "Artechouse",
            networkId: blockchainId,
        }),
    );
    const tokenContractInstance = createReadonlyContractInstance({
        contract,
        blockchainId,
    });

    const marketInstance: MarketInstance = yield call(
        loadMarketContract,
        blockchainId,
    );

    const artechouseTokensPool = tokensPool.slice();
    /**
     * iterate tokens in pool to find one with buy now deal
     */
    do {
        const [tokenId] = artechouseTokensPool.splice(
            random(0, artechouseTokensPool.length - 1),
            1,
        );

        if (tokenId) {
            const buyNowDeals: BuyNowListing[] = yield call(async () =>
                fromContractReturnedBuyNowListings(
                    await marketInstance.getBuyNowListings(
                        tokenId,
                        tokenContractInstance.address,
                    ),
                ),
            );

            if (buyNowDeals.length) {
                yield put(
                    loadToken({
                        tokenId: +tokenId,
                        networkId: blockchainId,
                        application,
                        tokenContractAddress: tokenContractInstance.address,
                    }),
                );
                yield put(
                    loadTokenMarketDataResponse({
                        [tokenId]: {
                            buyNowDeals,
                            auctions: [],
                            transfers: [],
                            tokenBids: [],
                            seller: "",
                        },
                    }),
                );
                return;
            }
        }
    } while (artechouseTokensPool.length);

    yield put(
        setLoading({ slot: LoadingSlots.LOAD_MARKET_DATA, loading: false }),
    );
}

export function* trustDropSaga() {
    yield takeLatest(
        loadArtechoseTokenMarketData.toString(),
        loadArtechoseTokenMarketDataSaga,
    );
}
