import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";

export function formatBidInUsd(bid: number = 0) {
    return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
    }).format(bid);
}

export function shortenWallet(name: string): string {
    if (name && name.length > 17) {
        return `${name.substring(0, 5)}...${name.slice(-8)}`;
    }
    return name;
}

type Price = string | BigNumber | number;

type PriceFormatConfig = {
    readonly prefix?: string;
    readonly suffix?: string;
    readonly digits?: number;
    readonly symbol?: string;
    readonly dynamicFractionDigits?: boolean;
};

function getMinimumFractionDigits(
    price: string,
    maximumFractionDigits: number,
): number {
    let minimumFractionDigits = 0;
    if (price.includes(".")) {
        minimumFractionDigits = price.split(".")[1].length;
    } else if (price.includes(",")) {
        minimumFractionDigits = price.split(",")[1].length;
    }
    return minimumFractionDigits > maximumFractionDigits
        ? maximumFractionDigits
        : minimumFractionDigits;
}

export function formatPrice(
    price: Price = "",
    pricingConfig: PriceFormatConfig = {},
    numberFormatConfig: Intl.NumberFormatOptions = {},
): string {
    if (!price || !isNumber(Number(price)) || isNaN(Number(price))) {
        return "";
    }
    const {
        digits = 2,
        symbol,
        dynamicFractionDigits = false,
        prefix = "",
        suffix = "",
    } = pricingConfig;
    const locale = "en-US";
    const minimumFractionDigits = dynamicFractionDigits
        ? getMinimumFractionDigits(`${price}`, digits)
        : 0;
    const value = new Intl.NumberFormat(locale, {
        maximumFractionDigits: digits,
        minimumFractionDigits,
        ...numberFormatConfig,
    }).format(new BigNumber(price).toNumber());

    const formatted: string = symbol ? `${symbol}${value}` : `${value}`;

    return `${prefix}${formatted}${suffix}`;
}
