import { Notification } from "~/api/data-schema";
import { enqueueSnackbar } from "~/features/snackbar";
import { normalizeMessage } from "./normalizeMessage";

const targetMessage = normalizeMessage(
    "Provided address is invalid, the capitalization checksum test failed",
);

export function walletIsNotConnectedHandler(notification: Notification) {
    const { message } = notification;
    if (normalizeMessage(message).includes(targetMessage)) {
        return {
            action: enqueueSnackbar({
                ...notification,
                message: "Please connect your wallet to run this transaction.",
            }),
        };
    }

    return {
        notification,
    };
}
