import { createAction } from "@reduxjs/toolkit";

export type ArtworkDetails = {
    name: string;
    description: string;
    maxTokenSupply: number;
    openEdition?: boolean;
    artistName: string;
    artistBirthDate: string;
    artworkCreationYear: string;
    publicMintPrice?: string;
    mintStartTimestamp?: string;
    mintEndTimestamp?: string;
    maxMintPerWallet?: string;
    noMaxMintPerWallet?: boolean;
    isEditionned?: number;
    freeMint?: boolean;
};
export type ArtworkDetailState = {
    artworkDetails: ArtworkDetails;
};
export const initialArtworkDetailsState: ArtworkDetailState = {
    artworkDetails: {
        name: "",
        description: "",
        maxTokenSupply: 1,
        openEdition: false,
        artistName: "",
        artistBirthDate: "",
        artworkCreationYear: "",
        publicMintPrice: "",
        mintStartTimestamp: "",
        mintEndTimestamp: "",
        isEditionned: 1,
        freeMint: false,
    },
};
export const setArtworkDetails = createAction<ArtworkDetails>(
    "MINTING/SET_ARTWORK_DETAILS",
);
export const ArtworkDetailsReducer = (
    state: ArtworkDetailState,
    { payload }: ReturnType<typeof setArtworkDetails>,
): ArtworkDetailState => ({
    ...state,
    artworkDetails: payload,
});
export const artworkDetailsSelector = (state: { mint: ArtworkDetailState }) =>
    state.mint.artworkDetails;
