import { call, put, select } from "redux-saga/effects";
import { useAuthModalDevEnvSelector } from "@liveart/injectables/dist/auth/authConfigReducer";
import {
    setLoading,
    handleAppNotification,
    bidFailed,
} from "~/api/choreography";
import { LoadingSlots } from "~/api/data-schema";
import { getPaymentMethodsFromBe } from "./marketBE";
import { createPaymentIntentOnBe } from "~/api/paymentGateway";
import {
    createPaymentIntent,
    createPaymentIntentResponse,
    loadPaymentMethods,
    loadPaymentMethodsResponse,
} from "../choreography";
import { getBlockchainId } from "~/api/web3";

export function* createPaymentIntentSaga(
    action: ReturnType<typeof createPaymentIntent>,
) {
    try {
        const { amount, customerId, paymentMethodId, metadata, user } =
            action.payload;

        const networkId = yield call(getBlockchainId);
        const respJSON = yield call(createPaymentIntentOnBe, {
            amount,
            customerId,
            metadata,
            paymentMethodId,
            networkId,
        } as Parameters<typeof createPaymentIntentOnBe>[0]);
        respJSON.user = user;
        yield put(createPaymentIntentResponse(respJSON));
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
        yield put(
            setLoading({
                slot: LoadingSlots.STRIPE_CONFIRM_PAYMENT_INTENT,
                loading: false,
            }),
        );
        yield put(bidFailed());
    }
}
export function* loadPaymentMethodsSaga(
    action: ReturnType<typeof loadPaymentMethods>,
) {
    try {
        const { customerId } = action.payload;

        const useAuthModalDevEnv = yield select(useAuthModalDevEnvSelector);
        const respJson = yield call(() =>
            getPaymentMethodsFromBe({
                customerId,
                useDevEnv: useAuthModalDevEnv,
            }),
        );
        yield put(loadPaymentMethodsResponse(respJson));
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
