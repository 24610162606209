export function isBrowser() {
    return typeof window !== "undefined";
}

export function isStorybook() {
    return (
        "STORYBOOK" in process.env ||
        globalThis?.window?.location.href.includes("viewMode=story")
    );
}
