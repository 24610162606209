import { createReducer } from "@reduxjs/toolkit";
import {
    searchUsersByIdResponse,
    searchUsersByWalletsResponse,
} from "~/api/choreography";

export type UsersState = {
    usersByWallet: Parameters<typeof searchUsersByWalletsResponse>[0];
    usersById: Parameters<typeof searchUsersByIdResponse>[0];
};
export type WithUsersState = {
    users: UsersState;
};
export const usersInitialState: UsersState = {
    usersByWallet: {},
    usersById: {},
};

export const usersReducer = createReducer(usersInitialState, (builder) =>
    builder
        .addCase(
            searchUsersByWalletsResponse,
            (state: UsersState, { payload }): UsersState => ({
                ...state,
                usersByWallet: { ...payload },
            }),
        )
        .addCase(
            searchUsersByIdResponse,
            (state: UsersState, { payload }): UsersState => ({
                ...state,
                usersById: { ...payload },
            }),
        ),
);

export const usersSelector = (state: WithUsersState) => state?.users;
