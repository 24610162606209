import { eventChannel } from "redux-saga";
import { call, put, take } from "redux-saga/effects";
import { ROLE_ADMIN } from "@liveart/rights-management";
import { ERC721WithRoyaltiesInstance } from "@liveart/nft-client/truffle-contracts";
import { keccak256 } from "web3-utils";
import { Contract, providers } from "ethers";
import {
    getMetamaskProvider,
    getSelectedMetamaskAccount,
    requestChainById,
} from "@liveart/nft-client/dist/metamask";
import { getChainId } from "@liveart/nft-client/dist/web3";
import {
    getIsAdminUser,
    getIsAdminUserResponse,
    createModifiedMetadata,
    loadToken,
    setLoadFileProgress,
    setLoading,
    handleAppNotification,
} from "~/api/choreography";
import { normalizeSmartContractError } from "~/api/normalize";
import { Token, RawModifiedTokenData, LoadingSlots } from "~/api/data-schema";
import { uploadToIPFS } from "~/api/ipfs";
import {
    MetadataJSONSchema,
    MetadataProperties,
    MetadataProperty,
} from "~/api/metadata";
import { hideDialog } from "~/features/dialog";
import { loadDymanicTokenUriContract } from "../nft-client";
import { updateTokenOnBe } from "./tokenBE";
import { getBlockchainId } from "../web3";
import { getTokenContractAddress } from "~/api/getTokenContractAddress";
import { loadContractByAddress } from "~/BFF/blockchain/solidityContracts/loadContractByAddress";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { createReadonlyContractInstance } from "~/BFF/blockchain/solidityContracts/createReadonlyContractInstance";
import { Unpromisify } from "~/typescript";

export function* getIsAdminUserSaga(action: ReturnType<typeof getIsAdminUser>) {
    const { userAccount, tokenContractDetails } = action.payload;
    const blockchainId = yield call(getBlockchainId);
    const admin = keccak256(ROLE_ADMIN);
    try {
        if (tokenContractDetails?.version === "v2") {
            const contract = yield call(() =>
                loadContractByAddress(getTokenContractAddress()),
            );
            const erc721ContractInstance: ERC721WithRoyaltiesInstance =
                yield call(createReadonlyContractInstance, {
                    contract,
                    blockchainId,
                });

            const isAdmin: boolean = yield call(
                erc721ContractInstance.hasRole,
                admin,
                userAccount,
            );
            yield put(getIsAdminUserResponse(isAdmin));
        }

        if (tokenContractDetails?.version === "v3") {
            const contract = (yield call(
                loadContractByAddress,
                tokenContractDetails.address,
            )) as Unpromisify<ReturnType<typeof loadContractByAddress>>;

            const mmProvider = yield call(getMetamaskProvider);

            if (mmProvider) {
                const contractInstance = createReadonlyContractInstance({
                    contract,
                    blockchainId: contract.blockchainId,
                });
                const collectionAdmin = keccak256("COLLECTION_ADMIN_ROLE");

                const isCollectionAdmin: boolean = yield call(
                    contractInstance.hasRole,
                    collectionAdmin,
                    userAccount,
                );

                yield put(getIsAdminUserResponse(isCollectionAdmin));
            } else {
                yield put(getIsAdminUserResponse(false));
            }
        }
    } catch (e) {
        const notification = normalizeSmartContractError(e);
        yield put(
            handleAppNotification({
                ...notification,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}

export async function updateV2TokenUri(
    tokenUri: string,
    tokenId: number,
    modifiedToken: Token,
    tokenContractDetails: SolidityContractDetails,
) {
    const blockchainId = await getBlockchainId();

    const contract = await loadContractByAddress(getTokenContractAddress());

    const instance = createReadonlyContractInstance({
        contract,
        blockchainId,
    }) as unknown as ERC721WithRoyaltiesInstance;

    const account = await getSelectedMetamaskAccount();

    const dynamicTokenUriContentInstance = await loadDymanicTokenUriContract();

    const { address } = tokenContractDetails;

    const app = await instance.name();
    if (tokenUri !== "") {
        const tx = await dynamicTokenUriContentInstance.updateTokenURIHistory(
            app,
            tokenId,
            tokenUri,
            {
                from: account,
            },
        );
        await tx.wait();

        await updateTokenOnBe(modifiedToken, address, 0);
    } else {
        throw new Error("new token URI is empty");
    }
}

export async function updateV3TokenUri(
    tokenUri: string,
    tokenId: number,
    modifiedToken: Token,
    tokenContractDetails: SolidityContractDetails,
) {
    if (tokenUri !== "") {
        const { abi, address } = tokenContractDetails;
        const mmProvider = await getMetamaskProvider();

        if (mmProvider) {
            const web3Provider = new providers.Web3Provider(mmProvider);
            const account = await getSelectedMetamaskAccount();

            const instance = new Contract(
                address,
                abi,
                web3Provider.getSigner(),
            );

            const { editionId } = await instance.parseEditionFromTokenId(
                tokenId,
                {
                    from: account,
                },
            );

            await instance.updateEdition(editionId.toString(), tokenUri, {
                from: account,
            });

            await updateTokenOnBe(modifiedToken, address, 0);
        }
    } else {
        throw new Error("new token URI is empty");
    }
}

interface UploadChannelEvent {
    type: UploadChannelEventType;
    payload: {
        total: number;
        loaded: number;
        fullPath?: string;
        cid?: string;
        error?: string;
    };
}

enum UploadChannelEventType {
    PROGRESS = "PROGRESS",
    FINISHED = "FINISHED",
    ERROR = "ERROR",
}

const uploadMetadata: (
    metadata: MetadataJSONSchema,
    chainId: number,
) => Promise<{
    cid: string;
    fullPath: string;
}> = async (metadata, chainId) => {
    const bits: BlobPart[] = [Buffer.from(JSON.stringify(metadata))];

    const file = new File(
        bits,
        `${encodeURIComponent(metadata.title)}_metadata.json`,
        {
            type: "application/json",
        },
    );
    const tokenContractAddress = getTokenContractAddress();

    return uploadToIPFS(file, chainId, tokenContractAddress);
};

function createUploadChannel(file: File, chainId: number) {
    const tokenContractAddress = getTokenContractAddress();

    return eventChannel((emit) => {
        uploadToIPFS(file, chainId, tokenContractAddress, {
            onProgress(total: number, loaded: number) {
                emit({
                    type: UploadChannelEventType.PROGRESS,
                    payload: { total, loaded },
                });
            },
            onLoaded: ({ fullPath, cid, total, loaded }) => {
                emit({
                    type: UploadChannelEventType.FINISHED,
                    payload: { fullPath, cid, total, loaded },
                });
            },
        }).catch((err) => {
            emit({
                type: UploadChannelEventType.ERROR,
                payload: {
                    error: err.message,
                },
            });
        });

        return () => {};
    });
}

export const prepareMetadata = ({
    mediaFile,
    mediaFileURL,
    previewFile,
    previewFileURL,
    additionalProperties,
    formProperties,
    AP,
    tokenType,
    externalUrl,
    application,
}: {
    mediaFile?: File;
    mediaFileURL: string;
    previewFile?: File;
    previewFileURL?: string;
    additionalProperties: {
        name: string;
        value: string;
    }[];
    formProperties: { name: string | number; description: string | number };
    AP: number;
    tokenType: string;
    externalUrl?: string;
    application: string;
}) => {
    const basicProperties: {
        name: keyof MetadataProperties;
        value: MetadataProperty["description"];
    }[] = [
        {
            name: "media",
            value: mediaFileURL,
        },
        {
            name: "createdAt",
            value: new Date().toString(),
        },
        {
            name: "fileName",
            value: mediaFile!.name,
        },
        {
            name: "fileSize",
            value: mediaFile!.size,
        },
        {
            name: "fileType",
            value: mediaFile!.type,
        },
        {
            name: "AP",
            value: AP,
        },
        {
            name: "application",
            value: application,
        },
        {
            name: "tokenType",
            value: tokenType,
        },
    ];

    const props = Object.entries(formProperties)
        .filter((i) => i[1] !== "undefined")
        .filter((i) => i[1])
        .map((i) => ({ name: i[0], value: i[1] }))
        .concat(additionalProperties)
        .concat(
            basicProperties as ConcatArray<{
                name: string;
                value: string | number;
            }>,
        )
        .concat(
            previewFile && previewFileURL
                ? [
                      {
                          name: "preview",
                          value: previewFileURL,
                      },
                      {
                          name: "previewFileType",
                          value: previewFile.type,
                      },
                  ]
                : [],
        )
        .filter(
            (el: { name: string; value: string }) =>
                el.name.trim() && el.value.toString().trim(),
        )
        .reduce((acc, el) => {
            return {
                ...acc,
                [el.name]: {
                    type: typeof el.value,
                    description: el.value,
                },
            };
        }, {}) as MetadataProperties;

    const metadata: MetadataJSONSchema = {
        title: "Asset Metadata",
        type: "object",
        properties: props,
        image: previewFile && previewFileURL ? previewFileURL : mediaFileURL,
        external_url: externalUrl,
        description: formProperties.description as string,
        name: formProperties.name as string,
        phygitalData: [],
    };

    return metadata;
};

function rawModifiedTokenDataToMetadata(
    {
        files,
        properties: additionalProperties,
        AP,
        tokenType,
        application,
        external_url,
        image,
        knownProperties,
        ...formProperties
    }: RawModifiedTokenData,
    mediaFileURL: string,
    previewFileURL: string | undefined,
) {
    return prepareMetadata({
        mediaFile: files.highResFile,
        mediaFileURL,
        previewFile: files?.previewFile,
        previewFileURL,
        additionalProperties,
        formProperties: {
            ...formProperties,
            ...Object.entries(knownProperties).reduce((acc, entry) => {
                return {
                    ...acc,
                    [entry[0]]: entry[1],
                };
            }, {}),
        },
        AP,
        tokenType,
        externalUrl: knownProperties.artworkFactSheet,
        application,
    });
}

function* watchUploadChannelEventsSaga(file: File, chainId: number) {
    const uploadChannel = yield call(createUploadChannel, file, chainId);
    let lastEvent: UploadChannelEvent | null = null;

    while (!lastEvent || lastEvent.type !== UploadChannelEventType.FINISHED) {
        lastEvent = yield take<UploadChannelEvent>(uploadChannel);

        if (lastEvent?.type === UploadChannelEventType.ERROR) {
            yield put(
                setLoadFileProgress(file.name, file.size, file.size, false),
            );
            throw new Error(lastEvent?.payload?.error);
        }

        if (lastEvent && lastEvent.type === UploadChannelEventType.PROGRESS) {
            yield put(
                setLoadFileProgress(
                    file.name,
                    file.size,
                    lastEvent.payload.loaded,
                    true,
                ),
            );
        }

        if (lastEvent && lastEvent.type === UploadChannelEventType.FINISHED) {
            yield put(
                setLoadFileProgress(file.name, file.size, file.size, true),
            );
        }
    }

    return {
        fullPath: lastEvent.payload.fullPath,
        cid: lastEvent.payload.cid,
    };
}

export function* prepareDataForContractsSaga(
    modifiedMetadata: RawModifiedTokenData,
    token: Token,
    tokenContractDetails: SolidityContractDetails,
    requiredTokenURI: string,
) {
    try {
        const chainId = yield call(getChainId);
        const blockchainId = yield call(getBlockchainId);
        const { files } = modifiedMetadata;
        const mediaFile = files.highResFile;
        const { previewFile } = files;
        const modifiedPayload = { ...modifiedMetadata };
        const { version } = tokenContractDetails;

        if (mediaFile && !requiredTokenURI) {
            yield put(
                setLoadFileProgress(mediaFile.name, mediaFile.size, 0, true),
            );

            const { fullPath: mediaFileURL } =
                yield watchUploadChannelEventsSaga(mediaFile!, chainId);
            modifiedPayload.knownProperties.media = mediaFileURL;
            modifiedPayload.knownProperties.fileName = files.highResFile!.name;
            modifiedPayload.knownProperties.fileSize = files.highResFile!.size;
            modifiedPayload.knownProperties.fileType = files.highResFile!.type;

            yield put(
                setLoadFileProgress(
                    mediaFile!.name,
                    mediaFile!.size,
                    mediaFile!.size,
                    false,
                ),
            );

            yield put(
                handleAppNotification({
                    message: `${mediaFile!.name}: upload finished`,
                    options: {
                        variant: "info",
                    },
                }),
            );
        }

        if (previewFile) {
            yield put(
                setLoadFileProgress(
                    previewFile.name,
                    previewFile.size,
                    0,
                    true,
                ),
            );

            const { fullPath: previewFileURL } = previewFile
                ? yield watchUploadChannelEventsSaga(previewFile, chainId)
                : {
                      fullPath: undefined,
                  };
            modifiedPayload.knownProperties.preview = previewFileURL;

            yield put(
                setLoadFileProgress(
                    previewFile.name,
                    previewFile.size,
                    previewFile.size,
                    false,
                ),
            );
            yield put(
                handleAppNotification({
                    message: `${previewFile.name}: upload finished`,
                    options: {
                        variant: "info",
                    },
                }),
            );
        }

        const mediaFileURL = modifiedPayload.knownProperties.media;
        const previewFileURL = modifiedPayload.knownProperties.preview;

        const metadata = rawModifiedTokenDataToMetadata(
            modifiedPayload,
            mediaFileURL,
            previewFileURL,
        );

        // Upload metadata
        const { fullPath: tokenURI } = yield uploadMetadata(metadata, chainId);

        // Update BE metadata
        const modifiedToken = { ...token };
        const { tokenId } = modifiedToken;

        modifiedToken.tokenURI = tokenURI;
        modifiedToken.metadata = metadata;

        // Update contract based on contract version
        if (version === "v2") {
            yield call(
                updateV2TokenUri,
                tokenURI,
                tokenId,
                modifiedToken,
                tokenContractDetails,
            );
        }

        if (version === "v3") {
            yield call(
                updateV3TokenUri,
                tokenURI,
                tokenId,
                modifiedToken,
                tokenContractDetails,
            );
        }

        const tokenContractAddress = getTokenContractAddress();
        yield put(
            loadToken({
                tokenId,
                networkId: blockchainId,
                tokenContractAddress,
            }),
        );
        yield put(hideDialog("dynamicTokenUriDialog"));

        yield put(
            handleAppNotification({
                message: "token metadata upload finished",
                options: {
                    variant: "info",
                },
            }),
        );
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
    }
}
export function* metamaskSelectedChainId({
    payload,
}: ReturnType<typeof createModifiedMetadata>) {
    const { modifiedMetadata, token, tokenContractDetails, requiredTokenURI } =
        payload;
    const chainId = yield call(getChainId);
    const blockchainId = yield call(getBlockchainId);

    yield put(
        setLoading({
            slot: LoadingSlots.AWAIT_SWITCH_NETWORK,
            loading: true,
        }),
    );

    if (chainId !== blockchainId) {
        try {
            yield call(requestChainById, blockchainId);
            yield call(
                prepareDataForContractsSaga,
                modifiedMetadata,
                token,
                tokenContractDetails,
                requiredTokenURI,
            );
        } catch (e) {
            yield put(
                handleAppNotification({
                    message: e.message,
                    options: {
                        variant: "error",
                        persist: true,
                    },
                }),
            );
        }
    } else {
        yield call(
            prepareDataForContractsSaga,
            modifiedMetadata,
            token,
            tokenContractDetails,
            requiredTokenURI,
        );
    }

    yield put(
        setLoading({
            slot: LoadingSlots.AWAIT_SWITCH_NETWORK,
            loading: false,
        }),
    );
}
