import {
    CreatedPaymentIntentAuctionMetadata,
    CreatedPaymentIntentMarketMetadata,
} from "~/api/data-schema";
import { fetchWithAuthHeaders } from "~/api/fetchWithHeaders/fetchWithAuthHeaders";
import { getBlockchainId } from "~/api/web3";

export async function createPaymentIntentOnBe(
    {
        amount,
        customerId,
        metadata,
        paymentMethodId,
        networkId,
    }: {
        amount: string;
        customerId: string;
        metadata:
            | CreatedPaymentIntentMarketMetadata
            | CreatedPaymentIntentAuctionMetadata
            | undefined;
        paymentMethodId?: string;
        networkId: number;
    },
    retry = 0,
): Promise<{
    clientSecret: string;
    id: string;
    paymentMethod?: string;
}> {
    const currency = "usd";

    const resp = await fetchWithAuthHeaders(
        "/api/stripe/create-payment-intent",
        {
            method: "POST",
            body: JSON.stringify({
                amount,
                currency,
                customerId,
                metadata,
                paymentMethodId,
                networkId,
            }),
        },
    );

    if (resp.status >= 500 && retry <= 10) {
        return createPaymentIntentOnBe(
            {
                amount,
                customerId,
                metadata,
                paymentMethodId,
                networkId,
            },
            retry + 1,
        );
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}

export async function getStripePublicKey(retry = 0) {
    const networkId = await getBlockchainId();

    const resp = await fetchWithAuthHeaders(
        `/api/stripe/public-key?networkId=${networkId}`,
    );

    if (resp.status >= 500 && retry <= 10) {
        return getStripePublicKey(retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    const json = await resp.json();
    return json.publicKey;
}
