import { createAction } from "@reduxjs/toolkit";
import { call, all, put } from "redux-saga/effects";
import { getChainId } from "@liveart/nft-client/dist/web3";
import {
    removeTokenMintData,
    completeMinting,
} from "../finishMinting/finishMintingDialogReducer";
import { removeMintTokenDataFromLocalStorage } from "~/api/indexedDB";

import {
    setMintingStep,
    MintingSteps,
} from "~/features/minting/mintWizard/mintingWizardReducer";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import { generatePDPLink } from "~/features/PDP/generatePDPLink";
import { MintingTypes } from "~/features/minting/mintWizard/mintingWizardTypeReducer";
import { loadContractByAddress } from "~/BFF/blockchain/solidityContracts/loadContractByAddress";
import { createReadonlyContractInstance } from "~/BFF/blockchain/solidityContracts/createReadonlyContractInstance";

export const completeSuccessMinting = createAction<{
    tokenId: number;
    tokenURI: string;
    selectedContract: SolidityContractDetails;
    openEdition: boolean;
    mintingType?: string;
}>("SUCCESS_DIALOG/COMPLETEMINTING_DIALOG");

export function* completeSuccessMintingSaga(
    action: ReturnType<typeof completeSuccessMinting>,
) {
    const {
        payload: {
            tokenId,
            tokenURI,
            selectedContract: { application, address: tokenContractAddress },
            openEdition,
            mintingType,
        },
    } = action;

    yield put(setMintingStep(MintingSteps.MINTING_VIEW_ARTWORK));
    yield all([
        call(removeMintTokenDataFromLocalStorage, tokenURI),
        put(completeMinting({})),
        put(removeTokenMintData(tokenURI)),
    ]);

    const networkId = yield call(getChainId);

    const contract = yield call(loadContractByAddress, tokenContractAddress);

    const contractInstance = createReadonlyContractInstance({
        contract,
        blockchainId: contract.blockchainId,
    });

    let [editionId] = yield call(
        contractInstance.parseEditionFromTokenId,
        tokenId,
    );
    editionId = editionId.toString();

    if (mintingType === MintingTypes.DIRECT) {
        globalThis.window.location.href = `/direct-mint?application=${application}&blockchainId=${networkId}&tokenContractAddress=${tokenContractAddress}&editionId=${editionId}`;
    }

    if (mintingType !== MintingTypes.DIRECT) {
        if (openEdition) {
            globalThis.window.location.href = `/open-edition/${networkId}/${tokenContractAddress}/${tokenId}`;
        } else {
            globalThis.window.location.href = generatePDPLink({
                tokenId,
                application,
                blockchainId: networkId,
                tokenContractAddress,
                withBasePath: true,
            });
        }
    }
}
