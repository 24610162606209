import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

export enum WLMintAddressesStates {
    CONTRACT = "SELECT_CONTRACT",
    EDITIONS = "VIEW_EDITIONS",
    CONFIGURATION = "VIEW_CONFIGURATION",
    OPERATIONS = "CRUD_OPERATIONS",
}

export enum WLMintOperationStates {
    NONE = "NONE",
    SEARCH = "PERFORM_SEARCH",
    ADD = "ADD_ADDRESS",
    REMOVE = "REMOVE_ADDRESS",
    EDIT = "EDIT_ADDRESS",
    BATCH = "BATCH_ADDRESSES",
    EXPORT = "EXPORT_ADDRESSES",
}

export enum WLMintConfigurationStates {
    VIEW = "VIEW_ONLY",
    EDIT = "EDIT_MODE",
    REGISTER = "REGISTER_WHITE_LIST",
}

export enum WLMintSettingsStates {
    EDIT = "EDIT",
    DELETE = "DELETE",
}

export type WLState = {
    state: WLMintAddressesStates;
    operation: WLMintOperationStates;
    configuration: WLMintConfigurationStates;
    errorMessage?: string;
    currentLoadedEdition: number;
    totalEditions: number;
    transactionComplete: boolean;
    editions: WhiteListedMintEdition[];
    settings: WLCardSettingsConfiguration;
    whitelists: WhiteList[];
    currentOperation: OperationDetails;
    userSelection: UserSelectionDetails;
};

export interface AddressesByContract {
    address: string;
    amount: number;
    editionId: number;
}

export interface UserSelectionDetails {
    address: string;
    contract: SolidityContractDetails;
    editionId: string;
    chainId: string;
    edition: WhiteListedMintEdition;
    selectedWhitelistIndex: number;
    newAmount?: string;
    newMintPrice?: string;
}

export interface WhiteListedMintEdition {
    editionId: string;
    maxSupply: string;
    currentSupply: string;
    name: string;
    baseURI: string;
    image: string;
    publicMintStartTS: string;
    publicMintEndTS: string;
    maxMintPerWallet: string;
    publicMintPriceInFinney: string;
}

export interface WhiteList {
    id: string;
    editionId: string;
    contractAddress: string;
    chainId: string;
    price: string;
    name: string;
    amount: string;
    addresses: string[];
    totalWallets: number;
    root: string;
    mintStart: string;
    mintEnd: string;
    contractMerkleRoot?: string;
    requiredContractUpgrade?: boolean;
    priceInEth?: string;
    priceInFinney?: string;
}

export interface RegisterWhitelist {
    price: string;
    name: string;
    contractChain: string;
    editionId: number;
    contractAddress: string;
    amount: number;
    mintStartTimestamp: string;
    mintEndTimestamp: string;
}

export interface OperationDetails {
    description: string;
    inputAddress: string;
    updatedInputAddress: string;
    resultMessage: string;
    isErrorMessage: boolean;
    isEditMode: boolean;
    whitelistedAddresses: string[];
    uploadedFile: File | undefined;
    uploadedFileContent: string[];
    batchInvalidAddresses: string[];
}

export interface UpdateRegisteredWhitelist {
    whitelistId: string;
    amount: number;
    price: string;
    mintStartTimestamp: string;
    mintEndTimestamp: string;
    contractAddress: string;
    editionId: number;
    contractChain: string;
    name: string;
}

export interface ContractWLConfiguration {
    editionId: number;
    contractAddress: string;
    amount: number;
    mintPriceInFinney: number;
    mintStartTS?: number;
    mintEndTS?: number;
    merkleRoot?: string;
}

export interface UpdateContractConfiguration {
    contractAddress: string;
    editionId: number;
    amount: number;
    mintPriceInFinney: number;
    newAmount?: number;
    newMintPriceInFinney?: number;
    newMintStartTS?: number;
    newMintEndTS?: number;
    newMerkleRoot: string;
}

export interface WLCardSettingsConfiguration {
    listName: string;
    amount: string;
    price: string;
    mintStartTimestamp: string;
    mintEndTimestamp: string;
    showSettingsConfirmation: boolean;
    errorMsg: string;
    settingMode: WLMintSettingsStates;
}

export interface WLContractValidation {
    editions: WhiteListedMintEdition[];
    errorMessage?: string;
}
