import React from "react";
import { Box } from "@mui/material";
import {
    colors,
    getFontFamilyBySupportedApplication,
    getTagFontSizeBySupportedApplication,
    TextTags,
} from "~/theme";

export function H1({
    children,
    strong,
    dataTestId,
    color,
    id,
}: React.PropsWithChildren<{
    strong?: boolean;
    dataTestId?: string;
    color?: string;
    id?: string;
}>) {
    return (
        <Box
            component="h1"
            fontFamily={getFontFamilyBySupportedApplication()}
            fontWeight={strong ? "600" : "400"}
            data-testid={dataTestId}
            fontStyle="normal"
            fontSize={getTagFontSizeBySupportedApplication(TextTags.h1)}
            lineHeight="54px"
            color={color || colors.black}
            margin={0}
            id={id}
        >
            {children}
        </Box>
    );
}
