import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { useSelector } from "react-redux";
import { DappWlConfigPerApplication } from "@liveart/injectables/dist/dataSchema/dappWlConfig";
import { remoteConfigSelector } from "~/features/remoteConfig/remoteConfigReducer";

export function useDappWlConfig(
    application: SupportedApplication,
): DappWlConfigPerApplication | undefined {
    const remoteConfig = useSelector(remoteConfigSelector);

    return remoteConfig?.dappWlConfig?.defaultValue?.value?.[application];
}
