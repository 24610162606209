import { getChainId } from "@liveart/nft-client/dist/web3";
import { query } from "../graphQL";
import { generatePresignedUrlMutation } from "./GeneratePresignedUrl";

type PresignedPostData = {
    url: string;
    fields: {
        AWSAccessKeyId: string;
        "Content-Disposition": string;
        "Content-Type": string;
        key: string;
        policy: string;
        signature: string;
    };
};

export async function uploadFileToS3(
    file: File,
    fileName: string,
    accessToken: string,
): Promise<string> {
    const chainId = await getChainId();

    const {
        data: {
            generateUploadUrls: { uploadUrls },
        },
    } = (await query({
        networkId: chainId,
        queryStr: generatePresignedUrlMutation,
        accessToken,
        variables: {
            fileNames: [fileName],
        },
    })) as {
        data: {
            generateUploadUrls: {
                uploadUrls: string[];
            };
        };
    };

    const formData = new FormData();
    const presignedPostData = JSON.parse(uploadUrls[0]) as PresignedPostData;

    Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
    });
    formData.append("file", file);

    const resp = await fetch(presignedPostData.url, {
        method: "POST",
        body: formData,
    });

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return presignedPostData.fields.key;
}
