export const webComponentsList = [
    "lax-header",
    "my-nft",
    "lax-footer",
    "lax-card",
    "lax-config",
    "ee-pdp",
    "lax-open-edition",
    "lax-pdp",
    "lax-poa",
    "nft-collection",
];

export const webComponentsListWithDependencies = {
    ...webComponentsList.reduce(
        (acc, el) => ({
            ...acc,
            [el]: [el],
        }),
        {},
    ),
    "lax-pdp": ["lax-pdp", "nft-collection"],
};
