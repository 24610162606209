import { call, put, select, takeLatest } from "redux-saga/effects";
import { PaymentIntentResult, StripeCardElement } from "@stripe/stripe-js";
import { getChainId } from "@liveart/nft-client/dist/web3";
import {
    bidFailed,
    buyArtwork,
    buyArtworkWithCreditCard,
    createPaymentIntent,
    createPaymentIntentResponse,
    loadPaymentMethods,
    placeCashBid,
    setLoading,
    handleAppNotification,
} from "~/api/choreography";
import { confirmCardPayment } from "~/api/market";
import { LoadingSlots, BuyNowListing, CashBuyer } from "~/api/data-schema";
import { purchaseFlowSelector, PurchaseFlowState } from "./purchaseFlowReducer";
import { onStripeCardChange, submitStripeBid } from "./purchaseFlowActions";
import { hideDialog } from "~/features/dialog";
import { getBuyerWallet } from "~/api/treasurer/getBuyerWallet";

export function* submitStripeBidSaga(
    action: ReturnType<typeof submitStripeBid>,
) {
    const {
        amount,
        customerId = "",
        paymentMethodId,
        metadata,
        user,
    } = action.payload;

    yield put(
        setLoading({
            slot: LoadingSlots.STRIPE_CONFIRM_PAYMENT_INTENT,
            loading: true,
        }),
    );

    yield put(
        createPaymentIntent({
            amount,
            customerId,
            paymentMethodId,
            metadata,
            user,
        }),
    );
}

function* confirmPaymentIntentSaga(
    action: ReturnType<typeof createPaymentIntentResponse>,
) {
    const paymentIntent = action.payload;
    const {
        card,
        saveStripeCard,
        totalBidAmountInEth,
        cashBuyer,
        tokenId,
        auctionId = 0,
        fullName,
    }: PurchaseFlowState = yield select(purchaseFlowSelector);
    try {
        const confirmPaymentResponse: PaymentIntentResult = yield call(
            confirmCardPayment,
            paymentIntent.clientSecret,
            {
                payment_method: paymentIntent.paymentMethod || {
                    card: card as StripeCardElement,
                    billing_details: {
                        name: fullName,
                    },
                },
                setup_future_usage:
                    !paymentIntent.paymentMethod && saveStripeCard
                        ? "on_session"
                        : null,
            },
        );

        if (confirmPaymentResponse.error) {
            const err = new Error(confirmPaymentResponse.error.message);
            yield put(
                onStripeCardChange({
                    stripeComplete: false,
                    stripeError: err,
                }),
            );
            throw err;
        }

        const networkId = yield call(getChainId);
        const bidder = yield call(getBuyerWallet, cashBuyer as CashBuyer);
        yield put(
            placeCashBid({
                networkId,
                paymentIntent: {
                    ...paymentIntent,
                    clientSecret: "",
                },
                tokenId,
                auctionId,
                bidder,
                price: totalBidAmountInEth || "",
                user: paymentIntent?.user,
            }),
        );

        if (cashBuyer?.stripeCustomerId) {
            yield put(
                loadPaymentMethods({
                    customerId: cashBuyer.stripeCustomerId,
                }),
            );
        }
    } catch (e) {
        yield put(
            handleAppNotification({
                message: e.message,
                errorObj: e,
                options: {
                    variant: "error",
                    persist: true,
                },
            }),
        );
        yield put(bidFailed());
    } finally {
        yield put(
            setLoading({
                slot: LoadingSlots.STRIPE_CONFIRM_PAYMENT_INTENT,
                loading: false,
            }),
        );
    }
}

export const executedBuyNowListingKey = "executedBuyNowListingKey";
function saveExecutedBuyNow(listing: BuyNowListing) {
    try {
        globalThis?.window?.sessionStorage.setItem(
            executedBuyNowListingKey,
            JSON.stringify(listing),
        );
    } catch {
        // do nothing
    }
}
function clearExecutedBuyNow() {
    try {
        globalThis?.window?.sessionStorage.removeItem(executedBuyNowListingKey);
    } catch {
        // do nothing
    }
}

export function* purchaseFlowSaga() {
    yield takeLatest(submitStripeBid.toString(), submitStripeBidSaga);
    yield takeLatest(
        createPaymentIntentResponse.toString(),
        confirmPaymentIntentSaga,
    );

    yield takeLatest(
        buyArtwork.toString(),
        function* onEthBuyNow(action: ReturnType<typeof buyArtwork>) {
            yield call(saveExecutedBuyNow, action.payload);
        },
    );
    yield takeLatest(
        [buyArtworkWithCreditCard.toString()],
        function* onEthBuyNow(
            action: ReturnType<typeof buyArtworkWithCreditCard>,
        ) {
            yield call(saveExecutedBuyNow, action.payload.listing);
        },
    );
    yield takeLatest(
        hideDialog.toString(),
        function* onHideDialog(action: ReturnType<typeof hideDialog>) {
            if (action.payload === "buyNowDialog") {
                yield call(clearExecutedBuyNow);
            }
        },
    );
}
