import memoize from "lodash/memoize";
import { call, takeEvery } from "redux-saga/effects";
import { Unpromisify } from "~/typescript";
import { loadDeployedContractsList } from "~/api/choreography/solidityContracts";

const importContractsModule = memoize(
    () => import("./solidityContracts"),
    () => 1,
);

export function* solidityContractsApiSaga() {
    yield takeEvery(
        loadDeployedContractsList.toString(),
        function* withLoadContractsListSaga() {
            const { loadDeployedContractsListSaga } = (yield call(
                importContractsModule,
            )) as Unpromisify<ReturnType<typeof importContractsModule>>;

            yield call(loadDeployedContractsListSaga);
        },
    );
}
