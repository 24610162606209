import { createAction } from "@reduxjs/toolkit";
import { SupportedApplication } from "@/../nft-client/dist/whiteLabel";

export const setApplicationAction = createAction<SupportedApplication>(
    "API/APPLICATION/SET_APPLICATION",
);

export const setApplicationSagaAction = createAction<SupportedApplication>(
    "API/APPLICATION/SET_APPLICATION_SAGA",
);
