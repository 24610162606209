import memoize from "lodash/memoize";
import { call, debounce, takeLatest } from "redux-saga/effects";
import {
    attachWallet,
    cognitoLogout,
    finishCognitoAuthentication,
    getCognitoUser,
    navigateToSSO,
    searchUsersById,
    searchUsersByWallets,
    setLamCognitoAuthInfo,
    updateCurrentUser,
} from "~/api/choreography";
import { Unpromisify } from "~/typescript";
import { attachWalletSaga } from "~/api/user/attachWalletSaga";

const importUserModule = memoize(
    () => import("./user/cognitoSaga"),
    () => 1,
);

export function* userApiSaga() {
    yield takeLatest(
        navigateToSSO.toString(),
        function* withNavigateToSSO(action: ReturnType<typeof navigateToSSO>) {
            const { navigateToSSOSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(navigateToSSOSaga, action);
        },
    );
    yield takeLatest(
        finishCognitoAuthentication.toString(),
        function* withFinishCognitoAuthenticationSaga(
            action: ReturnType<typeof finishCognitoAuthentication>,
        ) {
            const { finishCognitoAuthenticationSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(finishCognitoAuthenticationSaga, action);
        },
    );
    yield takeLatest(
        getCognitoUser.toString(),
        function* withGetCognitoUserSaga(
            action: ReturnType<typeof getCognitoUser>,
        ) {
            const { getCognitoUserSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(getCognitoUserSaga, action);
        },
    );
    yield takeLatest(
        setLamCognitoAuthInfo.toString(),
        function* withSetLamCognitoAuthInfo(
            action: ReturnType<typeof setLamCognitoAuthInfo>,
        ) {
            const { setLamCognitoAuthInfoSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(setLamCognitoAuthInfoSaga, action);
        },
    );
    yield takeLatest(
        cognitoLogout.toString(),
        function* withCognitoLogoutSaga(
            action: ReturnType<typeof cognitoLogout>,
        ) {
            const { cognitoLogoutSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(cognitoLogoutSaga, action);
        },
    );
    yield debounce(
        1000,
        updateCurrentUser.toString(),
        function* withUpdateCurrentUser(
            action: ReturnType<typeof updateCurrentUser>,
        ) {
            const { updateCurrentUserSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(updateCurrentUserSaga, action);
        },
    );
    yield takeLatest(
        searchUsersByWallets.toString(),
        function* withSearchUsersByWallets(
            action: ReturnType<typeof searchUsersByWallets>,
        ) {
            const { searchUsersByWalletsSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(searchUsersByWalletsSaga, action);
        },
    );
    yield takeLatest(
        searchUsersById.toString(),
        function* withSearchUsersById(
            action: ReturnType<typeof searchUsersById>,
        ) {
            const { searchUsersByIdSaga } = (yield call(
                importUserModule,
            )) as Unpromisify<ReturnType<typeof importUserModule>>;

            yield call(searchUsersByIdSaga, action);
        },
    );
    yield takeLatest(attachWallet.toString(), attachWalletSaga);
}
