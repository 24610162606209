export const v2FindContractByNameQuery = `
query FindContractByName($contractNameIlike: String) {
  allContracts(contractName_Ilike: $contractNameIlike) {
    edges {
      node {
        id
        address
        application
        contractName
        symbol
        network {
          chainId
        }
        version
        abi
      }
    }
  }
}
`;
