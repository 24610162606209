import React from "react";
import { BottomNavigation, Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import DiscordIcon from "~/features/icon/discord.svg";
import InstagramIcon from "~/features/icon/instagram.svg";
import TwitterIcon from "~/features/icon/twitter.svg";
import { BodyS } from "~/features/typography";

const StyledBottomNavigation = styled(BottomNavigation)(() => ({
    padding: "100px 50px 30px 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontFamily: "Raleway !important",
    height: "auto",
    "&": {
        height: "auto",
    },
}));

const LinkWrapper = styled(Box)(() => ({
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: 42,
    marginBottom: 30,
}));

const LinkSection = styled(Box)(() => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(4, 1fr)",
    justifyItems: "start",
    gridRowGap: 8,
}));

const LinkSectionGrey = styled(Box)(() => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    color: "rgba(0, 0, 0, 0.4)",
    textDecoration: "none",
}));

const StyledLink = styled(Link)(() => ({
    fontSize: 13,
    textDecoration: "none",
    fontWeight: 400,
    color: "black",
}));

const StyledLinkCookie = styled(Link)(() => ({
    fontSize: 13,
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.4)",
    marginRight: "100%",
}));

const StyledLinkGrey = styled(Link)(() => ({
    fontSize: 13,
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.4)",
    marginRight: 15,
    marginBottom: 10,
}));

const SocialLinks = styled(Box)(() => ({
    display: "flex",
    width: 134,
    justifyContent: "space-between",
    marginBottom: 30,
}));

const SocialText = styled("p")(() => ({
    marginTop: 30,
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.4)",
    fontWeight: 400,
}));

const year = new Date().getFullYear();

export const EphermalMobileFooter = () => (
    <StyledBottomNavigation data-testid="ephemeral-eternal-footer-mobile">
        <LinkWrapper>
            <LinkSection>
                <BodyS
                    strong
                    data-testid="ephemeral-eternal-footer-mobile-we-title"
                >
                    We
                </BodyS>
                <StyledLink
                    href="https://www.ephemeralethernal.com/about/"
                    data-testid="ephemeral-eternal-footer-mobile-about-link"
                >
                    Our purpose
                </StyledLink>
                <StyledLink
                    href="https://www.ephemeralethernal.com/how-it-works/"
                    data-testid="ephemeral-eternal-footer-mobile-how-does-it-work-link"
                >
                    How does it work?
                </StyledLink>
                <StyledLink
                    href="https://www.ephemeralethernal.com/how-it-works/#contacts"
                    data-testid="ephemeral-eternal-footer-mobile-contact-link"
                >
                    Contact us
                </StyledLink>
            </LinkSection>
            <LinkSection>
                <BodyS
                    strong
                    data-testid="ephemeral-eternal-footer-mobile-art-title"
                >
                    Art
                </BodyS>
                <StyledLink
                    href="https://www.ephemeralethernal.com/drops/"
                    data-testid="ephemeral-eternal-footer-mobile-drops-link"
                >
                    Drops
                </StyledLink>
                <StyledLink
                    href="https://www.ephemeralethernal.com/artworks/"
                    data-testid="ephemeral-eternal-footer-mobile-artworks-link"
                >
                    Artworks
                </StyledLink>
                <StyledLink
                    href="https://www.ephemeralethernal.com/artists/"
                    data-testid="ephemeral-eternal-footer-mobile-artists-link"
                >
                    Artists
                </StyledLink>
            </LinkSection>
        </LinkWrapper>
        <SocialLinks data-testid="ephemeral-eternal-footer-mobile-socials">
            <StyledLink
                href="https://discord.gg/9t7cDUfx"
                data-testid="ephemeral-eternal-footer-mobile-discord-link"
            >
                <DiscordIcon />
            </StyledLink>
            <StyledLink
                href="https://twitter.com/EphemeralEther1"
                style={{ position: "relative", top: "2px" }}
            >
                <TwitterIcon />
            </StyledLink>
            <StyledLink
                href="https://www.instagram.com/ephemeralethernal/?hl=en"
                data-testid="ephemeral-eternal-footer-mobile-instagram-link"
            >
                <InstagramIcon />
            </StyledLink>
        </SocialLinks>
        <LinkSectionGrey>
            <StyledLinkGrey
                href="https://www.ephemeralethernal.com/terms-of-service/"
                data-testid="ephemeral-eternal-footer-mobile-terms-of-service-link"
            >
                Terms of Service
            </StyledLinkGrey>
            <StyledLinkGrey
                href="https://www.ephemeralethernal.com/privacy-policy/"
                data-testid="ephemeral-eternal-footer-mobile-privacy-policy-link"
            >
                Privacy & Policy
            </StyledLinkGrey>
            <StyledLinkGrey
                href="https://www.livroreclamacoes.pt/Inicio/"
                data-testid="ephemeral-eternal-footer-mobile-complaints-book"
            >
                Complaints Book
            </StyledLinkGrey>
            <StyledLinkCookie
                href="/#"
                data-testid="ephemeral-eternal-footer-mobile-cookies-link"
            >
                Cookies
            </StyledLinkCookie>
        </LinkSectionGrey>
        <SocialText data-testid="ephemeral-eternal-footer-tmobile-rademark">
            &copy; {year} Ephemeral Ethernal <br />
            Digitally crafted by Adhesive/Brand + Digital
        </SocialText>
    </StyledBottomNavigation>
);
