// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import LogRocket from "logrocket";

const SENTRY_DSN: string | undefined =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn:
            SENTRY_DSN ||
            "https://f055a9de178f40a9b85e70edfb0644cd@o680629.ingest.sentry.io/6076745",
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        autoSessionTracking: true,
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
    if (
        process.env.NEXT_PUBLIC_LOGROCKET_APP_ID &&
        process.env.NODE_ENV === "production"
    )
        LogRocket.getSessionURL((sessionURL: string) => {
            Sentry.configureScope((scope) => {
                scope.setExtra("sessionURL", sessionURL);
            });
        });
}
