import { createAction } from "@reduxjs/toolkit";
import {
    MintingContext,
    MintOperation,
    ReadyToMintTokenData,
} from "~/api/data-schema";
import {
    mintToken,
    createMintingQueueResponse,
    queueMintOperation,
} from "~/api/choreography";

export type FinishMintDialogState = {
    completedSteps: {
        [step: number]: boolean;
    };
    errorSteps: {
        [step: number]: string;
    };
    mintTokenData: {
        [tokenURI: string]: ReadyToMintTokenData;
    };
    mintTokenQueue: MintOperation[];
    mintingContext: MintingContext;
};
export const initialFinishMintingDialogState: FinishMintDialogState = {
    mintTokenData: {},
    mintTokenQueue: [],
    mintingContext: {},
    completedSteps: {},
    errorSteps: {},
};

export const runMintingStep = createAction<{
    step: number;
    runNext?: boolean;
}>("FINISH_MINTING_DIALOG/RUN_MINTING_STEP");

export const addTokenMintData = createAction<ReadyToMintTokenData>(
    "MINT/ADD_TOKEN_MINT_DATA",
);
export const addTokenMintDataReducer = (
    state: FinishMintDialogState,
    { payload }: ReturnType<typeof addTokenMintData>,
): FinishMintDialogState => {
    return {
        ...state,
        mintTokenData: {
            ...state.mintTokenData,
            [payload.tokenURI]: payload,
        },
    };
};

export const removeTokenMintData = createAction<string>(
    "MINT/REMOVE_TOKEN_MINT_DATA",
);
export const removeTokenMintDataReducer = (
    state: FinishMintDialogState,
    { payload }: ReturnType<typeof removeTokenMintData>,
): FinishMintDialogState => {
    return {
        ...state,
        mintTokenData: Object.keys(state.mintTokenData).reduce((acc, el) => {
            if (el !== payload) {
                return {
                    ...acc,
                    [el]: state.mintTokenData[el],
                };
            }
            return acc;
        }, {}),
    };
};

export const runMintingStepResponse = createAction<{
    step: number;
    success: boolean;
}>("MINTING/RUN_MINTING_STEP_RESPONSE");
export const runMintingStepResponseReducer = (
    state: FinishMintDialogState,
    { payload }: ReturnType<typeof runMintingStepResponse>,
): FinishMintDialogState => {
    if (payload.success) {
        return {
            ...state,
            completedSteps: {
                ...state.completedSteps,
                [payload.step]: true,
            },
            errorSteps: {},
        };
    }
    return {
        ...state,
        errorSteps: {
            ...state.errorSteps,
            [payload.step]: "",
        },
    };
};

export const updateMintingContext = createAction<MintingContext>(
    "MINTING/UPDATE_MINTING_CONTEXT",
);
export const updateMintingContextReducer = (
    state: FinishMintDialogState,
    { payload }: ReturnType<typeof updateMintingContext>,
): FinishMintDialogState => {
    return {
        ...state,
        mintingContext: payload,
    };
};

export const queueMintOperationReducer = (
    state: FinishMintDialogState,
    { payload }: ReturnType<typeof queueMintOperation>,
): FinishMintDialogState => {
    return {
        ...state,
        mintTokenQueue: [...state.mintTokenQueue, payload],
    };
};

export const mintTokenReducer = (
    state: FinishMintDialogState,
    { payload }: ReturnType<typeof mintToken>,
): FinishMintDialogState => {
    return {
        ...state,
        mintingContext: {
            tokenURI: payload.tokenURI,
        },
        completedSteps: {},
        errorSteps: {},
    };
};

export const createMintingQueueResponseReducer = (
    state: FinishMintDialogState,
    { payload }: ReturnType<typeof createMintingQueueResponse>,
): FinishMintDialogState => {
    return {
        ...state,
        mintingContext: payload.success ? state.mintingContext : {},
        completedSteps: {},
        errorSteps: {},
    };
};

export const completeMinting = createAction<MintingContext>(
    "MINTING/REMOVE_CONTEXT",
);
export const createArtworkResponseReducer = (
    state: FinishMintDialogState,
): FinishMintDialogState => {
    return {
        ...state,
        completedSteps: {},
        errorSteps: {},
        mintingContext: {},
        mintTokenQueue: [],
    };
};

export const finishMintingDialogSelector = (state: {
    mint: FinishMintDialogState;
}) => state.mint;
