import React from "react";
import { BottomNavigation } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import Link from "next/link";
import { BodyS } from "~/features/typography";
import DiscordIcon from "~/features/icon/discord.svg";
import InstagramIcon from "~/features/icon/instagram.svg";
import TwitterIcon from "~/features/icon/twitter.svg";

const StyledBottomNavigation = styled(BottomNavigation)(() => ({
    padding: "166px 64px 80px 64px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Raleway !important",
}));

const LinkWrapper = styled(Box)(() => ({
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridColumnGap: 108,
    "@media (max-width: 1100px)": {
        gridGap: 78,
    },
}));

const LinkSection = styled(Box)(() => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(4, 1fr)",
    justifyItems: "start",
    gridRowGap: 8,
}));

const LinkSectionGrey = styled(Box)(() => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "repeat(4, 1fr)",
    justifyItems: "start",
    gridRowGap: 8,
    color: "rgba(0, 0, 0, 0.4)",
}));

const Social = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
}));

const SocialLinks = styled(Box)(() => ({
    display: "flex",
    width: 136,
    justifyContent: "space-between",
    marginBottom: 40,
}));

const SocialText = styled("p")(() => ({
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.4)",
    fontWeight: 400,
    textAlign: "right",
}));

const year = new Date().getFullYear();

export const EphermalDesktopFooter = () => (
    <StyledBottomNavigation data-testid="ephemeral-eternal-footer">
        <LinkWrapper>
            <LinkSection>
                <BodyS strong data-testid="ephemeral-eternal-footer-we-title">
                    We
                </BodyS>
                <Link
                    href="https://www.ephemeralethernal.com/about/"
                    data-testid="ephemeral-eternal-footer-about-link"
                >
                    Our purpose
                </Link>
                <Link
                    href="https://www.ephemeralethernal.com/how-it-works/"
                    data-testid="ephemeral-eternal-footer-how-does-it-work-link"
                >
                    How does it work?
                </Link>
                <Link
                    href="https://www.ephemeralethernal.com/how-it-works/#contacts"
                    data-testid="ephemeral-eternal-footer-contact-link"
                >
                    Contact us
                </Link>
            </LinkSection>
            <LinkSection>
                <BodyS strong data-testid="ephemeral-eternal-footer-art-title">
                    Art
                </BodyS>
                <Link
                    href="https://www.ephemeralethernal.com/drops/"
                    data-testid="ephemeral-eternal-footer-drops-link"
                >
                    Drops
                </Link>
                <Link
                    href="https://www.ephemeralethernal.com/artworks/"
                    data-testid="ephemeral-eternal-footer-artworks-link"
                >
                    Artworks
                </Link>
                <Link
                    href="https://www.ephemeralethernal.com/artists/"
                    data-testid="ephemeral-eternal-footer-artists-link"
                >
                    Artists
                </Link>
            </LinkSection>
            <LinkSectionGrey>
                <Link
                    href="https://www.ephemeralethernal.com/terms-of-service/"
                    data-testid="ephemeral-eternal-footer-terms-of-use"
                >
                    Terms of use
                </Link>
                <Link
                    href="https://www.ephemeralethernal.com/privacy-policy/"
                    data-testid="ephemeral-eternal-footer-privacy-policy-link"
                >
                    Privacy Policy
                </Link>
                <Link
                    href="https://www.livroreclamacoes.pt/Inicio/"
                    data-testid="ephemeral-eternal-footer-complaints-book-link"
                >
                    Complaints Book
                </Link>
                <Link href="/#" data-testid="ephemeral-eternal-footer-cookies">
                    Cookies
                </Link>
            </LinkSectionGrey>
        </LinkWrapper>
        <Social data-testid="ephemeral-eternal-footer-socials">
            <SocialLinks>
                <Link
                    href="https://discord.gg/9t7cDUfx"
                    data-testid="ephemeral-eternal-footer-discord-link"
                >
                    <DiscordIcon />
                </Link>
                <Link
                    href="https://twitter.com/EphemeralEther1"
                    style={{ position: "relative", top: "2px" }}
                    data-testid="ephemeral-eternal-footer-twitter-link"
                >
                    <TwitterIcon />
                </Link>
                <Link
                    href="https://www.instagram.com/ephemeralethernal/?hl=en"
                    data-testid="ephemeral-eternal-footer-instagram-link"
                >
                    <InstagramIcon />
                </Link>
            </SocialLinks>
            <SocialText data-testid="ephemeral-eternal-trademark">
                {year} Ephemeral Ethernal <br />
                Digitally crafted by Adhesive/Brand + Digital
            </SocialText>
        </Social>
    </StyledBottomNavigation>
);
