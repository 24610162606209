export interface Event {
    eventName: SegmentEvents;
    properties: Object;
    option?: SegmentOption;
}
export enum SegmentOption {
    track = "track",
    identify = "identify",
}
export enum SegmentEvents {
    IDENTIFY_SIGN_UP = "Identify Sign Up",
    IDENTIFY_LOG_IN = "Identify Log In",
    CUSTOMER_CREATED = "Customer Created",
    PAGE_VIEWED = "Page Viewed",

    USER_LOGGED_IN = "User Logged In",
    USER_LOG_OUT = "User Log Out",

    ARTWORK_PREVIEW = "Artwork Preview Play",
    ARTWORKS_PAGE_VIEWED = "Artwork Page Viewed",
    ARTWORK_POA_CLICKED = "Artwork View Proof of Authentity Clicked",
    ARTWORK_VIEW_META_DETAILS_CLICKED = "Artwork View Metadetails Clicked",
    ARTWORK_PLACE_BID_CLICKED = "Artwork Place Bid Clicked",
    BID_PLACED = "Bid Placed",
    CONTACT_US_CLICKED = "Contact Us Clicked",
    AQUIRE_NOW_CLICKED = "Acquire Now Clicked",
    PROFILE_VIEWED = "Profile Viewed",
    SETTINGS_VIEWED = "Settings Viewed",
    CLAIM_BUTTON_CLICKED = "Claim Button Clicked ",
    ARTWORK_PDF_GENERATED = "Artowork PDF Generated",
    ARTWORK_AQUIRED = "Artwork Acquired",
    COLLECTION_VIEWED = "Collection Viewed",

    WALLET_CLICKED = "Wallet Clicked",
    WALLET_CONNECTED = "Wallet Connected",
}
