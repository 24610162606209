import React from "react";
import { Box } from "@mui/material";
import { colors, fontFamilies } from "~/theme";

export function H6({
    children,
    strong,
    id,
}: React.PropsWithChildren<{ strong?: boolean; id?: string }>) {
    return (
        <Box
            id={id}
            component="h5"
            fontFamily={fontFamilies.hurme2}
            fontWeight={strong ? "600" : "400"}
            fontStyle="normal"
            fontSize="14px"
            lineHeight="18px"
            letterSpacing="0.07px"
            color={colors.black}
            margin={0}
        >
            {children}
        </Box>
    );
}
