import memoize from "lodash/memoize";
import { call, takeLatest } from "redux-saga/effects";
import { setDefaultApplication } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importApplicationModule = memoize(
    () => import("./application"),
    () => 1,
);

export function* applicationApiSaga() {
    yield takeLatest(
        setDefaultApplication.toString(),
        function* withSetDefaultApplicationSaga(
            action: ReturnType<typeof setDefaultApplication>,
        ) {
            const { setDefaultApplicationSaga } = (yield call(
                importApplicationModule,
            )) as Unpromisify<ReturnType<typeof importApplicationModule>>;

            yield call(setDefaultApplicationSaga, action);
        },
    );
}
