import React, { ReactElement } from "react";
import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import { CircularProgress, Typography } from "@mui/material";
import { Currency, LoadingSlots } from "~/api/data-schema";
import { useLoadingSlot } from "../loading";
import { useCurrencyExchange } from "./useCurrencyExchange";
import { formatPrice } from "../formatters";

type Props = {
    value: string | number | BigNumber;
    from?: Currency;
    to?: Currency;
    // eslint-disable-next-line react/no-unused-prop-types
    date?: Date;
    render?: (value: string) => ReactElement;
};

export const CurrencyExchange: React.FC<Props> = ({
    value,
    from,
    to,
    render,
}) => {
    const loading = useLoadingSlot(LoadingSlots.EXCHANGING_CURRENCY);

    const convertedValue = useCurrencyExchange({
        value,
        from,
        to,
    });

    if (loading) {
        return <CircularProgress size={18} />;
    }

    if (!loading && convertedValue) {
        const localisedValue = formatPrice(convertedValue, { symbol: "$" });
        if (render) {
            return render(localisedValue);
        }
        return <Typography component="span">{localisedValue}</Typography>;
    }

    return null;
};

export const ETHtoUSD: React.FC<Props> = (props) => {
    const propsWithDefaults: Props = {
        from: Currency.ETH,
        to: Currency.USD,
        date: new Date(),
        ...props,
    };
    return <CurrencyExchange {...propsWithDefaults} />;
};
