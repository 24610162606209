import { createReducer, createAction } from "@reduxjs/toolkit";
import { SupportedThemes } from "~/theme";
import { getThemeFromApplication } from "~/WhiteLabel/theme";

export const setTheme = createAction<SupportedThemes>("THEME/SET_THEME");

export type ThemeState = {
    themeName?: SupportedThemes;
};

export type WithThemeState = {
    theme: ThemeState;
};

export const initialThemeState: ThemeState = {
    themeName: getThemeFromApplication(),
};

export const themeReducer = createReducer(initialThemeState, (builder) =>
    builder.addCase(
        setTheme,
        (state: ThemeState, { payload }): ThemeState => ({
            ...state,
            themeName: payload,
        }),
    ),
);

export const themeSelector = (state: WithThemeState) => state?.theme;
