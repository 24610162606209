import { createAction } from "@reduxjs/toolkit";
import { showDialog } from "~/features/dialog";

export enum SellArtworkDialogStep {
    SELECT_LISTING_TYPE = "SELECT_LISTING_TYPE",
    BUY_NOW = "BUY_NOW",
    AUCTION = "AUCTION",
}
export type SellArtworkState = {
    dialogStep: SellArtworkDialogStep;
};
export type WithSellArtworkState = {
    sellArtwork: SellArtworkState;
};
export const defaultSellArtworkState: SellArtworkState = {
    dialogStep: SellArtworkDialogStep.SELECT_LISTING_TYPE,
};

export const setDialogStep = createAction<SellArtworkDialogStep>(
    "SELL_ARTWORK/SET_DIALOG_STEP",
);
export const setDialogStepReducer = (
    state: WithSellArtworkState,
    action: ReturnType<typeof setDialogStep>,
): WithSellArtworkState => ({
    sellArtwork: {
        ...state.sellArtwork,
        dialogStep: action.payload,
    },
});

export const showDialogReducer = (
    state: WithSellArtworkState,
    { payload }: ReturnType<typeof showDialog>,
): WithSellArtworkState => {
    if (payload === "sellArtworkDialog") {
        return {
            ...state,
            sellArtwork: {
                ...state.sellArtwork,
                dialogStep: SellArtworkDialogStep.SELECT_LISTING_TYPE,
            },
        };
    }

    return state;
};

export const sellArtworkStateSelector = (state: {
    pdp: WithSellArtworkState;
}) => state.pdp.sellArtwork;
