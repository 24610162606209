import { createReducer, createAction } from "@reduxjs/toolkit";

export type WithDownloadState = {
    download: DownloadState;
};
export type DownloadState = {
    isArtworkBeingDownloaded: boolean;
};
export const defaultDownloadState: DownloadState = {
    isArtworkBeingDownloaded: false,
};

export type DownloadLoadingState = {
    state: boolean;
};

export const setDownloadLoadingState = createAction<DownloadLoadingState>(
    "DOWNLOAD/LOADING_STATE",
);

export const downloadReducer = createReducer<DownloadState>(
    defaultDownloadState,
    (builder) =>
        builder.addCase(setDownloadLoadingState, (state, action) => ({
            ...state,
            isArtworkBeingDownloaded: action.payload.state,
        })),
);

export const downloadStateSelector = (state: WithDownloadState) =>
    state.download;
