import { put, takeLatest, call } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { MetadataJSONSchema } from "~/api/metadata";
import { setDownloadLoadingState } from "~/features/PDP/downloadReducer";

export type DownloadFileData = {
    url: string;
    type: string;
    name: string;
    index?: number;
    metadata: MetadataJSONSchema;
};

export const downloadFile = createAction<DownloadFileData>("DOWNLOAD/FILE");

async function getImage(url, type, name) {
    await fetch(url, {
        method: "GET",
    }).then((response) => {
        response.blob().then((blob) => {
            const createObjectURL = URL?.createObjectURL(
                new Blob([blob], {
                    type: response.headers.get("content-type") as string,
                }),
            );
            const link = document.createElement("a");
            link.href = createObjectURL;
            link.setAttribute("download", `${name}`);
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        });
    });
}

function* downloadFileSaga({ payload }: ReturnType<typeof downloadFile>) {
    const { url, type, name, index = 0, metadata } = payload;
    yield put(
        setDownloadLoadingState({
            state: true,
        }),
    );
    yield call(getImage, url, type, name);

    if (metadata?.phygitalData && metadata?.phygitalData.length > index) {
        yield put(
            downloadFile({
                url: metadata?.phygitalData[index].phygitalImage || "",
                type,
                name: `phygital_${index + 1}_${name}`,
                index: index + 1,
                metadata,
            }),
        );
    }
    yield put(
        setDownloadLoadingState({
            state: false,
        }),
    );
}

export function* downloadSaga() {
    yield takeLatest(downloadFile.toString(), downloadFileSaga);
}
