import { createAction } from "@reduxjs/toolkit";
import { PaymentMethod, StripeCardElement } from "@stripe/stripe-js";
import { CashBuyer } from "~/api/data-schema/CashBuyer";
import { buyArtworkWithCreditCard } from "~/api/choreography";

export enum CardPaymentUI {
    SAVED_CARD = "SAVED_CARD",
    NEW_CARD = "NEW_CARD",
}

export enum PurchaseMethod {
    CASH = "cash",
    ETHER = "ether",
}

export type BuyNowFlowState = {
    fullName?: string;
    saveStripeCard?: boolean;
    stripeError?: Error;
    stripeComplete?: boolean;
    card?: StripeCardElement;
    paymentMethods?: PaymentMethod[];
    cashBuyer?: CashBuyer;
    selectedPurchaseMethod: "cash" | "ether";
    defaultCardUI: CardPaymentUI;
    failReason?: string;
    buyComplete: boolean;
    buyFailed: boolean;
};

export type WithBuyNowFlow = {
    buyNowFlow: BuyNowFlowState;
};

export const initialBuyNowState: BuyNowFlowState = {
    saveStripeCard: true,
    defaultCardUI: CardPaymentUI.NEW_CARD,
    selectedPurchaseMethod: PurchaseMethod.ETHER,
    buyComplete: false,
    buyFailed: false,
};

export const setBuyNowFullName = createAction<string>(
    "BUY_NOW_FLOW/SET_FULL_NAME",
);

export const onBuyNowStripeCardChange = createAction<{
    stripeError: Error | undefined;
    stripeComplete: boolean | undefined;
}>("BUY_NOW_FLOW/ON_STRIPE_CARD_CHANGE");

export const toggleBuyNowSaveStripeCard = createAction<boolean>(
    "BUY_NOW_FLOW/TOGGLE_SAVE_STRIPE_CARD",
);

export const setBuyNowSelectedPurchaseMethod = createAction<"cash" | "ether">(
    "BUY_NOW_FLOW/SET_SELECTED_PAYMENT_METHOD",
);

export const setBuyNowFlowState = createAction<Partial<BuyNowFlowState>>(
    "BUY_NOW/SET_BUY_NOW_FLOW_STATE",
);

export type WithBuyNowFlowState = {
    buyNowFlow: BuyNowFlowState;
};

export const setBuyNowStateReducer = (
    state: WithBuyNowFlowState,
    { payload },
): WithBuyNowFlowState => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        ...payload,
    },
});

export const buyArtworkReducer = (state: WithBuyNowFlow): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        buyFailed: false,
        buyComplete: false,
    },
});

export const buyArtworkWithCreditCardReducer = (
    state: WithBuyNowFlow,
    { payload }: ReturnType<typeof buyArtworkWithCreditCard>,
): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        card: payload.stripeData.card,
        fullName: payload.stripeData.fullName,
    },
});

export const buyNowCompleteReducer = (
    state: WithBuyNowFlow,
): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        buyFailed: false,
        buyComplete: true,
        failReason: undefined,
    },
});

export const buyNowFailedReducer = (
    state: WithBuyNowFlow,
    { payload },
): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        buyComplete: false,
        buyFailed: true,
        failReason: payload,
    },
});

export const buyNowFullNameReducer = (
    state: WithBuyNowFlow,
    { payload },
): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        fullName: payload,
    },
});

export const buyNowCardChangeReducer = (
    state: WithBuyNowFlow,
    { payload },
): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        ...payload,
    },
});

export const buyNowSaveCardReducer = (
    state: WithBuyNowFlow,
    { payload },
): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        saveStripeCard: payload,
    },
});

export const buyNowSetPurchaseMethodReducer = (
    state: WithBuyNowFlow,
    { payload },
): WithBuyNowFlow => ({
    buyNowFlow: {
        ...state.buyNowFlow,
        selectedPurchaseMethod: payload,
    },
});

export const buyNowFlowSelector = (state: {
    purchaseFlow: { buyNowFlow: BuyNowFlowState };
}) => state.purchaseFlow.buyNowFlow;
