import { createReducer } from "@reduxjs/toolkit";
import {
    validateSelectedContractResponse,
    setCurrentWhiteListedStep,
    setCurrentWhiteListOperation,
    setCurrentWhiteListConfiguration,
    resetWhiteListedState,
    loadWhiteListedMintAddressesResponse,
    updateOperationDetails,
    userSelectedDetails,
    currentLoadedEditionsByContractResponse,
    totalEditionsByContractResponse,
    getWalletsByWhiteListIdResponse,
    isTransactionComplete,
    updateWLSettingsDetails,
} from "~/api/choreography";
import {
    WhiteListedMintEdition,
    WhiteList,
    UserSelectionDetails,
    WLMintAddressesStates,
    WLMintOperationStates,
    WLMintConfigurationStates,
    WLState,
    WLMintSettingsStates,
} from "~/api/data-schema/WhiteListedMintAddresses";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";

const generateTimestamp = () => {
    return new Date(Date.now()).toISOString();
};

export const initialWLState: WLState = {
    state: WLMintAddressesStates.CONTRACT,
    operation: WLMintOperationStates.NONE,
    configuration: WLMintConfigurationStates.VIEW,
    errorMessage: "",
    currentLoadedEdition: 0,
    totalEditions: 0,
    transactionComplete: false,
    editions: [],
    whitelists: [] as WhiteList[],
    settings: {
        listName: "",
        amount: "",
        price: "",
        mintStartTimestamp: generateTimestamp(),
        mintEndTimestamp: generateTimestamp(),
        showSettingsConfirmation: false,
        errorMsg: "",
        settingMode: WLMintSettingsStates.EDIT,
    },
    userSelection: {
        chainId: "",
        contract: {} as SolidityContractDetails,
        edition: {} as WhiteListedMintEdition,
        selectedWhitelistIndex: -1,
        newAmount: "",
        newMintPrice: "",
    } as unknown as UserSelectionDetails,
    currentOperation: {
        description: "",
        inputAddress: "",
        updatedInputAddress: "",
        resultMessage: "",
        isErrorMessage: false,
        isEditMode: false,
        whitelistedAddresses: [],
        uploadedFile: undefined,
        uploadedFileContent: [],
        batchInvalidAddresses: [],
    },
};

export const WLMintAddressReducer = createReducer(initialWLState, (builder) =>
    builder
        .addCase(
            validateSelectedContractResponse,
            (state, { payload: validationState }) => ({
                ...state,
                ...validationState,
            }),
        )
        .addCase(
            setCurrentWhiteListedStep,
            (state, { payload: currentStep }) => ({
                ...state,
                state: currentStep as WLMintAddressesStates,
            }),
        )
        .addCase(
            setCurrentWhiteListOperation,
            (state, { payload: currentOperation }) => ({
                ...state,
                operation: currentOperation as WLMintOperationStates,
            }),
        )
        .addCase(
            setCurrentWhiteListConfiguration,
            (state, { payload: currentConfiguration }) => ({
                ...state,
                configuration:
                    currentConfiguration as WLMintConfigurationStates,
            }),
        )

        .addCase(resetWhiteListedState, (state, { payload: resetState }) => ({
            ...state,
            ...resetState,
        }))
        .addCase(
            loadWhiteListedMintAddressesResponse,
            (state, { payload: whiteListedMintAddressesConfig }) => ({
                ...state,
                whitelists: whiteListedMintAddressesConfig,
            }),
        )
        .addCase(updateOperationDetails, (state, { payload: details }) => ({
            ...state,
            currentOperation: {
                ...state.currentOperation,
                ...details,
            },
        }))
        .addCase(userSelectedDetails, (state, { payload: selected }) => ({
            ...state,
            userSelection: {
                ...state.userSelection,
                ...selected,
            },
        }))
        .addCase(
            currentLoadedEditionsByContractResponse,
            (state, { payload: currentLoadedEdition }) => ({
                ...state,
                currentLoadedEdition,
            }),
        )
        .addCase(
            totalEditionsByContractResponse,
            (state, { payload: total }) => ({
                ...state,
                totalEditions: total,
            }),
        )
        .addCase(
            getWalletsByWhiteListIdResponse,
            (state, { payload: whitelistedAddresses }) => ({
                ...state,
                currentOperation: {
                    ...state.currentOperation,
                    whitelistedAddresses,
                },
            }),
        )

        .addCase(
            isTransactionComplete,
            (state, { payload: transactionComplete }) => ({
                ...state,
                transactionComplete,
            }),
        )

        .addCase(updateWLSettingsDetails, (state, { payload: setting }) => ({
            ...state,
            settings: {
                ...state.settings,
                ...setting,
            },
        })),
);

export const WLMintAddressSelector = (state: {
    whiteListedMintAddresses: WLState;
}) => state.whiteListedMintAddresses;
