import { call, put } from "redux-saga/effects";
import { setLoading } from "~/api/choreography";
import { LoadingSlots } from "~/api/data-schema";
import { Currency } from "../data-schema";
import { getCurrencyRates, getCurrencyRatesResponse } from "../choreography";

const requestComplete = {};

type OpenexchangeResponse = {
    base: Currency;
    rates: Record<Currency, number>;
    timestamp: number;
};
export function* getCurrencyRatesSaga(
    action: ReturnType<typeof getCurrencyRates>,
) {
    const {
        payload: { from, to },
    } = action;

    const cacheKey = `${from}.${to}`;

    if (requestComplete[cacheKey]) {
        yield put(
            getCurrencyRatesResponse({ [from]: requestComplete[cacheKey] }),
        );
        return;
    }

    try {
        setLoading({ slot: LoadingSlots.EXCHANGING_CURRENCY, loading: true });

        const response: Response = yield call(
            fetch,
            `https://openexchangerates.org/api/latest.json?app_id=f28ebe23a75d43cbb3e7d777d3f449f2&base=${from}&symbols=${to}&show_alternative=true`,
            {
                method: "GET",
            },
        );
        const { rates }: OpenexchangeResponse = yield call(() =>
            response.json(),
        );

        requestComplete[cacheKey] = rates;

        yield put(getCurrencyRatesResponse({ [from]: rates }));
    } finally {
        setLoading({ slot: LoadingSlots.EXCHANGING_CURRENCY, loading: false });
    }
}
