import { createAction } from "@reduxjs/toolkit";

export enum MintingTypes {
    NORMAL = "NORMAL",
    PRE_MINT = "PRE_MINT",
    LAZY = "LAZY",
    DIRECT = "DIRECT",
}

export type MintingWizardTypeState = {
    mintingType: MintingTypes;
};
export const initialMintingWizardTypeState: MintingWizardTypeState = {
    mintingType: MintingTypes.NORMAL,
};

export const setMintingType = createAction<MintingTypes>(
    "MINTING/SET_MINTING_TYPE",
);

export const mintingWizardTypeReducer = (
    state: MintingWizardTypeState,
    { payload }: ReturnType<typeof setMintingType>,
): MintingWizardTypeState => ({
    ...state,
    mintingType: payload,
});

export const mintingTypeSelector = (state: { mint: MintingWizardTypeState }) =>
    state.mint.mintingType;
