import {
    AnyAction,
    configureStore,
    ConfigureStoreOptions,
    Middleware,
    StateFromReducersMapObject,
    Store,
} from "@reduxjs/toolkit";
import type { EnhancedStore } from "@reduxjs/toolkit";
import { authReducer } from "@liveart/injectables/dist/auth/authReducer";
import { searchBarRunSaga } from "@liveart/injectables/dist/searchBar/searchBarSagas";
import { searchBarReducer } from "@liveart/injectables/dist/searchBar/searchBarReducer";
import { authSaga } from "@liveart/injectables/dist/auth/authSaga";
import { stytchSaga } from "@liveart/auth/lib/src/features/authentication/stytchSaga";
import { apiSaga as injectablesApiSaga } from "@liveart/injectables/dist/apiSaga";
import { allAllowListsReducer } from "@liveart/injectables/dist/allowList/allAllowlistsReducer";
import { allowListUserReducer } from "@liveart/injectables/dist/allowList/allowListUserReducer";
import { initWebStoreWithContext } from "@liveart/injectables/dist/consolidated-store/initWebStore";
import { getChainId } from "@liveart/nft-client/dist/web3";
import {
    HeaderReducerState,
    injectablesHeaderWebReducer,
} from "@liveart/injectables/dist/header/web/headerReducer";
import { injectablesWebFooterReducer } from "@liveart/injectables/dist/footer/web/liveart/footerReducer";
import { FooterConfig } from "@liveart/injectables/dist/dataSchema/Footer";
import { dialogSaga } from "@liveart/injectables/dist/dialog/dialogSaga";
import { dialogReducer } from "@liveart/injectables/dist/dialog/dialogReducer";
import { loadRemoteConfig } from "@liveart/injectables/dist/choreography/remoteConfig";
import { remoteConfigApiSaga } from "@liveart/injectables/dist/remoteConfig/remoteConfigApiSaga";
import { navigationStackReducer } from "@liveart/injectables/dist/navigationStack/navigationReducer";
import { downloadSaga } from "~/api/downloadSaga";
import { snackbarReducer } from "~/features/snackbar/snackbarReducer";
import { uploadProgressReducer } from "~/features/uploadProgress/uploadProgressReducer";
import { artworkReducer } from "~/features/artwork/artworkReducer";
import { artworkSaga } from "~/features/artwork/artworkSaga";
import { buyCryptoReducer } from "~/features/buy-crypto/buyCryptoReducer";
import { mintReducer } from "~/features/minting/mintReducer";
import { mintSaga } from "~/features/minting/mintSaga";
import { loadingStateReducer } from "~/features/loading/loadingReducer";
import { userReducer } from "~/features/user/userReducer";
import { userSaga } from "~/features/user/userSaga";
import { currencyExchangeReducer } from "~/features/currencyExchange/currencyExchangeReducer";
import { tokenMarketDataReducer } from "~/features/tokenMarketData/tokenMarketDataReducer";
import { purchaseFlowReducer } from "~/features/purchaseFlow/purchaseFlowReducer";
import { purchaseFlowSaga } from "~/features/purchaseFlow/purchaseFlowSaga";
import { helpReducer } from "~/features/help/helpReducer";
import { featureFLagReducer } from "~/features/featureFlag/featureFlagReducer";
import { appNotificationsReducer } from "~/features/notifications/notificationsReducer";
import { appNotificationsSaga } from "~/features/notifications/notificationsSaga";
import { sentryTrackingSaga } from "~/features/errorTracking/sentryTrackingSaga";
import { usersReducer } from "~/features/users/usersReducer";
import { pdpReducer } from "~/features/PDP/pdpReducer";
import { downloadReducer } from "~/features/PDP/downloadReducer";
import { erc721v4Reducer } from "~/features/PDPv4/erc721v4Reducer";
import { themeReducer } from "~/features/muiThemeProvider/themeReducer";
import { trustDropReducer } from "~/features/artechouse/TrustDrop/trustDropReducer";
import { trustDropSaga } from "~/features/artechouse/TrustDrop/trustDropSaga";
import { remoteConfigReducer } from "~/features/remoteConfig/remoteConfigReducer";
import { marketFeatureReducer } from "~/features/market/marketFeatureReducer";
import { marketFeatureSaga } from "~/features/market/marketFeatureSaga";
import { applicationApiSaga } from "~/api/applicationApiSaga";
import { hubspotApiSaga } from "~/api/hubspotApiSaga";
import { segmentApiSaga } from "~/api/segmentApiSaga";
import { pdfApiSaga } from "~/api/pdfApiSaga";
import { web3ApiSaga } from "~/api/web3ApiSaga";
import { userApiSaga } from "~/api/userApiSaga";
import { pusherApiSaga } from "~/api/pusherApiSaga";
import { marketApiSaga } from "~/api/marketApiSaga";
import { dynamicTokenUriApiSaga } from "~/api/dynamicTokenUriApiSaga";
import { loadCertificateApiSaga } from "~/api/loadCertificateApiSaga";
import { mintTokenApiSaga } from "~/api/mintTokenApiSaga";
import { getTokenRightsApiSaga } from "~/api/getTokenRightsApiSaga";
import { loadTokenApiSaga } from "~/api/loadTokenApiSaga";
import { unlockableContentApiSaga } from "~/api/unlockableContentApiSaga";
import { claimTokenApiSaga } from "~/api/claimTokenApiSaga";
import { solidityContractsApiSaga } from "~/api/solidityContractsApiSaga";
import { solidityContractsReducer } from "~/features/solidityContracts/solidityContractsReducer";
import { applicationModeSaga } from "~/features/app-mode/applicationModeSaga";
import { applicationModeReducer } from "~/features/app-mode/applicationModeReducer";
import { openEditionSaga } from "~/api/openEdition/openEditionSaga";
import { openEditionReducer } from "~/features/openEdition/openEditionReducer";
import { airdropApiSaga } from "~/api/airdropApiSaga";
import { airdropReducer } from "~/features/airdrop/airdropReducer";
import { getApplicationFromLocation } from "~/api/application";
import { getCashBuyer } from "~/api/market";
import { discoverApiSaga } from "~/api/discoverApiSaga";
import { isTestnet } from "~/api/blockchain/isTestnet";
import { discoverReducer } from "~/features/discover/discoverReducer";
import { whiteListedMintedAddressesApiSaga } from "~/api/whiteListedMintedAddressesApiSaga";
import { WLMintAddressReducer } from "~/features/whitelistedMintAddresses";
import { erc721v4ApiSaga } from "~/api/erc721v4ApiSaga";
import { directMintApiSaga } from "~/api/directMintApiSaga";
import { directMintReducer } from "~/features/directMint/directMintReducer";

const reducer = {
    artwork: artworkReducer,
    buyCrypto: buyCryptoReducer,
    loadingState: loadingStateReducer,
    uploadProgress: uploadProgressReducer,
    navigationStack: navigationStackReducer,
    user: userReducer,
    snackbar: snackbarReducer,
    mint: mintReducer,
    currencyExchange: currencyExchangeReducer,
    tokenMarketData: tokenMarketDataReducer,
    purchaseFlow: purchaseFlowReducer,
    help: helpReducer,
    featureFlag: featureFLagReducer,
    dialog: dialogReducer,
    appNotifications: appNotificationsReducer,
    users: usersReducer,
    pdp: pdpReducer,
    download: downloadReducer,
    theme: themeReducer,
    trustDrop: trustDropReducer,
    remoteConfig: remoteConfigReducer,
    market: marketFeatureReducer,
    solidityContracts: solidityContractsReducer,
    applicationMode: applicationModeReducer,
    openEdition: openEditionReducer,
    airdrop: airdropReducer,
    searchBar: searchBarReducer,
    allAllowlists: allAllowListsReducer,
    allowListUser: allowListUserReducer,
    discover: discoverReducer,
    whiteListedMintAddresses: WLMintAddressReducer,
    erc721v4: erc721v4Reducer,
    directMint: directMintReducer,
    // needed for the lax-header
    ...authReducer,
};
const saga = [
    dynamicTokenUriApiSaga(),
    getTokenRightsApiSaga(),
    loadCertificateApiSaga(),
    loadTokenApiSaga(),
    mintTokenApiSaga(),
    unlockableContentApiSaga(),
    applicationApiSaga(),
    hubspotApiSaga(),
    segmentApiSaga(),
    marketApiSaga(),
    solidityContractsApiSaga(),
    pdfApiSaga(),
    pusherApiSaga(),
    remoteConfigApiSaga(),
    userApiSaga(),
    web3ApiSaga(),
    claimTokenApiSaga(),
    airdropApiSaga(),
    discoverApiSaga(),
    mintSaga(),
    userSaga(),
    artworkSaga(),
    purchaseFlowSaga(),
    dialogSaga(),
    downloadSaga(),
    appNotificationsSaga(),
    sentryTrackingSaga(),
    trustDropSaga(),
    marketFeatureSaga(),
    applicationModeSaga(),
    openEditionSaga(),
    authSaga(),
    stytchSaga(),
    searchBarRunSaga(),
    injectablesApiSaga(),
    whiteListedMintedAddressesApiSaga(),
    erc721v4ApiSaga(),
    directMintApiSaga(),
];
export type RootState = StateFromReducersMapObject<typeof reducer> & {
    injectablesWebHeader: HeaderReducerState;
    injectablesWebFooter: FooterConfig;
};

export const initStore = () => {
    const configuredStore = initWebStoreWithContext({
        reducer,
        getChainId,
        saga,
        getApplicationFromLocation,
        getCashBuyer,
        isTestnet,
    });

    configuredStore.dispatch(loadRemoteConfig());

    return configuredStore;
};

export const initTestStore = (
    preloadedState?: Partial<RootState>,
): EnhancedStore<RootState, AnyAction, Middleware[]> =>
    configureStore({
        reducer: {
            ...reducer,
            injectablesWebHeader: injectablesHeaderWebReducer,
            injectablesWebFooter: injectablesWebFooterReducer,
        },
        preloadedState,
        middleware: [],
    } as ConfigureStoreOptions);

const isTestEnv = process?.env?.NODE_ENV?.toLowerCase() === "test";

export const store = isTestEnv ? initTestStore() : initStore();

if (globalThis.window !== undefined) {
    (
        window as typeof window & {
            laxStore: Store;
        }
    ).laxStore = store;
}
