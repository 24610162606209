import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { isTestnet } from "../blockchain/isTestnet";
import { getApplicationFromHostname } from "~/WhiteLabel/host";
import { getBlockchainId } from "~/api/web3/getBlockchainId";

const applicationSensitiveParameters = {
    [SupportedApplication.artechouse]: {
        PROD_AUTHENTICATION_URL: "http://nftaccounts.artechouse.com/",
        DEV_AUTHENTICATION_URL: "https://sso-artechouse.vercel.app",
        PROD_COGNITO_USER_POOL_WEB_CLIENT_ID: "1cs7jr1gkedl5a526nnh06oamn",
        DEV_COGNITO_USER_POOL_WEB_CLIENT_ID: "1qednqlf8cjvbpooi2j26i38cd",
    },
    [SupportedApplication.artuner]: {
        PROD_AUTHENTICATION_URL: "https://accounts.artuner.com/",
        DEV_AUTHENTICATION_URL: "https://sso-artuner.vercel.app",
        PROD_COGNITO_USER_POOL_WEB_CLIENT_ID: "1cs7jr1gkedl5a526nnh06oamn",
        DEV_COGNITO_USER_POOL_WEB_CLIENT_ID: "1qednqlf8cjvbpooi2j26i38cd",
    },
    [SupportedApplication.dminti]: {
        PROD_AUTHENTICATION_URL: "https://accounts.dminti.com",
        DEV_AUTHENTICATION_URL: "https://sso-dminti.vercel.app",
        PROD_COGNITO_USER_POOL_WEB_CLIENT_ID: "1cs7jr1gkedl5a526nnh06oamn",
        DEV_COGNITO_USER_POOL_WEB_CLIENT_ID: "1qednqlf8cjvbpooi2j26i38cd",
    },
    [SupportedApplication.icamiami]: {
        PROD_AUTHENTICATION_URL: "https://accounts.icamiami.org",
        DEV_AUTHENTICATION_URL: "https://sso-icamiami.vercel.app",
        PROD_COGNITO_USER_POOL_WEB_CLIENT_ID: "1cs7jr1gkedl5a526nnh06oamn",
        DEV_COGNITO_USER_POOL_WEB_CLIENT_ID: "1qednqlf8cjvbpooi2j26i38cd",
    },
    [SupportedApplication.ephemeralEternal]: {
        PROD_AUTHENTICATION_URL: "http://nftaccounts.ephemeralethernal.com/",
        DEV_AUTHENTICATION_URL: "https://ephemeral-eternal-sso.vercel.app/",
        PROD_COGNITO_USER_POOL_WEB_CLIENT_ID: "1cs7jr1gkedl5a526nnh06oamn",
        DEV_COGNITO_USER_POOL_WEB_CLIENT_ID: "1qednqlf8cjvbpooi2j26i38cd",
    },

    // for all other
    [SupportedApplication.liveart]: {
        PROD_AUTHENTICATION_URL: "https://accounts.liveart.io/",
        DEV_AUTHENTICATION_URL: "https://accounts-dev.liveart.io/",
        PROD_COGNITO_USER_POOL_WEB_CLIENT_ID: "1cs7jr1gkedl5a526nnh06oamn",
        DEV_COGNITO_USER_POOL_WEB_CLIENT_ID: "1qednqlf8cjvbpooi2j26i38cd",
    },
};
function getApplicationParameters(application: SupportedApplication) {
    const applicationParameters = applicationSensitiveParameters[application];
    if (applicationParameters) {
        return applicationParameters;
    }

    return applicationSensitiveParameters[SupportedApplication.liveart];
}

export async function getENV() {
    const application = getApplicationFromHostname();
    const applicationParameters = getApplicationParameters(application);

    const networkId = await getBlockchainId();

    if (isTestnet(networkId)) {
        return {
            authenticationURL: applicationParameters.DEV_AUTHENTICATION_URL,
            clientId: applicationParameters.DEV_COGNITO_USER_POOL_WEB_CLIENT_ID,
            cognitoURL: process.env.DEV_COGNITO_URL,
        };
    }
    return {
        authenticationURL: applicationParameters.PROD_AUTHENTICATION_URL,
        clientId: applicationParameters.PROD_COGNITO_USER_POOL_WEB_CLIENT_ID,
        cognitoURL: process.env.PROD_COGNITO_URL,
    };
}
