export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const setLoadFileProgress = (
    fileName: string,
    fileSize: number,
    loaded: number,
    fileLoading: boolean,
) => {
    return {
        type: SET_UPLOAD_PROGRESS,
        payload: {
            fileName,
            fileSize,
            loaded,
            fileLoading,
        },
    };
};
setLoadFileProgress.type = SET_UPLOAD_PROGRESS;
