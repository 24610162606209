import { put, select, takeEvery } from "redux-saga/effects";
import { isAction } from "~/features/redux";
import { handleAppNotification } from "~/api/choreography";
import {
    appNotificationsSelector,
    AppNotificationsState,
} from "./notificationsReducer";

export function* handleAppNotificationSaga(
    action: ReturnType<typeof handleAppNotification>,
) {
    const { notificationHandlers } = (yield select(
        appNotificationsSelector,
    )) as AppNotificationsState;
    const { payload } = action;

    const { action: notificationAction = {} } = notificationHandlers
        .slice(1)
        .reduce((acc, next) => {
            if (acc.notification) {
                return next(acc.notification);
            }
            return acc;
        }, notificationHandlers[0](payload));

    if (isAction(notificationAction)) {
        yield put(notificationAction);
    }
}

export function* appNotificationsSaga() {
    yield takeEvery(
        handleAppNotification.toString(),
        handleAppNotificationSaga,
    );
}
