import memoize from "lodash/memoize";
import { call, takeLatest } from "redux-saga/effects";
import { claimToken } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721/claimTokenSaga"),
    () => 1,
);

export function* claimTokenApiSaga() {
    yield takeLatest(
        claimToken.toString(),
        function* withClaimTokenSaga(action: ReturnType<typeof claimToken>) {
            const { claimTokenSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(claimTokenSaga, action);
        },
    );
}
