export const colors = {
    // primary
    black: "#000000",
    black80: "rgb(0 0 0 / 0.80)",
    black70: "rgb(0 0 0 / 0.70)",
    black50: "rgb(0 0 0 / 0.50)",
    black40: "rgb(0 0 0 / 0.40)",
    black30: "rgb(0 0 0 / 0.30)",
    black20: "rgb(0 0 0 / 0.30)",
    black15: "rgb(0 0 0 / 0.15)",
    black12: "rgb(0 0 0 / 0.12)",
    black10: "rgb(0 0 0 / 0.10)",
    black08: "rgb(0 0 0 / 0.08)",
    black05: "rgb(0 0 0 / 0.05)",
    black03: "rgb(0 0 0 / 0.03)",
    blackNeutral: "#222222",

    textBlack: "#222222",
    textBlackHover: "#555555",

    textGrey: "#777787",
    textLightGray: "#666666",
    darkGrey: "#2d2d2d",

    availableGreen: "#36AF75",

    soldRed: "#FF1F00",

    bodyGrey: "#8e8e8e",
    lightGrey: "#c5c5c5",

    borderGrey: "#E1E1E6",
    backgroundGrey: "#f6f6f6",
    innerBorder: "#CCCCCC",

    cardGrey: "#e9e9e9",
    sectionColor: "#F8F8FA",

    white: "#FFFFFF",
    white10: "rgba(255, 255, 255, 0.10)",
    white15: "rgba(255, 255, 255, 0.10)",
    white40: "rgba(255, 255, 255, 0.40)",
    white50: "rgba(255, 255, 255, 0.50)",
    white70: "rgba(255, 255, 255, 0.70)",
    white80: "rgba(255, 255, 255, 0.70)",
    white90: "rgba(255, 255, 255, 0.90)",

    notificationRed: "#D51B1B",
    soldBadge: "#EA1D27",

    tabsGrey: "#C4C4C4",

    checkIconGrey: "#757575",

    auctionTimerRed: "#E97370",

    mintHeaderInactiveText: "#00000066",

    redDark: "#D81A00",
    red05: "rgba(255, 31, 0, 0.05);",

    cornFlowerBlue: "#262626",
    malachite: "#1DF359",
    aquamarine: "#51FFFF",
    seance: "#861ABA",
    darkBlue: "#0D00C4",
    webOrange: "#FFA800",
    shockingPink: "#F40DDE",
    dodgerBlue: "#1a90ff",
    tundora: "#4d4d4d",
    quillGrey: "#d4d4d4",

    pink: "#FF5893",
    purple: "#6E62C3",
    pinkBackground: "#F1E3E1",

    nobel: "#B3B3B3",
    blue: "#223A86",

    linkPink: "#FF24FF",
};

export default { colors };
