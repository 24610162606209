import Snackbar from "@mui/material/Snackbar";
import { FormControl, OutlinedInput, NativeSelect } from "@mui/material";
import React, { useCallback } from "react";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { Box } from "@mui/system";
import { setManuallySelectedApplication } from "@liveart/injectables/dist/WhiteLabel/manuallySelectedApplication";
import { getApplicationFromHostname } from "~/WhiteLabel/host";
import { useFeature } from "~/features/featureFlag";

export function WlSelect() {
    const showWlSelectEnabled = Boolean(useFeature("showWlSelect"));

    const handleChange = useCallback((event) => {
        setManuallySelectedApplication(event.target.value);
        globalThis?.window?.location?.reload?.();
    }, []);

    return showWlSelectEnabled ? (
        <Snackbar open>
            <Box
                sx={{
                    backgroundColor: "rgb(2, 136, 209)",
                }}
            >
                <>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <NativeSelect
                            data-test-id="wl-select"
                            id="wl-select"
                            value={getApplicationFromHostname()}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                        >
                            {Object.values(SupportedApplication).map((app) => (
                                <option key={app} value={app}>
                                    {app}
                                </option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                </>
            </Box>
        </Snackbar>
    ) : null;
}
