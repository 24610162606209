import { CreatedPaymentIntent } from "~/api/data-schema";
import { fetchWithAuthHeaders } from "~/api/fetchWithHeaders/fetchWithAuthHeaders";

export async function captureFunds(
    {
        networkId,
        paymentIntent,
    }: { paymentIntent: CreatedPaymentIntent; networkId: number },
    retry = 0,
) {
    const resp = await fetchWithAuthHeaders(
        "/api/cash-purchase/capture-funds",
        {
            method: "POST",
            body: JSON.stringify({
                paymentIntent,
                networkId,
            }),
        },
    );

    if (resp.status >= 500 && retry <= 3) {
        return captureFunds({ networkId, paymentIntent }, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return resp.json();
}
