import { createReducer } from "@reduxjs/toolkit";
import { MetadataJSONSchema } from "~/api/metadata";
import { WhiteList } from "~/api/data-schema/WhiteListedMintAddresses";
import { SolidityContractDetails } from "~/api/data-schema/SolidityContractDetails";
import {
    loadDirectMintContractResponse,
    loadDirectMintDetailsResponse,
    loadDirectMintWhiteListsResponse,
    walletDetails,
    directMintUserMintCountResponse,
    updateMintStatus,
    mintedTokenIdResponse,
    loadActiveMintDetailsResponse,
    loadTokenMintStagesResponse,
} from "~/api/choreography";
import {
    DirectMintState,
    DirectMintEditionDetials,
    WalletStates,
    WalletDetails,
    DirectMintStates,
    DirectMintActiveMintDetails,
} from "~/api/data-schema";

export const initialDirectMintState: DirectMintState = {
    contract: {} as SolidityContractDetails,
    edition: {} as DirectMintEditionDetials,
    metadata: {} as MetadataJSONSchema,
    whitelists: [] as WhiteList[],
    activeMintDetails: {} as DirectMintActiveMintDetails,
    selectedWallet: {
        walletName: "",
        walletState: WalletStates.CONNECTED_WALLET,
    } as WalletDetails,
    userMintCount: 0,
    mintState: DirectMintStates.NO_PUBLIC_MINT_END_DATE,
    mintedTokenId: "",
    winterProject: undefined,
    totalMintStages: 0,
};

export const directMintReducer = createReducer(
    initialDirectMintState,
    (builder) =>
        builder
            .addCase(
                loadDirectMintContractResponse,
                (state, { payload: contract }) => ({
                    ...state,
                    contract,
                }),
            )
            .addCase(
                loadDirectMintDetailsResponse,
                (state, { payload: { edition, metadata, winterProject } }) => {
                    return {
                        ...state,
                        edition: {
                            ...state.edition,
                            ...edition,
                        },
                        metadata: {
                            ...state.metadata,
                            ...metadata,
                        },
                        winterProject,
                    };
                },
            )
            .addCase(
                loadDirectMintWhiteListsResponse,
                (state, { payload }) => ({
                    ...state,
                    whitelists: payload,
                }),
            )
            .addCase(walletDetails, (state, { payload: walletInfo }) => ({
                ...state,
                selectedWallet: {
                    ...state.selectedWallet,
                    ...walletInfo,
                },
            }))
            .addCase(
                directMintUserMintCountResponse,
                (state, { payload: userMintCount }) => ({
                    ...state,
                    userMintCount,
                }),
            )
            .addCase(updateMintStatus, (state, { payload: mintState }) => ({
                ...state,
                mintState,
            }))
            .addCase(
                mintedTokenIdResponse,
                (state, { payload: mintedTokenId }) => ({
                    ...state,
                    mintedTokenId,
                }),
            )
            .addCase(
                loadActiveMintDetailsResponse,
                (state, { payload: activeMintDetails }) => {
                    return {
                        ...state,
                        activeMintDetails: {
                            ...state.activeMintDetails,
                            ...activeMintDetails,
                        },
                    };
                },
            )
            .addCase(
                loadTokenMintStagesResponse,
                (state, { payload: totalMintStages }) => ({
                    ...state,
                    totalMintStages,
                }),
            ),
);

export const directMintSelector = (state: { directMint: DirectMintState }) =>
    state.directMint;
