import dynamic from "next/dynamic";

export * from "./tokenMarketDataReducer";
export * from "./useTokenMarketData";
export * from "./useAuctionData";

export const TokenLockSubscription = dynamic(() =>
    import("./TokenLockSubscription").then(
        (module) => module.TokenLockSubscription,
    ),
);
