import LogRocket from "logrocket";
import React, { useEffect } from "react";

import { useCognitoUser } from "~/features/user/useCognitoUser";

export function LogRocketComponent() {
    const currentUser = useCognitoUser();

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_LOGROCKET_APP_ID)
            LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID, {
                network: {
                    requestSanitizer: (request) => {
                        request.headers.authorization = undefined;
                        return request;
                    },
                },
            });
    }, []);

    useEffect(() => {
        if (
            process.env.NEXT_PUBLIC_LOGROCKET_APP_ID &&
            currentUser?.externalId
        ) {
            LogRocket.identify(currentUser.externalId, {
                name: currentUser.firstName as string,
                lastName: currentUser.lastName as string,
                email: currentUser.email as string,
            });
        }
    }, [currentUser]);
    return <></>;
}
