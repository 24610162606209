import { createAction, createReducer } from "@reduxjs/toolkit";
import unionBy from "lodash/unionBy";
import {
    getTokenRights,
    getTokenRightsResponse,
    getCertificateURI,
    loadTokenResponse,
    setTokensLoaded,
    loadTokensResponse,
    loadToken,
    loadTokens,
} from "~/api/choreography";
import type { Policy, Token } from "~/api/data-schema";

export type ArtworkState = {
    contractAddress?: string;
    tokens: Token[];
    tokensByChainId: {
        [chainId: number]: Token[];
    };
    tokenRights: {
        [tokenId: number]: Policy[];
    };
    tokenId: number;
    certificateURI?: string;
    tokenData: {
        tokenId: number;
        blockchainId: number;
        tokenContractAddress: string;
    };
    loaded?: boolean;
};
export type WithArtworkState = {
    artwork: ArtworkState;
};
export const initialArtworkState: ArtworkState = {
    tokens: [],
    tokenRights: {},
    tokenId: NaN,
    tokensByChainId: {},
    tokenData: {
        tokenId: NaN,
        blockchainId: NaN,
        tokenContractAddress: "",
    },
    loaded: false,
};

export const setTokenParams = createAction<ArtworkState["tokenData"]>(
    "ARTWORK/SET_TOKEN_DATA",
);

export const artworkReducer = createReducer(initialArtworkState, (builder) =>
    builder
        .addCase(setTokenParams, (state, { payload }) => ({
            ...state,
            tokenData: payload,
        }))
        .addCase(loadToken, (state, { payload: { tokenId } }) => ({
            ...state,
            tokenId,
        }))
        .addCase(loadTokens, (state, { payload }) => {
            if ((payload?.offset && payload?.offset > 1) || !payload?.reset) {
                return state;
            }

            return {
                ...state,
                tokenId: NaN,
                tokens: [],
                tokensByChainId: {
                    ...state.tokensByChainId,
                    [payload.networkId]: [],
                },
            };
        })
        .addCase(
            loadTokensResponse,
            (state, { payload: { networkId, tokens } }) => {
                const data: Token[] = tokens;

                return {
                    ...state,
                    tokens: unionBy(state.tokens, data, "tokenURI"),
                    tokensByChainId: {
                        ...state.tokensByChainId,
                        [networkId]: data,
                    },
                };
            },
        )
        .addCase(loadTokenResponse, (state, { payload }) => {
            return {
                ...state,
                tokens: [payload],
            };
        })
        .addCase(setTokensLoaded, (state, { payload }) => {
            return {
                ...state,
                loaded: payload.loaded,
            };
        })
        .addCase(getTokenRightsResponse, (state, { payload }) => {
            return {
                ...state,
                tokenRights: {
                    ...state.tokenRights,
                    [payload.tokenId]: payload.rights,
                },
                contractAddress: payload.contractAddress,
            };
        })
        .addCase(getCertificateURI, (state, { payload }) => {
            return {
                ...state,
                certificateURI: payload,
            };
        })
        .addCase(getTokenRights, (state, { payload }) => {
            return {
                ...state,
                tokenId: payload,
            };
        }),
);

export const artworkStateSelector = (state: WithArtworkState) =>
    state?.artwork || initialArtworkState;
