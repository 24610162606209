import { createAction, createReducer } from "@reduxjs/toolkit";
import { Token } from "~/api/data-schema";

export type MarketState = {
    batchListTokens: Token[];
};
export const initialMarketState: MarketState = {
    batchListTokens: [],
};

export const setBatchListTokens = createAction<Token[]>(
    "FEATURE/MARKET/SET_BATCH_LIST_TOKENS",
);

export const marketFeatureReducer = createReducer(
    initialMarketState,
    (builder) =>
        builder.addCase(setBatchListTokens, (state, { payload }) => ({
            ...state,
            batchListTokens: payload,
        })),
);

export const marketFeatureStateSelector = (state) => state.market;
