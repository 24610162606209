import { createReducer } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";
import {
    buyNowComplete,
    loadToken,
    loadTokenMarketDataResponse,
    newAuctionBidFromSubscription,
} from "~/api/choreography";
import {
    BasicAuction,
    FeBid,
    LoadTokenMarketDataResponsePayload,
} from "~/api/data-schema";

export type TokenMarketDataState = LoadTokenMarketDataResponsePayload;
export type WithTokenMarketData = {
    tokenMarketData: TokenMarketDataState;
};

export const initialTokenMarketData: TokenMarketDataState = {};

export const tokenMarketDataReducer = createReducer(
    initialTokenMarketData,
    (builder) =>
        builder
            .addCase(buyNowComplete, () => initialTokenMarketData)
            .addCase(loadToken, () => initialTokenMarketData)
            .addCase(
                loadTokenMarketDataResponse,
                (
                    state: TokenMarketDataState,
                    { payload },
                ): TokenMarketDataState => ({
                    ...state,
                    ...payload,
                }),
            )
            .addCase(
                newAuctionBidFromSubscription,
                (
                    state: TokenMarketDataState,
                    { payload: { auctionId, tokenId, bid } },
                ): TokenMarketDataState => {
                    if (state[tokenId]?.auctions?.length) {
                        return {
                            ...state,
                            [tokenId]: {
                                ...state[tokenId],
                                auctions: state[tokenId].auctions.map((el) => {
                                    if (+el.auctionId === +auctionId) {
                                        return {
                                            ...el,
                                            bids: uniqBy<FeBid>(
                                                [bid].concat(el.bids),
                                                "bidAmount",
                                            ),
                                        };
                                    }

                                    return el;
                                }) as BasicAuction[],
                            },
                        };
                    }
                    return state;
                },
            ),
);

export const tokenMarketDataSelector = (state: WithTokenMarketData) =>
    state.tokenMarketData;
