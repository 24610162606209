import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { getApplicationFromQueryParams } from "~/WhiteLabel/host";
import { defaultApplicationKey } from "./applicationSaga";
import { pageContextSessionStorage } from "~/api/pageContextSessionStorage";

export function getPDPPageApplication() {
    /**
     * if application query param is not present,
     * old link is open. (probably some old page on liveart.market)
     */
    return (
        getApplicationFromQueryParams() ||
        pageContextSessionStorage.getItem(defaultApplicationKey) ||
        SupportedApplication.liveartProprietary
    );
}
