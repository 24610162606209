const domain = [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
];
const basicOperation = [{ name: "timestamp", type: "uint256" }];

const domainData = {
    name: "LiveArt",
    version: "1",
};

function splitSignature(signature: string) {
    const r = `0x${signature.substring(0, 64)}`;
    const s = `0x${signature.substring(64, 128)}`;
    const v = parseInt(signature.substring(128, 130), 16);

    return { r, s, v };
}

export async function signMessage(
    signer: string,
    timestamp: number,
    getProvider,
): Promise<ReturnType<typeof splitSignature>> {
    const { getMetamaskProvider } = await import(
        "@liveart/nft-client/dist/metamask"
    );
    if (!getProvider) {
        // eslint-disable-next-line
        getProvider = getMetamaskProvider;
    }

    const message = {
        timestamp,
    };
    const data = JSON.stringify({
        types: {
            EIP712Domain: domain,
            BasicOperation: basicOperation,
        },
        domain: domainData,
        primaryType: "BasicOperation",
        message,
    });

    const provider = await getProvider();
    if (provider) {
        const result = (await provider.request({
            method: "eth_signTypedData_v4",
            params: [signer, data],
            from: signer,
        })) as string;

        return splitSignature(result.substring(2));
    }
    throw new Error("can't get provider to sign message");
}
