import { createAction } from "@reduxjs/toolkit";
import { Permission } from "~/api/data-schema";

export type TokenPermissionsState = {
    permissions: Permission[];
    firstOwner?: string;
};
export const initialTokenPermissionsState: TokenPermissionsState = {
    permissions: [],
};

export const setPermissions = createAction<Permission[]>(
    "MINTING/SET_PERMISSIONS",
);
export const setPermissionsReducer = (
    state: TokenPermissionsState,
    { payload }: ReturnType<typeof setPermissions>,
): TokenPermissionsState => ({
    ...state,
    permissions: payload,
});

export const addPermission = createAction<Permission>("MINTING/ADD_PERMISSION");
export const addPermissionReducer = (
    state: TokenPermissionsState,
    { payload }: ReturnType<typeof addPermission>,
): TokenPermissionsState => {
    return {
        ...state,
        permissions: state.permissions
            .filter(
                (el) =>
                    !(el.wallet === payload.wallet && el.role === payload.role),
            )
            .concat(payload),
    };
};

export const removePermission = createAction<Permission>(
    "MINTING/REMOVE_PERMISSION",
);
export const removePermissionReducer = (
    state: TokenPermissionsState,
    { payload }: { payload: Permission },
): TokenPermissionsState => ({
    ...state,
    permissions: state.permissions.filter((el) => el.wallet !== payload.wallet),
});

export const setFirstOwner = createAction<string>("MINTING/SET_FIRST_OWNER");
export const setFirstOwnerReducer = (
    state: TokenPermissionsState,
    { payload: firstOwner }: ReturnType<typeof setFirstOwner>,
): TokenPermissionsState => ({
    ...state,
    firstOwner,
});

export const tokenPermissionsSelector = ({
    mint,
}: {
    mint: TokenPermissionsState;
}) => mint;
