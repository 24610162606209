import { createAction } from "@reduxjs/toolkit";

export enum MintingSteps {
    SELECT_TOKEN = "SELECT_TOKEN",
    UPLOAD_FILES = "UPLOAD_FILES",
    ARTWORK_DETAILS = "ARTWORK_DETAILS",
    TOKEN_RIGHTS_AND_ROYALTIES = "TOKEN_RIGHTS_AND_ROYALTIES",
    PREVIEW_AND_MINT = "PREVIEW_AND_MINT",
    MINTING_STEPS = "MINTING_STEPS",
    MINTING_SUCCESS = "MINTING_SUCCESS",
    MINTING_VIEW_ARTWORK = "MINTING_VIEW_ARTWORK",
}
export type MintingWizardState = {
    mintingFormStep: MintingSteps;
};
export const initialMintingWizardState: MintingWizardState = {
    mintingFormStep: MintingSteps.SELECT_TOKEN,
};

export const setMintingStep = createAction<MintingSteps>(
    "MINTING/SET_MINTING_STEP",
);
export const mintingWizardReducer = (
    state: MintingWizardState,
    { payload }: ReturnType<typeof setMintingStep>,
): MintingWizardState => ({
    ...state,
    mintingFormStep: payload,
});

export const mintingStepSelector = (state: { mint: MintingWizardState }) =>
    state.mint.mintingFormStep;
