import React, { useEffect, useMemo, useContext } from "react";
import type { AppProps } from "next/app";
import { Provider, useDispatch, useSelector } from "react-redux";
import { CssBaseline, NoSsr, Box, useMediaQuery } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useRouter } from "next/router";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Web3Provider } from "@liveart/injectables/dist/auth/web3connectors/Web3Provider";
import { getApplicationFromHostname } from "@liveart/injectables/dist/WhiteLabel/host";
import { SupportedApplication } from "@liveart/injectables/dist/dataSchema/whiteLabel";
import { MuiPickersAdapter } from "@mui/x-date-pickers/internals/models";
import { store } from "~/store";
import { ProgramNavigator } from "~/features/navigationStack";
import { Notifier } from "~/features/snackbar";
import { BuyCryptoDialog } from "~/features/buy-crypto";
import { PageComponent } from "./PageComponent";
import {
    FaviconByApplication,
    MetaTagsByApplication,
} from "~/WhiteLabel/features";
import { TokenLockSubscription } from "~/features/tokenMarketData";
import { UncaughtExceptionErrorReporter } from "~/features/errorTracking/UncaughtExceptionErrorReporter";
import { AppVersion } from "~/features/app/AppVersion";
import { LogRocketComponent } from "~/features/logrocket";
import { applicationModeSelector } from "~/features/app-mode/applicationModeReducer";
import { getApplicationModeFromStorage } from "~/features/app-mode/applicationLocalStorage";
import { setApplicationSagaAction } from "~/features/app-mode/applicationModeActions";
import { AppErrorBoundary } from "~/features/app/AppErrorBoundary";
import { Mui5ThemeProvider } from "~/features/muiThemeProvider/Mui5ThemeProvider";
import { HostContext } from "~/features/app/hostContext";
import {
    webComponentsList,
    webComponentsListWithDependencies,
} from "~/WebComponents/webComponentsList";
import { SupportedApplicationHeader } from "~/features/header/SupportedApplicationHeader";
import { getFeatureFlagFromStorage } from "~/api/featureFLag";
import { SupportedApplicationFooter } from "~/features/footer/SupportedApplicationFooter";
import { StytchProvider } from "~/features/stytch/StytchProvider";
import { Web3UserDispatcher } from "~/features/user/Web3UserDispatcher";
import { WlSelect } from "~/features/app/WlSelect";
import { useFeature } from "~/features/featureFlag";
import { WlHead } from "~/WhiteLabel/features/wlHead/WlHead";

function DefaultFooterWrapper({
    Footer: FooterComponent,
}: {
    Footer: React.FC<unknown>;
}) {
    return <FooterComponent />;
}

function DefaultHeaderWrapper({ Header: HeaderComponent }) {
    return (
        <Box>
            <HeaderComponent />
        </Box>
    );
}
function DefaultPageWrapper({ Page }) {
    const isDesktop = useMediaQuery("(min-width:1201px)");
    return (
        <Box
            component="main"
            style={{
                minHeight: "100vh",
                paddingTop: isDesktop ? "40px" : "16px",
            }}
        >
            <Page />
        </Box>
    );
}

function ApplicationModeWrapper({ children }) {
    const { application: appQS } = useRouter().query;

    const dispatch = useDispatch();
    const { application } = useSelector(applicationModeSelector);

    useEffect(() => {
        const appInStorage = getApplicationModeFromStorage();
        if (!application && !appQS && appInStorage) {
            dispatch(
                setApplicationSagaAction(appInStorage as SupportedApplication),
            );
        }
    }, [dispatch, application, appQS]);

    useEffect(() => {
        if (appQS)
            dispatch(setApplicationSagaAction(appQS as SupportedApplication));
    }, [appQS, dispatch]);

    return <>{children}</>;
}

export const HeavyApp = React.memo(({ Component, pageProps }: AppProps) => {
    const noWebComponents = getFeatureFlagFromStorage("noWebComponents");
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles && jssStyles.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);
    const value = useContext(HostContext);
    const cache = useMemo(() => {
        const emotionRoot =
            globalThis?.window?.document?.createElement?.("style");
        globalThis?.window?.document?.head?.appendChild(emotionRoot);

        return createCache({
            key: `heavyapp${Array.from(Date.now().toString())
                .map((char) => String.fromCharCode(+char + 80))
                .join("")
                .toLowerCase()}`,
            prepend: true,
            container: emotionRoot,
            nonce: Date.now().toString(),
        });
    }, []);

    const FooterWrapper =
        (Component as PageComponent).footerWrapper || DefaultFooterWrapper;

    const HeaderWrapper =
        (Component as PageComponent).headerWrapper || DefaultHeaderWrapper;

    const PageWrapper =
        (Component as PageComponent).pageWrapper || DefaultPageWrapper;

    return (
        <div>
            <Web3Provider>
                <StytchProvider store={store}>
                    <Provider store={store}>
                        <WlHead />

                        <AppErrorBoundary>
                            <Web3UserDispatcher />
                            <CacheProvider value={cache}>
                                <Mui5ThemeProvider>
                                    <CssBaseline />
                                    <MetaTagsByApplication />
                                    <FaviconByApplication />
                                    <LogRocketComponent />
                                    <NoSsr>
                                        <HeaderWrapper
                                            // eslint-disable-next-line react/jsx-no-bind
                                            Header={function HeaderComponent() {
                                                const showLiveArtHeaderEnabled =
                                                    Boolean(
                                                        useFeature(
                                                            "showLiveArtHeader",
                                                        ),
                                                    );
                                                const headerApp =
                                                    showLiveArtHeaderEnabled
                                                        ? SupportedApplication.liveart
                                                        : getApplicationFromHostname(
                                                              value,
                                                          );

                                                return noWebComponents ? (
                                                    <SupportedApplicationHeader
                                                        application={headerApp}
                                                    />
                                                ) : (
                                                    <lax-header
                                                        key="lax-header"
                                                        application={headerApp}
                                                    />
                                                );
                                            }}
                                        />
                                    </NoSsr>
                                    <PageWrapper
                                        Page={() => (
                                            <ApplicationModeWrapper>
                                                <LocalizationProvider
                                                    dateAdapter={
                                                        AdapterDateFns as unknown as new (
                                                            ...args: unknown[]
                                                        ) => MuiPickersAdapter<unknown>
                                                    }
                                                >
                                                    <Component {...pageProps} />
                                                </LocalizationProvider>
                                            </ApplicationModeWrapper>
                                        )}
                                    />
                                    <ProgramNavigator />
                                    <SnackbarProvider>
                                        <Notifier />
                                    </SnackbarProvider>
                                    <BuyCryptoDialog />
                                    <TokenLockSubscription />
                                    <UncaughtExceptionErrorReporter />
                                    <AppVersion />
                                    <WlSelect />

                                    <footer>
                                        <NoSsr>
                                            <FooterWrapper
                                                Footer={() => {
                                                    return noWebComponents ? (
                                                        <SupportedApplicationFooter
                                                            application={getApplicationFromHostname()}
                                                        />
                                                    ) : (
                                                        <lax-footer
                                                            application={getApplicationFromHostname()}
                                                        />
                                                    );
                                                }}
                                            />
                                        </NoSsr>
                                    </footer>

                                    <script
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                    var loaded = {}
                                    var components = ${JSON.stringify(
                                        webComponentsList,
                                        null,
                                        4,
                                    )}
                                    var componentsWithDependencies = ${JSON.stringify(
                                        webComponentsListWithDependencies,
                                        null,
                                        4,
                                    )}

                                    function insertLaxComponents() {
                                        components.forEach(el => {
                                            var elements = document.querySelectorAll(el);
                                            if (elements.length > 0) {
                                                if (!loaded[el]) {
                                                    if (Boolean(window.laxStore)) {
                                                        componentsWithDependencies[el].forEach(window.loadLaxComponentByName)
                                                        loaded[el] = true;
                                                    } else {
                                                        setTimeout(insertLaxComponents, 200)
                                                    }
                                                }
                                            }
                                        })
                                    }

                                    var targetNode = document.querySelector('body')
                                    var config = { attributes: true, childList: true, subtree: true }
                                    var observer = new MutationObserver(insertLaxComponents)
                                    observer.observe(targetNode, config)
                                `,
                                        }}
                                    />
                                </Mui5ThemeProvider>
                            </CacheProvider>
                        </AppErrorBoundary>
                    </Provider>
                </StytchProvider>
            </Web3Provider>
        </div>
    );
});
