import { createReducer } from "@reduxjs/toolkit";
import { SupportedApplication } from "@/../nft-client/dist/whiteLabel";
import { setApplicationAction } from "./applicationModeActions";

export type ApplicationState = {
    application: SupportedApplication | "";
};

export const applicationModeInitialState: ApplicationState = {
    application: "",
};

export const applicationModeReducer = createReducer(
    applicationModeInitialState,
    (builder) =>
        builder.addCase(
            setApplicationAction,
            (_: ApplicationState, { payload }): ApplicationState => ({
                application: payload,
            }),
        ),
);

export const applicationModeSelector = (state: {
    applicationMode: ApplicationState;
}) => state.applicationMode;
