import { RawMintTokenData } from "~/api/data-schema";
import { prepareMetadata } from "./prepareMetadata";

export function rawMintTokenDataToMetadata(
    {
        files,
        properties: additionalProperties,
        tokenRights: rights,
        royaltyReceivers,
        maxTokenSupply: editions,
        openEdition,
        AP,
        tokenType,
        buyoutPrice,
        unlockableContent,
        knownProperties,
        selectedContract,
        ...formProperties
    }: RawMintTokenData,
    mediaFileURL: string,
    previewFileURL: string | undefined,
    mediaPhygitalFileURL: string[],
) {
    return prepareMetadata({
        mediaFile: files.highResFile,
        mediaFileURL,
        previewFile: files.previewFile,
        previewFileURL,
        mediaPhygitalFileURL,
        additionalProperties,
        formProperties: {
            ...formProperties,
            phygitalData: knownProperties.phygitalData,
            ...Object.entries(knownProperties).reduce((acc, entry) => {
                return {
                    ...acc,
                    [entry[0]]: entry[1],
                };
            }, {}),
        },
        AP,
        tokenType,
        externalUrl: knownProperties.artworkFactSheet,
        selectedContract,
    });
}
