import { call, put } from "redux-saga/effects";
import { getChainId } from "@liveart/nft-client/dist/web3";
import { getMetamaskProvider } from "@liveart/nft-client/dist/metamask";
import { providers } from "ethers";
import { attachWallet, getCognitoUser } from "~/api/choreography";
import { query } from "~/api/graphQL";
import { getAccessToken } from "./getAccessToken";
import { AttachWalletGetChallengeMutation } from "~/api/user/AttachWalletGetChallengeMutation";
import { AttachWalletMutation } from "~/api/user/AttachWalletMutation";
import { getCurrentCognitoUser } from "~/api/user/getCognitoUser";
import { Unpromisify } from "~/typescript";

function* getChallengeSaga({
    walletAddress,
    walletType,
}: {
    walletAddress: string;
    walletType: string;
}) {
    const chainId = yield call(getChainId);
    const accessToken = yield call(getAccessToken);

    try {
        const rawData = (yield call(async () =>
            query({
                networkId: chainId,
                queryStr: AttachWalletGetChallengeMutation,
                accessToken,
                variables: {
                    walletAddress,
                    walletType,
                },
            }),
        )) as {
            data: {
                attachWalletGetChallenge: {
                    challenge: string;
                };
            };
        };

        return rawData?.data?.attachWalletGetChallenge?.challenge;
    } catch (error) {
        console.error(error);
    }

    return undefined;
}

export function* attachWalletSaga(action: ReturnType<typeof attachWallet>) {
    try {
        const { walletAddress } = action.payload;
        const chainId = yield call(getChainId);
        const accessToken = yield call(getAccessToken);
        const currentUser = (yield call(async () =>
            getCurrentCognitoUser(accessToken, chainId),
        )) as Unpromisify<ReturnType<typeof getCurrentCognitoUser>>;
        const walletConnected = currentUser?.wallets?.find(
            (wallet) =>
                wallet?.wallet?.toLowerCase() === walletAddress?.toLowerCase(),
        );
        if (walletConnected) return;

        const challenge = yield call(getChallengeSaga, action.payload);

        if (challenge) {
            const connectorProvider = yield call(getMetamaskProvider);
            const provider = new providers.Web3Provider(
                connectorProvider as providers.ExternalProvider,
            );
            const signer = provider.getSigner();
            const signedMessage = yield call(() =>
                signer.signMessage(challenge),
            );

            yield call(async () =>
                query({
                    networkId: chainId,
                    queryStr: AttachWalletMutation,
                    accessToken,
                    variables: {
                        signature: signedMessage,
                        ...action.payload,
                    },
                }),
            );

            yield put(getCognitoUser());
        }
    } catch (error) {
        console.error(error);
    }
}
