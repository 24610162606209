import { Notification } from "~/api/data-schema";
import { enqueueSnackbar } from "~/features/snackbar";
import { normalizeMessage } from "./normalizeMessage";

const targetMessage = normalizeMessage(
    "Request of type 'wallet_requestPermissions' already pending",
);

export function authenticationRequestIsAlreadyPendingHandler(
    notification: Notification,
) {
    const { message } = notification;
    if (normalizeMessage(message).includes(targetMessage)) {
        return {
            action: enqueueSnackbar({
                ...notification,
                message:
                    "Authentication request is pending. Please open MetaMask extension and accept it",
            }),
        };
    }

    return {
        notification,
    };
}
