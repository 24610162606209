import { BigNumber } from "@liveart/nft-client/dist/bignumber";
import {
    ROLE_CREATOR,
    ROLE_CURATOR,
    ROLE_PUBLISHER,
} from "@liveart/rights-management";

export interface Permission {
    role: string;
    wallet: string | BigNumber;
}

export type Policy = {
    action: string;
    target: number | BigNumber | string;
    permission: Permission;
};

export const RolesDisplayMap = {
    [ROLE_PUBLISHER]: "Publisher",
    [ROLE_CREATOR]: "Creator",
    [ROLE_CURATOR]: "Curator",
};
