import { createAction } from "@reduxjs/toolkit";
import {
    WhiteList,
    OperationDetails,
    UserSelectionDetails,
    WLMintAddressesStates,
    WLMintOperationStates,
    WLMintConfigurationStates,
    WLState,
    WLCardSettingsConfiguration,
    WLContractValidation,
} from "~/api/data-schema/WhiteListedMintAddresses";

export const loadEditionsForSelectedContract = createAction<{
    contractAddress: string;
}>("API/WL_MINTED_ADDRESSES/VALIDATE_SELECTED_CONTRACT");

export const validateSelectedContractResponse = createAction<
    Partial<WLContractValidation>
>("API/WL_MINTED_ADDRESSES/VALIDATE_SELECTED_CONTRACT_RESPONSE");

export const setCurrentWhiteListedStep = createAction<WLMintAddressesStates>(
    "API/WL_MINTED_ADDRESSES/SET_CURRENT_STEP",
);

export const setCurrentWhiteListOperation = createAction<WLMintOperationStates>(
    "API/WL_MINTED_ADDRESSES/SET_CURRENT_OPERATION",
);

export const setCurrentWhiteListConfiguration =
    createAction<WLMintConfigurationStates>(
        "API/WL_MINTED_ADDRESSES/SET_CURRENT_CONFIGURATION",
    );

export const setContractLoaded = createAction<boolean>(
    "API/WL_MINTED_ADDRESSES/SET_CONTRACT_LOADED",
);

export const resetWhiteListedState = createAction<Partial<WLState>>(
    "API/WL_MINTED_ADDRESSES/RESET_STATE",
);

export const loadAllWhiteLists = createAction<{
    chainId: string;
    contractAddress: string;
    editionId: string;
    userSelection?: UserSelectionDetails;
    whitelistHasBeenDeleted?: boolean;
}>("API/WL_MINTED_ADDRESSES/LOAD_ALL_WHITELISTS");

export const loadWhiteListedMintAddressesResponse = createAction<WhiteList[]>(
    "API/WL_MINTED_ADDRESSES/LOAD_WL_MINTED_ADDRESSES_RESPONSE",
);

export const updateOperationDetails = createAction<Partial<OperationDetails>>(
    "API/WL_MINTED_ADDRESSES/UPDATE_OPERATION_DETAILS",
);

export const userSelectedDetails = createAction<Partial<UserSelectionDetails>>(
    "API/WL_MINTED_ADDRESSES/USER_SELECTED_DETAILS",
);

export const createWhiteList = createAction<{
    userSelection: UserSelectionDetails;
    settings: WLCardSettingsConfiguration;
}>("API/WL_MINTED_ADDRESSES/CREATE_NEW_WHITELIST");

export const deleteSelectedWhiteList = createAction<{
    userSelection: UserSelectionDetails;
    whitelists: WhiteList[];
    settings: WLCardSettingsConfiguration;
}>("API/WL_MINTED_ADDRESSES/DELETE_SELECTED_WHITELIST");

export const addWalletToWhiteList = createAction<{
    wallets: string[];
    userSelection: UserSelectionDetails;
    whitelists: WhiteList[];
}>("API/WL_MINTED_ADDRESSES/ADD_WALLET_TO_SELECTED_WHITELIST");

export const currentLoadedEditionsByContractResponse = createAction<number>(
    "API/WL_MINTED_ADDRESSES/CURRENT_LOADED_EDITIONS_BY_CONTRACT_RESPONSE",
);

export const totalEditionsByContractResponse = createAction<number>(
    "API/WL_MINTED_ADDRESSES/TOTAL_EDITIONS_BY_CONTRACT",
);

export const getWalletsByWhiteListId = createAction<string>(
    "API/WL_MINTED_ADDRESSES/GET_WALLETS_BY_WHITELIST_ID",
);

export const getWalletsByWhiteListIdResponse = createAction<string[]>(
    "API/WL_MINTED_ADDRESSES/GET_WALLETS_BY_WHITELIST_ID_RESPONSE",
);

export const batchAddWalletsToWhiteList = createAction<{
    uploadedFile: File;
    userSelection: UserSelectionDetails;
    whitelists: WhiteList[];
}>("API/WL_MINTED_ADDRESSES/BATCH_ADD_ADDRESSES_TO_WHITELIST");

export const deleteSelectedWhiteListWallet = createAction<{
    walletAddress: string;
    userSelection: UserSelectionDetails;
    whitelists: WhiteList[];
}>("API/WL_MINTED_ADDRESSES/DELETE_SELECTED_WHITELIST_WALLET");

export const updateExistingWhiteList = createAction<{
    userSelection: UserSelectionDetails;
    whitelists: WhiteList[];
    settings: WLCardSettingsConfiguration;
}>("API/WL_MINTED_ADDRESSES/UPDATE_EXISTING_WHITELIST");

export const updateWalletOnWhiteList = createAction<{
    whitelists: WhiteList[];
    userSelection: UserSelectionDetails;
    currentOperation: OperationDetails;
}>("API/WL_MINTED_ADDRESSES/UPDATE_WALLET_ON_WHITELIST");

export const updateWLConfigOnContractEdition = createAction<{
    userSelection: UserSelectionDetails;
    whitelists: WhiteList[];
}>("API/WL_MINTED_ADDRESSES/UPDATE_WL_CONFIG_ON_CONTRACT_EDITION");

export const isTransactionComplete = createAction<boolean>(
    "API/WL_MINTED_ADDRESSES/SET_TRANSACTION_STATUS",
);

export const updateWLSettingsDetails = createAction<
    Partial<WLCardSettingsConfiguration>
>("API/WL_MINTED_ADDRESSES/UPDATE_WL_SETTINGS_DETAILS");
