import { createAction } from "@reduxjs/toolkit";

export const downloadPDF = createAction<{
    dialogId: string;
    email?: string;
    user_id?: string;
    artwork_id: string | number;
    artwork_name: string;
    artist_name: string;
    step: string;
}>("API/PDF/DOWNLOAD");

export const printPDF = createAction<string>("API/PDF/PRINT");

export const downloadPDFByUrl = createAction<{
    url: string;
    contentType: string;
    fileName?: string;
}>("API/PDF/DOWNLOAD_BY_URL");
