import memoize from "lodash/memoize";
import { normalizeContracts } from "./normalizeContracts";
import { query } from "~/api/graphQL";
import { currentContractsQueryApiVersion } from "./currentContractsQueryApiVersion";
import { queryNetworkByApiVersion } from "~/BFF/blockchain/solidityContracts/queryNetworkByApiVersion";
import { v2FindContractByAddressQuery } from "~/BFF/blockchain/solidityContracts/v2FindContractByAddressQuery";

export const loadContractByAddress = memoize(
    async (
        contractAddress: string,
        apiVersion: ReturnType<
            typeof currentContractsQueryApiVersion
        > = currentContractsQueryApiVersion(),
    ) => {
        const [contract] = normalizeContracts(
            await query({
                networkId: queryNetworkByApiVersion(apiVersion),
                queryStr: v2FindContractByAddressQuery,
                variables: {
                    addressIlike: contractAddress,
                },
            }),
        ).splice(-1);

        return contract;
    },
    (contractAddress: string, apiVersion = currentContractsQueryApiVersion()) =>
        contractAddress + apiVersion,
);
