import {
    sellableRights,
    nonSellableRights,
    ROLE_CREATOR,
    ROLE_PUBLISHER,
    buyOutTokenRight,
} from "@liveart/rights-management";
import {
    Permission,
    Policy,
    RoyaltyReceiver,
    TokenType,
} from "~/api/data-schema";
import { supportedActionsToPolicies } from "~/api/ERC721";
import { web3AddressesEqual } from "~/api/web3";

export const LiveArtProjectPermissions = (
    publisher: string,
    currentUser: string,
): Permission[] =>
    web3AddressesEqual(publisher, currentUser)
        ? [
              {
                  role: ROLE_PUBLISHER,
                  wallet: publisher,
              },
          ]
        : [
              {
                  role: ROLE_PUBLISHER,
                  wallet: publisher,
              },
              {
                  role: ROLE_CREATOR,
                  wallet: currentUser,
              },
          ];

export const LiveArtProjectRoyaltyReceivers = (
    publisher: string,
    currentUser: string,
): RoyaltyReceiver[] =>
    web3AddressesEqual(publisher, currentUser)
        ? [
              {
                  role: ROLE_PUBLISHER,
                  wallet: publisher,
                  percentage: 100,
                  CAPPS: 0,
                  resalePercentage: 0,
                  fixedCut: 0,
              },
          ]
        : [
              {
                  role: ROLE_PUBLISHER,
                  wallet: publisher,
                  percentage: 15,
                  CAPPS: 0,
                  resalePercentage: 0,
                  fixedCut: 0,
              },
              {
                  role: ROLE_CREATOR,
                  wallet: currentUser,
                  percentage: 85,
                  CAPPS: 0,
                  resalePercentage: 0,
                  fixedCut: 0,
              },
          ];

export const LiveArtProjectTokenRights = (
    publisher: string,
    currentUser: string,
    tokenType: TokenType,
): Policy[] => [
    ...supportedActionsToPolicies(
        tokenType === TokenType.ERC721_WITH_BUY_OUT
            ? [...sellableRights, buyOutTokenRight]
            : sellableRights,
        ROLE_CREATOR,
        currentUser,
    ),
    ...supportedActionsToPolicies(nonSellableRights, ROLE_CREATOR, currentUser),
];
