export function trackExceptions(message: string, error?: Error): void {
    try {
        import("@sentry/nextjs").then((Sentry) => {
            console.info("SENTRY:", new Error().stack);
            console.error("SENTRY:", error?.message || message);

            if (error) {
                console.log(error.stack);
            }

            if (!process.env.NEXT_PUBLIC_VERCEL_ENV) {
                return;
            }
            if (error instanceof Error) {
                Sentry.captureException(error);
                return;
            }
            Sentry.captureMessage(message);
        });
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
}
