import { Action, createAction } from "@reduxjs/toolkit";

export const addToAuthenticationLine = createAction<
    [(payload?: unknown) => Action, unknown]
>("USER/ADD_TO_AUTHENTICATION_LINE");

export const authenticateWithMetamask = createAction<string | undefined>(
    "USER/AUTHENTICATE_WITH_METAMASK",
);
export const authenticateWithMetamaskResponse = createAction<string>(
    "USER/AUTHENTICATE_WITH_METAMASK_RESPONSE",
);
