import React, { useEffect, useMemo, useState } from "react";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { QRCode } from "react-qrcode-logo";
import { getApplicationFromHostname } from "../../host";
import LiveArtQrLogo from "./logoQrCode.png";
import LogoQrCodeICA from "./logoQrCodeICA.png";
import LogoQrCodeArtechouse from "./logoQrCodeArtechouse.png";
import LogoQrCodeArtuner from "./logoQrCodeArtuner.png";
import LogoQrCodeEphemeralEthernal from "./logoQrCodeEphemeralEthernal.png";

type QRCodeByApplicationProps = {
    link?: string;
};

export const QRCodeByApplication: React.FC<QRCodeByApplicationProps> = ({
    link = globalThis?.location?.href,
}) => {
    const size = 200;
    const application = getApplicationFromHostname();

    const logoImage = useMemo(() => {
        switch (application) {
            case SupportedApplication.artuner:
                return LogoQrCodeArtuner;
            case SupportedApplication.artechouse:
                return LogoQrCodeArtechouse;
            case SupportedApplication.icamiami:
                return LogoQrCodeICA;
            case SupportedApplication.ephemeralEternal:
                return LogoQrCodeEphemeralEthernal;
            default:
                return LiveArtQrLogo;
        }
    }, [application]);

    const [logoWidth, setLogoWidth] = useState(0);
    const [logoHeight, setLogoHeight] = useState(0);

    useEffect(() => {
        const img = new Image();
        img.src = logoImage;

        img.onload = function () {
            setLogoWidth(img.width);
            setLogoHeight(img.height);
        };
    }, [logoImage]);

    if (!logoWidth || !logoHeight) {
        return null;
    }

    return (
        <QRCode
            value={link}
            logoImage={logoImage}
            logoWidth={logoWidth}
            logoHeight={logoHeight}
            size={size}
            ecLevel="M"
            quietZone={0}
        />
    );
};
