import { getChainId } from "@liveart/nft-client/dist/web3";
import { fetchWithAuthHeaders } from "~/api/fetchWithHeaders/fetchWithAuthHeaders";
import { CustomHeaders } from "~/api/fetchWithHeaders/getHeaders";
import { JWTSupportedServices } from "~/BFF/jwtSupportedServices";
import { getWeb3Token } from "./web3/web3-token";
import { LogEvent } from "./data-schema";

export async function logEventOnBe(data: LogEvent, retry = 0) {
    const resp = await fetchWithAuthHeaders("/api/logger", {
        method: "POST",
        body: JSON.stringify(data),
    });

    if (resp.status >= 500 && retry <= 10) {
        return logEventOnBe(data, retry + 1);
    }

    if (!resp.ok) {
        throw new Error(await resp.text());
    }

    return undefined;
}

export async function getPinataJwtToken(
    {
        tokenContractAddress,
    }: {
        tokenContractAddress: string;
    },
    retry = 0,
) {
    const networkId = await getChainId();
    const searchParams = new URLSearchParams();
    searchParams.set("service", JWTSupportedServices.PINATA);
    searchParams.set("networkId", String(networkId));
    searchParams.set("tokenContractAddress", tokenContractAddress);

    const res = await fetchWithAuthHeaders(
        `/api/jwt?${searchParams.toString()}`,
        {
            headers: {
                [CustomHeaders.X_WEB3_TOKEN]: await getWeb3Token(),
            },
        },
    );

    if (res.status === 500 && retry <= 10) {
        return getPinataJwtToken(
            {
                tokenContractAddress,
            },
            retry + 1,
        );
    }

    if (!res.ok) {
        throw new Error(await res.text());
    }

    return res.text();
}
