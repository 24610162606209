import {
    Typography,
    AppBar,
    Toolbar,
    IconButton,
    AppBarProps,
    MenuProps,
} from "@mui/material";
import { styled } from "@mui/system";
import Link from "next/link";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { AuthModal } from "@liveart/injectables/dist/auth/AuthModal";
import { stytchAuthenticatedSelector } from "@liveart/auth";
import { StytchWalletMenu } from "@liveart/injectables/dist/header/web/liveart/WalletMenu/StytchWalletMenu";
import {
    CognitoPersistKeys,
    getValueFromStorage,
} from "@liveart/injectables/dist/user/getValueFromStorage";
import { AuthModalTrigger } from "@liveart/injectables/dist/header/web/liveart/Authentication/AuthModalTrigger";
import { useElementSize } from "usehooks-ts";
import { useShowDialog } from "@liveart/injectables/dist/dialog/useShowDialog";
import MobileDrawer from "./Drawer";
import Logo from "./logo.svg";
import DiscordICA from "~/features/icon/discordICA.svg";
import TwitterICA from "~/features/icon/twitterICA.svg";
import InstaICA from "~/features/icon/instagramICA.svg";
import { colors } from "~/theme";
import { showDialog } from "~/features/dialog";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    "@media (max-width: 767px)": {
        height: "56px",
        "& > .MuiToolbar-regular": {
            minHeight: "56px",
        },
    },

    ".MuiToolbar-root": {
        [theme.breakpoints.down(767)]: {
            justifyContent: "space-between",
        },
    },
}));
const LinkStyle = styled(Typography)(({ theme }) => ({
    color: "#9f94ae",
    fontFamily: "Arial",
    fontSize: "16px",
    cursor: "pointer",
    margin: "0px 20px",
    textUnderlineOffset: "4px",
    "&:hover": {
        textDecoration: "underline",
    },
    [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
        margin: "0px 15px",
    },
}));
const SocialWrap = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
const SocialButton = styled("a")({
    marginLeft: "15px",
    height: "24px",
});
const ButtonWrap = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});
const StyledToolbar = styled(Toolbar)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F1E2E0",
    padding: "15px 20px",
});
const DesktopMenu = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        display: "none !important",
    },
}));
const LinkStyleSelected = styled(Typography)(({ theme }) => ({
    color: "#6062c3",
    fontFamily: "Arial",
    fontSize: "16px",
    cursor: "pointer",
    margin: "0px 20px",
    textUnderlineOffset: "4px",
    [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
        margin: "0px 15px",
    },
}));
const MobileMenu = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        marginTop: "5px",
        marginLeft: "20px",
    },
    [theme.breakpoints.up("md")]: {
        display: "none",
    },
}));

interface Properties {
    position?: AppBarProps["position"];
    transitionDuration?: MenuProps["transitionDuration"];

    mobileDrawerOpen?: boolean;
}
export function ICAHeader({
    position = "fixed",
    transitionDuration,
    mobileDrawerOpen,
}: Properties) {
    const authModalOpen = useShowDialog("stytchDialog");
    const headerRef = React.useRef(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const accessToken = getValueFromStorage(CognitoPersistKeys.ACCESS_TOKEN);
    const stytchAuthenticated = useSelector(stytchAuthenticatedSelector);
    const isAuthenticated = accessToken || stytchAuthenticated;

    const handleOpenUserMenu = useCallback(() => {
        setAnchorElUser(headerRef.current);
    }, []);
    const handleCloseUserMenu = useCallback(() => {
        setAnchorElUser(null);
    }, []);

    const [appBarRef, { height: headerHeight }] = useElementSize();

    const dispatch = useDispatch();
    const showAuthModal = useCallback(() => {
        dispatch(showDialog("stytchDialog"));
    }, [dispatch]);

    return (
        <>
            <Box height={headerHeight} ref={headerRef} />
            <StyledAppBar
                position={position}
                data-target="header"
                data-testid="ica-header"
                style={{
                    boxShadow: "none",
                    backgroundColor: colors.pinkBackground,
                }}
                ref={appBarRef}
            >
                <StyledToolbar>
                    <Link
                        href="/"
                        passHref
                        data-testid="ica-header-home-logo-link"
                    >
                        <IconButton disableRipple>
                            <Logo data-testid="ica-header-logo" />
                        </IconButton>
                    </Link>

                    <DesktopMenu alignItems="center" display="flex">
                        <Box
                            component="a"
                            href="https://365.icamiami.org/"
                            data-testid="ica-header-home-link"
                        >
                            <LinkStyle>Home</LinkStyle>
                        </Box>

                        <Box
                            component="a"
                            href="https://nft.icamiami.org"
                            data-testid="ica-header-nft-auction-link"
                        >
                            <LinkStyleSelected>NFT Auction</LinkStyleSelected>
                        </Box>
                        <Box
                            component="a"
                            href="https://365.icamiami.org/#about-the-artist"
                            data-testid="ica-header-about-artist-link"
                        >
                            <LinkStyle>About the Artist</LinkStyle>
                        </Box>
                        <Box
                            component="a"
                            href="https://365.icamiami.org/#honoree"
                            data-testid="ica-header-honoree-link"
                        >
                            <LinkStyle>Honoree</LinkStyle>
                        </Box>
                        <Box
                            component="a"
                            href="https://365.icamiami.org/#tickets-and-tables"
                            data-testid="ica-header-ticket-link"
                        >
                            <LinkStyle>Tickets + Tables</LinkStyle>
                        </Box>

                        <SocialWrap data-testid="ica-header-socials">
                            <SocialButton
                                href="https://discord.com/invite/3Jn7vSHXbZ"
                                target="_blank"
                                rel="noreferrer"
                                data-testid="ica-header-discord-link"
                            >
                                <DiscordICA />
                            </SocialButton>
                            <SocialButton
                                href="https://twitter.com/PalmNft"
                                target="_blank"
                                rel="noreferrer"
                                data-testid="ica-header-twitter-link"
                            >
                                <TwitterICA />
                            </SocialButton>

                            <SocialButton
                                href="https://www.instagram.com/palmnft/"
                                target="_blank"
                                rel="noreferrer"
                                data-testid="ica-header-instagram-link"
                            >
                                <InstaICA />
                            </SocialButton>
                        </SocialWrap>
                    </DesktopMenu>

                    <ButtonWrap>
                        <DesktopMenu>
                            {isAuthenticated && (
                                <Box data-target="cognito-user-menu">
                                    <Box sx={{ padding: 0 }}>
                                        <IconButton
                                            data-target="cognito-user-menu-icon"
                                            onClick={handleOpenUserMenu}
                                            disableRipple
                                        >
                                            <Box
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    whiteSpace: "nowrap",
                                                    color: "#000",
                                                }}
                                            >
                                                My Account
                                            </Box>
                                        </IconButton>
                                    </Box>
                                    <StytchWalletMenu
                                        transitionDuration={transitionDuration}
                                        anchorElUser={anchorElUser}
                                        handleCloseUserMenu={
                                            handleCloseUserMenu
                                        }
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        hideItems={{
                                            MyDeals: true,
                                            ExternalWallets: true,
                                            MyNFTs: true,
                                        }}
                                    />
                                </Box>
                            )}

                            <Box
                                sx={{
                                    display: isAuthenticated ? "none" : "flex",
                                }}
                                onClick={showAuthModal}
                            >
                                <AuthModalTrigger />
                            </Box>
                        </DesktopMenu>

                        <MobileMenu>
                            <MobileDrawer openState={mobileDrawerOpen} />
                        </MobileMenu>
                    </ButtonWrap>
                </StyledToolbar>
            </StyledAppBar>

            <AuthModal trigger={<></>} open={authModalOpen} />
        </>
    );
}
