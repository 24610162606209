import React, { Component } from "react";
import { PreLoaderByApplication } from "~/WhiteLabel/features";
import { ContentWrapper } from "~/features/contentWrapper";

export const AppErrorBoundary = class extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state: {
        error?: Error;
    } = {};

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            return (
                <ContentWrapper>
                    <PreLoaderByApplication />
                </ContentWrapper>
            );
        }

        return <div>{children}</div>;
    }
};
