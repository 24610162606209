import { createReducer } from "@reduxjs/toolkit";
import {
    TokenDataSchema,
    PaginatedTokenDataSchema,
} from "~/api/ERC721v4/TokenDataJSONSchema";
import {
    fetchTokenMetadataResponse,
    fetchTokenMetadata,
    fetchTokenListByAddress,
    fetchTokenListByAddressResponse,
    setEditionNames,
} from "~/api/choreography";

export type WithERC721v4State = {
    erc721v4: ERC721v4State;
};

export type ERC721v4State = {
    tokenData: TokenDataSchema;
    myNFTs: PaginatedTokenDataSchema;
    loading: boolean;
    editionNames: string[];
};

export const defaultERC721v4State: ERC721v4State = {
    tokenData: {} as TokenDataSchema,
    myNFTs: [] as PaginatedTokenDataSchema,
    loading: true,
    editionNames: [] as string[],
};

export const erc721v4Reducer = createReducer<ERC721v4State>(
    defaultERC721v4State,
    (builder) => {
        builder.addCase(fetchTokenListByAddress, (state) => ({
            ...state,
            loading: true,
        }));
        builder.addCase(fetchTokenListByAddressResponse, (state, action) => ({
            ...state,
            myNFTs: action.payload,
            loading: false,
        }));
        builder.addCase(fetchTokenMetadata, (state) => ({
            ...state,
            loading: false,
        }));
        builder.addCase(fetchTokenMetadataResponse, (state, action) => ({
            ...state,
            tokenData: action.payload,
            loading: false,
        }));
        builder.addCase(setEditionNames, (state, action) => ({
            ...state,
            editionNames: action.payload,
        }));
    },
);

export const erc721v4StateSelector = (state: WithERC721v4State) =>
    state.erc721v4;
