import { call, takeEvery } from "redux-saga/effects";
import memoize from "lodash/memoize";
import { mintToken } from "~/api/choreography";
import { Unpromisify } from "~/typescript";

const importSagaModule = memoize(
    () => import("./ERC721/mintTokenSaga"),
    () => 1,
);

export function* mintTokenApiSaga() {
    yield takeEvery(
        mintToken.toString(),
        function* withMintTokenSaga(action: ReturnType<typeof mintToken>) {
            const { mintTokenSaga } = (yield call(
                importSagaModule,
            )) as Unpromisify<ReturnType<typeof importSagaModule>>;

            yield call(mintTokenSaga, action);
        },
    );
}
