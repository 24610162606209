import { createTheme, ThemeOptions, Theme } from "@mui/material/styles";
import { colors } from "./color/colors";

// Create a theme instance.
const defaultTheme = createTheme({
    palette: {
        primary: {
            main: colors.black,
        },
        secondary: {
            main: colors.blackNeutral,
        },
        error: {
            main: "#FF3B2F",
        },
        success: {
            main: "#4DD964",
        },
        background: {
            default: "#fff",
        },

        text: {
            primary: colors.textBlack,
            secondary: colors.textBlack,
        },
    },

    typography: {
        fontFamily: "Fira Sans",

        h1: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 50,
            lineHeight: "120%",
            letterSpacing: "-0.02em",
        },

        h2: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 40,
            lineHeight: "120%",
            letterSpacing: "-0.02em",
        },

        h3: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 22,
            lineHeight: "120%",
            letterSpacing: "-0.02em",
        },

        h4: {
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 20,
            lineHeight: "130%",
            letterSpacing: "-0.02em",
        },
    },
});

const { breakpoints } = defaultTheme;
export const theme: Theme = createTheme({
    ...defaultTheme,

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                a: {
                    textDecoration: "none",
                    color: "unset",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                colorPrimary: {
                    color: "#000",
                },
                h1: {
                    [breakpoints.down("xs")]: {
                        fontSize: 50,
                    },
                },
                h2: {
                    [breakpoints.down("xs")]: {
                        fontSize: 32,
                    },
                },
                h3: {
                    [breakpoints.down("xs")]: {
                        fontSize: 22,
                    },
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                iconSizeSmall: {
                    "& > *:first-child": null,
                },
                iconSizeMedium: {
                    "& > *:first-child": null,
                },
                iconSizeLarge: {
                    "& > *:first-child": null,
                },
            },
        },
    },
} as unknown as ThemeOptions);

export const themePink: Theme = createTheme(theme as unknown as ThemeOptions, {
    palette: {
        primary: {
            main: colors.pink,
        },
        secondary: {
            main: colors.purple,
        },
        error: {
            main: "#FF3B2F",
        },
        success: {
            main: "#4DD964",
        },
        background: {
            default: colors.pinkBackground,
        },

        text: {
            primary: colors.textBlack,
            secondary: colors.textBlack,
        },
    },
});
