import { AnalyticsBrowser } from "@segment/analytics-next";
import { getUseAuthModalDevEnvFeatureFlag } from "@liveart/injectables/dist/auth/authConfigReducer";
import { Event, SegmentOption } from "./segmentEvents";
import { getUtms } from "../getUtms";

const devTrackingId = "1pkKvSgYxVbT2qpxUj1CGqmYPov6xaIG";
const prodTrackingId = "N5WKYA6kWB5gaKtYpKomcqmVqUp0omuz";

export async function trackSegment(payload: Event) {
    const trackingId = getUseAuthModalDevEnvFeatureFlag()
        ? devTrackingId
        : prodTrackingId;

    const analytics = AnalyticsBrowser.load({
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY || trackingId,
    });

    const additonalProp = { ...getUtms(), platform: "web" };
    if (payload.option === SegmentOption.identify) {
        await analytics.identify(payload.eventName, {
            ...payload.properties,
            ...additonalProp,
        });
    } else {
        await analytics.track(payload.eventName, {
            ...payload.properties,
            ...additonalProp,
        });
    }
}
